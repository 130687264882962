<template>
  <BaseLayout title="Global settings">
    <div class="flex flex-col gap-2 mb-6">
      <label for="minVersion"
        >Require minimum version: {{ appConfigVersion }}</label
      >
    </div>
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import { useToast } from "primevue/usetoast";
import { onMounted, ref } from "vue";
import { getAppConfig } from "@/modules/settings/api";

let appConfigVersion = ref({});

const toast = useToast();
const showToast = (data: object) => {
  toast.add({
    severity: data.severity ?? "error",
    summary: data?.summary ?? "Error",
    detail: data?.msg,
    life: data.life ?? 10000,
  });
};

onMounted(async () => {
  const appConfigResult = await getAppConfig();
  if (appConfigResult?.error) {
    showToast({ msg: appConfigResult.error });
  } else {
    appConfigVersion.value = appConfigResult.app_version || "";
  }
});
</script>
