/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminOrganization,
  AdminV1OrganizationsListParams,
  CopyTFAContentsRequest,
  CopyTrainingCollectionsStatusResponse,
  PaginatedAdminOrganizationList,
  PatchedAdminOrganization
} from '.././model'
import adminV1OrganizationsListMutator from '../../shared/services/axios-client';
import adminV1OrganizationsCreateMutator from '../../shared/services/axios-client';
import adminV1OrganizationsRetrieveMutator from '../../shared/services/axios-client';
import adminV1OrganizationsUpdateMutator from '../../shared/services/axios-client';
import adminV1OrganizationsPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1OrganizationsDestroyMutator from '../../shared/services/axios-client';
import adminV1OrganizationsCopyTfaContentCreateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1OrganizationsList = (
    params?: AdminV1OrganizationsListParams,
 options?: SecondParameter<typeof adminV1OrganizationsListMutator>,) => {
      return adminV1OrganizationsListMutator<PaginatedAdminOrganizationList>(
      {url: `/api/admin/v1/organizations/`, method: 'GET',
        params
    },
      options);
    }
  export const adminV1OrganizationsCreate = (
    adminOrganization: NonReadonly<AdminOrganization>,
 options?: SecondParameter<typeof adminV1OrganizationsCreateMutator>,) => {
      return adminV1OrganizationsCreateMutator<AdminOrganization>(
      {url: `/api/admin/v1/organizations/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminOrganization
    },
      options);
    }
  export const adminV1OrganizationsRetrieve = (
    id: number,
 options?: SecondParameter<typeof adminV1OrganizationsRetrieveMutator>,) => {
      return adminV1OrganizationsRetrieveMutator<AdminOrganization>(
      {url: `/api/admin/v1/organizations/${id}/`, method: 'GET'
    },
      options);
    }
  export const adminV1OrganizationsUpdate = (
    id: number,
    adminOrganization: NonReadonly<AdminOrganization>,
 options?: SecondParameter<typeof adminV1OrganizationsUpdateMutator>,) => {
      return adminV1OrganizationsUpdateMutator<AdminOrganization>(
      {url: `/api/admin/v1/organizations/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminOrganization
    },
      options);
    }
  export const adminV1OrganizationsPartialUpdate = (
    id: number,
    patchedAdminOrganization: NonReadonly<PatchedAdminOrganization>,
 options?: SecondParameter<typeof adminV1OrganizationsPartialUpdateMutator>,) => {
      return adminV1OrganizationsPartialUpdateMutator<AdminOrganization>(
      {url: `/api/admin/v1/organizations/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminOrganization
    },
      options);
    }
  export const adminV1OrganizationsDestroy = (
    id: number,
 options?: SecondParameter<typeof adminV1OrganizationsDestroyMutator>,) => {
      return adminV1OrganizationsDestroyMutator<void>(
      {url: `/api/admin/v1/organizations/${id}/`, method: 'DELETE'
    },
      options);
    }
  export const adminV1OrganizationsCopyTfaContentCreate = (
    id: number,
    copyTFAContentsRequest: CopyTFAContentsRequest,
 options?: SecondParameter<typeof adminV1OrganizationsCopyTfaContentCreateMutator>,) => {
      return adminV1OrganizationsCopyTfaContentCreateMutator<CopyTrainingCollectionsStatusResponse>(
      {url: `/api/admin/v1/organizations/${id}/copy_tfa_content/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: copyTFAContentsRequest
    },
      options);
    }
  export type AdminV1OrganizationsListResult = NonNullable<Awaited<ReturnType<typeof adminV1OrganizationsList>>>
export type AdminV1OrganizationsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1OrganizationsCreate>>>
export type AdminV1OrganizationsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1OrganizationsRetrieve>>>
export type AdminV1OrganizationsUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1OrganizationsUpdate>>>
export type AdminV1OrganizationsPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1OrganizationsPartialUpdate>>>
export type AdminV1OrganizationsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1OrganizationsDestroy>>>
export type AdminV1OrganizationsCopyTfaContentCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1OrganizationsCopyTfaContentCreate>>>
