<template>
  <BaseLayout
    :title="team?.name ? `Team: ${team?.name}` : ''"
    :breadcrumb="[
      { label: 'Organizations', to: { name: ROUTE_NAME.ORGANIZATIONS } },
      {
        label: team?.organization_name,
        to: {
          name: ROUTE_NAME.ORGANIZATION_UPDATE,
          params: { id: team?.organization },
        },
      },
      {
        label: 'Teams',
        to: {
          name: ROUTE_NAME.ORGANIZATION_UPDATE,
          params: { id: team?.organization },
        },
      },
      { label: team?.name },
    ]"
  >
    <InitialPageLoader :loading="false" list />
    <OrganizationForm />
  </BaseLayout>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import InitialPageLoader from "@/shared/components/InitialPageLoader.vue";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import OrganizationForm from "../components/TeamsForm.vue";
import { ROUTE_NAME } from "../../../shared/constants/routes";
import type { AdminTeam } from "../../../api/model";
import { getTeam } from "../api";
import { useRoute } from "vue-router";
const route = useRoute();
const team = ref<AdminTeam>();
const teamId = parseInt((route?.params?.teamId || 0).toString());

onMounted(async () => {
  const response = await getTeam(teamId);
  if ("error" in response) {
    console.log(response.error);
  } else {
    team.value = response;
  }
});
</script>
