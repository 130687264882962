<template>
  <div :style="props.maxWidth ? `width: ${props.maxWidth}px` : undefined">
    <div v-if="props.label" class="mb-2">
      {{ props.label }}
    </div>

    <div
      v-if="!assetImageUrl"
      class="image-placeholder"
      @click="onUpdate"
    ></div>

    <div v-if="assetImageUrl" class="position-relative">
      <div class="image-preview-box relative flex items-center justify-center">
        <img :src="assetImageUrl" class="rounded-lg" alt="image" />
      </div>
    </div>

    <div v-if="uploadProgress > 0 && uploadProgress < 100" class="col-span-12">
      <ProgressBar :value="uploadProgress" />
    </div>

    <input
      ref="inputRef"
      type="file"
      hidden
      :accept="allowedImageFileExtensionsByKey"
      @change="onFilePicked"
    />
    <div class="flex mt-6">
      <AppButton
        class="py-1 px-2 text-xs mr-2"
        :disabled="isLoading"
        :label="!!assetImageUrl ? 'Update' : 'Add'"
        @click="onUpdate"
      />
      <AppButton
        v-if="!!assetImageUrl && props.allowDeleteButton"
        severity="danger"
        class="py-1 px-2 text-xs"
        :disabled="isLoading"
        label="Delete"
        @click="onDelete"
      />
    </div>

    <div v-if="props.disclaimerText" class="mt-6 text-sm italic">
      {{ props.disclaimerText }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watchEffect } from "vue";
import { uploadFile } from "@/shared/services/file-upload";
import { UploadableFile } from "@/shared/datamodels/uploadableFile";
import { getThumbnailUrl } from "@/shared/utils/helpers";
import type AssetFile from "@/shared/datamodels/assetFile";
import { useToast } from "primevue/usetoast";
import ProgressBar from "primevue/progressbar";
import {
  allowedImageTypes,
  allowedImageFileExtensionsByKey,
} from "@/shared/constants/uploader";

const emit = defineEmits(["uploadComplete", "uploadFailed", "requestDelete"]);

let uploadProgress = ref(0);

const uploadStatus = (d: any) => {
  uploadProgress.value = d.progress;
};

const toast = useToast();

const props = defineProps<{
  imageData?: AssetFile;
  organizationId: number;
  label?: string;
  thumbnailWidth?: number;
  allowDeleteButton?: boolean;
  disclaimerText?: string;
  maxWidth?: number;
}>();

const assetImage = ref<AssetFile | undefined>();

watchEffect(() => (assetImage.value = props.imageData));

const inputRef = ref<HTMLInputElement | null>();
const isLoading = ref(false);

const assetImageUrl = computed(() => {
  if (assetImage.value?.thumbnail_url_tpl) {
    return getThumbnailUrl(
      assetImage.value.thumbnail_url_tpl,
      props.thumbnailWidth ?? 200
    );
  } else {
    return undefined;
  }
});

onMounted(() => {
  if (props.imageData) {
    assetImage.value = props.imageData;
  }
});

const onUpdate = () => {
  inputRef.value?.click();
};

const onDelete = async () => {
  if (!assetImage.value?.id) {
    return;
  }

  emit("requestDelete", { assetId: assetImage.value.id });
};

const onFilePicked = async (event: Event) => {
  if (!event.target) {
    return;
  }
  const files = (event.target as HTMLInputElement).files;
  if (!files || files.length == 0) {
    return;
  }
  const file = new UploadableFile(files[0]);

  const matchesType = allowedImageTypes[file?.file?.type];

  if (!matchesType) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Acceptable file types: ${allowedImageFileExtensionsByKey}`,
      life: 10000,
    });
    return;
  }
  isLoading.value = true;

  try {
    const asset = await uploadFile(
      props.organizationId,
      new UploadableFile(files[0]),
      uploadStatus
    );

    emit("uploadComplete", { assetId: asset.id, asset: asset });
    isLoading.value = false;
  } catch (e: any) {
    emit("uploadFailed", { error: e.message });
    isLoading.value = false;
  }
};
</script>

<style scoped>
.image-preview-box {
  width: 140px;
  height: 140px;
}

.image-preview-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
</style>
