import { storeToRefs } from "pinia";
import { useOrganizationStoreV2 } from "@/modules/organizations/stores/organizationsStoreV2";
import type { Ref } from "vue";

/* 
    This composable is used to fetch and return the organizations and default organization from the organization store. 
    */
export interface Organizations {
  organizations: Ref<
    ReturnType<typeof useOrganizationStoreV2>["organizations"]
  >;
  defaultOrganization: Ref<
    ReturnType<typeof useOrganizationStoreV2>["defaultOrganization"]
  >;
  loading: Ref<ReturnType<typeof useOrganizationStoreV2>["loading"]>;
  error: Ref<ReturnType<typeof useOrganizationStoreV2>["error"]>;
}

export function useOrganizations(): Organizations {
  const organizationStore = useOrganizationStoreV2();
  const { organizations, defaultOrganization, loading, error } =
    storeToRefs(organizationStore);

  return {
    organizations,
    defaultOrganization,
    loading,
    error,
  };
}
