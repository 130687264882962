export const MAGIC_LINK_SENT_COPY =
  "Magic link sent to your email. It expires shortly so please use it soon.";
export const NOT_ADMIN_ERROR_COPY =
  "You are not an administrator. Log in with an administrator account or if you think this is in error, contact the system administrator.";

export const TOKEN_EXPIRED_ERROR_COPY =
  "Your token has expired. Please generate a new Magic Link.";

export const EMAIL_REQUIRED_MAGIC_LINK_ERROR =
  "Email is required to generate a magic link.";
