<template>
  <div class="mb-4 flex items-center space-x-2 pt-2">
    <Checkbox
      v-model="selectAll"
      @change="selectAllItems"
      data-testid="select-all-checkbox"
      inputId="select-all"
      :binary="true"
    />
    <div class="relative flex-grow">
      <IconField>
        <InputText
          v-model="localSearchQuery"
          :placeholder="searchPlaceholder"
          class="w-full pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <InputIcon class="pi pi-search" />
      </IconField>
    </div>
  </div>
  <div class="p-4 rounded-lg border border-gray-300">
    <div class="space-y-2 rounded-md">
      <div
        v-for="item in filteredItems"
        :key="item.id"
        class="flex items-center space-x-2 p-2 hover:bg-gray-200 rounded-md"
      >
        <Checkbox
          v-model="localSelectedItems"
          :value="item"
          :inputId="item.id.toString()"
          @change="updateSelection"
        />
        <label :for="item.id.toString()" class="flex-grow cursor-pointer">{{
          item.name
        }}</label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import InputIcon from "primevue/inputicon";
import IconField from "primevue/iconfield";

interface SelectableItem {
  id: number | string;
  name: string;
}

const props = defineProps<{
  items: SelectableItem[];
  selectedItems: SelectableItem[];
  searchPlaceholder?: string;
}>();

const emit = defineEmits<{
  (e: "update:selectedItems", items: SelectableItem[]): void;
}>();

const localSearchQuery = ref("");
const localSelectedItems = ref<SelectableItem[]>([]);
const selectAll = ref(false);

const selectAllItems = () => {
  localSelectedItems.value = selectAll.value ? [...props.items] : [];
  updateSelection();
};

watch(
  () => props.selectedItems,
  (newValue) => {
    localSelectedItems.value = [...newValue];
    selectAll.value = localSelectedItems.value.length === props.items.length;
  },
  { immediate: true }
);

const filteredItems = computed(() => {
  return props.items.filter((item) =>
    item.name.toLowerCase().includes(localSearchQuery.value.toLowerCase())
  );
});

const updateSelection = () => {
  emit("update:selectedItems", localSelectedItems.value);
  selectAll.value = localSelectedItems.value.length === props.items.length;
};
</script>
