import { type RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import ViewStatCategories from "@/modules/stats/views/ViewStatCategories.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/stats/categories",
    name: ROUTE_NAME.STATS_CATEGORIES,
    component: ViewStatCategories,
  },
];
