<template>
  <ul class="menu-ul">
    <li v-for="(item, i) in menuItems" :key="i">
      <a
        v-if="item.value === undefined"
        href="#"
        class="menu-link"
        @click.prevent
        @click="
          () => {
            onToggleOpen(item.title);
          }
        "
      >
        <div>
          <i class="menu-link-icon" :class="[item.icon]" />
          <span>{{ item.title }}</span>
        </div>
        <i
          v-if="item.children && item.children.length > 0"
          class="menu-toggle-icon pi pi-angle-down"
        />
      </a>
      <router-link
        v-else
        :to="{ name: item.value, params: item.params ?? {} }"
        class="menu-link"
        @click="
          () => {
            item.addEvent && item.addEvent();
          }
        "
      >
        <div>
          <i class="menu-link-icon" :class="[item.icon]" />
          <span>{{ item.title }}</span>
        </div>
        <i
          v-if="item.children && item.children.length > 0"
          class="menu-toggle-icon pi pi-angle-down"
        />
      </router-link>
      <ul
        v-if="item.children && item.children.length > 0"
        class="menu-link-children"
        :class="{ opened: opened.includes(item.title) }"
      >
        <li v-for="(child, k) in item.children" :key="k">
          <router-link
            :to="{ name: child.value }"
            class="menu-link"
            @click="
              () => {
                child.addEvent && child.addEvent();
              }
            "
          >
            {{ child.title }}
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";

import { useAuthStore } from "@/modules/auth/stores/auth";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { addMixpanelEvent, EVENT_NAMES } from "../utils/analytics";
import { useCurrentUser } from "@/composables/useCurrentUser";

const authStore = useAuthStore();
const { isSingleOrgUser, isStaff, defaultOrg } = storeToRefs(authStore);
const { fetchOrganizations, defaultOrganization } = useCurrentUser();

const opened = ref<string[]>([]);

const onToggleOpen = (title: string) => {
  const index = opened.value.indexOf(title);
  if (index !== -1) {
    opened.value.splice(index, 1);
  } else {
    opened.value.push(title);
  }
};

onMounted(async () => {
  if (!defaultOrganization.value) await fetchOrganizations();
});

const DEFAULT_MENU_ITEMS = [
  {
    icon: "pi pi-book",
    title: "Feed",
    value: ROUTE_NAME.FEED,
    addEvent: () => {
      addMixpanelEvent(EVENT_NAMES.Navigation.NavAdminFeed);
    },
  },
  {
    icon: "pi pi-users",
    title: "Training",
    value: ROUTE_NAME.TRAINING_COLLECTION_LIST,
    addEvent: () => {
      addMixpanelEvent(EVENT_NAMES.Navigation.NavAdminTraining);
    },
  },
  {
    icon: "pi pi-video",
    title: "Video Submissions",
    value: ROUTE_NAME.CHAT_LIST,
    addEvent: () => {
      addMixpanelEvent(EVENT_NAMES.Navigation.NavAdminSubmissions);
    },
  },
  {
    icon: "pi pi-file",
    title: "Workouts & Exercises",
    group: "calendars",

    children: [
      {
        icon: "pi pi-file",
        title: "Workouts",
        value: ROUTE_NAME.WORKOUT_LIST,
        addEvent: () => {
          addMixpanelEvent(EVENT_NAMES.Navigation.NavAdminWorkouts);
        },
      },
      {
        icon: "pi pi-file",
        title: "Exercises",
        value: ROUTE_NAME.EXERCISE_LIST,
        addEvent: () => {
          addMixpanelEvent(EVENT_NAMES.Navigation.NavAdminExercises);
        },
      },
    ],
  },
  {
    icon: "pi pi-users",
    title: "Users",
    value: ROUTE_NAME.USERS,
    addEvent: () => {
      addMixpanelEvent(EVENT_NAMES.Navigation.NavAdminUsers);
    },
  },
  {
    icon: "pi pi-chart-line",
    title: "Stats",
    value: ROUTE_NAME.STATS_CATEGORIES,
    addEvent: () => {
      addMixpanelEvent(EVENT_NAMES.Navigation.NavAdminStats);
    },
  },
];

const menuItems = computed(() => {
  const items = [...DEFAULT_MENU_ITEMS];

  if (isSingleOrgUser.value && defaultOrg.value.name) {
    items.push({
      icon: "pi pi-users",
      title: "Teams",
      value: ROUTE_NAME.ORGANIZATION_UPDATE,
      params: { id: defaultOrg.value.id },
      addEvent: () => {
        addMixpanelEvent(EVENT_NAMES.Navigation.NavAdminTeams);
      },
    });
  } else {
    items.push({
      icon: "pi pi-users",
      title: "Organizations",
      value: ROUTE_NAME.ORGANIZATION_LIST,
    });
  }

  if (defaultOrganization?.value?.payments_enabled) {
    items.push({
      icon: "pi pi-box",
      title: "Payments & Invoices",
      group: "admin",
      value: ROUTE_NAME.PAYMENT_INVOICES,
    });
  }

  if (isStaff.value) {
    items.push({
      icon: "pi pi-box",
      title: "Administration",
      group: "admin",
      children: [
        {
          icon: "pi pi-database",
          title: "Global settings",
          value: ROUTE_NAME.GLOBAL_SETTINGS,
        },
      ],
    });
  }

  return items;
});
</script>

<style scoped>
.menu-ul {
  list-style: none;
  padding: 0;
}

.menu-link-children {
  list-style: none;
  padding: 0 0 0 12px;
  margin: 0 0 0 22px;
  border-left: 1px solid var(--surface-200);
  display: none;
}

.menu-link-children.opened {
  display: block;
}

.menu-link-children a {
  font-weight: normal;
}

.menu-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 0.8rem;
  color: black;
  padding: 8px 10px;
  border-radius: 6px;
  text-decoration: none;
  border: 1px solid transparent;
  margin-bottom: 5px;
}

.menu-link i.menu-link-icon {
  color: black;
  font-size: 14px;
  border: 1px solid var(--surface-200);
  border-radius: 4px;
  padding: 4px;
  margin-right: 10px;
}

.menu-link i.menu-toggle-icon {
  color: #333;
  font-size: 0.8rem;
}

.menu-link:hover {
  background: var(--surface-50);
}

.menu-link:focus {
  border: 1px solid var(--primary-500);
}

.menu-link.router-link-active {
  background: var(--highlight-bg);
  color: var(--primary-700);
}

.menu-link.router-link-active i {
  color: var(--primary-700);
}
</style>
