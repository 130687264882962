<template>
  <BaseLayout
    :title="userName ? `User: ${userName}` : ''"
    :breadcrumb="[
      { label: 'Users', to: { name: ROUTE_NAME.USER_LIST } },
      { label: userName },
    ]"
  >
    <InitialPageLoader :loading="false" list />
    <UserForm @user="(d) => (userName = d.userName)" />
  </BaseLayout>
</template>

<script setup lang="ts">
import { ref } from "vue";
import InitialPageLoader from "@/shared/components/InitialPageLoader.vue";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import UserForm from "../components/UserForm.vue";
import { ROUTE_NAME } from "../../../shared/constants/routes";
const userName = ref("");
</script>
