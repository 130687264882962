import { RouterView, type RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import List from "./views/OrganizationList.vue";
import Update from "./views/OrganizationUpdate.vue";
import Create from "./views/OrganizationCreate.vue";
import { h } from "vue";
import TeamUpdate from "../teams/views/TeamsUpdate.vue";
import TeamCreate from "../teams/views/TeamsCreate.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/organizations",
    name: ROUTE_NAME.ORGANIZATIONS,
    component: { render: () => h(RouterView) },
    redirect: { name: ROUTE_NAME.ORGANIZATION_LIST },
    children: [
      {
        path: "",
        name: ROUTE_NAME.ORGANIZATION_LIST,
        component: List,
      },
      {
        path: ":id",
        name: ROUTE_NAME.ORGANIZATION_UPDATE,
        component: Update,
      },
      {
        path: "create",
        name: ROUTE_NAME.ORGANIZATION_CREATE,
        component: Create,
      },

      {
        path: ":id/teams/:teamId",
        name: ROUTE_NAME.TEAM_UPDATE,
        component: TeamUpdate,
      },
      {
        path: ":id/teams/create",
        name: ROUTE_NAME.TEAM_CREATE,
        component: TeamCreate,
      },
    ],
  },
];
