<template>
  <Avatar
    v-if="profileImage"
    :image="profileImage"
    size="large"
    shape="circle"
    class="avatar-object-fit"
  />
  <div v-else-if="!userInitials" class="pi pi-user"></div>
  <UserAvatar
    v-else
    :initials="userInitials"
    class="cursor-pointer"
    aria-haspopup="true"
    aria-controls="profile-menu"
  />
</template>

<script setup lang="ts">
import Avatar from "primevue/avatar";
import UserAvatar from "@/shared/components/UserAvatar.vue";
import { useAuthStore } from "@/modules/auth/stores/auth";
const { userInitials } = storeToRefs(useAuthStore());
import { storeToRefs } from "pinia";
import { useAdminAvatar } from "@/modules/profile/stores/adminAvatar";
const { profileImage } = storeToRefs(useAdminAvatar());
</script>
