<template>
  <BaseLayout
    :breadcrumb="[
      {
        label: 'Training',
        to: { name: ROUTE_NAME.TRAINING_COLLECTION_LIST },
      },
    ]"
    :title="Training"
  >
    <InitialPageLoader
      :loading="isLoading"
      list
      :message="
        trainingCollections.length === 0
          ? 'No Training Collections found.'
          : undefined
      "
    />
    <div v-if="!isLoading" class="flex justify-end">
      <AppButton
        label="Create Collection"
        @click="createTrainingCollectionIdAndRedirect"
      />
    </div>

    <div
      class="bg-white rounded-border p-12 shadow-sm mt-6 animate-fadein animate-duration-1000"
    >
      <div class="flex justify-end">
        <Button
          v-if="!isReorderTrainingCollections"
          icon="pi pi-bars"
          class="border-0 mb-4"
          text
          label="Reorder Training Collections"
          severity="secondary"
          @click="
            async () => {
              isReorderTrainingCollections = true;
              filter.limit = 100000;
              filter.offset = 0;
              await fetch();
            }
          "
        />

        <Button
          v-if="isReorderTrainingCollections"
          icon="pi pi-bars"
          text
          class="border-0 mb-4"
          label="Disable Reorder Training Collections"
          severity="secondary"
          @click="
            async () => {
              isReorderTrainingCollections = false;
              filter.limit = defaultLimit;
              filter.offset = 0;
              await fetch();
            }
          "
        />
      </div>

      <DataTable
        v-if="!isReorderTrainingCollections"
        :value="trainingCollections"
        striped-rows
        row-hover
        selection-mode="single"
        @row-click="
          (e) => {
            addMixpanelEvent(EVENT_NAMES.Navigation.NavAdminTrainingCollection);
            router.push({
              name: ROUTE_NAME.TRAINING_COLLECTION_UPDATE,
              params: { id: e?.data?.id },
            });
          }
        "
      >
        <Column header="Image" style="width: 150px">
          <template #body="slotProps">
            <UserAvatar
              :asset="getFirstAsset(slotProps?.data)"
              :initials="getNameInitials(slotProps?.data?.name)"
            />
          </template>
        </Column>
        <Column field="name" header="Name">
          <template #body="slotProps">
            <span>{{ slotProps?.data?.name }} </span>
          </template>
        </Column>
        <Column header="Drills">
          <template #body="slotProps">
            <span>{{ slotProps?.data?.drill_count }} drills</span>
          </template>
        </Column>
        <Column header="Videos">
          <template #body="slotProps">
            <span>{{ slotProps?.data?.comparison_video_count }} videos</span>
          </template>
        </Column>
        <Column header="Last updated">
          <template #body="slotProps">
            <span>{{ formatDate(slotProps?.data?.updated_at) }}</span>
          </template>
        </Column>
      </DataTable>

      <div v-if="isReorderTrainingCollections">
        <div class="mb-6">
          <strong
            >Drag Training Collections to put them in the proper order</strong
          >
        </div>
        <div class="rounded-md shadow-md">
          <div class="p-4 bg-surface-50">
            <div>Training Collections</div>
          </div>
          <Sortable
            :key="`sortable_${(trainingCollections.value && trainingCollections.value?.length > 0) ?? 0}`"
            :list="trainingCollections"
            item-key="id"
            :options="sortableOptions"
            class="flex flex-wrap"
            @end="changeSortOrder"
          >
            <template #item="{ element }">
              <div
                :key="element?.id"
                class="draggable flex w-full"
                :data-id="element?.id"
              >
                <div
                  class="flex items-center p-4 w-full border-t-2 hover:cursor-move"
                >
                  <div class="pi pi-bars sort-icon">
                    <span class="ml-2">{{ element.name }}</span>
                  </div>
                </div>
              </div>
            </template>
          </Sortable>
        </div>
      </div>
    </div>
    <Paginator
      v-if="!isReorderTrainingCollections"
      :always-show="true"
      :rows="filter.limit"
      :total-records="totalRecords"
      :first="first"
      template="
        FirstPageLink
        PrevPageLink
        CurrentPageReport
        NextPageLink
        LastPageLink
        RowsPerPageDropdown"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      @page="onPageChange"
    />
  </BaseLayout>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { formatDistanceToNow } from "date-fns";
import { useToast } from "primevue/usetoast";

import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import { Sortable } from "sortablejs-vue3";
import Paginator from "primevue/paginator";

import InitialPageLoader from "@/shared/components/InitialPageLoader.vue";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import UserAvatar from "@/shared/components/UserAvatar.vue";

import {
  changeOrderTrainingCollections,
  createTrainingCollection,
} from "@/modules/trainingCollections/api";
import { catchErrorMessage } from "@/shared/utils/custom-errors";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { getNameInitials } from "@/shared/utils/helpers";
import { TRAINING_COLLECTIONS } from "../constants";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";
import { useTrainingCollections } from "../composables/useTrainingCollections";

import type { AdminTrainingCollection } from "@/api/model";

const createTrainingCollectionIdAndRedirect = async () => {
  const trainingCollection = await createTrainingCollection();
  if ("error" in trainingCollection) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: trainingCollection.error,
      life: 10000,
    });
  } else {
    await router.push({
      name: ROUTE_NAME.TRAINING_COLLECTION_UPDATE,
      params: { id: trainingCollection.id },
    });
  }
};

const onPageChange = async (e: any) => {
  currentPage.value = e.page;

  router.push({
    query: { page: currentPage.value },
  });

  await fetch();
};

const toast = useToast();

const {
  isLoading,
  trainingCollections,
  totalRecords,
  errorMessage,
  filter,
  fetch,
  currentPage,
} = useTrainingCollections();

const defaultLimit = 25;
filter.value.limit = defaultLimit;

const route = useRoute();
currentPage.value = parseInt((route?.query?.page || 0).toString());

const first = computed(() => filter.value.offset);

const sortableOptions = {
  animation: 100,
  store: {
    set: function (sortable: typeof Sortable) {
      const ids = sortable.toArray();
      for (let i = 0; i < ids.length; i++) {
        const id = parseInt(ids[i]);
        if (trainingCollections.value) {
          const index = trainingCollections.value.findIndex((e) => e.id === id);
          trainingCollections.value[index].order = i;
        }
      }
    },
  },
};

const changeSortOrder = async () => {
  const updatedOrderList = trainingCollections.value.map((e) => ({
    id: e.id,
    order: e.order,
  }));
  const result = await changeOrderTrainingCollections(updatedOrderList);
  if ("error" in result) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Order updated.",
      life: 3000,
    });
  }
};

let isReorderTrainingCollections = ref(false);

onMounted(async () => {
  try {
    await fetch();
    if (errorMessage.value) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: errorMessage.value,
        life: 10000,
      });
    }
  } catch (e: unknown) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 10000,
    });
  }
});

const formatDate = (date: string) => {
  return formatDistanceToNow(new Date(date), { addSuffix: true });
};

const getFirstAsset = (trainingCollection: AdminTrainingCollection) => {
  if (
    trainingCollection.training_collection_assets &&
    trainingCollection.training_collection_assets.length > 0
  ) {
    return trainingCollection.training_collection_assets[0].asset;
  }
  return undefined;
};
</script>
