<template>
  <BaseLayout
    :title="CREATE_INVOICE_FORM_TITLE"
    :breadcrumb="[
      {
        label: 'Invoices',
        to: {
          name: ROUTE_NAME.PAYMENT_INVOICES,
        },
      },
      {
        label: 'Create Invoice',
      },
    ]"
  >
    <div>
      <div class="fixed-action-bar justify-end rounded-md p-2">
        <AppButton
          type="button"
          label="Send"
          icon="pi pi-send"
          class="mr-2"
          @click="onSend"
        />
        <AppButton
          icon="pi pi-trash"
          severity="danger"
          outlined
          aria-label="Exit"
          class="shrink-0"
          @click="onExit"
        />
      </div>
    </div>

    <div>
      <InvoiceForm
        v-model:invoice-form-fields="invoiceFormFields"
        :field-validations="formValidations"
      />

      <MissingContactsDialog
        v-model:show-modal="showMissingContactDialog"
        :recipients-missing-contact="invoiceRecipientsMissingContacts"
        :valid-invoice-recipients="validInvoiceRecipients"
        :handle-invoice-creation="handleInvoiceCreation"
      />
    </div>
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";

import { useCreateInvoices } from "../composables/useCreateInvoices";

import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import InvoiceForm from "../components/InvoiceForm.vue";

import { useInvoiceForm } from "../composables/useInvoiceForm";
import { CREATE_INVOICE_FORM_TITLE } from "../constants";
import MissingContactsDialog from "../components/MissingContactsDialog.vue";
import { computed, ref } from "vue";
import { useToast } from "primevue/usetoast";

const { invoiceFormFields, formValidations, addBlankLineItem } =
  useInvoiceForm();

const {
  createInvoices,
  invoiceRecipientsMissingContacts,
  validInvoiceRecipients,
} = useCreateInvoices(invoiceFormFields);

const toast = useToast();

const showMissingContactDialog = ref(false);

addBlankLineItem();

const onSend = async () => {
  const formValid = await formValidations.value.$validate();

  if (!formValid) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Invalid invoice data. Please fix errors.",
      life: 3000,
    });

    return false;
  }

  const hasMissingContacts = computed(
    () => invoiceRecipientsMissingContacts.value.length
  );

  if (hasMissingContacts.value) {
    showMissingContactDialog.value = true;

    return;
  }

  await handleInvoiceCreation();
};

const handleInvoiceCreation = async () => {
  const result = await createInvoices();

  if (result) {
    router.push({ name: ROUTE_NAME.PAYMENT_INVOICES });
  }
};

const onExit = () => {
  router.push({
    name: ROUTE_NAME.PAYMENT_INVOICES,
  });
};
</script>

<style scoped>
.fixed-action-bar {
  display: flex;
  width: 100%;
}
</style>
