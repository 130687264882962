import { RouterView, type RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import ChatList from "./views/ChatList.vue";
import ChatUpdate from "./views/ChatUpdate.vue";
import ChatMessage from "./views/ChatMessage.vue";
import { h } from "vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/chats",
    name: ROUTE_NAME.CHATS,
    component: { render: () => h(RouterView) },
    redirect: { name: ROUTE_NAME.CHAT_LIST },
    children: [
      {
        path: "",
        name: ROUTE_NAME.CHAT_LIST,
        component: ChatList,
      },
      {
        path: ":id",
        name: ROUTE_NAME.CHAT_SINGLE,
        component: ChatUpdate,
      },
      {
        path: ":id/:messageid",
        name: ROUTE_NAME.CHAT_MESSAGE,
        component: ChatMessage,
      },
    ],
  },
];
