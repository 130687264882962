import type { AdminWorkout } from "@/api/model";

export interface WorkoutRow {
  day: number | undefined;
  workout?: AdminWorkout | undefined;
  label?: string;
  type: "workout" | "day" | "week";
}

export enum WorkoutRowType {
  workout = "workout",
  day = "day",
  week = "week",
}
