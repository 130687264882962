<template>
  <Editor
    :model-value="model"
    :editor-style="props.editorStyle"
    :modules="{
      toolbar: false,
      clipboard: {
        matchers: [
          [
            Node.ELEMENT_NODE,
            // https://github.com/quilljs/quill/issues/1184#issuecomment-384935594
            function (_node: any, delta: typeof Delta) {
              let ops: Op[] = [];
              delta.ops.forEach((op: Op) => {
                if (op.insert && typeof op.insert === 'string') {
                  ops.push({
                    insert: op.insert,
                  });
                }
              });
              delta.ops = ops;
              return delta;
            },
          ],
        ],
      },
    }"
    :pt="{
      toolbar: { style: { display: 'none' } },
    }"
    @update:model-value="(d: string) => emit('editorUpdated', d)"
  >
    <template #toolbar>
      <br />
    </template>
  </Editor>
</template>

<script setup lang="ts">
import { watchEffect, ref, onMounted } from "vue";
import Editor from "primevue/editor";
import Quill from "quill";

const props = defineProps<{
  model?: string;
  editorStyle: string;
}>();
var Delta = Quill.import("delta");

const emit = defineEmits(["editorUpdated"]);
const Node = window.Node;
onMounted(async () => {
  const model = ref(props.model);
  watchEffect(() => (model.value = props.model));
});

interface Op {
  // only one property out of {insert, delete, retain} will be present
  insert?: string | Record<string, unknown>;
  delete?: number;
  retain?: number | Record<string, unknown>;
}
</script>

<style>
.ql-editor {
  min-height: 240px;
  padding: 0.5rem;
  border: 1px solid #ced4da;
}

.ql-hidden {
  display: none !important;
}

.ql-clipboard {
  display: none !important;
}
</style>
