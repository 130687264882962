<template>
  <div class="fadein animation-duration-500">
    <DataTable
      :value="statCategories"
      striped-rows
      row-hover
      selection-mode="single"
      @row-click="onRowClick"
    >
      <Column header="Category">
        <template #body="slotProps">
          <div class="text-base">
            {{ slotProps.data.title }}
          </div>
        </template>
      </Column>
      <Column header="Unit Label">
        <template #body="slotProps">
          {{ slotProps.data.unit_label }}
        </template>
      </Column>
      <Column header="Headline Value">
        <template #body="slotProps">
          {{
            STAT_CATEGORY_HEADLINE_VALUE_LABELS[
              slotProps.data
                .headline_value as keyof typeof STAT_CATEGORY_HEADLINE_VALUE_LABELS
            ]
          }}
        </template>
      </Column>

      <Column header="Remove">
        <template #body="{ data }">
          <Button
            icon="pi pi-times"
            severity="danger"
            text
            rounded
            aria-label="delete"
            class="flex align-items-center justify-content-center"
            @click="handleDeleteStatCategory(data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import type { AdminStatCategory } from "@/api/model";

import Column from "primevue/column";
import DataTable, { type DataTableRowClickEvent } from "primevue/datatable";
import Button from "primevue/button";

import { STAT_CATEGORY_HEADLINE_VALUE_LABELS } from "../constants";

const props = defineProps<{
  showModalEdit: () => void;
  buildFormState: (statCategory: AdminStatCategory) => void;
  deleteStatCategory: (id: number) => void;
  statCategoryId: number | null | undefined;
}>();

const statCategories = defineModel<AdminStatCategory[]>("statCategories", {
  required: true,
});

const { showModalEdit, buildFormState, deleteStatCategory } = props;

const onRowClick = (event: DataTableRowClickEvent) => {
  const statCategoryData: AdminStatCategory = event.data;

  buildFormState(statCategoryData);
  showModalEdit();
};

const handleDeleteStatCategory = (statCategory: AdminStatCategory) => {
  deleteStatCategory(statCategory.id);
};
</script>
