import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/modules/auth/stores/auth";
import { ROUTE_NAME } from "@/shared/constants/routes";

import { LOGIN_MAGIC_LINK_PATH } from "@/shared/constants/routes";

import SentryView from "@/views/SentryView.vue";

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      name: ROUTE_NAME.HOME,
      redirect: "/feed",
    },
    {
      path: "/sentry-debug-caetheechoh9mo1faePa",
      name: ROUTE_NAME.SENTRY,
      component: SentryView,
    },
  ],
});

router.beforeEach(async (to, _from, next) => {
  const { isLoggedIn } = useAuthStore();
  const publicRouteNames = [
    ROUTE_NAME.LOGIN,
    ROUTE_NAME.LOGIN_PASSWORD,
    ROUTE_NAME.LOGIN_MAGIC_LINK,
    ROUTE_NAME.LOGOUT,
    ROUTE_NAME.FORGOT_PASSWORD,
    ROUTE_NAME.RESET_PASSWORD,
  ];

  const isMagicLinkPath = LOGIN_MAGIC_LINK_PATH.includes(to.path);

  const magicLinkToken = to.query.token;

  const isPublic =
    publicRouteNames.some((x) => x === to.name) || isMagicLinkPath;

  if (to.name == ROUTE_NAME.LOGOUT) {
    next();
  } else if (!isPublic && !isLoggedIn) {
    next({ name: ROUTE_NAME.LOGIN });
  } else if (isPublic && isLoggedIn) {
    next({ name: ROUTE_NAME.HOME });
  } else if (isMagicLinkPath && magicLinkToken) {
    next({
      name: ROUTE_NAME.LOGIN_MAGIC_LINK,
      params: { token: magicLinkToken },
    });
    next({ name: ROUTE_NAME.LOGIN });
  } else {
    next();
  }
});

export default router;
