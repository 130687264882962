import http from "@/shared/services/api-client";
import {
  BadAuthCredentialsError,
  JsonParsingError,
  UnexpectedError,
} from "@/shared/utils/custom-errors";

import {
  adminV1ExercisesCreate,
  adminV1ExercisesDestroy,
  adminV1ExercisesList,
  adminV1ExercisesPartialUpdate,
  adminV1ExercisesRetrieve,
} from "@/api/admin-exercises/admin-exercises";
import {
  adminV1WorkoutsPartialUpdate,
  adminV1WorkoutsDestroy,
  adminV1WorkoutsCreate,
  adminV1WorkoutsList,
  adminV1WorkoutsRetrieve,
  adminV1WorkoutsCopyWorkoutCreate,
} from "@/api/admin-workouts/admin-workouts";
import {
  adminV1CalendarsCreate,
  adminV1CalendarsDestroy,
  adminV1CalendarsList,
  adminV1CalendarsPartialUpdate,
} from "@/api/admin-calendars/admin-calendars";
import { adminV1BlocksExercisesCreate } from "@/api/admin-block-exercises/admin-block-exercises";
import type {
  AdminBlockExerciseCreate,
  AdminV1WorkoutsListParams,
  AdminV1WorkoutsTypesListParams,
  AdminCalendar,
  AdminWorkout,
  AdminWorkoutsExercise,
  AdminBlock,
  CopyWorkoutResponse,
  PaginatedAdminCalendarList,
  AdminSet,
  AdminBlockExercise,
  SwaggerAdminWorkoutList,
  PaginatedAdminWorkoutTypeList,
  AdminV1ExercisesListParams,
  AdminV1CalendarsListParams,
  PatchedAdminWorkoutsExercise,
  AdminBlockCreate,
  PatchedAdminCalendar,
  AdminV1SelectedWorkoutTypesListParams,
  PaginatedSelectedWorkoutTypeList,
  SelectedWorkoutType,
} from "@/api/model";
import { adminV1WorkoutsTypesList } from "@/api/admin-workout-types/admin-workout-types";
import { adminV1BlocksCreate } from "@/api/admin-blocks/admin-blocks";
import {
  adminV1SelectedWorkoutTypesCreate,
  adminV1SelectedWorkoutTypesList,
} from "@/api/admin-workout-type-selected/admin-workout-type-selected";
import { EVENT_NAMES, addMixpanelEvent } from "../../shared/utils/analytics";

interface errorMessage {
  error: string;
}

export const getWorkouts = async (
  filter: AdminV1WorkoutsListParams
): Promise<SwaggerAdminWorkoutList | errorMessage> => {
  try {
    const results = await adminV1WorkoutsList({
      day_end: filter?.day_end,
      day_start: filter?.day_start,
      calendar_id: filter.calendar_id,
    });
    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const cloneWorkout = async (
  workoutId: number,
  dayToPaste: number,
  calendarId?: number
): Promise<CopyWorkoutResponse | errorMessage> => {
  try {
    const results = await adminV1WorkoutsCopyWorkoutCreate({
      workout_id: workoutId,
      day: dayToPaste,
      calendar_id: calendarId,
    });
    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getWorkout = async (
  workoutId: number
): Promise<AdminWorkout | errorMessage> => {
  try {
    const results = await adminV1WorkoutsRetrieve(workoutId);
    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const createWorkout = async (
  data: Pick<
    AdminWorkout,
    "calendar_id" | "status" | "instructions" | "day" | "organization_id"
  >
): Promise<AdminWorkout | errorMessage> => {
  try {
    const results = await adminV1WorkoutsCreate(data);
    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const updateWorkout = async (
  workoutId: number,
  data: {}
): Promise<AdminWorkout | errorMessage> => {
  try {
    const results = await adminV1WorkoutsPartialUpdate(workoutId, data);
    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const deleteWorkout = async (workoutId: number) => {
  try {
    addMixpanelEvent(EVENT_NAMES.Workouts.WorkoutDeleted);
    const results: unknown = await adminV1WorkoutsDestroy(workoutId);
    return results as boolean;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getExercises = async (args: AdminV1ExercisesListParams) => {
  try {
    const exercises = await adminV1ExercisesList(args);
    return exercises;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getExercise = async (exerciseId: number) => {
  try {
    const exercises = await adminV1ExercisesRetrieve(exerciseId);
    return exercises;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const createExercise = async (
  form: Omit<
    AdminWorkoutsExercise,
    "video" | "updated_at" | "created_at" | "id"
  >
) => {
  addMixpanelEvent(EVENT_NAMES.Exercises.ExerciseCreated);
  try {
    const exercises = await adminV1ExercisesCreate(form);
    return exercises;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const updateExercise = async (
  exerciseId: number,
  form: PatchedAdminWorkoutsExercise
) => {
  try {
    const exercises = await adminV1ExercisesPartialUpdate(exerciseId, form);
    return exercises;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const deleteExercise = async (exerciseId: number) => {
  addMixpanelEvent(EVENT_NAMES.Exercises.ExerciseDeleted);
  try {
    const exercises = await adminV1ExercisesDestroy(exerciseId);
    return exercises;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getCalendars = async (
  filter: AdminV1CalendarsListParams
): Promise<PaginatedAdminCalendarList | errorMessage> => {
  try {
    const result = await adminV1CalendarsList({
      limit: filter.limit,
      offset: filter.offset,
      organization_id: filter?.organization_id,
      search: filter?.search,
    });
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const createCalendar = async (
  data: Pick<AdminCalendar, "type_name" | "with_weights" | "is_private">
): Promise<AdminCalendar | errorMessage> => {
  try {
    const result = await adminV1CalendarsCreate(data);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const editCalendar = async (
  id: number,
  data: PatchedAdminCalendar
): Promise<AdminCalendar | errorMessage> => {
  try {
    const result = await adminV1CalendarsPartialUpdate(id, data);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const deleteCalendar = async (
  id: number
): Promise<void | errorMessage> => {
  try {
    const result = await adminV1CalendarsDestroy(id);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getWorkoutTypes = async (
  filter: AdminV1WorkoutsTypesListParams
): Promise<PaginatedAdminWorkoutTypeList | errorMessage> => {
  try {
    const result = await adminV1WorkoutsTypesList({
      limit: filter?.limit,
      offset: filter?.offset,
      search: filter.search ?? "",
    });
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const assignWorkoutCalendar = async (
  data: Pick<
    SelectedWorkoutType,
    "profile_id" | "workout_type_id" | "seed_date"
  >
): Promise<SelectedWorkoutType | errorMessage> => {
  try {
    const result = await adminV1SelectedWorkoutTypesCreate(data);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getSelectedWorkoutTypes = async (
  data: AdminV1SelectedWorkoutTypesListParams
): Promise<PaginatedSelectedWorkoutTypeList | errorMessage> => {
  try {
    const result = await adminV1SelectedWorkoutTypesList(data);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getAssignedWorkoutCalendar = async (
  data: AdminV1SelectedWorkoutTypesListParams
): Promise<PaginatedSelectedWorkoutTypeList | errorMessage> => {
  try {
    const result = await adminV1SelectedWorkoutTypesList(data);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const postBlock = async (
  data: Pick<AdminBlockCreate, "order" | "workout_id">
): Promise<AdminBlockCreate | errorMessage> => {
  try {
    const result = await adminV1BlocksCreate(data);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const updateBlock = async (
  id: number,
  data: {}
): Promise<AdminBlock> => {
  const res = await http.put(`/api/admin/v1/blocks/${id}/`, data);
  if (res?.status == 401) throw new BadAuthCredentialsError();
  if (res?.status != 200) throw new UnexpectedError();
  try {
    return res?.data as AdminBlock;
  } catch (e) {
    throw new JsonParsingError();
  }
};

export const postBlockExercise = async (
  data: Pick<
    AdminBlockExerciseCreate,
    "exercise_id" | "order" | "with_weights" | "block_id"
  >
): Promise<AdminBlockExerciseCreate | errorMessage> => {
  try {
    const exercises = await adminV1BlocksExercisesCreate(data);
    return exercises;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const updateBlockExercise = async (
  id: number,
  data: {}
): Promise<AdminBlockExercise> => {
  const res = await http.put(`/api/admin/v1/blocks_exercises/${id}/`, data);
  if (res?.status == 401) throw new BadAuthCredentialsError();
  if (res?.status != 200) throw new UnexpectedError();
  try {
    return res?.data as AdminBlockExercise;
  } catch (e) {
    throw new JsonParsingError();
  }
};

export const deleteBlockExercise = async (
  id: number | string
): Promise<boolean> => {
  const res = await http.delete(`/api/admin/v1/blocks_exercises/${id}/`);
  if (res?.status == 401) throw new BadAuthCredentialsError();
  if (res?.status != 204) throw new UnexpectedError();
  try {
    return true;
  } catch (e) {
    throw new JsonParsingError();
  }
};

export const deleteBlock = async (id: number | string): Promise<boolean> => {
  const res = await http.delete(`/api/admin/v1/blocks/${id}/`);
  if (res?.status == 401) throw new BadAuthCredentialsError();
  if (res?.status != 204) throw new UnexpectedError();
  try {
    return true;
  } catch (e) {
    throw new JsonParsingError();
  }
};

export const postSet = async (data: {}): Promise<AdminSet> => {
  const res = await http.post(`/api/admin/v1/sets/`, data);
  if (res?.status == 401) throw new BadAuthCredentialsError();
  if (res?.status != 201) throw new UnexpectedError();
  try {
    return res?.data as AdminSet;
  } catch (e) {
    throw new JsonParsingError();
  }
};

export const updateSet = async (id: number, data: {}): Promise<AdminSet> => {
  const res = await http.put(`/api/admin/v1/sets/${id}/`, data);
  if (res?.status == 401) throw new BadAuthCredentialsError();
  if (res?.status != 200) throw new UnexpectedError();
  try {
    return res?.data as AdminSet;
  } catch (e) {
    throw new JsonParsingError();
  }
};

export const deleteSet = async (id: number | string): Promise<boolean> => {
  const res = await http.delete(`/api/admin/v1/sets/${id}/`);
  if (res?.status == 401) throw new BadAuthCredentialsError();
  if (res?.status != 204) throw new UnexpectedError();
  try {
    return true;
  } catch (e) {
    throw new JsonParsingError();
  }
};
