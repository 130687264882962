<template>
  <div class="stat-categories-reorder fadein animation-duration-1000">
    <div class="reorder-instructions">
      <strong>Drag stat categories to put them in the proper order.</strong>
    </div>

    <div class="stat-categories-table">
      <div class="table-header">
        <div class="column">Category</div>
        <div class="column">Unit Label</div>
        <div class="column">Headline Value</div>
      </div>

      <Sortable
        :key="`sortable_${(statCategories && statCategories.length > 0) ?? 0}`"
        :list="statCategories"
        item-key="id"
        :options="sortableOptions"
        class="table-body"
        @end="changeSortOrder"
      >
        <template #item="{ element }">
          <div :key="element?.id" class="table-row" :data-id="element?.id">
            <div class="column">
              <i class="pi pi-bars drag-handle"></i>
              {{ element.title }}
            </div>
            <div class="column">{{ element.unit_label }}</div>
            <div class="column">
              {{
                STAT_CATEGORY_HEADLINE_VALUE_LABELS[
                  element.headline_value as keyof typeof STAT_CATEGORY_HEADLINE_VALUE_LABELS
                ]
              }}
            </div>
          </div>
        </template>
      </Sortable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type AdminStatCategory } from "@/api/model";
import { useToast } from "primevue/usetoast";
import { Sortable } from "sortablejs-vue3";
import { updateStatCategoryOrder } from "../api";
import { STAT_CATEGORY_HEADLINE_VALUE_LABELS } from "../constants";

const toast = useToast();

const statCategories = defineModel<AdminStatCategory[]>("statCategories", {
  required: true,
});

const sortableOptions = {
  animation: 100,
  store: {
    set: function (sortable: typeof Sortable) {
      const ids = sortable.toArray();
      for (let i = 0; i < ids.length; i++) {
        const id = parseInt(ids[i]);
        if (statCategories.value) {
          const index = statCategories.value.findIndex((e) => e.id === id);
          statCategories.value[index].order = i;
        }
      }
    },
  },
};

const changeSortOrder = async () => {
  const updatedOrderList = statCategories.value.map((e) => ({
    id: e.id,
    // 0 is safe... we will ALWAYS get order back from the API and 0 is the default.
    // this is  workaround for an orval type issue.
    order: e.order ?? 0,
  }));

  if (updatedOrderList.length === 0) {
    return;
  }

  const result = await updateStatCategoryOrder(updatedOrderList);
  if ("error" in result) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Order updated.",
      life: 3000,
    });
  }
};
</script>

<style scoped>
.stat-categories-reorder {
  max-width: 100%;
  margin: 0 auto;
}

.reorder-instructions {
  margin-bottom: 20px;
  font-size: 14px;
}

.stat-categories-table {
  border-radius: 6px;
  overflow: hidden;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}

.table-header {
  display: flex;
  background-color: #f8f9fa;
  font-weight: 600;
  border-bottom: 2px solid #dee2e6;
}

.table-body {
  overflow-y: auto;
}

.table-row {
  display: flex;
  border-bottom: 1px solid #dee2e6;
  background-color: #ffffff;
}

.column {
  flex: 1;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.table-header .column {
  font-size: 14px;
  color: #495057;
}

.drag-handle {
  cursor: move;
  margin-right: 10px;
  color: #6c757d;
  font-size: 18px;
}

.drag-handle:hover {
  color: #495057;
}
</style>
