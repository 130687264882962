<template>
  <div>
    <Button
      v-if="options.length"
      label="Select Calendar"
      @click="showModal = true"
    />
    <Dialog
      v-model:visible="showModal"
      header="Select Calendar"
      :style="{ width: '32rem', height: 'h-full' }"
      :closable="true"
      @show="v$.$reset()"
      @hide="
        v$.$reset();
        resetCalendar();
      "
    >
      <Listbox
        v-model="form.calendarId"
        :options="options"
        :pt="{
          list: { class: 'h-[28rem]' },
        }"
        option-label="type.name"
        option-value="id"
        empty-message="No results found."
        @change="onOptionSelect"
      >
        <template #header>
          <div
            class="flex justify-center p-[var(--content-padding)] bg-[var(--surface-50)] text-[var(--surface-700)] rounded-[var(--border-radius)]"
          >
            <div class="relative w-1/2">
              <InputText
                v-model="searchSelection"
                placeholder=""
                class="w-full"
                @input="searchCalendars"
              />
              <i
                class="pi pi-search absolute right-3 top-1/2 transform -translate-y-1/2 text-[var(--surface-500)]"
              />
            </div>
          </div>
        </template>
      </Listbox>

      <div class="mt-2">
        <small
          v-if="v$ErrorMessage(v$.calendarId.$errors)"
          class="text-red-500"
        >
          {{ v$ErrorMessage(v$.calendarId.$errors) }}
        </small>
      </div>
      <div class="flex justify-end mt-12">
        <AppButton
          label="Select"
          :disabled="v$.$errors.length || options.length === 0"
          @click="
            async () => ((await v$.$validate()) ? selectCalendar() : null)
          "
        />
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, reactive, onMounted, watch } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Listbox, { type ListboxChangeEvent } from "primevue/listbox";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import { getCalendars } from "@/modules/workouts/api";
import { useToast } from "primevue/usetoast";
import { debounce } from "lodash";
import type { AdminCalendar } from "@/api/model";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";

const emit = defineEmits<{
  (e: "change", calendar: AdminCalendar): void;
}>();

let searchSelection = ref<string | undefined>(undefined);

const toast = useToast();

const showModal = ref(false);

interface optionsType {
  type: {
    name: string;
  };
  id: number;
}
const options = ref<optionsType[]>([]);

interface Props {
  organizationId: number | null;
  refreshCalendar?: string;
}

const props = withDefaults(defineProps<Props>(), {
  organizationId: -1,
  refreshCalendar: "0-0",
});

let form = reactive({
  calendarId: undefined,
});

const rules = computed(() => ({
  calendarId: {
    required: helpers.withMessage("Please select a calendar.", required),
  },
}));

const v$ = useVuelidate(rules, form);

const onOptionSelect = (event: ListboxChangeEvent) => {
  form.calendarId = event.value;
};

const searchCalendars = debounce(async (e: Event) => {
  v$.value.$reset();

  if (searchSelection.value) {
    const results = await getCalendars({
      search: searchSelection.value,
      organization_id: props.organizationId ?? undefined,
    });

    if ("error" in results) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: results.error,
        life: 10000,
      });
    } else {
      options.value = results?.results || [];
    }
  } else {
    options.value = defaultInitialCalendars.value;
  }
}, 500);

const resetCalendar = () => {
  searchSelection.value = undefined;
  options.value = defaultInitialCalendars.value;
};

const selectCalendar = (disableEvent?: boolean) => {
  showModal.value = false;
  const pickedCalendar = options.value.find((e) => e.id === form.calendarId);
  if (!disableEvent) {
    addMixpanelEvent(EVENT_NAMES.Workouts.CalendarSelected, {
      calendarName: pickedCalendar?.type.name,
    });
  }

  if (pickedCalendar) {
    emit("change", pickedCalendar);

    resetCalendar();
  }
};

let defaultInitialCalendars = ref<AdminCalendar[]>([]);

const getLatestCalendars = async () => {
  // get first calendars to fill in the list
  return (
    (
      await getCalendars({
        limit: 1000,
        offset: 0,
        organization_id: props.organizationId ?? undefined,
      })
    )?.results || []
  );
};

watch(
  () => props.refreshCalendar,
  async () => {
    defaultInitialCalendars.value = await getLatestCalendars();
    options.value = defaultInitialCalendars.value;

    const rawRefreshCalendar = props.refreshCalendar.split("-") || [];
    const calendarId = rawRefreshCalendar.length
      ? parseInt(rawRefreshCalendar[0])
      : undefined;
    if (calendarId) {
      form.calendarId = calendarId;
      selectCalendar(true);
    }
  }
);
onMounted(async () => {
  // get first calendars to fill in the list
  defaultInitialCalendars.value = await getLatestCalendars();
  options.value = defaultInitialCalendars.value;

  // set default calendar on load
  if (
    defaultInitialCalendars.value.length &&
    defaultInitialCalendars.value[0]?.id
  ) {
    const defaultCalendarId = parseInt(props.refreshCalendar.split("-")[0]);
    form.calendarId =
      defaultCalendarId > 0
        ? defaultCalendarId
        : defaultInitialCalendars.value[0]?.id;
    selectCalendar(true);
  }
});
</script>
