import { RouterView, type RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import TrainingCollectionList from "./views/TrainingCollectionList.vue";
import TrainingCollectionUpdate from "./views/TrainingCollectionUpdate.vue";
import DrillUpdate from "./views/DrillUpdate.vue";
import { h } from "vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/training-collections",
    name: ROUTE_NAME.TRAINING_COLLECTIONS,
    component: { render: () => h(RouterView) },
    redirect: { name: ROUTE_NAME.TRAINING_COLLECTION_LIST },
    children: [
      {
        path: "",
        name: ROUTE_NAME.TRAINING_COLLECTION_LIST,
        component: TrainingCollectionList,
      },
      {
        path: ":id",
        name: ROUTE_NAME.TRAINING_COLLECTION_UPDATE,
        component: TrainingCollectionUpdate,
      },
      {
        path: ":id/drills/:drill_id",
        name: ROUTE_NAME.DRILL_UPDATE,
        component: DrillUpdate,
      },
    ],
  },
];
