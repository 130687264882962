import { addContactToUser } from "@/modules/users/api";
import type { FormValidation, Recipient } from "@/types";
import useVuelidate from "@vuelidate/core";
import { email as emailValidator, required } from "@vuelidate/validators";
import { computed, ref, type Ref } from "vue";
import { useToast } from "primevue/usetoast";
import type { AdminProfileAssociatedContact } from "@/api/model";

interface UseCreateAssociatedContact {
  formValidations: Ref<FormValidation>;
  associatedContactForm: {
    name: Ref<string>;
    primaryContact: Ref<boolean>;
    email: Ref<string>;
  };
  clearForm: () => void;
  createContact: (
    recipient: Recipient
  ) => Promise<AdminProfileAssociatedContact | undefined>;
}

export const useCreateAssociatedContact = (): UseCreateAssociatedContact => {
  const name: Ref<string> = ref("");
  const primaryContact: Ref<boolean> = ref(false);
  const email: Ref<string> = ref("");

  const toast = useToast();

  const associatedContactForm = {
    name,
    primaryContact,
    email,
  };

  const associatedContactRules = {
    name: { required },
    email: { required, emailValidator },
    primaryContact: { boolean: true },
  };

  const fieldValidations = useVuelidate(
    associatedContactRules,
    associatedContactForm
  );

  const createContact = async (recipient: Recipient) => {
    if (await fieldValidations.value.$validate()) {
      const athleteProfileId = recipient.id;

      const result = await addContactToUser(athleteProfileId, {
        name: name.value,
        email: email.value,
        primary_contact: primaryContact.value,
        profile: athleteProfileId,
        organization: recipient.organization.id,
      });

      if ((result as { error: any }).error) {
        if ("error" in result) {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: result.error,
          });

          return;
        }
      }

      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Contact added successfully",
        life: 3000,
      });

      return result;
    }
  };

  const clearFormState = () => {
    name.value = "";
    email.value = "";
    primaryContact.value = false;
  };

  return {
    formValidations: fieldValidations,
    associatedContactForm,
    clearForm: clearFormState,
    createContact,
  };
};
