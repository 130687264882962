<template>
  <form @submit.prevent>
    <div class="flex justify-between mb-10">
      <div />
      <div>
        <AppButton
          class="ml-6"
          type="submit"
          label="Save"
          :loading="isSubmitting"
          @click="async () => ((await v$.$validate()) ? onSubmit() : null)"
        />

        <AppButton
          v-if="!isCreateForm"
          icon="pi pi-trash"
          severity="danger"
          outlined
          aria-label="Cancel"
          class="shrink-0 ml-2"
          @click="confirmDeleteExercise(exerciseId)"
        />
      </div>
    </div>

    <div class="flex flex-col gap-1 mb-2">
      <label for="name">Name</label>
      <InputText
        id="name"
        v-model="form.name"
        type="text"
        placeholder="Name"
        @blur="v$.name.$touch"
      />
      <small v-if="v$ErrorMessage(v$.name.$errors)" class="text-red-500">
        {{ v$ErrorMessage(v$.name.$errors) }}
      </small>
    </div>
    <div class="flex flex-col gap-1 mb-2">
      <label for="instructions">Instructions</label>
      <Textarea
        id="instructions"
        v-model="form.instructions"
        placeholder="Instructions"
      />
    </div>

    <div class="flex flex-col gap-1 mb-2">
      <SingleVideoUploaderAndDisplay
        label="Video"
        class="mr-20"
        :disclaimer-text="`Allowed file types: ${allowedVideoFileExtensionsByKey}`"
        :max-width="200"
        :video-data="newVideo || props.video"
        :organization-id="organizationSelection"
        :allow-delete-button="true"
        :autoplay="false"
        @upload-complete="assetUploadSuccess"
        @upload-failed="uploadVideoFailed"
        @request-delete="assetDelete"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import { computed, onMounted, reactive, ref, watchEffect } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import { catchErrorMessage } from "@/shared/utils/custom-errors";
import { allowedVideoFileExtensionsByKey } from "@/shared/constants/uploader";
import { useToast } from "primevue/usetoast";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { useConfirm } from "primevue/useconfirm";
import { useRoute } from "vue-router";
import router from "@/router";
const route = useRoute();
import {
  createExercise,
  deleteExercise,
  updateExercise,
} from "@/modules/workouts/api";

import { useAuthStore } from "@/modules/auth/stores/auth";
import type { AdminAsset, AdminWorkoutsExercise, Asset } from "@/api/model";
import SingleVideoUploaderAndDisplay from "@/shared/components/SingleVideoUploaderAndDisplay.vue";
const { defaultOrg } = useAuthStore();

interface FormState {
  name: string;
  organization: number;
  instructions?: string;
  video_id?: number;
}
const newVideo = ref();
const props = defineProps<{
  initialData?: FormState;
  video?: AdminAsset;
}>();

const resetAsset = ref(false);

const emit = defineEmits(["setExercise", "exercise"]);

const isSubmitting = ref(false);

const isCreateForm = computed<boolean>(() => !props.initialData);

const form = reactive<FormState>({
  name: "",
  instructions: undefined,
  organization: -1,
  video_id: undefined,
});

const rules = computed(() => ({
  name: { required },
}));

const setFormValues = (
  data: Omit<
    AdminWorkoutsExercise,
    "video" | "created_at" | "updated_at" | "id"
  >
) => {
  form.name = data?.name || "";
  form.instructions = data?.instructions;

  if (data?.video_id) {
    form.video_id = data?.video_id;
  }

  emit("exercise", { exerciseName: form?.name });
};

const v$ = useVuelidate(rules, form);

const exerciseId = parseInt(((route.params || {}).id || 0).toString());

interface assetUploadSuccessType {
  assetId: number;
}

const assetUploadSuccess = async ({ asset }: { asset: Asset }) => {
  form.video_id = asset.id;

  // Don't update the exercise if it's a create form.
  if (isCreateForm.value) {
    newVideo.value = asset;
    return;
  }

  const result = await updateExercise(exerciseId, { video_id: form.video_id });
  if ("error" in result) {
    showToast({ msg: result.error });
  } else {
    showToast({
      severity: "success",
      msg: "Added video.",
      summary: "Success",
      life: 3000,
    });
    emit("setExercise");
  }
};

const assetDelete = async () => {
  form.video_id = undefined;

  if (!exerciseId) {
    return;
  }

  const result = await updateExercise(exerciseId, { video_id: null });
  if ("error" in result) {
    showToast({ msg: result.error });
  } else {
    showToast({
      severity: "success",
      msg: "Removed video.",
      summary: "Success",
      life: 3000,
    });
    emit("setExercise");
  }
};

const uploadVideoFailed = (d: { error: string }) => {
  if (d?.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: d?.error,
      life: 10000,
    });
  }
};

const toast = useToast();

interface showToastType {
  severity?: "success" | "info" | "warn" | "error" | undefined;
  summary?: string;
  msg: string;
  life?: number;
}

const showToast = (data: showToastType) => {
  toast.add({
    severity: data?.severity ?? "error",
    summary: data?.summary ?? "Error",
    detail: data?.msg,
    life: data?.life ?? 10000,
  });
};

const confirm = useConfirm();
const confirmDeleteExercise = (exerciseId: number) => {
  confirm.require({
    message: "Are you sure you want to delete this exercise?",
    header: "Delete Exercise",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        await deleteExercise(exerciseId);
        router.push({
          name: ROUTE_NAME.EXERCISE_LIST,
        });
      } catch (e: any) {
        if ("message" in e) {
          showToast({ msg: e.message });
        }
      }
    },
  });
};

let organizationSelection = ref<number>(defaultOrg.id);

onMounted(async () => {
  if (props.initialData) {
    setFormValues(props.initialData);
  }

  form.organization = organizationSelection.value;
});

const onSubmit = async () => {
  emit("exercise", { exerciseName: form?.name });

  resetAsset.value = true;
  if (props.initialData) {
    await onUpdate();
    return;
  } else {
    await onCreate();
  }
};

const onCreate = async () => {
  try {
    const result = await createExercise(form);
    if ("error" in result) {
      showToast({ msg: result.error });
    } else {
      showToast({
        severity: "success",
        msg: "Created exercise.",
        summary: "Success",
        life: 3000,
      });

      await router.push({
        name: ROUTE_NAME.EXERCISE_UPDATE,
        params: { id: result?.id },
      });
    }
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 10000,
    });
  }
};

const onUpdate = async () => {
  try {
    const result = await updateExercise(exerciseId, form);
    if ("error" in result) {
      showToast({ msg: result.error });
    } else {
      showToast({
        severity: "success",
        msg: "Updated exercise.",
        summary: "Success",
        life: 3000,
      });
      emit("setExercise");
    }
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 3000,
    });
  }
};
</script>
