<template>
  <div
    class="wk-box w-80 bg-surface-100 dark:bg-surface-700 p-6 rounded-md shrink-0 ml-6"
  >
    <slot>
      <div
        class="flex items-center justify-center h-full cursor-pointer rounded-md hover:bg-surface-300 dark:hover:bg-surface-500 transition-all duration-300"
        @click="() => emit('add')"
      >
        <div class="text-center">
          <i class="pi pi-plus text-3xl" />
          <div class="m-1">Superset</div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: "add"): void;
}>();
</script>
