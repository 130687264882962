import { RouterView, type RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import FeedListView from "./views/FeedListView.vue";
import FeedUpdate from "./views/FeedUpdate.vue";
import { h } from "vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/feed",
    name: ROUTE_NAME.FEED,
    component: { render: () => h(RouterView) },
    redirect: { name: ROUTE_NAME.FEED_LIST },
    children: [
      {
        path: "",
        name: ROUTE_NAME.FEED_LIST,
        component: FeedListView,
      },
      {
        path: ":id",
        name: ROUTE_NAME.FEED_SINGLE,
        component: FeedUpdate,
      },
    ],
  },
];
