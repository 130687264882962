import mixpanel from "mixpanel-browser";
import { captureException } from "@sentry/vue";
import type User from "../datamodels/user";
import { MIXPANEL_TOKEN } from "@/shared/utils/env";

export const EVENT_NAMES = {
  USER: {
    UserCreated: "UserCreated",
    UserMadeCoach: "UserMadeCoach",
    UserMadeAdmin: "UserMadeAdmin",
    AdminNavUser: "AdminNavUser",
    AdminLoggedIn: "AdminLoggedIn",
  },
  TEAMS: {
    TEAM_CREATED: "TeamCreated",
    TEAM_DELETED: "TeamDeleted",
  },
  Exercises: {
    ExerciseCreated: "ExerciseCreated",
    ExerciseDeleted: "ExerciseDeleted",
  },
  Feed: {
    FeedPostCreated: "FeedPostCreated",
    FeedPostPublished: "FeedPostPublished",
    FeedPostDraftSaved: "FeedPostDraftSaved",
    FeedPostDeleted: "FeedPostDeleted",
    FeedPostEdited: "FeedPostEdited",
    FeedFiltered: "FeedFiltered",
  },
  VideoSubmissions: {
    SubmissionCoachChanged: "SubmissionCoachChanged",
    SubmissionVideoViewed: "SubmissionVideoViewed",
    SubmissionsRefreshed: "SubmissionsRefreshed",
    VideoSubmittedFromAdmin: "VideoSubmittedFromAdmin",
  },

  TrainingCollections: {
    TrainingCollectionCreated: "TrainingCollectionCreated",
    TrainingDrillCreated: "TrainingDrillCreated",
    TrainingComparisonVideoAdded: "TrainingComparisonVideoAdded",
    TrainingDrillExerciseAdded: "TrainingDrillExerciseAdded",
    TrainingDrillDeleted: "TrainingDrillDeleted",
  },
  Workouts: {
    CalendarSelected: "CalendarSelected",
    CalendarCreated: "CalendarCreated",
    WorkoutCreated: "WorkoutCreated",
    WorkoutEdited: "WorkoutEdited",
    WorkoutDeleted: "WorkoutDeleted",
    WorkoutPasted: "WorkoutPasted",
    SetAdded: "SetAdded",
    SupersetAdded: "SupersetAdded",
    ExerciseBlockAdded: "ExerciseBlockAdded",
    CalendarAssigned: "CalendarAssigned",
  },
  Navigation: {
    NavAdminFeed: "NavAdminFeed",
    NavAdminTraining: "NavAdminTraining",
    NavAdminSubmissions: "NavAdminSubmissions",
    NavAdminWorkouts: "NavAdminWorkouts",
    NavAdminExercises: "NavAdminExercises",
    NavAdminUsers: "NavAdminUsers",
    NavAdminTeams: "NavAdminTeams",
    NavAdminTeam: "NavAdminTeam",
    NavAdminUser: "NavAdminUser",
    NavAdminWorkout: "NavAdminWorkout",
    NavAdminExercise: "NavAdminExercise",
    NavAdminVideoSubmission: "NavAdminVideoSubmission",
    NavAdminTrainingCollection: "NavAdminTrainingCollection",
    NavAdminStats: "NavAdminStats",
  },
  PASSWORD: {
    PasswordResetRequested: "PasswordResetRequested",
    PasswordResetFailed: "PasswordResetFailed",
    PasswordResetSucceeded: "PasswordResetSucceeded",
  },
  STATS: {
    StatCategoryCreated: "StatCategoryCreated",
    StatCategoryUpdated: "StatCategoryUpdated",
  },
} as const;

export const addMixpanelEvent = (
  eventName: string,
  tags: Record<string, unknown> = {}
) => {
  try {
    mixpanel.track(eventName, tags);
  } catch (e: any) {
    captureException(e);
  }
};

export const mixpanelLogout = () => {
  if (MIXPANEL_TOKEN) {
    mixpanel.reset();
  }
};

export const mixpanelLogin = (user: User) => {
  if (MIXPANEL_TOKEN) {
    mixpanel.identify(user?.id.toString());

    // ios sets team, and teamId but not including as an Admin might be part of multiple teams
    mixpanel.people.set({
      $email: user?.email,
      role: user?.is_coach ? "Coach" : "Athlete",
      loginType: "Email",
      name: user?.name,
      userEmail: user?.email,
      userId: user?.id,
      orgId: user?.default_organization?.id,
      orgName: user?.default_organization?.name,
    });
  }
};
