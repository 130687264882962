<template>
  <BaseLayout
    title="Create Team"
    :breadcrumb="[
      { label: 'Organizations', to: { name: ROUTE_NAME.ORGANIZATIONS } },
      {
        label: orgName,
        to: {
          name: ROUTE_NAME.ORGANIZATION_UPDATE,
          params: { id: organizationIdParam },
        },
      },
      {
        label: 'Teams',
        to: {
          name: ROUTE_NAME.ORGANIZATION_UPDATE,
          params: { id: organizationIdParam },
        },
      },
      { label: 'Create' },
    ]"
  >
    <OrganizationForm />
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { ROUTE_NAME } from "../../../shared/constants/routes";
import { useOrganizationStore } from "../../organizations/stores/organizationsStore";
import OrganizationForm from "../components/TeamsForm.vue";
import { computed } from "vue";

const { organizations } = storeToRefs(useOrganizationStore());
const route = useRoute();

const organizationIdParam = parseInt((route?.params?.id || 0).toString());
const orgName = computed(() => {
  const org = organizations.value.find((org) => org.id === organizationIdParam);
  return org?.name;
});
</script>
