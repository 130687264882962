import { adminV1ChatsPartialUpdate } from "@/api/admin-chat/admin-chat";
import {
  adminV1ChatsList,
  adminV1ChatsRetrieve,
} from "../../api/admin-chat/admin-chat";
import type {
  AdminChat,
  AdminChatMessage,
  AdminV1ChatMessagesListParams,
  AdminV1ChatsListParams,
} from "../../api/model";
import {
  adminV1ChatMessagesCreate,
  adminV1ChatMessagesPartialUpdate,
} from "@/api/admin-chat-messages/admin-chat-messages";
import { EVENT_NAMES, addMixpanelEvent } from "../../shared/utils/analytics";
import { adminV1ChatsCreate } from "@/api/admin-chat/admin-chat";

type ApiError = {
  error: string;
};

export const updateMessage = async (
  messageId: number,
  args: AdminV1ChatMessagesListParams
) => {
  try {
    const result = await adminV1ChatMessagesPartialUpdate(messageId, args);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getAllChats = async (args: AdminV1ChatsListParams) => {
  try {
    const result = await adminV1ChatsList(args);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getChat = async (chatId: number) => {
  try {
    const result = await adminV1ChatsRetrieve(chatId);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const createAdminChat = async (
  adminChat: AdminChat
): Promise<AdminChat | ApiError> => {
  try {
    const result = await adminV1ChatsCreate(adminChat);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const createAdminChatMessage = async (
  adminChatMessage: AdminChatMessage
): Promise<AdminChatMessage | ApiError> => {
  try {
    const result = await adminV1ChatMessagesCreate(adminChatMessage);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const reassignCoach = async (
  chatId: number,
  coachId: number,
  coachIsRecipient: boolean
) => {
  addMixpanelEvent(EVENT_NAMES.VideoSubmissions.SubmissionCoachChanged);
  try {
    let args;

    if (coachIsRecipient) {
      args = {
        recipient_id: coachId,
      };
    } else {
      args = {
        sender_id: coachId,
      };
    }

    const result = await adminV1ChatsPartialUpdate(chatId, args);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};
