import {
  HeadlineValueEnum,
  IconEnum,
  ReminderDayEnum,
  ReminderFrequencyEnum,
} from "@/api/model";

export const MOST_RECENT_LABEL = "Most Recent";
export const AVERAGE_LABEL = "Average";
export const HIGHEST_LABEL = "Highest";
export const LOWEST_LABEL = "Lowest";

export const WEIGHT_LABEL = "Weight";
export const RUNNER_LABEL = "Runner";
export const SPIN_LABEL = "Spin";
export const UP_LABEL = "Up";
export const SPEEDOMETER_LABEL = "Speedometer";
export const PERCENT_LABEL = "Percent";
export const CLOCK_LABEL = "Clock";
export const DUMBBELL_LABEL = "Dumbbell";
export const CHARTLINEUP_LABEL = "Chart: Line-Up";

export const WEIGHT_ICON = "pi pi-gauge";
export const RUNNER_ICON = "pi pi-star";
export const SPIN_ICON = "pi pi-spinner";
export const UP_ICON = "pi pi-sort-up-fill";
export const SPEEDOMETER_ICON = "pi pi-fast-forward";
export const PERCENT_ICON = "pi pi-percentage";
export const CLOCK_ICON = "pi pi-clock";
export const DUMBBELL_ICON = "pi pi-hammer";

export const STAT_CATEGORY_HEADLINE_VALUE_LABELS = {
  [HeadlineValueEnum.most_recent]: MOST_RECENT_LABEL,
  [HeadlineValueEnum.average]: AVERAGE_LABEL,
  [HeadlineValueEnum.highest]: HIGHEST_LABEL,
  [HeadlineValueEnum.lowest]: LOWEST_LABEL,
};

export const ICON_LABELS = {
  [IconEnum.chartlineuptrendxyaxis]: CHARTLINEUP_LABEL,
  [IconEnum.percent]: PERCENT_LABEL,
  [IconEnum.arrowtriangle2circlepath]: SPIN_LABEL,
  [IconEnum.clock]: CLOCK_LABEL,
  [IconEnum.arrowup]: UP_LABEL,
  [IconEnum.figurerun]: RUNNER_LABEL,
  [IconEnum.dumbbell]: DUMBBELL_LABEL,
  [IconEnum.scalemass]: WEIGHT_LABEL,
  [IconEnum.gaugewithneedle]: SPEEDOMETER_LABEL,
} as const;

export const ICON_CLASSES = {
  [IconEnum.chartlineuptrendxyaxis]: SPEEDOMETER_ICON,
  [IconEnum.percent]: PERCENT_ICON,
  [IconEnum.arrowtriangle2circlepath]: SPIN_ICON,
  [IconEnum.clock]: CLOCK_ICON,
  [IconEnum.arrowup]: UP_ICON,
  [IconEnum.figurerun]: RUNNER_ICON,
  [IconEnum.dumbbell]: DUMBBELL_ICON,
  [IconEnum.scalemass]: WEIGHT_ICON,
} as const;

export const REMINDER_FREQUENCY_OPTIONS = [
  { label: "None", value: ReminderFrequencyEnum.NONE },
  { label: "Every Day", value: ReminderFrequencyEnum.DAILY },
  { label: "Every Week", value: ReminderFrequencyEnum.WEEKLY },
  { label: "Every 2 Weeks", value: ReminderFrequencyEnum.BIWEEKLY },
];

export const REMINDER_DAY_OPTIONS = [
  { label: "Sunday", value: ReminderDayEnum.SUN },
  { label: "Monday", value: ReminderDayEnum.MON },
  { label: "Tuesday", value: ReminderDayEnum.TUE },
  { label: "Wednesday", value: ReminderDayEnum.WED },
  { label: "Thursday", value: ReminderDayEnum.THU },
  { label: "Friday", value: ReminderDayEnum.FRI },
  { label: "Saturday", value: ReminderDayEnum.SAT },
];

export const HEADLINE_VALUE_OPTIONS = [
  { label: "Most Recent", value: HeadlineValueEnum.most_recent },
  { label: "Average", value: HeadlineValueEnum.average },
  { label: "Highest", value: HeadlineValueEnum.highest },
  { label: "Lowest", value: HeadlineValueEnum.lowest },
];

export const ICON_DROPDOWN_OPTIONS = Object.values(IconEnum).map((value) => ({
  label: ICON_LABELS[value],
  icon: ICON_CLASSES[value],
  value: value,
}));

export const CREATE_STAT_CATEGORY_HEADER = "Create Category";
export const EDIT_STAT_CATEGORY_HEADER = "Edit Category";

export const CREATE_SUCCESS_MESSAGE = "Stat category created successfully";
export const UPDATE_SUCCESS_MESSAGE = "Stat category updated successfully";

export const STAT_CATEGORY_DIALOG_CREATE_BUTTON = "Confirm";
export const STAT_CATEGORY_DIALOG_UPDATE_BUTTON = "Save";

export const REORDER_STAT_CATEGORY_BUTTON_LABEL = "Reorder Categories";
export const DISABLE_REORDER_LABEL = "Disable Reorder Categories";
