import { defineStore } from "pinia";
import { downloadAvatar } from "../api";
import { useAuthStore } from "@/modules/auth/stores/auth";
import { getUserMe } from "@/modules/profile/api";
import type AssetFile from "@/shared/datamodels/assetFile";

interface AdminAvatarState {
  profileImage?: string;
}

export const useAdminAvatar = defineStore("adminAvatar", {
  persist: true,
  state: (): AdminAvatarState => ({
    profileImage: "",
  }),
  actions: {
    async downloadAvatarAndSaveToLocalStorage(url: string = ""): Promise<void> {
      const base64Thumbnail: any = await downloadAvatar(url);
      if (!base64Thumbnail?.error) {
        this.profileImage = base64Thumbnail;
      }
    },
    async lookupAvatarUrl(): Promise<void> {
      const { loggedInUserId } = useAuthStore();

      if (!loggedInUserId) {
        return undefined;
      }

      const result: any = await getUserMe(loggedInUserId);
      return result?.picture?.file;
    },
    async saveThumbnailToLocalStorage(file: AssetFile): Promise<void> {
      if (!file) {
        return;
      }

      // get 64x64 image and save it to localstorage
      const avatarResizedUrl = (file?.thumbnail_url_tpl || "").replace(
        "{width}x{height}",
        "64x64"
      );

      await this.downloadAvatarAndSaveToLocalStorage(avatarResizedUrl);
    },
    async clearLocalStorageAdminProfileImage(): Promise<void> {
      this.profileImage = undefined;
    },
    async updateLatestAdminProfileImage(): Promise<void> {
      const fileInfo = await this.lookupAvatarUrl();
      if (fileInfo !== undefined) {
        await this.saveThumbnailToLocalStorage(fileInfo);
      } else {
        await this.clearLocalStorageAdminProfileImage();
      }
    },
  },
});
