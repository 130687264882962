<template>
  <AuthLayout>
    <div class="text-2xl font-semibold">Forgot Your Password?</div>
    <p class="text-sm mb-6">
      Please enter your user name, follow instruction when you receive password
      recovery email.
    </p>
    <form @submit.prevent="submit">
      <div class="flex flex-col gap-1">
        <label for="email">Email</label>
        <InputText
          id="email"
          v-model="form.email"
          type="text"
          :class="{ 'p-invalid': v$ErrorMessage(v$.email.$errors) }"
          placeholder="Email"
          @blur="v$.email.$touch"
        />
        <small v-if="v$ErrorMessage(v$.email.$errors)" class="text-red-500">
          {{ v$ErrorMessage(v$.email.$errors) }}
        </small>
      </div>
      <AppButton
        type="submit"
        class="mt-2"
        :loading="form.isSubmitting"
        label="Submit"
      />
    </form>
  </AuthLayout>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import AuthLayout from "../components/AuthLayout.vue";
import { forgotPassword } from "../api";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import { computed, reactive } from "vue";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useToast } from "primevue/usetoast";

const toast = useToast();

interface LoginForm {
  email: string;
  isSubmitting: boolean;
}

const form = reactive<LoginForm>({
  email: "",
  isSubmitting: false,
});

const rules = computed(() => ({
  email: { required, email },
}));

const v$ = useVuelidate(rules, form);

const submit = async () => {
  await v$.value.$validate();
  if (v$.value.$invalid) {
    return;
  }

  const result = await forgotPassword(form.email);
  if (result?.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result?.error,
      life: 3000,
    });
  } else {
    await router.push({ name: ROUTE_NAME.RESET_PASSWORD });
  }
};
</script>
