<template>
  <form>
    <FixedActionBar
      button-type="submit"
      :is-loading="isSaving"
      :show-update="true"
      :show-delete="true"
      :show-create-label="form.status === 'draft' ? 'Save Draft' : 'Update'"
      :show-update-label="form.status === 'draft' ? 'Save Draft' : 'Update'"
      :show-update-style="
        form.status === 'draft'
          ? 'bg-surface-500 dark:bg-surface-300 border-surface-500 dark:border-surface-300'
          : ''
      "
      :show-update-label-style="form.status === 'draft' ? 'font-normal' : ''"
      class="mb-6"
      @delete="onConfirmDelete"
      @create="async () => ((await v$.$validate()) ? onSubmitForm() : null)"
      @update="async () => ((await v$.$validate()) ? onSubmitForm() : null)"
    >
      <AppButton
        v-if="form.status === 'draft'"
        class="mr-2"
        @click="async () => ((await v$.$validate()) ? onPublishClick() : null)"
      >
        <span class="p-button-label">Publish</span>
      </AppButton>
    </FixedActionBar>

    <AppCard class="mb-6 animate-fadein animate-duration-1000">
      <div class="flex">
        <div class="col flex flex-col gap-2">
          <label for="title">Title</label>
          <InputText
            id="title"
            v-model="form.title"
            type="text"
            placeholder="Title"
            @blur="v$.title.$touch"
          />
          <small v-if="v$ErrorMessage(v$.title.$errors)" class="text-red-500">
            {{ v$ErrorMessage(v$.title.$errors) }}
          </small>
        </div>
      </div>
      <div class="mt-4">
        <div class="col flex flex-col gap-2">
          <label for="subtitle">Subtitle</label>
          <InputText
            id="subtitle"
            v-model="form.subtitle"
            type="text"
            placeholder="Subtitle"
          />
        </div>
        <div v-if="showPublishDateControl" class="col flex flex-col gap-2">
          <label for="published_at">Published date</label>
          <InputText
            id="published_at"
            v-model="form.published_at"
            type="datetime-local"
            placeholder="Published date"
          />
        </div>
      </div>

      <div class="flex flex-col gap-2 mt-2">
        <label>Body</label>
        <ContentEditor
          :model="form?.body"
          editor-style="min-height: 240px; height: auto;"
          @editor-updated="(d) => (form.body = d)"
        />
      </div>

      <div class="mt-4">
        <div class="col flex flex-col gap-2">
          <label for="training_collection_id">Training Collection</label>
          <Select
            id="training_collection_id"
            v-model="form.training_collection_id"
            :options="trainingCollectionsStore.getAsOptions"
            option-label="label"
            option-value="value"
            filter
            fluid
            :pt="{
              pcFilter: {
                root: {
                  class: 'w-full rounded-md border p-2 border-gray-300 mb-2',
                },
              },
              // @ts-ignore
              option: ({ context }) => ({
                class: `p-2 w-full border-t border-gray-200 hover:bg-gray-200 ${context.selected ? 'bg-gray-300' : ''}`,
              }),
              list: { class: 'cursor-pointer' },
            }"
            @change="changeTrainingCollectionConfirm"
          />
          <small
            v-if="v$ErrorMessage(v$.training_collection_id.$errors)"
            class="text-red-500"
          >
            {{ v$ErrorMessage(v$.training_collection_id.$errors) }}
          </small>
        </div>
      </div>
    </AppCard>
  </form>

  <AppCard class="mb-6">
    <div class="flex justify-between">
      <div class="text-xl font-bold mb-2">Attachments</div>
    </div>
    <div v-if="feedId">
      <Sortable
        :key="`sortable_${generateRandomId()}-${attachments?.length}`"
        :list="attachments"
        item-key="randomId"
        :options="sortableOptions"
        class="flex flex-wrap"
        @update="changeSortOrder"
      >
        <template #item="{ element }">
          <div :key="element?.id" class="draggable mb-6" :data-id="element?.id">
            <div class="relative mr-6">
              <div
                :class="
                  attachments?.length > 1 ? 'pi pi-bars is-link' : undefined
                "
              >
                <AssetThumbnail
                  :key="`${element?.id}-${element?.asset?.file?.thumbnail_url_tpl}`"
                  :asset-prop="getAttachmentAsset(element?.id)"
                  class="mt-2"
                  box-size="140px"
                />
                <div class="flex">
                  <Tag
                    value="Delete"
                    class="cursor-pointer mt-6"
                    severity="danger"
                    @click="() => deleteAttachmentConfirm(element?.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </Sortable>
    </div>
    <div class="">
      <div class="col-span-12">
        <AttachDrillDialog
          v-if="form.training_collection_id"
          :key="`attach-drill-${form.training_collection_id}`"
          :training-collection-id="parseInt(form.training_collection_id)"
          @confirm="onAttachmentCreate"
        />
      </div>
      <div class="col-span-12">
        <FileDropzone
          v-if="feedId"
          :organization-id="organization_id"
          :clear_previous="clear_previous"
          @completed="onFileUploaded"
        />
      </div>
    </div>
  </AppCard>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import Select from "primevue/select";
import Tag from "primevue/tag";
import ContentEditor from "@/shared/components/ContentEditor.vue";
import AttachDrillDialog from "@/modules/feed/components/AttachDrillDialog.vue";
import AssetThumbnail from "@/shared/components/AssetThumbnail.vue";
import FileDropzone from "@/shared/components/FileDropzone.vue";
import { Sortable } from "sortablejs-vue3";
import { computed, onMounted, reactive, ref } from "vue";
import { useTrainingCollectionsStore } from "@/modules/feed/stores/trainingCollectionsStore";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import { updateFeed, removeFeed } from "../api";
import type { AdminDrill } from "@/api/model";

import { feedFormToPostData } from "@/shared/utils/helpers";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
const route = useRoute();
import { useRoute } from "vue-router";
import { helpers, minLength, minValue, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import FixedActionBar from "@/shared/components/FixedActionBar.vue";
import AppCard from "@/shared/components/AppCard.vue";
import { AssetType } from "@/shared/datamodels/asset";
import { useAuthStore } from "@/modules/auth/stores/auth";
import { useFeedPreferenceStore } from "@/modules/feed/stores/feedPreference";
import { format } from "date-fns";
import {
  PublishStatus,
  type AdminPost,
  type AdminPostAttachment,
} from "@/api/model";
import { getSingle } from "@/modules/feed/api";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";
const { defaultOrg } = useAuthStore();

const confirm = useConfirm();
const toast = useToast();
const generateRandomId = () => (Math.random() + 1).toString(36).substring(7);

const isDrill = (object: any): object is Drill => {
  return "id" in object;
};

const showDateControlArg = route?.query?.show_date_control
  ? parseInt((route?.query?.show_date_control).toString())
  : null;
const { shouldShowDateControl } = useFeedPreferenceStore();
let showPublishDateControl = ref(false);

const props = defineProps<{
  initialData?: AdminPost;
}>();

const emit = defineEmits<{
  (e: "update", feed: AdminPost): void;
}>();

const trainingCollectionsStore = useTrainingCollectionsStore();
const isSaving = ref(false);

let clear_previous = ref(false);

let previousTrainingCollection = ref<number>(0);

const feedId = computed(() => props.initialData?.id);

const form = reactive<AdminPost>({
  title: "",
  subtitle: "",
  body: "",
  status: "draft",
  training_collection_id: 0,
  published_at: undefined,
});

let attachments = ref<AdminPostAttachment[]>([]);

const rules = computed(() => ({
  title: { required, minLength: minLength(3) },
  training_collection_id: {
    required,
    minValueValue: helpers.withMessage(
      "This field cannot be empty",
      minValue(1)
    ),
  },
}));

const v$ = useVuelidate(rules, form);

const setAttachments = (attachments: []) => {
  const arr: AdminPostAttachment[] = [];
  for (let i = 0; i < attachments.length; i++) {
    const item = attachments[i];
    arr.push({
      randomId: item?.randomId ? item.randomId : generateRandomId(),
      id: item?.id,
      order: i,
      drill_id: item?.drill ? item?.drill?.id : undefined,
      drill: item?.drill,
      asset_id: item?.asset ? item?.asset?.id : undefined,
      asset: item?.asset,
    });
  }

  return arr;
};

const setFormValues = (feed: AdminPost) => {
  form.title = feed?.title;
  form.subtitle = feed?.subtitle;
  form.body = feed?.body;
  form.training_collection_id = feed?.training_collection?.id;
  previousTrainingCollection.value = feed?.trainingCollection?.id;

  if (feed?.published_at) {
    const date = new Date(feed.published_at);
    form.published_at = format(date, "yyyy-MM-dd'T'HH:mm");
  }
  form.status = feed?.status;

  attachments.value = setAttachments(feed?.attachments || []);
};

let organization_id = ref(-1);

const changeSortOrder = async () => {
  const result = await updateFeed(feedId.value, {
    attachments: attachments.value,
  });

  if ("error" in result) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Attachment order updated.",
      life: 3000,
    });
  }
};
onMounted(async () => {
  showPublishDateControl.value = shouldShowDateControl(showDateControlArg);

  trainingCollectionsStore.fetchTrainingCollections();

  if (props?.initialData) {
    setFormValues(props.initialData);
  }

  if (props?.initialData?.organization_id) {
    organization_id.value = props.initialData.organization_id;
  } else {
    organization_id.value = defaultOrg.id;
  }
});

// create attachment mock before saving to backend
const createAttachmentMock = (data: number | AdminDrill) => {
  let newAttachment: AdminPostAttachment | null = null;
  if (typeof data === "number") {
    newAttachment = {
      id: 0,
      order: attachments.value?.length ?? 0,
      asset_id: data,
      randomId: generateRandomId(),
    };
  }
  if (typeof data === "object" && isDrill(data)) {
    newAttachment = {
      id: 0,
      order: attachments.value?.length ?? 0,
      drill_id: data?.id,
      randomId: generateRandomId(),
    };
  }
  if (!newAttachment) {
    return;
  }

  clear_previous.value = false;

  return newAttachment;
};

const sortableOptions = {
  animation: 100,
  store: {
    set: function (sortable: typeof Sortable) {
      const ids = sortable.toArray();
      for (let i = 0; i < ids.length; i++) {
        const id = parseInt(ids[i]);
        if (attachments.value != undefined) {
          const index = attachments.value.findIndex((e) => e.id === id);
          attachments.value[index].order = i;
        }
      }
    },
  },
};

const getAttachmentAsset = (attachmentId: number) => {
  try {
    const attachment = attachments.value.find((e) => e.id === attachmentId);

    if (attachment?.drill) {
      const drillAttachments = attachment.drill?.drill_assets ?? [];
      if (drillAttachments.length) {
        return drillAttachments[0].asset;
      }
    } else if (attachment?.asset) {
      return attachment?.asset;
    }
  } catch (e) {
    return undefined;
  }
};

const onSubmitForm = async () => {
  await v$.value.$validate();
  if (v$.value.$invalid) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Form is not valid.",
      life: 3000,
    });
    return;
  }
  isSaving.value = true;
  if (feedId.value) {
    await onSubmitUpdate(feedId.value);
  }

  isSaving.value = false;
};

const onSubmitUpdate = async (id: number) => {
  // Ensure we are sending the correct time in UTC format
  form.published_at = getPublishedDate();
  const feedPost = await updateFeed(id, feedFormToPostData(form));
  if ("error" in feedPost) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: feedPost.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Feed updated.",
      life: 10000,
    });

    emit("update", feedPost);
    clear_previous.value = true;
    setFormValues(feedPost);

    await router.push({
      name: ROUTE_NAME.FEED_SINGLE,
      params: { id: feedPost.id },
    });
  }
};
const getPublishedDate = () => {
  return form.published_at
    ? new Date(form.published_at).toISOString()
    : undefined;
};

const onPublishClick = async () => {
  addMixpanelEvent(EVENT_NAMES.Feed.FeedPostPublished, {
    hasAttachement: attachments.value.length > 0,
    hasDrills: attachments.value?.some((e) => e?.drill_id),
  });

  form.status = PublishStatus.published;
  await onSubmitForm();
};
const onAttachmentCreate = async (drill: AdminDrill) => {
  let newAttachments = attachments.value || [];
  newAttachments.push(createAttachmentMock(drill));

  const result = await updateFeed(feedId.value, {
    attachments: newAttachments,
  });

  if ("error" in result) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
      life: 10000,
    });
  } else {
    attachments.value = setAttachments(result?.attachments || []);
    emit("update", result);
  }
};

const onAttachmentRemove = async (id: number | string) => {
  const index = attachments.value?.findIndex((e) => e.id === id);
  if (index === -1) {
    return;
  }

  attachments.value.splice(index, 1);

  if (!feedId.value) {
    return;
  }

  const result = await updateFeed(feedId.value, {
    attachments: attachments.value,
  });
  if ("error" in result) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Attachment deleted.",
      life: 10000,
    });

    attachments.value = setAttachments(result?.attachments || []);
    emit("update", result);
  }
};

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const pollForUpdatedVideo = async (assetId: number) => {
  if (!feedId.value) {
    return;
  }

  // check every 10 seconds for new image...if found exit early
  for (let idx = 0; idx < 5; idx++) {
    await delay(10000);
    const refreshedVideo = await getSingle(feedId.value);

    if ("error" in refreshedVideo) {
      return;
    } else {
      const refreshedVideoAttachments = refreshedVideo?.attachments;

      // find where in the response the assetId is
      const refreshedVideoAttachmentIndex =
        refreshedVideoAttachments?.findIndex((e) => e?.asset?.id === assetId);

      if (refreshedVideoAttachmentIndex > -1) {
        const refreshedVideoAttachment =
          refreshedVideoAttachments[refreshedVideoAttachmentIndex];
        if (refreshedVideoAttachment?.asset?.file?.thumbnail_url_tpl) {
          // now find refreshedVideoAttachment.id in attachments.value
          const attachmentIndex = attachments.value?.findIndex(
            (e) => e.id === refreshedVideoAttachment.id
          );

          if (attachments.value[attachmentIndex]) {
            attachments.value[attachmentIndex] = refreshedVideoAttachment;
            attachments.value = setAttachments(attachments.value);
            emit("update", refreshedVideo);
            return;
          }
        }
      }
    }
  }
};

const onFileUploaded = async (ids: number[]) => {
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    let newAttachments = attachments.value || [];
    if (id) {
      newAttachments.push(createAttachmentMock(id));
    }

    if (!feedId.value) {
      return;
    }

    const result = await updateFeed(feedId.value, {
      attachments: newAttachments,
    });

    if ("error" in result) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: result.error,
        life: 10000,
      });
    } else {
      attachments.value = setAttachments(result?.attachments || []);

      const index = result.attachments?.findIndex((e) => e?.asset?.id === id);
      if (index > -1) {
        const attachment = result?.attachments[index];
        const assetType = attachment?.asset?.type;

        if (assetType === AssetType.video) {
          toast.add({
            severity: "info",
            summary: "Processing",
            detail: "Video is processing.",
            life: 3000,
          });

          // check endpoint for thumbnail
          await pollForUpdatedVideo(attachment?.asset?.id);
        }
      }

      emit("update", result);
    }
  }
};

const deleteAndRedirect = async () => {
  if (!feedId.value) {
    return;
  }

  const feedPost = await removeFeed(feedId.value);

  if (feedPost?.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: feedPost.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Feed deleted.",
      life: 10000,
    });

    await router.push({
      name: ROUTE_NAME.FEED,
    });
  }
};

const onConfirmDelete = async () => {
  confirm.require({
    message: "Are you sure you want to delete this feed item?",
    header: "Delete Feed Item",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        await deleteAndRedirect();
      } catch (e) {}
    },
  });
};

interface changeTrainingCollectionConfirmType {
  value: number;
}

const changeTrainingCollectionConfirm = async (
  event: changeTrainingCollectionConfirmType
) => {
  // check if any attachments are drills
  const hasDrills = attachments.value?.some((e) => e?.drill_id);

  // if there are no drills but training_collection has changed, just update the training_collection so attachments can be added properly later
  if (!hasDrills) {
    const feedPostJustTrainingCollection = await updateFeed(feedId.value, {
      training_collection_id: form?.training_collection_id,
    });
    if ("error" in feedPostJustTrainingCollection) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: feedPostJustTrainingCollection?.error,
        life: 10000,
      });
    } else {
      previousTrainingCollection.value = form.training_collection_id;
    }
  }

  // do not show popup if no drills or previousTrainingCollection is the same as the new training collection
  if (
    !hasDrills ||
    previousTrainingCollection.value === form.training_collection_id
  ) {
    return;
  }

  confirm.require({
    message:
      "Changing a Training Collection will remove any previous drills. Continue?",
    header: "Delete Training Collection Attachments",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    reject: () => {
      form.training_collection_id = previousTrainingCollection.value;
    },
    accept: async () => {
      // need to only get rid of drills - keep everything else
      let attachmentsToSave = [];
      for (let idx = 0; idx < attachments.value?.length; idx++) {
        const attachment = Object.assign({}, attachments.value[idx]);
        if (!attachment.drill_id) {
          attachment.order = idx;
          attachmentsToSave.push(attachment);
        }
      }

      const feedPost = await updateFeed(feedId.value, {
        training_collection_id: form?.training_collection_id,
        attachments: attachmentsToSave,
      });
      if ("error" in feedPost) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: feedPost.error,
          life: 10000,
        });
      } else {
        previousTrainingCollection.value = form.training_collection_id;

        toast.add({
          severity: "success",
          summary: "Attachments updated.",
          life: 3000,
        });

        attachments.value = attachmentsToSave;
      }
    },
  });
};

const deleteAttachmentConfirm = async (id: number) => {
  confirm.require({
    message: "You sure you want to delete this attachment?",
    header: "Delete Attachment",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      await onAttachmentRemove(id);
    },
  });
};
</script>

<style scoped>
.attachment-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  line-height: 1;
  padding: 3px 5px;
  background: white;
  width: 140px;
}

.pending-attachment {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
