import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import {
  adminV1OrganizationsCreate,
  adminV1OrganizationsList,
  adminV1OrganizationsRetrieve,
  adminV1OrganizationsDestroy,
  adminV1OrganizationsPartialUpdate,
} from "@/api/admin-organizations/admin-organizations";
import { adminV1TeamsList } from "@/api/admin-teams/admin-teams";
import {
  adminV1OrganizationsPaymentSettingsRetrieve,
  adminV1OrganizationsPaymentSettingsCreate,
  adminV1OrganizationsPaymentSettingsUpdate,
} from "@/api/admin-payment-settings/admin-payment-settings";
import {
  adminV1CoachesList,
  adminV1CoachesDestroy,
} from "@/api/admin-coaches/admin-coaches";
import type {
  AdminOrganization,
  PaginatedAdminCoachList,
  PaginatedAdminTeamList,
  PatchedAdminOrganization,
  AdminPaymentSetting,
} from "@/api/model";

interface errorMessage {
  error: string;
}

export const createOrganization = async (
  form: NonReadonly<AdminOrganization>
) => {
  try {
    const result = await adminV1OrganizationsCreate(form);

    return result;
  } catch (e) {
    return { error: e.message };
  }
};

export const deleteOrganization = async (orgId: number) => {
  try {
    await adminV1OrganizationsDestroy(orgId);

    router.push({
      name: ROUTE_NAME.ORGANIZATION_LIST,
    });
  } catch (e) {
    return { error: e.message };
  }
};

export const getTeams = async (
  orgId: number,
  limit: number,
  offset: number
) => {
  try {
    const teams = await adminV1TeamsList({
      organization: orgId,
      limit: limit,
      offset: offset,
    });
    return teams || [];
  } catch (e) {
    return {
      error: e.message,
    };
  }
};

export const getCoaches = async (
  orgId: number,
  limit: number,
  offset: number
) => {
  try {
    const coaches =
      (await adminV1CoachesList({
        organization: orgId,
        limit: limit,
        offset: offset,
      })) || [];
    return coaches;
  } catch (e) {
    return {
      error: e.message,
    };
  }
};

interface getCoachesByTeamProps {
  orgId?: number;
  teams: number[];
  limit: number;
  offset: number;
}

export const getCoachesByTeam = async ({
  orgId,
  teams,
  limit,
  offset,
}: getCoachesByTeamProps) => {
  try {
    const coaches =
      (await adminV1CoachesList({
        organization: orgId,
        teams: teams,
        limit: limit,
        offset: offset,
      })) || [];
    return coaches;
  } catch (e) {
    return {
      error: e.message,
    };
  }
};

interface AdminOrganizationType extends AdminOrganization {
  teams?: PaginatedAdminTeamList;
  coaches?: PaginatedAdminCoachList;
}

export const getOrganization = async (
  orgId: number,
  limit: number,
  offset: number
) => {
  let org: AdminOrganizationType;

  try {
    org = (await adminV1OrganizationsRetrieve(orgId)) || {};

    const teams = await adminV1TeamsList({
      organization: orgId,
      offset,
      limit,
    });
    const coaches = await adminV1CoachesList({
      organization: orgId,
      offset,
      limit,
    });
    return {
      data: {
        ...org,
        ...{ teams: teams || [] },
        ...{ coaches: coaches || [] },
      },
      error: "",
    };
  } catch (e) {
    return {
      data: org,
      error: e.message,
    };
  }
};

export const getOrganizations = async (
  is_active = null,
  resultsPerPage = 300,
  offset = null,
  searchTerm = null
) => {
  try {
    let args = {
      is_active: is_active,
      limit: resultsPerPage,
      offset: offset,
    };

    if (searchTerm) {
      args.search = searchTerm;
    }

    const orgs = await adminV1OrganizationsList(args);
    return orgs;
  } catch (e) {
    return { error: e.message };
  }
};

export const updateOrganization = async (
  orgId: number,
  form: PatchedAdminOrganization
): Promise<AdminOrganization | errorMessage> => {
  try {
    const result = await adminV1OrganizationsPartialUpdate(orgId, form);
    return result;
  } catch (e) {
    return { error: e.message };
  }
};

export const deleteCoach = async (coachId: number) => {
  try {
    await adminV1CoachesDestroy(coachId);
  } catch (e) {
    return { error: e.message };
  }
};

export const getPaymentSettings = async (orgId: number) => {
  try {
    const result = await adminV1OrganizationsPaymentSettingsRetrieve(orgId);

    return result;
  } catch (e) {
    return { error: e.message };
  }
};

export const createPaymentSettings = async (
  orgId: number,
  form: NonReadonly<AdminPaymentSetting>
) => {
  try {
    const result = await adminV1OrganizationsPaymentSettingsCreate(orgId, form);

    return result;
  } catch (e) {
    return { error: e.message };
  }
};

export const updatePaymentSettings = async (
  orgId: number,
  form: NonReadonly<AdminPaymentSetting>
) => {
  try {
    const result = await adminV1OrganizationsPaymentSettingsUpdate(orgId, form);

    return result;
  } catch (e) {
    return { error: e.message };
  }
};
