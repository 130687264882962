import http from "@/shared/services/api-client";
import type User from "@/shared/datamodels/user";
import {
  BadRequestError,
  NotFoundError,
  UnauthorizedError,
  UnexpectedError,
} from "@/shared/utils/custom-errors";
import {
  adminV1EmailCheckCreate,
  adminV1LoginCreate,
  adminV1LoginMagicLinkCreate,
  adminV1MagicLinkGenerateCreate,
} from "@/api/admin-auth/admin-auth";
import { adminV1ResetPasswordCreate } from "@/api/admin-auth/admin-auth";
import type {
  AdminEmailCheckResponse,
  AdminTokenObtainPair,
  MagicLinkAuthenticate,
} from "@/api/model";

import type { ApiError } from "@/types";

export const loginWithEmail = async (
  email: string,
  password: string
): Promise<AdminTokenObtainPair | ApiError> => {
  try {
    const result = await adminV1LoginCreate({
      email: email.toLowerCase().trim(),
      password: password,
    } as AdminTokenObtainPair);

    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const loginWithToken = async (token: MagicLinkAuthenticate) => {
  try {
    const result = await adminV1LoginMagicLinkCreate(token);

    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const checkEmail = async (
  email: string
): Promise<AdminEmailCheckResponse | ApiError> => {
  try {
    const result = await adminV1EmailCheckCreate({ email });

    return result;
  } catch (e: any) {
    return { error: e.data };
  }
};

export const sendMagicLinkEmail = async (
  email: string
): Promise<void | ApiError> => {
  try {
    const result = await adminV1MagicLinkGenerateCreate({ email });

    return result;
  } catch (e: any) {
    return { error: e?.data?.detail };
  }
};

export const fetchCurrentUser = async (): Promise<User> => {
  const res = await http.get("/api/admin/v1/profiles/me/");
  if (res?.status == 401) throw new UnauthorizedError();
  if (res?.status != 200) throw new UnexpectedError();
  try {
    return {
      id: res?.data?.id,
      uuid: res?.data?.uuid,
      email: res?.data?.user?.email,
      is_coach: res?.data?.is_coach,
      is_staff: res?.data?.user?.is_staff,
      is_admin: res?.data?.is_admin,
      name: res?.data?.name,
      level_of_play: res?.data?.level_of_play,
      default_organization: {
        id: res?.data?.organization?.id,
        name: res?.data?.organization?.name,
      },
    } as User;
  } catch (e) {
    throw new UnexpectedError();
  }
};

export const forgotPassword = async (
  email: string
): Promise<void | ApiError> => {
  try {
    const result = await adminV1ResetPasswordCreate({
      email: email.toLowerCase().trim(),
    });
    return result;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const changePassword = async (
  code: string,
  new_password: string
): Promise<boolean> => {
  const res = await http.post("/api/v2/change-password/", {
    code,
    new_password,
  });
  const message = "Invalid confirmation code.";
  if (res?.status == 404) throw new NotFoundError(message);
  if (res?.status == 400) throw new BadRequestError(message);
  if (res?.status != 200) throw new UnexpectedError();
  return res?.status == 200;
};
