/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminDrillExercise,
  AdminV1DrillsExercisesListParams,
  DrillsExercisesOrderResponse,
  OrderDrillExercises,
  PaginatedAdminDrillExerciseList,
  PatchedAdminDrillExercise
} from '.././model'
import adminV1DrillsExercisesListMutator from '../../shared/services/axios-client';
import adminV1DrillsExercisesCreateMutator from '../../shared/services/axios-client';
import adminV1DrillsExercisesRetrieveMutator from '../../shared/services/axios-client';
import adminV1DrillsExercisesUpdateMutator from '../../shared/services/axios-client';
import adminV1DrillsExercisesPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1DrillsExercisesDestroyMutator from '../../shared/services/axios-client';
import adminV1DrillsExercisesUpdateOrderCreateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1DrillsExercisesList = (
    params?: AdminV1DrillsExercisesListParams,
 options?: SecondParameter<typeof adminV1DrillsExercisesListMutator>,) => {
      return adminV1DrillsExercisesListMutator<PaginatedAdminDrillExerciseList>(
      {url: `/api/admin/v1/drills_exercises/`, method: 'GET',
        params
    },
      options);
    }
  export const adminV1DrillsExercisesCreate = (
    adminDrillExercise: NonReadonly<AdminDrillExercise>,
 options?: SecondParameter<typeof adminV1DrillsExercisesCreateMutator>,) => {
      return adminV1DrillsExercisesCreateMutator<AdminDrillExercise>(
      {url: `/api/admin/v1/drills_exercises/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminDrillExercise
    },
      options);
    }
  export const adminV1DrillsExercisesRetrieve = (
    id: number,
 options?: SecondParameter<typeof adminV1DrillsExercisesRetrieveMutator>,) => {
      return adminV1DrillsExercisesRetrieveMutator<AdminDrillExercise>(
      {url: `/api/admin/v1/drills_exercises/${id}/`, method: 'GET'
    },
      options);
    }
  export const adminV1DrillsExercisesUpdate = (
    id: number,
    adminDrillExercise: NonReadonly<AdminDrillExercise>,
 options?: SecondParameter<typeof adminV1DrillsExercisesUpdateMutator>,) => {
      return adminV1DrillsExercisesUpdateMutator<AdminDrillExercise>(
      {url: `/api/admin/v1/drills_exercises/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminDrillExercise
    },
      options);
    }
  export const adminV1DrillsExercisesPartialUpdate = (
    id: number,
    patchedAdminDrillExercise: NonReadonly<PatchedAdminDrillExercise>,
 options?: SecondParameter<typeof adminV1DrillsExercisesPartialUpdateMutator>,) => {
      return adminV1DrillsExercisesPartialUpdateMutator<AdminDrillExercise>(
      {url: `/api/admin/v1/drills_exercises/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminDrillExercise
    },
      options);
    }
  export const adminV1DrillsExercisesDestroy = (
    id: number,
 options?: SecondParameter<typeof adminV1DrillsExercisesDestroyMutator>,) => {
      return adminV1DrillsExercisesDestroyMutator<void>(
      {url: `/api/admin/v1/drills_exercises/${id}/`, method: 'DELETE'
    },
      options);
    }
  export const adminV1DrillsExercisesUpdateOrderCreate = (
    orderDrillExercises: OrderDrillExercises[],
 options?: SecondParameter<typeof adminV1DrillsExercisesUpdateOrderCreateMutator>,) => {
      return adminV1DrillsExercisesUpdateOrderCreateMutator<DrillsExercisesOrderResponse>(
      {url: `/api/admin/v1/drills_exercises/update_order/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: orderDrillExercises
    },
      options);
    }
  export type AdminV1DrillsExercisesListResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsExercisesList>>>
export type AdminV1DrillsExercisesCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsExercisesCreate>>>
export type AdminV1DrillsExercisesRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsExercisesRetrieve>>>
export type AdminV1DrillsExercisesUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsExercisesUpdate>>>
export type AdminV1DrillsExercisesPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsExercisesPartialUpdate>>>
export type AdminV1DrillsExercisesDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsExercisesDestroy>>>
export type AdminV1DrillsExercisesUpdateOrderCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1DrillsExercisesUpdateOrderCreate>>>
