<template>
  <form>
    <FixedActionBar
      button-type="submit"
      :is-loading="isSubmitting"
      :show-create="isCreateForm"
      :show-update="!isCreateForm"
      :show-delete="!isCreateForm"
      show-create-label="Save"
      show-update-label="Save"
      class="mb-6"
      @delete="onConfirmDelete"
      @create="onSubmit('Training Collection created.')"
      @update="onSubmit('Training Collection updated.')"
    />

    <AppCard class="mb-6">
      <div class="flex">
        <!-- main_photo -->
        <SingleImageUploaderAndDisplay
          label="Profile image"
          class="mr-20 my-4"
          :disclaimer-text="`250x250 resolution recommended. Allowed file types: ${allowedImageFileExtensionsByKey}.`"
          :max-width="160"
          :image-data="profileImageData?.asset?.file"
          :thumbnail-width="140"
          :organization-id="props.organizationId"
          :allow-delete-button="true"
          @upload-complete="uploadProfileImageComplete"
          @upload-failed="uploadImageFailed"
          @request-delete="uploadProfileImageDelete"
        />

        <!-- cover_photo -->
        <SingleImageUploaderAndDisplay
          label="Background image"
          class="my-4"
          :disclaimer-text="`1170x496 resolution recommended. Allowed file types: ${allowedImageFileExtensionsByKey}.`"
          :max-width="160"
          :image-data="backgroundImageData?.asset?.file"
          :thumbnail-width="140"
          :organization-id="props.organizationId"
          :allow-delete-button="true"
          @upload-complete="uploadBackgroundImageComplete"
          @upload-failed="uploadImageFailed"
          @request-delete="uploadBackgroundImageDelete"
        />
      </div>
    </AppCard>

    <AppCard class="mb-6 animate-fadein animate-duration-1000">
      <div class="text-xl font-bold mb-6">Info</div>
      <div class="col flex flex-col gap-2">
        <label for="name">Name</label>
        <InputText
          id="name"
          v-model="form.name"
          type="text"
          placeholder="Name"
          @blur="v$.name.$touch"
        />
        <small v-if="v$ErrorMessage(v$.name.$errors)" class="text-red-500">
          {{ v$ErrorMessage(v$.name.$errors) }}
        </small>
      </div>

      <div class="col flex flex-col gap-2 h-40 mt-4">
        <label for="description">Description</label>
        <Textarea id="description" v-model="form.description" rows="20" />
      </div>

      <div class="col flex flex-col gap-2 mt-4">
        <label for="show_in_mobile">Show in Mobile App</label>
        <ToggleSwitch id="show_in_mobile" v-model="form.show_in_mobile" />
      </div>
    </AppCard>

    <AppCard class="mb-6">
      <div class="text-xl font-bold mb-6">Comparison videos</div>
      <div
        v-if="
          initialData?.training_collection_comparison_videos?.length || 0 > 0
        "
        class="mb-6 text-sm italic"
      ></div>
      <ComparisonVideos
        v-if="trainingCollectionId"
        :training-collection-id="trainingCollectionId"
        :initial-data="initialData?.training_collection_comparison_videos"
        :organization-id="props.organizationId"
      />
    </AppCard>

    <AppCard class="mb-6">
      <div class="text-xl font-bold mb-6">Drills</div>
      <DrillsList
        :training-collection-id="trainingCollectionId"
        @edit-drill="
          (d: editDrillType) =>
            saveTrainingCollectionPageThenRedirect(d.drillId)
        "
      >
        <AppButton
          size="small"
          label="Create Drill"
          class="mt-6 mb-4"
          @click="
            saveTrainingCollectionPageThenCreateDrillIdAndRedirect(
              trainingCollectionId
            )
          "
        />
      </DrillsList>
    </AppCard>
  </form>
</template>

<script setup lang="ts">
import FixedActionBar from "@/shared/components/FixedActionBar.vue";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import ToggleSwitch from "primevue/toggleswitch";
import SingleImageUploaderAndDisplay from "@/shared/components/SingleImageUploaderAndDisplay.vue";
import { allowedImageFileExtensionsByKey } from "@/shared/constants/uploader";
import ComparisonVideos from "./ComparisonVideos.vue";
import DrillsList from "./DrillsList.vue";
import { computed, onMounted, reactive, ref, type PropType } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { catchErrorMessage } from "@/shared/utils/custom-errors";
import {
  updateTrainingCollection,
  deleteTrainingCollection,
  createTrainingCollectionAsset,
  deleteTrainingCollectionAsset,
} from "@/modules/trainingCollections/api";
import { v$ErrorMessage, enumToSelectOptions } from "@/shared/utils/helpers";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
const route = useRoute();
import { useRoute } from "vue-router";

interface editDrillType {
  drillId: number;
}

let profileImageData = ref();
let backgroundImageData = ref();
const uploadProfileImageComplete = async ({
  assetId,
  asset,
}: {
  assetId: number;
  asset?: Asset;
}) => {
  if (!trainingCollectionId.value) {
    return;
  }

  const results = await createTrainingCollectionAsset({
    asset_id: assetId,
    training_collection_id: trainingCollectionId.value,
    type: "main_photo",
  });

  if ("error" in results) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: results.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Image updated.",
      life: 3000,
    });

    // load asset into component
    profileImageData.value = results;
  }
};

const uploadImageFailed = (d: { error: string }) => {
  if (d.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: d.error,
      life: 10000,
    });
  }
};

interface uploadProfileImageDeleteType {
  assetId: number;
}

const uploadProfileImageDelete = async (d: uploadProfileImageDeleteType) => {
  const trainingCollectionAssetId = profileImageData.value?.id;
  if (!trainingCollectionAssetId) {
    return;
  }

  const deleteAsset = await deleteTrainingCollectionAsset(
    trainingCollectionAssetId
  );
  if (deleteAsset?.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: deleteAsset.error,
      life: 10000,
    });
  } else {
    profileImageData.value = undefined;

    toast.add({
      severity: "success",
      summary: "Image removed.",
      life: 3000,
    });
  }
};

const uploadBackgroundImageComplete = async ({
  assetId,
  asset,
}: {
  assetId: number;
  asset?: Asset;
}) => {
  if (!trainingCollectionId.value) {
    return;
  }

  const results = await createTrainingCollectionAsset({
    asset_id: assetId,
    training_collection_id: trainingCollectionId.value,
    type: "cover_photo",
  });

  if ("error" in results) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: results.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Image updated.",
      life: 3000,
    });

    // load asset into component
    backgroundImageData.value = results;
  }
};

interface uploadProfileImageDeleteType {
  assetId: number;
}

const uploadBackgroundImageDelete = async (d: uploadProfileImageDeleteType) => {
  const trainingCollectionAssetId = backgroundImageData.value?.id;
  if (!trainingCollectionAssetId) {
    return;
  }

  const deleteAsset = await deleteTrainingCollectionAsset(
    trainingCollectionAssetId
  );
  if (deleteAsset?.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: deleteAsset.error,
      life: 10000,
    });
  } else {
    backgroundImageData.value = undefined;

    toast.add({
      severity: "success",
      summary: "Image removed.",
      life: 3000,
    });
  }
};

const emit = defineEmits(["trainingCollection"]);

const toast = useToast();
const confirm = useConfirm();

interface propsType extends AdminTrainingCollection {
  id: number;
  training_collection_comparison_videos: AdminTrainingCollectionComparisonVideo[];
  training_collection_assets: AdminTrainingCollectionAsset[];
}

const props = defineProps<{
  initialData?: propsType;
  organizationId: number;
}>();

const isSubmitting = ref(false);
const deleteDialog = ref(false);

const isCreateForm = computed<boolean>(() => route?.query?.create === "1");
const trainingCollectionId = computed(() => props.initialData?.id || -1);

const form = reactive<
  Pick<AdminTrainingCollection, "name" | "description" | "show_in_mobile">
>({
  name: "",
  description: undefined,
  show_in_mobile: true,
});

const noFutureDates = (argDate: string) => {
  if (!argDate) {
    form.date_of_birth = null;
    return true;
  }

  const today = new Date().getTime();
  const userDate = new Date(argDate).getTime();

  if (userDate > today) {
    return false;
  } else {
    return true;
  }
};

const rules = computed(() => ({
  name: { required },
}));

import type {
  AdminDrill,
  AdminTrainingCollection,
  AdminTrainingCollectionAsset,
  AdminTrainingCollectionComparisonVideo,
  Asset,
} from "@/api/model";

import { createDrill } from "@/modules/trainingCollections/api";

const saveTrainingCollectionPageThenRedirect = async (drillId: number) => {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Please fix the errors above before you can view a drill",
      life: 10000,
    });
    return;
  }

  // save current trainingCollection page
  await onSubmit(undefined);

  // now go to drill page
  await router.push({
    name: ROUTE_NAME.DRILL_UPDATE,
    params: { drill_id: drillId },
  });
};

const saveTrainingCollectionPageThenCreateDrillIdAndRedirect = async (
  trainingCollectionId: number
) => {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Please fix the errors above before you can create a drill",
      life: 10000,
    });
    return;
  }

  // save current trainingCollection page
  await onSubmit(undefined);

  // now go to drill page
  const drill = await createDrill({
    training_collection_id: trainingCollectionId,
  });
  if ("error" in drill) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: drill.error,
      life: 10000,
    });
  } else {
    await router.push({
      name: ROUTE_NAME.DRILL_UPDATE,
      params: { drill_id: drill.id },
      query: {
        create: 1,
      },
    });
  }
};

const setFormValues = (trainingCollection: propsType) => {
  form.name = trainingCollection?.name ?? "";
  form.description = trainingCollection?.description ?? undefined;
  form.show_in_mobile = trainingCollection?.show_in_mobile ?? undefined;
};

const v$ = useVuelidate(rules, form);

onMounted(async () => {
  if (props.initialData) {
    setFormValues(props.initialData);

    profileImageData.value =
      (props.initialData?.training_collection_assets || []).find(
        (d) => d?.type === "main_photo"
      ) || null;
    backgroundImageData.value =
      (props.initialData?.training_collection_assets || []).find(
        (d) => d?.type === "cover_photo"
      ) || null;
  }
});

const onSubmit = async (toastMessage: string | undefined) => {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Please fix the errors and click Save again",
      life: 10000,
    });
    return;
  }

  isSubmitting.value = true;
  if (trainingCollectionId.value) {
    emit("trainingCollection", {
      name: form?.name,
    });
    await onSubmitUpdate(toastMessage);
  }
  isSubmitting.value = false;
};

const onSubmitUpdate = async (toastMessage: string | undefined) => {
  if (!trainingCollectionId.value) {
    return;
  }

  const trainingCollection = await updateTrainingCollection(
    trainingCollectionId.value,
    form
  );
  if ("error" in trainingCollection) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: trainingCollection.error,
      life: 10000,
    });
  } else {
    if (toastMessage) {
      toast.add({
        severity: "success",
        summary: toastMessage,
        life: 10000,
      });
    }

    await router.push({
      name: ROUTE_NAME.TRAINING_COLLECTION_UPDATE,
      params: { id: trainingCollection.id },
    });
  }
};

const deleteAndRedirect = async () => {
  deleteDialog.value = false;
  if (!trainingCollectionId.value) {
    return;
  }
  try {
    const result = await deleteTrainingCollection(trainingCollectionId.value);
    if (result?.error) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: result.error,
        life: 10000,
      });
    } else {
      toast.add({
        severity: "success",
        summary: "Training Collection deleted.",
        life: 10000,
      });

      await router.push({
        name: ROUTE_NAME.TRAINING_COLLECTION_LIST,
      });
    }
  } catch (e: unknown) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 3000,
    });
  }
};

const onConfirmDelete = async () => {
  confirm.require({
    message: "Are you sure you want to delete this Training Collection?",
    header: "Delete Training Collection",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        await deleteAndRedirect();
      } catch (e) {}
    },
  });
};
</script>
