<template>
  <AppCard class="mb-3">
    <div class="flex justify-between items-center">
      <p class="text-xl font-bold m-0">Recipients</p>
      <div>
        <Button class="mr-2" @click="openSelectPlayerDialog">
          Add Players
        </Button>
        <Button @click="openSelectTeamsDialog">Add Teams</Button>
      </div>
    </div>
    <div class="w-full mt-3">
      <SelectPlayerDialog
        v-model:show-player-dialog="showPlayerDialog"
        v-model:selected-recipients="selectedRecipients"
        :players="players"
      />

      <SelectTeamsDialog
        v-model:show-teams-dialog="showTeamsDialog"
        v-model:selected-teams="selectedTeams"
        :players="players"
        v-model:selected-recipients="selectedRecipients"
        :teams="teams"
      />

      <PlayerTable
        v-model:selected-recipients="selectedRecipients"
        :invoiceTotal="props.invoiceTotal"
      />
    </div>
  </AppCard>
</template>

<script setup lang="ts">
import type { Recipient, RecipientProps } from "@/types";
import type { AdminTeam } from "@/api/model";
import { ref, toRefs, type Ref } from "vue";
import Button from "primevue/button";
import AppCard from "@/shared/components/AppCard.vue";
import SelectPlayerDialog from "./SelectPlayerDialog.vue";
import PlayerTable from "./PlayerTable.vue";
import SelectTeamsDialog from "./SelectTeamsDialog.vue";

const props = defineProps<RecipientProps>();
const showPlayerDialog: Ref<boolean> = ref(false);
const showTeamsDialog: Ref<boolean> = ref(false);

const { players } = toRefs(props);

const selectedRecipients = defineModel<Recipient[]>("selectedRecipients", {
  required: true,
});
const selectedTeams = defineModel<AdminTeam[]>("selectedTeams", {
  required: true,
});

const openSelectPlayerDialog = () => {
  showPlayerDialog.value = true;
};

const openSelectTeamsDialog = () => {
  showTeamsDialog.value = true;
};
</script>
