import { type RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import InvoiceList from "./views/InvoiceList.vue";
import CreateInvoice from "./views/CreateInvoice.vue";
import ViewInvoice from "./views/ViewInvoice.vue";
import EditInvoice from "./views/EditInvoice.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/payments/invoices",
    name: ROUTE_NAME.PAYMENT_INVOICES,
    component: InvoiceList,
  },
  {
    path: "/payments/invoices/create",
    name: ROUTE_NAME.CREATE_INVOICE,
    component: CreateInvoice,
  },
  {
    path: "/payments/invoices/:id",
    name: ROUTE_NAME.VIEW_INVOICE,
    component: ViewInvoice,
  },
  {
    path: "/payments/invoices/:id/edit",
    name: ROUTE_NAME.EDIT_INVOICE,
    component: EditInvoice,
  },
];
