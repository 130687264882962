<template></template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { useAuthStore } from "../stores/auth";
import { onMounted } from "vue";
import { useToast } from "primevue/usetoast";

import router from "@/router";

import { ROUTE_NAME } from "@/shared/constants/routes";
import {
  NOT_ADMIN_ERROR_COPY,
  TOKEN_EXPIRED_ERROR_COPY,
} from "@/shared/constants/auth";

const route = useRoute();
const toast = useToast();

const token = route.params.token.toString();

const { login } = useAuthStore();

onMounted(async () => {
  await login({ magicLinkToken: token });

  const { isLoggedInButNotAdmin, isLoggedIn } = useAuthStore();

  if (isLoggedInButNotAdmin) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: NOT_ADMIN_ERROR_COPY,
      life: 10000,
    });

    await router.push({ name: ROUTE_NAME.LOGIN });
  } else if (!isLoggedIn) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: TOKEN_EXPIRED_ERROR_COPY,
      life: 10000,
    });

    await router.push({ name: ROUTE_NAME.LOGIN });
  } else {
    await router.push({ name: ROUTE_NAME.FEED });
  }
});
</script>
