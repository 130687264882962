<template>
  <BaseLayout
    title="Organizations"
    :breadcrumb="[
      { label: 'Organizations', to: { name: ROUTE_NAME.ORGANIZATION_LIST } },
    ]"
  >
    <div v-if="!isSingleOrgUser" class="flex justify-between">
      <div class="mr-6 flex">
        <div class="relative mr-4">
          <i
            v-if="isSearching"
            class="pi pi-spin pi-spinner absolute left-3 top-1/2 transform -translate-y-1/2"
          />
          <i
            v-else
            class="pi pi-search absolute left-3 top-1/2 transform -translate-y-1/2"
          />
          <InputText
            v-model="searchSelection"
            class="pl-10 pr-3 border rounded-md w-full"
            placeholder="Search..."
            @input="onSearchInput"
          />
        </div>
        <Select
          v-model="filterIsActive"
          :options="[
            { name: 'No', id: false },
            { name: 'Yes', id: true },
          ]"
          option-label="name"
          option-value="id"
          show-clear
          placeholder="Is active"
          class="ml-2 md:w-56"
          @change="showActiveToggled"
        />
      </div>
      <AppButton
        v-if="isStaff"
        label="Create"
        @click="router.push({ name: ROUTE_NAME.ORGANIZATION_CREATE })"
      />
    </div>

    <div
      v-if="hasOrganization"
      class="bg-white rounded-border p-12 shadow-sm mt-6"
    >
      <DataTable
        striped-rows
        :value="allOrganizations"
        row-hover
        table-style="min-width: 50rem"
        selection-mode="single"
        @row-click="
          (e) =>
            router.push({
              name: ROUTE_NAME.ORGANIZATION_UPDATE,
              params: { id: e.data.id },
            })
        "
      >
        <Column field="name" header="Name" />
        <Column field="code.code" header="Coach code" />
      </DataTable>

      <Paginator
        :always-show="true"
        :rows="resultsPerPage"
        :total-records="totalRecords"
        :first="first"
        template="
        FirstPageLink
        PrevPageLink
        CurrentPageReport
        NextPageLink
        LastPageLink
        RowsPerPageDropdown"
        current-page-report-template="Showing {first} to {last} of {totalRecords}"
        @page="onPageChange"
      />
    </div>

    <div
      v-if="!hasOrganization"
      class="bg-white rounded-border p-12 shadow-sm mt-6"
    >
      No results found.
    </div>
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import DataTable from "primevue/datatable";
import Select from "primevue/select";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Paginator from "primevue/paginator";
import { onMounted, ref, computed } from "vue";
import { debounce } from "lodash";
import router from "@/router";
import { useRoute } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { getOrganizations } from "@/modules/organizations/api";
import { storeToRefs } from "pinia";

import { useAuthStore } from "@/modules/auth/stores/auth";
const authStore = useAuthStore();
const { isSingleOrgUser, isStaff } = storeToRefs(authStore);

const convertToBool = (str: string) => {
  if (str === "") {
    return undefined;
  } else {
    return str === "true";
  }
};

const route = useRoute();
let paginationNum = ref(parseInt(route?.query?.page || 1));
let queryString = ref((route.query || {}).q || "");
let queryfilterIsActive = ref(convertToBool((route.query || {}).active || ""));
let filterIsActive = ref(null);

let resultsPerPage = 10;

let isSearching = ref(false);
let searchSelection = ref();

const first = computed(
  () => paginationNum.value * resultsPerPage - resultsPerPage
);

const onSearchInput = debounce(async (e: Event) => {
  isSearching.value = true;

  paginationNum.value = 1;

  await getOrganizationsPaginated();

  isSearching.value = false;
}, 500);

const showActiveToggled = async () => {
  paginationNum.value = 1;

  router.push({
    query: {
      page: paginationNum.value ?? undefined,
      q: searchSelection.value ?? undefined,
      active: filterIsActive.value ?? undefined,
    },
  });

  await getOrganizationsPaginated();
};

const onPageChange = async (e: any) => {
  paginationNum.value = e.page + 1;

  router.push({
    query: {
      page: paginationNum.value ?? undefined,
      q: searchSelection.value ?? undefined,
      active: filterIsActive.value ?? undefined,
    },
  });

  await getOrganizationsPaginated();
};

const getOrganizationsPaginated = async () => {
  router.push({
    query: {
      page: paginationNum.value ?? undefined,
      q: searchSelection.value ?? undefined,
      active: filterIsActive.value ?? undefined,
    },
  });

  try {
    const paginatedResults = await getOrganizations(
      filterIsActive.value,
      resultsPerPage,
      (paginationNum.value - 1) * resultsPerPage,
      searchSelection.value
    );
    totalRecords.value = (paginatedResults || {}).count || 0;
    allOrganizations.value = (paginatedResults || {}).results || [];
    hasOrganization.value = totalRecords.value > 0;
  } catch (e) {
    hasOrganization.value = false;
    console.error(e);
  }
};

let allOrganizations = ref([]);
let hasOrganization = ref(true);
let totalRecords = ref(0);
onMounted(async () => {
  if (queryString.value) {
    searchSelection.value = queryString.value;
  }

  if (queryfilterIsActive.value != undefined) {
    filterIsActive.value = queryfilterIsActive.value;
  }

  await getOrganizationsPaginated();
});
</script>
