<template>
  <BaseLayout
    :title="title"
    :breadcrumb="[
      {
        label: 'Workouts',
        to: {
          name: ROUTE_NAME.WORKOUT_LIST,
          query: {
            page: previousPageForBreadcrumb?.page,
            cid: previousPageForBreadcrumb?.cid,
          },
        },
      },
      { label: title },
    ]"
  >
    <InitialPageLoader
      :loading="isLoading"
      list
      :message="!workout ? 'Workout not found.' : undefined"
    />
    <WorkoutForm
      v-if="workout"
      :initial-data="workout"
      @set-workout-form="(d) => (title = d.title)"
    />
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import InitialPageLoader from "@/shared/components/InitialPageLoader.vue";
import WorkoutForm from "../components/WorkoutForm.vue";
import { ref, onMounted } from "vue";
import { getWorkout } from "@/modules/workouts/api";
import { useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import type { AdminWorkout } from "@/api/model";
import { ROUTE_NAME } from "../../../shared/constants/routes";

const toast = useToast();

const route = useRoute();

let title = ref<string>("");

const isLoading = ref(false);
const workout = ref<AdminWorkout | undefined>(undefined);

const workoutId = parseInt((route?.params?.id || "").toString());

interface previousPageForBreadcrumbType {
  cid: number;
  query: {
    page: number;
  };
}

let previousPageForBreadcrumb = ref<previousPageForBreadcrumbType>();

onMounted(async () => {
  await fetch();
});

const fetch = async () => {
  if (!workoutId) {
    return;
  }

  isLoading.value = true;

  const result = await getWorkout(workoutId);
  if ("error" in result) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
      life: 10000,
    });
  } else {
    workout.value = result;

    previousPageForBreadcrumb.value = {
      cid: workout.value?.calendar_id,
      page: Math.floor(workout.value?.day / 28) + 1,
    };
  }

  isLoading.value = false;
};
</script>
