<template>
  <div class="inline-flex flex-grow">
    <AppButton
      class="mr-2 shrink-0 inline-flex"
      label="Copy TFA Content"
      severity="secondary"
      @click="onCopyTfaContentButtonClick"
    />
  </div>

  <Dialog
    v-model:visible="visible"
    modal
    :header="`Copy TFA Content to ${orgName}`"
    :style="{ maxWidth: '500px' }"
  >
    <p>
      This process may take a few minutes to complete, It will be performed in
      the background and you can close this window.
    </p>
    <h4>Select resources to copy:</h4>
    <form class="relative">
      <div class="flex flex-col gap-2">
        <div class="flex items-center gap-2 mb-2">
          <ToggleSwitch v-model="form.training_collections" />
          <div>Copy training collections</div>
        </div>
        <div class="flex items-center gap-2 mb-2">
          <ToggleSwitch v-model="form.workouts" />
          <div>Copy workouts</div>
        </div>
        <div class="flex items-center gap-2 mb-2">
          <ToggleSwitch v-model="exSpecial" @update:model-value="onExChange" />
          <div>Copy exercises</div>
        </div>
      </div>
    </form>

    <div class="flex justify-end">
      <AppButton
        :label="isCopying ? 'Copying…' : 'Copy content'"
        :disabled="isCopying"
        @click="handleCopyButton"
      />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import ToggleSwitch from "primevue/toggleswitch";
import { useToast } from "primevue/usetoast";
import { computed, reactive, ref } from "vue";
import { adminV1OrganizationsCopyTfaContentCreate } from "../../../api/admin-organizations/admin-organizations";

const props = defineProps({
  orgId: { type: Number, required: true },
  orgName: { type: String, required: true },
});

const visible = ref(false);
const isCopying = ref(false);
let form = reactive({
  training_collections: false,
  workouts: false,
  exercises: false,
});
const toast = useToast();
let onExChange = (e: any) => {
  form.exercises = e;
};
let exSpecial = computed(() => {
  return form.exercises || form.workouts;
});

function onCopyTfaContentButtonClick() {
  visible.value = true;
  form.training_collections = false;
  form.exercises = false;
  form.workouts = false;
  isCopying.value = false;
}

async function handleCopyButton() {
  isCopying.value = true;
  try {
    const data = await adminV1OrganizationsCopyTfaContentCreate(props.orgId, {
      is_copy_exercises: form.exercises,
      is_copy_training_collections: form.training_collections,
      is_copy_workouts: form.workouts,
    });
    if (data.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Copying TFA resources...",
        life: 10000,
      });
    }
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Failed",
      detail: e?.message ?? "Failed to copy TFA resources...",
      life: 10000,
    });
  }
}
</script>
