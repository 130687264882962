<template>
  <div class="flex justify-between mb-2">
    <slot />
  </div>

  <InitialPageLoader
    :loading="isLoadingDrills"
    :message="drills.length === 0 ? 'No drills found.' : undefined"
  />

  <Sortable
    :key="`sortable_${(drills && drills?.length > 0) ?? 0}`"
    :list="drills"
    item-key="id"
    :options="sortableOptions"
    class="flex flex-wrap"
    @end="changeSortOrder"
  >
    <template #item="{ element }">
      <div
        :key="element.id"
        class="draggable flex w-3/12"
        :data-id="element?.id"
      >
        <div
          class="col-span-12 flex no-underline is-link"
          @click="saveTrainingCollectionPageThenRedirectDrillPage(element?.id)"
        >
          <div class="col-span-12">
            <div class="pi pi-bars is-link sort-icon"></div>
            <div class="flex">
              <Avatar
                v-if="getDrillThumbnail(element)"
                class="avatar-object-fit mr-2"
                size="large"
                shape="circle"
                :image="getDrillThumbnail(element)"
                :pt="{
                  image: { class: 'w-full h-12' },
                }"
              />
              <div class="no-underline text-surface-900 dark:text-surface-0">
                <div>{{ element.name }}</div>
                <div class="text-xs">
                  {{ element?.drill_assets?.length }} assets
                </div>
              </div>
            </div>

            <div class="mt-2">
              <Tag
                v-for="(tag, i) in element?.tags"
                :key="i"
                :value="tag?.name"
                class="bg-surface-300 dark:bg-surface-500 text-surface-900 dark:text-surface-0 font-light mr-1"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Sortable>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import type { OrderDrills } from "@/api/model/orderDrills";
import Avatar from "primevue/avatar";
import { Sortable } from "sortablejs-vue3";
import Tag from "primevue/tag";
import { getAssetThumbnail } from "@/shared/utils/helpers";
import { changeOrderDrills } from "@/modules/trainingCollections/api";
import { useToast } from "primevue/usetoast";
import type { AdminTrainingCollectionDrillList } from "@/api/model";
import { adminV1DrillsList } from "../../../api/admin-drills/admin-drills";
import InitialPageLoader from "@/shared/components/InitialPageLoader.vue";

const toast = useToast();

const emit = defineEmits(["editDrill"]);

const saveTrainingCollectionPageThenRedirectDrillPage = async (
  drillId: number
) => {
  emit("editDrill", {
    drillId: drillId,
  });
};

const props = defineProps<{
  trainingCollectionId?: number;
}>();

let drills = ref<AdminTrainingCollectionDrillList[]>([]);
let isLoadingDrills = ref<boolean>(false);
const sortableOptions = {
  animation: 100,
  store: {
    set: function (sortable: typeof Sortable) {
      const ids = sortable.toArray();
      for (let i = 0; i < ids.length; i++) {
        const id = parseInt(ids[i]);
        if (drills.value) {
          const index = drills.value.findIndex((e) => e.id === id);
          drills.value[index].order = i;
        }
      }
    },
  },
};

const changeSortOrder = async () => {
  let updatedOrderList: OrderDrills[] = [];

  for (let idx = 0; idx < drills.value.length; idx++) {
    const drill = drills.value[idx];
    const drillOrder = drill?.order ?? -1;
    if (drill?.id && drillOrder > -1) {
      updatedOrderList.push({
        id: drill.id,
        order: drillOrder,
      });
    }
  }
  const result = await changeOrderDrills(updatedOrderList);

  if ("error" in result) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Order updated.",
      life: 3000,
    });
  }
};

const getDrillThumbnail = (drill: AdminTrainingCollectionDrillList) => {
  if (drill?.drill_assets && drill?.drill_assets?.length > 0) {
    return getAssetThumbnail(drill?.drill_assets[0]?.asset);
  }
  return undefined;
};

onMounted(async () => {
  // If trainingCollectionId not pass in, don't load drills
  if (props.trainingCollectionId == -1) {
    return;
  }

  isLoadingDrills.value = true;
  try {
    if (props.trainingCollectionId) {
      const drillsResponse = await adminV1DrillsList({
        training_collection_id: props.trainingCollectionId,
      });

      drills.value = drillsResponse;
    }
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error loading the drills for this Training Collection",
      life: 10000,
    });
  } finally {
    isLoadingDrills.value = false;
  }
});
</script>

<style scoped>
.sort-icon {
  color: #000;
}
</style>
