<template>
  <BaseLayout
    :title="organizationName ? `Organization: ${organizationName}` : ''"
    :breadcrumb="[
      { label: 'Organizations', to: { name: ROUTE_NAME.ORGANIZATION_LIST } },
      { label: organizationName },
    ]"
  >
    <InitialPageLoader :loading="false" list />
    <OrganizationForm
      @organization="(d) => (organizationName = d.organizationName)"
    />
  </BaseLayout>
</template>

<script setup lang="ts">
import { ref } from "vue";
import InitialPageLoader from "@/shared/components/InitialPageLoader.vue";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import OrganizationForm from "../components/OrganizationForm.vue";
import { ROUTE_NAME } from "../../../shared/constants/routes";
const organizationName = ref("");
</script>
