<template>
  <BaseLayout :title="isCreateForm ? 'Create Feed Post' : 'Edit Feed Post'">
    <InitialPageLoader
      :loading="state.isLoadingInitial"
      list
      :message="!state.feedPost ? 'Feed not found.' : undefined"
    />
    <div v-if="state?.feedPost">
      <FeedForm :initial-data="state?.feedPost" @update="onUpdateFeed" />
    </div>
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import InitialPageLoader from "@/shared/components/InitialPageLoader.vue";
import FeedForm from "@/modules/feed/components/FeedForm.vue";
import { onMounted, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { catchErrorMessage } from "@/shared/utils/custom-errors";
import { getSingle } from "@/modules/feed/api";
import { useToast } from "primevue/usetoast";
import type { AdminPost } from "@/api/model";

const isCreateForm = computed<boolean>(() => route?.query?.create === "1");

const toast = useToast();

interface FeedPostState {
  feedPost: AdminPost | null;
  isLoadingInitial: boolean;
}

const route = useRoute();
const state: FeedPostState = reactive({
  feedPost: null,
  isLoadingInitial: false,
});

const fetchFeed = async (feedId: number) => {
  state.isLoadingInitial = true;
  try {
    const data = await getSingle(feedId);
    if ("error" in data) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: data?.error,
        life: 10000,
      });
    } else {
      state.feedPost = data;
    }
  } catch (e: any) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: e.message,
      life: 10000,
    });
  }
  state.isLoadingInitial = false;
};

onMounted(async () => {
  try {
    const feedId = parseInt(route.params.id.toString());
    await fetchFeed(feedId);
  } catch (e: unknown) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 10000,
    });
  }
});

const onUpdateFeed = (data: AdminPost) => {
  state.feedPost = data;
};
</script>
