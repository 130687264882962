<template>
  <ManageAssociatedContactsDialog
    v-model:visible="showContactDialog"
    v-model:recipient="manageRecipient"
  />

  <DataTable
    :value="selectedRecipients"
    table-style="min-width: 50rem"
    striped-rows
  >
    <Column header="Player">
      <template #body="slotProps">
        <div>{{ slotProps.data.name }}</div>
      </template>
    </Column>

    <Column header="Invoice Recipient">
      <template #body="slotProps: { data: Recipient }">
        <div
          class="cursor-pointer"
          v-if="slotProps.data.selectedContact || slotProps.data.defaultContact"
          @click="openManageAssoicatedContact(slotProps.data)"
        >
          <div>{{ getContactName(slotProps.data) }}</div>
          <div>
            {{ getContactEmail(slotProps.data) }}
            <i class="pi pi-pencil" />
          </div>
        </div>

        <div
          v-else
          class="text-red-600 cursor-pointer"
          @click="openManageAssoicatedContact(slotProps.data)"
        >
          Add Invoice Recipient
          <i class="pi pi-plus" />
        </div>
      </template>
    </Column>

    <Column field="formattedInvoiceTotal" header="Invoice Total">
      <template #body="">
        <div>{{ formattedInvoiceTotal }}</div>
      </template>
    </Column>

    <Column field="removeAthlete" header="Remove">
      <template #body="{ data }">
        <Button
          icon="pi pi-times"
          severity="danger"
          text
          rounded
          aria-label="Cancel"
          class="flex align-items-center justify-content-center p-3"
          @click="removeAthlete(data)"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import type { Recipient } from "@/types";
import { computed, ref, type Ref } from "vue";
import ManageAssociatedContactsDialog from "./ManageAssociatedContactsDialog.vue";

import { getFormattedCurrency } from "../helpers";

const manageRecipient: Ref<Recipient | undefined> = ref();
const showContactDialog: Ref<boolean> = ref(false);

const selectedRecipients = defineModel<Recipient[]>("selectedRecipients", {
  required: true,
});

const props = defineProps<{
  invoiceTotal: number;
}>();

const openManageAssoicatedContact = (data: Recipient) => {
  manageRecipient.value = selectedRecipients.value.find(
    (recipient) => recipient.id === data.id
  );

  showContactDialog.value = true;
};

const removeAthlete = (athlete: Recipient) => {
  selectedRecipients.value = selectedRecipients.value.filter(
    (recipient) => recipient.id !== athlete.id
  );
};

const getContactName = (recipient: Recipient) => {
  if (recipient.selectedContact) {
    return recipient.selectedContact.name;
  }

  return recipient.defaultContact?.name;
};

const getContactEmail = (recipient: Recipient) => {
  if (recipient.selectedContact) {
    return recipient.selectedContact.email;
  }

  return recipient.defaultContact?.email;
};

const formattedInvoiceTotal = computed(() => {
  return getFormattedCurrency(props.invoiceTotal);
});
</script>
