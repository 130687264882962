<template>
  <BaseLayout title="Feed">
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 md:col-span-6 lg:col-span-9">
        <Select
          v-model="filter.status"
          :options="enumToSelectOptions(FeedPostStatus, false)"
          placeholder="Status"
          class="w-full md:w-56 mr-6 mb-6"
          show-clear
          @change="filterLoadFeed"
        />

        <Select
          v-model="filter.order_by"
          :options="[
            { name: 'Published - Oldest', id: 'published_at' },
            { name: 'Published - Newest', id: '-published_at' },
            { name: 'Created - Oldest', id: 'created_at' },
            { name: 'Created - Newest', id: '-created_at' },
            { name: 'Views - Least', id: 'view_count' },
            { name: 'Views - Most', id: '-view_count' },
          ]"
          placeholder="Order by"
          option-label="name"
          option-value="id"
          class="w-full md:w-56 mr-6 mb-6"
          show-clear
          @change="filterLoadFeed"
        />

        <Select
          id="training_collection_id"
          v-model="trainingCollection"
          :options="trainingCollectionsStore.getAsOptions"
          option-label="label"
          option-value="value"
          placeholder="Training"
          class="w-full md:w-56 mr-6 mb-6"
          show-clear
          @change="filterByTrainingCollection"
        />
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-3 flex justify-end">
        <div>
          <AppButton
            label="Create Feed Post"
            @click="createFeedPostIdThenRedirect"
          />
        </div>
      </div>
    </div>

    <div
      v-if="!isLoadingInitial"
      class="grid grid-cols-12 gap-6 animate-fadein animate-duration-1000"
    >
      <div v-for="(feedPost, i) in feedPosts" :key="i" class="col-span-12">
        <FeedGridItem :feed-post="feedPost" />
      </div>
    </div>
    <div
      v-if="!isLoadingInitial && hasNextPage"
      class="flex items-center justify-center mt-20 mb-20"
    >
      <AppButton
        :disabled="isLoadingMore"
        :icon="isLoadingMore ? 'pi pi-spin pi-spinner' : undefined"
        label="Load more"
        @click="loadMore"
      />
    </div>
    <InitialPageLoader
      :loading="isLoadingInitial"
      :message="feedPosts.length === 0 ? 'No feed items found.' : undefined"
    />
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import InitialPageLoader from "@/shared/components/InitialPageLoader.vue";
import FeedGridItem from "../components/FeedGridItem.vue";
import Select from "primevue/select";
import { useFeedListStore } from "../stores/feedListStore";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import { catchErrorMessage } from "@/shared/utils/custom-errors";
import { enumToSelectOptions } from "@/shared/utils/helpers";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { FeedPostStatus } from "@/shared/datamodels/feedPost";
import { useToast } from "primevue/usetoast";

import { useTrainingCollectionsStore } from "@/modules/feed/stores/trainingCollectionsStore";
import { createFeedPostId } from "../api";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";
const trainingCollectionsStore = useTrainingCollectionsStore();
const trainingCollection = ref<number | undefined>(undefined);

const createFeedPostIdThenRedirect = async () => {
  const feedPost = await createFeedPostId({
    status: "draft",
    attachments: [],
  });

  if ("error" in feedPost) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: feedPost.error,
      life: 10000,
    });
  } else {
    await router.push({
      name: ROUTE_NAME.FEED_SINGLE,
      params: { id: feedPost.id },
      query: { create: 1 },
    });
  }
};

interface filterByTrainingCollectionType {
  value?: number;
}
const filterByTrainingCollection = async (
  d: filterByTrainingCollectionType
) => {
  const filteredTrainingCollectionId = d?.value;

  if (filteredTrainingCollectionId) {
    trainingCollection.value = filteredTrainingCollectionId;
  }

  filter.value.training_collection_id = filteredTrainingCollectionId;
  await filterLoadFeed();
};
const filterLoadFeed = async () => {
  addMixpanelEvent(EVENT_NAMES.Feed.FeedFiltered, {
    filter: { ...filter.value },
  });
  await loadFeed();
};

const loadFeed = async () => {
  try {
    filter.value.offset = 0;
    currentPage.value = 0;
    isLoadingFilters.value = true;
    await fetch(true);
    if (errorMessage.value) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: errorMessage.value,
        life: 10000,
      });
    }
    isLoadingFilters.value = false;
  } catch (e: unknown) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 10000,
    });
    isLoadingFilters.value = false;
  }
};

const toast = useToast();

const {
  isLoadingInitial,
  isLoadingMore,
  feedPosts,
  filter,
  currentPage,
  hasNextPage,
  errorMessage,
  isLoadingFilters,
} = storeToRefs(useFeedListStore());
const { fetch, loadMore } = useFeedListStore();

onMounted(async () => {
  try {
    trainingCollectionsStore.fetchTrainingCollections();

    await loadFeed();
  } catch (e: unknown) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 10000,
    });
    isLoadingFilters.value = false;
  }
});
</script>
