<template>
  <div class="border-1 border-gray-300 rounded-lg p-4">
    <div class="space-y-4">
      <div class="flex flex-row space-x-4">
        <div class="flex-1">
          <p class="mb-2 font-semibold">New Contact Name</p>
          <div class="field mr-4">
            <InputText
              id="contactName"
              v-model="name"
              type="text"
              placeholder="Name"
              class="w-full"
            />
          </div>

          <small
            v-if="v$ErrorMessage(formValidations.name.$errors)"
            class="error-message"
          >
            {{ v$ErrorMessage(formValidations.name.$errors) }}
          </small>
        </div>

        <div class="flex-1">
          <p class="mb-2 font-semibold">Email</p>
          <div class="field">
            <InputText
              id="contactEmail"
              v-model="email"
              type="text"
              placeholder="Email"
              class="w-full"
            />
          </div>
          <small
            class="error-message"
            v-if="v$ErrorMessage(formValidations.email.$errors)"
          >
            {{ v$ErrorMessage(formValidations.email.$errors) }}
          </small>
        </div>
      </div>

      <span>
        <div class="flex items-center pt-4">
          <Checkbox
            id="primaryContact"
            v-model="primaryContact"
            :binary="true"
          />

          <label class="ml-2" for="primaryContact"
            >Make default invoice recipient</label
          >
        </div>

        <div class="flex space-x-2 justify-end">
          <Button
            label="Back"
            text
            class="p-button-secondary"
            @click="onBack"
          />
          <Button
            label="Save"
            @click="createContact"
            text
            class="p-button-primary"
          />
        </div>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";

import { v$ErrorMessage } from "@/shared/utils/helpers";
import { defineProps, toRefs } from "vue";
import {
  type AssociatedContactForm,
  type CreateAssociatedContactProps,
} from "@/types";

const associatedContactModel = defineModel<AssociatedContactForm>(
  "associatedContactForm",
  {
    required: true,
  }
);

const { name, email, primaryContact } = associatedContactModel.value;

const props = defineProps<CreateAssociatedContactProps>();

const { createContact, onBack } = props;
const { formValidations } = toRefs(props);
</script>
