import type { AdminStatCategory } from "@/api/model";
import { ref, type Ref } from "vue";
import { createStatCategory as apiCreateStatCategory } from "../api";

export function useCreateStatCategory(): {
  statCategoryCreateError: Ref<string | null>;
  createStatCategory: (params: NonReadonly<AdminStatCategory>) => Promise<void>;
  statCategory: Ref<AdminStatCategory | null>;
} {
  const statCategoryCreateError: Ref<string | null> = ref(null);
  const statCategory: Ref<AdminStatCategory | null> = ref(null);

  async function createStatCategory(params: NonReadonly<AdminStatCategory>) {
    statCategoryCreateError.value = null;

    const result = await apiCreateStatCategory(params);

    if ("error" in result) {
      statCategoryCreateError.value = result.error;
    } else {
      statCategory.value = result;
    }
  }

  return {
    statCategoryCreateError,
    createStatCategory,
    statCategory,
  };
}
