import http from "@/shared/services/api-client";
import {
  BadAuthCredentialsError,
  JsonParsingError,
  UnexpectedError,
} from "@/shared/utils/custom-errors";
import {
  adminV1PostsCreate,
  adminV1PostsDestroy,
  adminV1PostsList,
  adminV1PostsPartialUpdate,
  adminV1PostsRetrieve,
} from "@/api/admin-feed/admin-feed";

import { adminV1TrainingCollectionsList } from "@/api/admin-training-collections/admin-training-collections";

import type {
  AdminTrainingCollection,
  AdminPost,
  AdminV1PostsListParams,
  PaginatedAdminPostList,
  PatchedAdminPost,
  SwaggerAdminRequestPost,
  AdminTrainingCollectionDrillList,
} from "@/api/model";
import { EVENT_NAMES, addMixpanelEvent } from "../../shared/utils/analytics";

import { adminV1DrillsList } from "@/api/admin-drills/admin-drills";

interface errorMessage {
  error: string;
}

export const getFeedPosts = async (
  filter: AdminV1PostsListParams
): Promise<PaginatedAdminPostList | errorMessage> => {
  try {
    const results = await adminV1PostsList(filter);
    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getSingle = async (
  feedId: number
): Promise<AdminPost | errorMessage> => {
  try {
    const results = await adminV1PostsRetrieve(feedId);
    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

// if attachments field is not passed -> post will be created without attachments
export const createFeedPost = async (
  data: SwaggerAdminRequestPost
): Promise<AdminPost | errorMessage> => {
  addMixpanelEvent(EVENT_NAMES.Feed.FeedPostCreated);
  try {
    const results = await adminV1PostsCreate(data);
    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const createFeedPostId = async (
  data: Pick<SwaggerAdminRequestPost, "status" | "attachments">
): Promise<AdminPost | errorMessage> => {
  addMixpanelEvent(EVENT_NAMES.Feed.FeedPostCreated);
  try {
    const results = await adminV1PostsCreate(data);
    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

// if attachments field is passed it will be overwritten.
// if attachments field is not passed, attachments will be deleted.
export const updateFeed = async (
  id: number,
  data: PatchedAdminPost
): Promise<AdminPost | errorMessage> => {
  if (data.status === "draft") {
    addMixpanelEvent(EVENT_NAMES.Feed.FeedPostDraftSaved);
  }
  addMixpanelEvent(EVENT_NAMES.Feed.FeedPostEdited, {
    isPublished: data.status === "published",
  });

  try {
    const results = await adminV1PostsPartialUpdate(id, data);
    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const removeFeed = async (id: number): Promise<void | errorMessage> => {
  addMixpanelEvent(EVENT_NAMES.Feed.FeedPostDeleted);
  try {
    const results = await adminV1PostsDestroy(id);
    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getTrainingCollections = async (): Promise<
  AdminTrainingCollection[] | errorMessage
> => {
  try {
    const results = await adminV1TrainingCollectionsList();
    return results?.results || [];
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getDrills = async (): Promise<
  AdminTrainingCollectionDrillList[] | errorMessage
> => {
  try {
    const results = await adminV1DrillsList();

    return results || [];
  } catch (e: any) {
    return { error: e.message };
  }
};
