import { defineStore } from "pinia";
import { ref } from "vue";
import {
  adminV1OrganizationsList,
  adminV1OrganizationsRetrieve,
} from "@/api/admin-organizations/admin-organizations";
import type {
  AdminOrganization,
  PaginatedAdminOrganizationList,
} from "@/api/model";
import type { Ref } from "vue";

export interface OrganizationState {
  organizations: Ref<AdminOrganization[]>;
  defaultOrganization: Ref<AdminOrganization | undefined>;
  loading: Ref<boolean>;
  error: Ref<string | null>;
  fetchOrganizations: () => Promise<void>;
  fetchOrganization: (id: number) => Promise<void>;
  setLoading: (value: boolean) => void;
  setError: (message: string | null) => void;
  setOrganizations: (data: AdminOrganization[]) => void;
  setDefaultOrganization: (data: AdminOrganization | undefined) => void;
}

export const useOrganizationStoreV2 = defineStore(
  "v2:organization",
  (): OrganizationState => {
    const organizations = ref<AdminOrganization[]>([]);
    const defaultOrganization = ref<AdminOrganization | undefined>(undefined);
    const loading = ref<boolean>(false);
    const error = ref<string | null>(null);

    const setLoading = (value: boolean): void => {
      loading.value = value;
    };

    const setError = (message: string | null): void => {
      error.value = message;
    };

    const setOrganizations = (data: AdminOrganization[]): void => {
      organizations.value = data;
    };

    const setDefaultOrganization = (
      data: AdminOrganization | undefined
    ): void => {
      defaultOrganization.value = data;
    };

    const fetchOrganizations = async () => {
      setLoading(true);
      setError(null);
      try {
        const data: PaginatedAdminOrganizationList =
          await adminV1OrganizationsList();
        setOrganizations(data.results ?? []);
      } catch (err) {
        setError("Failed to fetch organizations");
      } finally {
        setLoading(false);
      }
    };

    const fetchOrganization = async (id: number) => {
      setLoading(true);
      setError(null);
      try {
        const data: AdminOrganization = await adminV1OrganizationsRetrieve(id);
        setDefaultOrganization(data);
      } catch (err) {
        setError(`Failed to fetch organization with id ${id}`);
      } finally {
        setLoading(false);
      }
    };

    return {
      organizations,
      defaultOrganization,
      loading,
      error,
      fetchOrganizations,
      fetchOrganization,
      setLoading,
      setError,
      setOrganizations,
      setDefaultOrganization,
    };
  }
);

export type OrganizationStoreType = ReturnType<typeof useOrganizationStoreV2>;
