<template>
  <BaseLayout
    :title="exerciseName ? `Exercise: ${exerciseName}` : ''"
    :breadcrumb="[
      { label: 'Exercises', to: { name: ROUTE_NAME.EXERCISES } },
      { label: exerciseName },
    ]"
  >
    <InitialPageLoader
      :loading="isLoadingInitial"
      list
      :message="!hasExercise ? 'Workout not found.' : undefined"
    />
    <div v-if="form?.name">
      <ExerciseForm
        :key="videoData?.id"
        :initial-data="form"
        :video="videoData"
        @set-exercise="setExercise"
        @exercise="(d) => (exerciseName = d.exerciseName)"
      />
    </div>
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import InitialPageLoader from "@/shared/components/InitialPageLoader.vue";
import ExerciseForm from "../components/ExerciseForm.vue";
import { ref, reactive, computed, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useRoute } from "vue-router";
const route = useRoute();
import { getExercise } from "@/modules/workouts/api";
import type { AdminAsset } from "@/api/model";
import { ROUTE_NAME } from "../../../shared/constants/routes";

const exerciseName = ref("");

const isLoadingInitial = ref(false);

const exerciseId = parseInt(((route.params || {}).id || 0).toString());

interface formType {
  name: string;
  instructions?: string;
  weights?: string;
  organization: number;
  video_id?: number;
}

let form = reactive<formType>({
  name: "",
  instructions: undefined,
  weights: undefined,
  organization: -1,
  video_id: undefined,
});
const rules = computed(() => ({
  name: { required },
}));
const v$ = useVuelidate(rules, form);

const videoData = ref<AdminAsset>();

let hasExercise = ref(true);

const setExercise = async () => {
  if (!exerciseId) {
    return;
  }

  const result = (await getExercise(exerciseId)) || {};

  if ("error" in result) {
    return;
  }

  form.name = result?.name || "";
  form.instructions = result?.instructions || "";
  form.video_id = result?.video?.id;
  form.weights = result?.weights;
  form.organization = result?.organization;

  if (form.name) {
    hasExercise.value = true;
  }

  videoData.value = result?.video || {};
};

onMounted(async () => {
  if (exerciseId) {
    setExercise();
  }
});
</script>
