import {
  adminV1ProfilesPartialUpdate,
  adminV1ProfilesRetrieve,
} from "@/api/admin-profiles/admin-profiles";
import type { PatchedAdminProfile } from "@/api/model";
import axios from "axios";

export const downloadAvatar = async (url: string) => {
  try {
    const result = await axios.get(url, {
      responseType: "arraybuffer",
    });
    const base64Image = btoa(
      new Uint8Array(result.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );

    const contentType = result.headers["content-type"];

    return `data:${contentType};base64, ${base64Image}`;
  } catch (e) {
    return { error: (e as any)?.message };
  }
};

export const getUserMe = async (userId: number) => {
  try {
    const result = await adminV1ProfilesRetrieve(userId);
    return result;
  } catch (e) {
    return { error: (e as any)?.message };
  }
};

export const updateUserProfile = async (
  userId: number,
  form: PatchedAdminProfile
) => {
  try {
    const result = await adminV1ProfilesPartialUpdate(userId, form);
    return result;
  } catch (e) {
    return { error: (e as any)?.message };
  }
};
