<template>
  <div>
    <div v-if="props.label" class="mb-2">
      {{ props.label }}
    </div>

    <div
      v-if="!sources.length"
      class="image-placeholder"
      @click="onUpdate"
    ></div>

    <div v-if="sources.length" class="position-relative">
      <video-player
        :options="{
          autoplay: props?.autoplay ?? false,
        }"
        :asset="assetVideo"
      />
    </div>

    <div v-if="uploadProgress > 0 && uploadProgress < 100" class="col-span-12">
      <ProgressBar :value="uploadProgress" />
    </div>

    <input
      ref="inputRef"
      type="file"
      hidden
      :accept="allowedVideoFileExtensionsByKey"
      @change="onFilePicked"
    />
    <div class="flex mt-6">
      <AppButton
        class="py-1 px-2 text-xs mr-2"
        :disabled="isLoading"
        :label="!!sources.length ? 'Update' : 'Add'"
        @click="onUpdate"
      />
      <AppButton
        v-if="!!sources.length && props.allowDeleteButton"
        severity="danger"
        class="py-1 px-2 text-xs"
        :disabled="isLoading"
        label="Delete"
        @click="onDelete"
      />
    </div>

    <div v-if="props.disclaimerText" class="mt-6 text-sm italic">
      {{ props.disclaimerText }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watchEffect } from "vue";
import { uploadFile } from "@/shared/services/file-upload";
import { UploadableFile } from "@/shared/datamodels/uploadableFile";
import { useToast } from "primevue/usetoast";
import ProgressBar from "primevue/progressbar";
import VideoPlayer from "@/shared/components/VideoPlayer.vue";
import {
  allowedVideoTypes,
  allowedVideoFileExtensionsByKey,
} from "@/shared/constants/uploader";
import type { AdminAsset } from "@/api/model";

const emit = defineEmits(["uploadComplete", "uploadFailed", "requestDelete"]);

let uploadProgress = ref(0);

const uploadStatus = (d: any) => {
  uploadProgress.value = d.progress;
};

const toast = useToast();

const props = defineProps<{
  videoData?: AdminAsset;
  organizationId: number;
  label?: string;
  allowDeleteButton?: boolean;
  disclaimerText?: string;
  autoplay?: boolean;
}>();

const assetVideo = ref<AdminAsset | undefined>();

watchEffect(() => (assetVideo.value = props.videoData));

const inputRef = ref<HTMLInputElement | null>();
const isLoading = ref(false);

const sources = computed(() => {
  let allSources = [];

  if (assetVideo.value?.video?.hls?.cf_url) {
    allSources.push({
      src: assetVideo.value?.video?.hls?.cf_url,
      type: assetVideo.value?.video?.hls?.content_type,
      withCredentials: true,
    });
  }
  if (assetVideo.value?.file?.cf_url) {
    allSources.push({
      src: assetVideo.value?.file?.cf_url,
      type: assetVideo.value?.file?.content_type,
    });
  }

  return allSources;
});

onMounted(() => {
  if (props.videoData) {
    assetVideo.value = props.videoData;
  }
});

const onUpdate = () => {
  inputRef.value?.click();
};

const onDelete = async () => {
  if (!assetVideo.value?.id) {
    return;
  }

  emit("requestDelete", { assetId: assetVideo.value.id });
};

const onFilePicked = async (event: Event) => {
  if (!event.target) {
    return;
  }
  const files = (event.target as HTMLInputElement).files;
  if (!files || files.length == 0) {
    return;
  }
  const file = new UploadableFile(files[0]);

  const matchesType = allowedVideoTypes[file?.file?.type];

  if (!matchesType) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Acceptable file types: ${allowedVideoFileExtensionsByKey}`,
      life: 10000,
    });
    return;
  }
  isLoading.value = true;

  try {
    const asset = await uploadFile(
      props.organizationId,
      new UploadableFile(files[0]),
      uploadStatus
    );

    emit("uploadComplete", { assetId: asset.id, asset: asset });
    isLoading.value = false;
  } catch (e: any) {
    emit("uploadFailed", { error: e.message });
    isLoading.value = false;
  }
};
</script>

<style scoped>
.image-preview-box {
  width: 140px;
  height: 140px;
}

.image-preview-box.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
