import { type Ref, ref, watch } from "vue";
import { getOrganization } from "@/modules/organizations/api";
import type { AdminTeam, Organization } from "@/api/model";
import type { ListOption } from "@/types";
import { useCurrentUser } from "./useCurrentUser";

interface UseOrganizationTeamsReturn {
  orgTeams: Ref<AdminTeam[]>;
  teamIdToCoachesMap: Ref<Record<number, ListOption[]>>;
  fetchAndInitializeData: () => Promise<void>;
}

export function useOrganizationTeams(): UseOrganizationTeamsReturn {
  const orgTeams = ref<AdminTeam[]>([]);
  const teamIdToCoachesMap = ref<Record<number, ListOption[]>>({});

  const fetchAndInitializeData = async () => {
    const { defaultOrganizationId: organizationIdRef } = useCurrentUser();

    if (!organizationIdRef.value) return;
    try {
      const response = await getOrganization(organizationIdRef.value, 100, 0);
      if (response && !response.error) {
        const org: Organization = response.data;
        orgTeams.value = org.teams.results || [];

        buildTeamIdToCoachesMap();
      } else {
        throw new Error(response.error || "Failed to fetch organization data");
      }
    } catch (error) {
      console.error("Failed to fetch organization teams", error);
    }
  };

  const buildTeamIdToCoachesMap = () => {
    if (orgTeams.value?.length === 0) return;
    teamIdToCoachesMap.value = orgTeams.value.reduce(
      (acc, team) => {
        acc[team.id] = team.coaches.map((coach) => ({
          id: coach.profile.id,
          name: String(coach.profile.name),
        }));
        return acc;
      },
      {} as Record<number, ListOption[]>
    );
  };

  watch(
    () => orgTeams,
    () => {
      buildTeamIdToCoachesMap();
    }
  );

  return {
    orgTeams,
    teamIdToCoachesMap,
    fetchAndInitializeData,
  };
}
