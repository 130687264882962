<template>
  <Dialog
    v-model:visible="showTeamsDialog"
    modal
    header="Add Teams"
    :style="{ width: '30vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '90vw' }"
  >
    <MultiSelectList
      :items="props.teams"
      v-model:selectedItems="dirtySelectedTeams"
      searchPlaceholder="Search teams"
    />
    <div class="flex justify-between items-center mt-4">
      <span>{{ dirtySelectedTeams.length }} Teams Selected</span>
      <Button
        label="Confirm"
        class="p-button-primary"
        @click="confirmSelection"
      />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import MultiSelectList from "@/shared/components/MultiSelectList.vue";
import type { Recipient, SelectTeamsDialogProps } from "@/types";
import type { AdminTeam } from "@/api/model";

const props = defineProps<SelectTeamsDialogProps>();
const selectedTeams = defineModel<AdminTeam[]>("selectedTeams", {
  required: true,
});
const selectedRecipients = defineModel<Recipient[]>("selectedRecipients", {
  required: true,
});
const showTeamsDialog = defineModel<boolean>("showTeamsDialog", {
  required: true,
});

const dirtySelectedTeams = ref<AdminTeam[]>([]);

watch(
  selectedTeams,
  (newValue) => {
    dirtySelectedTeams.value = [...newValue];
  },
  { immediate: true }
);

const updateRecipients = () => {
  dirtySelectedTeams.value.forEach((team) => {
    const teamMembers = props.players.filter((recipient) =>
      recipient.teamIds.includes(team.id)
    );
    teamMembers.forEach((member) => {
      if (!selectedRecipients.value.some((r) => r.id === member.id)) {
        selectedRecipients.value.push(member);
      }
    });
  });
};

const confirmSelection = () => {
  selectedTeams.value = dirtySelectedTeams.value;
  updateRecipients();
  selectedTeams.value = [];
  showTeamsDialog.value = false;
};
</script>
