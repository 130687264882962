<template>
  <div class="upload-container">
    <div class="flex flex-col space-y-2">
      <div class="flex items-center justify-start pr-6">
        <div v-if="props.label" class="label shrink-0">
          {{ props.label }}
        </div>

        <div
          v-if="!sources.length"
          class="image-placeholder"
          @click="onUpdate"
        ></div>

        <div v-if="isUploadSuccess" class="upload-success mr-12">
          <span class="file-label">{{ assetVideo?.file?.name }}</span>

          <i class="pi pi-check" style="color: green"></i>
        </div>

        <div class="input-buttons-container">
          <input
            ref="inputRef"
            type="file"
            hidden
            :accept="allowedVideoFileExtensionsByKey"
            @change="onFilePicked"
          />
          <AppButton
            v-if="!isUploadSuccess"
            class="update-button"
            :disabled="uploadInProgress"
            :label="!uploadInProgress ? 'Add' : 'Uploading...'"
            @click="onUpdate"
          />
        </div>

        <div v-if="!uploadInProgress && !isUploadSuccess" class="disclaimer">
          {{ props.disclaimerText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useToast } from "primevue/usetoast";
import { useVideoUpload } from "@/composables/useVideoUpload";

import { allowedVideoFileExtensionsByKey } from "@/shared/constants/uploader";

const emit = defineEmits(["uploadComplete", "uploadFailed", "uploadStarted"]);

const props = defineProps<{
  organizationId: number;
  label?: string;
  allowDeleteButton?: boolean;
  disclaimerText?: string;
}>();

const toast = useToast();
const { uploadInProgress, assetVideo, uploadVideo } = useVideoUpload(
  props.organizationId
);

const isUploadSuccess = ref(false);

const inputRef = ref<HTMLInputElement | null>();

const sources = computed(() => {
  const videoSource = assetVideo.value?.video;
  const fileSource = assetVideo.value?.file;

  const sourceDefinitions = [
    {
      condition: videoSource?.hls?.cf_url,
      src: videoSource?.hls?.cf_url,
      type: videoSource?.hls?.content_type,
      withCredentials: true,
    },
    {
      condition: fileSource?.cf_url,
      src: fileSource?.cf_url,
      type: fileSource?.content_type,
    },
  ];

  return sourceDefinitions
    .filter((source) => source.condition)
    .map(({ condition, ...source }) => source);
});

const onUpdate = () => {
  isUploadSuccess.value = false;
  inputRef.value?.click();
};

const onFilePicked = async (event: Event) => {
  if (!event.target) {
    return;
  }

  emit("uploadStarted");

  const files = (event.target as HTMLInputElement).files;
  if (!files || files.length === 0) {
    return;
  }
  const file = files[0];

  uploadInProgress.value = true;

  try {
    const asset = await uploadVideo(file);

    emit("uploadComplete", { assetId: asset.id, asset });
    isUploadSuccess.value = true;
    uploadInProgress.value = false;
  } catch (e: any) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: e.message,
      life: 10000,
    });
    emit("uploadFailed", { error: e.message });
    isUploadSuccess.value = false;
    uploadInProgress.value = false;
  }
};
</script>

<style scoped>
.upload-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.progress-bar-container {
  flex-grow: 1;
}

.upload-success {
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-label {
  margin-top: 10px;
}

.input-buttons-container {
  display: flex;
  gap: 10px;
}

.update-button {
  padding: 5px 15px;
  margin-top: 5px;
}

.label {
  margin-right: 10px;
  margin-top: 10px;
}

.disclaimer {
  margin-top: 10px;
  clear: both;
  display: block;
  padding-left: 10px;
}
</style>
