import {
  adminV1ProfilesCreate,
  adminV1ProfilesList,
  adminV1ProfilesPartialUpdate,
  adminV1ProfilesRetrieve,
  adminV1ProfilesDestroy,
} from "@/api/admin-profiles/admin-profiles";
import {
  adminV1CoachesDestroy,
  adminV1CoachesCreate,
  adminV1CoachesPartialUpdate,
  adminV1CoachesList,
} from "@/api/admin-coaches/admin-coaches";
import {
  adminV1ProfilesAssociatedContactsCreate,
  adminV1ProfilesAssociatedContactsDestroy,
  adminV1ProfilesAssociatedContactsList,
  adminV1ProfilesAssociatedContactsPartialUpdate,
} from "@/api/admin-profile-associated-contacts/admin-profile-associated-contacts";
import type {
  AdminProfile,
  PatchedAdminCoach,
  AdminProfileAssociatedContact,
  AdminV1AssociatedContactsListParams,
} from "../../api/model";
import {
  adminV1AssociatedContactsAddProfileCreate,
  adminV1AssociatedContactsList,
} from "@/api/admin-associated-contacts/admin-associated-contacts";

export const createUser = async (form: AdminProfile) => {
  try {
    const result = await adminV1ProfilesCreate(form);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const deleteUser = async (userId: number) => {
  try {
    const result = await adminV1ProfilesDestroy(userId);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getAllMembers = async (
  resultsPerPage: number,
  offset: number,
  teamId: number
) => {
  try {
    const result = await adminV1ProfilesList({
      limit: resultsPerPage,
      offset: offset,
      team_id: teamId,
    });
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getAllUsers = async (args: object) => {
  try {
    const result = await adminV1ProfilesList(args);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getUser = async (userId: number) => {
  try {
    const result = await adminV1ProfilesRetrieve(userId);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const updateUser = async (userId: number, form: object) => {
  try {
    const result = await adminV1ProfilesPartialUpdate(userId, form);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const restoreUser = async (userId: number) => {
  try {
    const result = await adminV1ProfilesPartialUpdate(userId, {
      is_active: true,
    });
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const removeAsCoach = async (coachId: number) => {
  try {
    const result = await adminV1CoachesDestroy(coachId);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const updateCoach = async (
  coachId: number,
  formCoach: PatchedAdminCoach
) => {
  try {
    const result = await adminV1CoachesPartialUpdate(coachId, formCoach);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const makeACoach = async (userId: number, organization_id: number) => {
  try {
    const result = await adminV1CoachesCreate({
      profile_id: userId,
      organization_id: organization_id,
    });
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getCoaches = async (resultsPerPage: number, offset: number) => {
  try {
    const result = await adminV1CoachesList({
      limit: resultsPerPage,
      offset: offset,
    });
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const addContactToUser = async (
  profileId: number,
  form: Pick<
    AdminProfileAssociatedContact,
    "email" | "name" | "primary_contact"
  > & { organization: number; profile: number }
) => {
  try {
    const result = await adminV1ProfilesAssociatedContactsCreate(
      profileId,
      form
    );

    return result;
  } catch (e: any) {
    if (e.status && e.status == 409 && e.data) {
      const contactId = e.data?.id;

      try {
        await adminV1AssociatedContactsAddProfileCreate(contactId, {
          profile: form.profile,
          primary_contact: form.primary_contact,
        });

        return e.data.associated_contact;
      } catch (e: any) {
        return { error: e.message };
      }
    }
    return { error: e.message };
  }
};

export const updateAssociatedContactApi = async (
  profileId: number,
  contactId: number,
  form: object
) => {
  try {
    const result = await adminV1ProfilesAssociatedContactsPartialUpdate(
      profileId,
      contactId,
      form
    );
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const removeContactFromUser = async (
  profileId: number,
  contactId: number
) => {
  try {
    const result = await adminV1ProfilesAssociatedContactsDestroy(
      profileId,
      contactId
    );
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getAssociatedContacts = async (profileId: number) => {
  try {
    const response = await adminV1ProfilesAssociatedContactsList(profileId);
    return response;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getOrganizationAssociatedContacts = async (
  params: AdminV1AssociatedContactsListParams
) => {
  try {
    const response = await adminV1AssociatedContactsList(params);
    return response;
  } catch (e: any) {
    return { error: e.message };
  }
};
