import { defineStore } from "pinia";

import { getFeedPosts } from "../api";
import type { AdminPost, AdminV1PostsListParams } from "@/api/model";

interface FeedState {
  feedPosts: AdminPost[] | [];
  isLoadingInitial: boolean;
  isLoadingMore: boolean;
  isLoadingFilters: boolean;
  currentPage: number;
  hasNextPage: boolean;
  errorMessage: string;
  filter: AdminV1PostsListParams;
}

export const useFeedListStore = defineStore("feedListStore", {
  state: (): FeedState => ({
    feedPosts: [],
    isLoadingInitial: false,
    isLoadingMore: false,
    isLoadingFilters: false,
    currentPage: 0,
    hasNextPage: true,
    errorMessage: "",
    filter: {
      limit: 20,
      offset: 0,
      status: undefined,
      order_by: undefined,
      training_collection_id: undefined,
    },
  }),
  actions: {
    async fetch(showLoader: boolean = true): Promise<void> {
      if (showLoader) {
        this.isLoadingInitial = true;
      }
      try {
        const data = await getFeedPosts(this.filter);
        if ("error" in data) {
          this.errorMessage = data?.error;
        } else {
          this.hasNextPage = data?.next != null;
          this.feedPosts = data?.results || [];
        }
      } catch (e) {
        this.errorMessage = e;
      }
      this.isLoadingInitial = false;
    },
    async loadMore(): Promise<void> {
      this.isLoadingMore = true;
      this.currentPage++;
      this.filter.offset = this.currentPage * this.filter.limit;
      try {
        const data = await getFeedPosts(this.filter);
        if ("error" in data) {
          this.errorMessage = data?.error;
        } else {
          this.hasNextPage = data?.next != null;
          this.feedPosts = [...this.feedPosts, ...data?.results];
        }
      } catch (e) {
        this.errorMessage = e;
      }
      this.isLoadingMore = false;
    },
  },
});
