/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminPost,
  AdminV1PostsListParams,
  PaginatedAdminPostList,
  PatchedAdminPost,
  PostLikeResponse,
  SwaggerAdminPostLike,
  SwaggerAdminRequestPost
} from '.././model'
import adminV1PostsListMutator from '../../shared/services/axios-client';
import adminV1PostsCreateMutator from '../../shared/services/axios-client';
import adminV1PostsRetrieveMutator from '../../shared/services/axios-client';
import adminV1PostsUpdateMutator from '../../shared/services/axios-client';
import adminV1PostsPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1PostsDestroyMutator from '../../shared/services/axios-client';
import adminV1PostsLikeCreateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1PostsList = (
    params?: AdminV1PostsListParams,
 options?: SecondParameter<typeof adminV1PostsListMutator>,) => {
      return adminV1PostsListMutator<PaginatedAdminPostList>(
      {url: `/api/admin/v1/posts/`, method: 'GET',
        params
    },
      options);
    }
  /**
 * Create a post

Each of the `attachments` objects should have either `drill_id` or `asset_id`.
 * @summary Create a post
 */
export const adminV1PostsCreate = (
    swaggerAdminRequestPost: NonReadonly<SwaggerAdminRequestPost>,
 options?: SecondParameter<typeof adminV1PostsCreateMutator>,) => {
      return adminV1PostsCreateMutator<AdminPost>(
      {url: `/api/admin/v1/posts/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: swaggerAdminRequestPost
    },
      options);
    }
  export const adminV1PostsRetrieve = (
    id: number,
 options?: SecondParameter<typeof adminV1PostsRetrieveMutator>,) => {
      return adminV1PostsRetrieveMutator<AdminPost>(
      {url: `/api/admin/v1/posts/${id}/`, method: 'GET'
    },
      options);
    }
  /**
 * Update the post

The attachments are deleted and recreated on every update if
`attachments` field exists on request.

Each of the `attachments` objects should have either `drill_id` or `asset_id`.
 * @summary Update the post
 */
export const adminV1PostsUpdate = (
    id: number,
    swaggerAdminRequestPost: NonReadonly<SwaggerAdminRequestPost>,
 options?: SecondParameter<typeof adminV1PostsUpdateMutator>,) => {
      return adminV1PostsUpdateMutator<AdminPost>(
      {url: `/api/admin/v1/posts/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: swaggerAdminRequestPost
    },
      options);
    }
  export const adminV1PostsPartialUpdate = (
    id: number,
    patchedAdminPost: NonReadonly<PatchedAdminPost>,
 options?: SecondParameter<typeof adminV1PostsPartialUpdateMutator>,) => {
      return adminV1PostsPartialUpdateMutator<AdminPost>(
      {url: `/api/admin/v1/posts/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminPost
    },
      options);
    }
  export const adminV1PostsDestroy = (
    id: number,
 options?: SecondParameter<typeof adminV1PostsDestroyMutator>,) => {
      return adminV1PostsDestroyMutator<void>(
      {url: `/api/admin/v1/posts/${id}/`, method: 'DELETE'
    },
      options);
    }
  /**
 * Toggle post like status
 * @summary Toggle post like status
 */
export const adminV1PostsLikeCreate = (
    id: number,
    swaggerAdminPostLike: SwaggerAdminPostLike,
 options?: SecondParameter<typeof adminV1PostsLikeCreateMutator>,) => {
      return adminV1PostsLikeCreateMutator<PostLikeResponse>(
      {url: `/api/admin/v1/posts/${id}/like/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: swaggerAdminPostLike
    },
      options);
    }
  export type AdminV1PostsListResult = NonNullable<Awaited<ReturnType<typeof adminV1PostsList>>>
export type AdminV1PostsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PostsCreate>>>
export type AdminV1PostsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1PostsRetrieve>>>
export type AdminV1PostsUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1PostsUpdate>>>
export type AdminV1PostsPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1PostsPartialUpdate>>>
export type AdminV1PostsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1PostsDestroy>>>
export type AdminV1PostsLikeCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PostsLikeCreate>>>
