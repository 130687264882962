<template>
  <BaseLayout title="Payments & Invoices">
    <div class="flex justify-end">
      <AppButton
        label="Create Invoice"
        @click="router.push({ name: ROUTE_NAME.CREATE_INVOICE })"
      />
    </div>
    <AppCard class="mt-6 animate-fadein animate-duration-1000">
      <Tabs>
        <TabPanel header="All Invoices">
          <DataTable
            :value="invoices"
            striped-rows
            row-hover
            selection-mode="single"
            @row-click="onRowClick"
          >
            <Column header="Title">
              <template #body="slotProps">
                <div class="text-base">
                  <span class="font-bold">
                    {{ getFormattedTitle(slotProps.data) }}
                  </span>
                  <span class="ml-2 text-grey-400 font-normal">
                    {{ getLineItemCount(slotProps.data) }}
                  </span>
                </div>
                <div class="text-surface-500 dark:text-surface-300 text-sm">
                  Created
                  {{ formatDateTime(slotProps.data.invoice_created_at) }}
                </div>
              </template>
            </Column>
            <Column header="Total Amount">
              <template #body="slotProps">
                {{ getFormattedCurrency(slotProps.data.invoice_amount) }}
              </template>
            </Column>
            <Column header="Recipient">
              <template #body="slotProps">
                {{ slotProps.data.customer_first_name }}
                {{ slotProps.data.customer_last_name }}
                <div class="text-surface-400 dark:text-surface-400 text-sm">
                  {{ slotProps.data.profile_name || "Unknown Player" }}
                </div>
              </template>
            </Column>
            <Column header="Due Date">
              <template #body="slotProps">
                {{ formatDate(slotProps.data.invoice_due_date) }}
              </template>
            </Column>
            <Column field="invoice_status" header="Status">
              <template #body="slotProps">
                <span
                  :style="{
                    color: getInvoiceStatusColor(slotProps.data.invoice_status),
                  }"
                >
                  {{ getFormattedInvoiceStatus(slotProps.data.invoice_status) }}
                </span>
              </template>
            </Column>
          </DataTable>

          <Paginator
            :always-show="true"
            :rows="pageSize"
            :total-records="totalRecords"
            template="
              FirstPageLink
              PrevPageLink
              CurrentPageReport
              NextPageLink
              LastPageLink
              RowsPerPageDropdown"
            current-page-report-template="Showing {first} to {last} of {totalRecords}"
            @page="onPageChange"
          />
        </TabPanel>
      </Tabs>
    </AppCard>
  </BaseLayout>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";

import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";

import DataTable, { type DataTableRowClickEvent } from "primevue/datatable";
import Column from "primevue/column";
import TabPanel from "primevue/tabpanel";
import Tabs from "primevue/tabs";
import Paginator, { type PageState } from "primevue/paginator";

import { format } from "date-fns";
import { useToast } from "primevue/usetoast";

import BaseLayout from "@/shared/components/BaseLayout.vue";
import AppCard from "@/shared/components/AppCard.vue";

import { useInvoices } from "@/modules/payments/composables/useInvoices";
import {
  getInvoiceStatusColor,
  getFormattedTitle,
  getLineItemCount,
  getFormattedInvoiceStatus,
} from "../helpers";

const {
  paginatedInvoices,
  fetchInvoices,
  currentPage,
  pageSize,
  totalRecords,
  invoiceFetchError,
} = useInvoices();

const invoices = computed(() => paginatedInvoices.value?.results);

const toast = useToast();

import { getFormattedCurrency } from "../helpers";

if (invoiceFetchError.value) {
  toast.add({
    severity: "error",
    summary: "Error",
    detail: invoiceFetchError.value,
  });
}

onMounted(() => {
  fetchInvoices();
});

const onPageChange = (event: PageState) => {
  currentPage.value = event.page;
  fetchInvoices();
};

const onRowClick = (event: DataTableRowClickEvent) => {
  router.push({
    name: ROUTE_NAME.VIEW_INVOICE,
    params: { id: event.data.invoice_id },
  });
};

const formatDate = (date: string) => {
  if (!date) {
    return "N/A";
  }

  const [year, month, day] = date.split("-").map(Number);
  const parsedDate = new Date(year, month - 1, day); // months are 0-indexed

  if (isNaN(parsedDate.getTime())) {
    return "N/A";
  }

  return format(parsedDate, "MM/dd/yy");
};

const formatDateTime = (date: string) => {
  if (!date) {
    return "N/A";
  }

  const parsedDate = new Date(date);

  if (isNaN(parsedDate.getTime())) {
    return "N/A";
  }

  return format(parsedDate, "MM/dd/yy");
};
</script>
<style scoped>
:deep(.p-datatable .p-datatable-tbody > tr) {
  cursor: pointer;
}
</style>
