import axios, { AxiosError } from "axios";
import { useAuthStore } from "@/modules/auth/stores/auth";
import { API_URL } from "@/shared/utils/env";
import { ErrorMessages, UnexpectedError } from "@/shared/utils/custom-errors";
import qs from "qs";

const getAuthHeaders = () => {
  // return auth header with jwt if user is logged in and request is to the api url
  const { token } = useAuthStore();
  if (token != null) {
    return { Authorization: `Bearer ${token?.access}` };
  } else {
    return {};
  }
};

const instance = axios.create({
  baseURL: API_URL,
  paramsSerializer: {
    serialize: function (params: any) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  },
});

instance.interceptors.request.use((config) => {
  // if url starts with http it means that's external url
  if (!(config.url ?? "").startsWith("http")) {
    config.headers = Object.assign(config.headers, getAuthHeaders());
  }
  return config;
});

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (e) {
    const statusCode = e?.response?.status || 0;

    if (e instanceof AxiosError) {
      const { isLoggedIn, logout } = useAuthStore();

      if ([401].includes(statusCode) && isLoggedIn) {
        // auto logout if 401 Unauthorized response returned from api
        await logout();
        throw new UnexpectedError("Session expired");
      }
      // handle this in LoginView.vue
      if (
        [401].includes(statusCode) &&
        !isLoggedIn &&
        e?.response?.data?.detail === "Password change required"
      ) {
        // pass this through as is
        throw new UnexpectedError("Password change required");
      }
      if ([401].includes(statusCode) && !isLoggedIn) {
        throw new UnexpectedError("Unauthorized");
      }
      if ([409].includes(statusCode)) {
        const conflictData = e?.response?.data;
        return Promise.reject({
          success: false,
          status: 409,
          data: conflictData,
        });
      }
      if ([429].includes(statusCode)) {
        const conflictData = e?.response?.data;
        return Promise.reject({
          success: false,
          status: 429,
          data: conflictData,
        });
      }

      if ([404].includes(statusCode)) {
        const error = e?.response?.data?.error;

        return Promise.reject({
          success: 404,
          status: 404,
          data: error,
        });
      }
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // Extract the response data
    //
    const responseData = e?.response?.data || {};

    // Initialize an array to store error messages
    let errorMessages = [];

    // Check if responseData contains an items array
    if (Array.isArray(responseData.items)) {
      responseData.items.forEach((item) => {
        // Extract keys and values from each item
        const keys = Object.keys(item || {});
        keys.forEach((key) => {
          const values = item[key] || [];
          const rawMessage = Array.isArray(values) ? values.join(", ") : values;

          // Construct error message based on the key
          let errorMessage = "";
          if (
            key === "error" ||
            key === "detail" ||
            key === "non_field_errors"
          ) {
            errorMessage = rawMessage;
          } else {
            errorMessage = `${key}: ${rawMessage}`;
          }
          errorMessages.push(errorMessage);
        });
      });
    }

    const keys = Object.keys(responseData || {});
    const key = keys[0] || "";
    const values = Object.values(responseData || {});
    const firstErrorMessage =
      values.length > 0 ? values.concat() : "Unknown error";
    const rawMessage = Array.isArray(firstErrorMessage)
      ? firstErrorMessage.join(", ")
      : firstErrorMessage;

    if (key == "error" || key == "detail") {
      errorMessages.push(rawMessage);
    } else if (key === "non_field_errors") {
      errorMessages.push(rawMessage);
    } else {
      errorMessages.push(`${key}: ${rawMessage}`);
    }

    const combinedErrorMessage = errorMessages.join("; ");

    if (
      (combinedErrorMessage && statusCode >= 500 && statusCode <= 599) ||
      statusCode == 0
    ) {
      throw new UnexpectedError("Internal Server Error");
    } else if (combinedErrorMessage && statusCode != 401) {
      throw new UnexpectedError(combinedErrorMessage);
    }
  }
);

export default instance;

export type AxiosInstance = ReturnType<typeof instance>;
