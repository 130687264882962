import {
  adminV1StatCategoriesCountRetrieve,
  adminV1StatCategoriesCreate,
  adminV1StatCategoriesDestroy,
  adminV1StatCategoriesList,
  adminV1StatCategoriesRetrieve,
  adminV1StatCategoriesUpdate,
  adminV1StatCategoriesUpdateOrderCreate,
  type AdminV1StatCategoriesListResult,
} from "@/api/admin-stats/admin-stats";

import type {
  AdminStatCategory,
  AdminV1StatCategoriesListParams,
  OrderStatCategories,
} from "@/api/model";

import type { ApiError } from "@/types";

export const getStatCategories = async (
  params: AdminV1StatCategoriesListParams
): Promise<AdminV1StatCategoriesListResult | ApiError> => {
  try {
    const results = await adminV1StatCategoriesList(params);

    return results;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getStatCategory = async (id: number) => {
  try {
    const result = await adminV1StatCategoriesRetrieve(id);

    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const createStatCategory = async (
  params: NonReadonly<AdminStatCategory>
) => {
  try {
    const result = adminV1StatCategoriesCreate(params);

    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const updateStatCategory = async (
  id: number,
  params: NonReadonly<AdminStatCategory>
) => {
  try {
    const result = adminV1StatCategoriesUpdate(id, params);

    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const updateStatCategoryOrder = async (
  orderedStatCategories: OrderStatCategories[]
) => {
  try {
    const result = adminV1StatCategoriesUpdateOrderCreate(
      orderedStatCategories
    );

    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const deleteStatCategory = async (id: number) => {
  try {
    const result = adminV1StatCategoriesDestroy(id);

    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const fetchStatCategoryStatCount = async (id: number) => {
  try {
    const result = await adminV1StatCategoriesCountRetrieve(id);

    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};
