<template>
  <div class="flex flex-row">
    <div>
      <div class="block text-l mt-1">
        <p>
          Invoice Amount
          <span v-if="payerPaysFees">
            <small class="text-gray-700 ml-2">
              (Processing fee will be added, to be paid by the Invoice
              Recipient.)
            </small>
          </span>
        </p>
        <p>{{ formattedLineItemTotal }}</p>
      </div>
    </div>

    <div v-if="selectedRecipientsCount > 1" class="block text-l mt-1 pl-12">
      <p>Group Total</p>
      <p>{{ totalBulkInvoiceAmount }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useOrganizations } from "@/composables/useOrganizations";
import { usePaymentSettings } from "@/composables/usePaymentSettings";
import { getFormattedCurrency } from "../helpers";
import { type AdminPaymentSetting } from "@/api/model";

const props = defineProps<{
  invoiceTotal: number;
  selectedRecipientsCount: number;
}>();

const formattedLineItemTotal = computed(() =>
  getFormattedCurrency(props.invoiceTotal)
);

const totalBulkInvoiceAmount = computed(() => {
  return getFormattedCurrency(
    props.invoiceTotal * props.selectedRecipientsCount
  );
});

const { defaultOrganization } = useOrganizations();
const paymentSettings = ref<AdminPaymentSetting | null>(null);
const payerPaysFees = ref<boolean>(false);

const fetchPayerPaysFees = async () => {
  try {
    const { paymentSettings: orgPaymentSettings, fetchPaymentSettings } =
      usePaymentSettings(defaultOrganization.value?.id as number);
    await fetchPaymentSettings();
    paymentSettings.value = orgPaymentSettings.value;
    if (paymentSettings.value) {
      payerPaysFees.value = paymentSettings.value?.payer_pays_fees || false;
    }
  } catch (error) {
    console.error(error);
  }
};

watch(defaultOrganization, (orgData) => {
  if (orgData) {
    fetchPayerPaysFees();
  }
});

onMounted(() => fetchPayerPaysFees());
</script>
