/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `draft` - Draft
* `published` - Published
 */
export type PublishStatus = typeof PublishStatus[keyof typeof PublishStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishStatus = {
  draft: 'draft',
  published: 'published',
} as const;
