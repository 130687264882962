/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  CompleteMultipart,
  CreateMultiPartUpload,
  CreateUploadUrl,
  PresignedMultipart,
  PresignedUrl
} from '.././model'
import adminV1CompleteMultipartCreateMutator from '../../shared/services/axios-client';
import adminV1UploadUrlCreateMutator from '../../shared/services/axios-client';
import adminV1UploadUrlMultipartCreateMutator from '../../shared/services/axios-client';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  /**
 * Complete a multipart upload session
 * @summary Complete a multipart upload session
 */
export const adminV1CompleteMultipartCreate = (
    completeMultipart: CompleteMultipart,
 options?: SecondParameter<typeof adminV1CompleteMultipartCreateMutator>,) => {
      return adminV1CompleteMultipartCreateMutator<void>(
      {url: `/api/admin/v1/complete-multipart/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: completeMultipart
    },
      options);
    }
  /**
 * Generate a signed URL for uploading a file.
 * @summary Generate a signed URL for uploading a file.
 */
export const adminV1UploadUrlCreate = (
    createUploadUrl: CreateUploadUrl,
 options?: SecondParameter<typeof adminV1UploadUrlCreateMutator>,) => {
      return adminV1UploadUrlCreateMutator<PresignedUrl>(
      {url: `/api/admin/v1/upload-url/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createUploadUrl
    },
      options);
    }
  /**
 * Generate signed URLs for uploading a file in multiple parts
 * @summary Generate signed URLs for uploading a file in multiple parts
 */
export const adminV1UploadUrlMultipartCreate = (
    createMultiPartUpload: CreateMultiPartUpload,
 options?: SecondParameter<typeof adminV1UploadUrlMultipartCreateMutator>,) => {
      return adminV1UploadUrlMultipartCreateMutator<PresignedMultipart>(
      {url: `/api/admin/v1/upload-url-multipart/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createMultiPartUpload
    },
      options);
    }
  export type AdminV1CompleteMultipartCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1CompleteMultipartCreate>>>
export type AdminV1UploadUrlCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1UploadUrlCreate>>>
export type AdminV1UploadUrlMultipartCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1UploadUrlMultipartCreate>>>
