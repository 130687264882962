/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminChatMessage,
  AdminV1ChatMessagesListParams,
  PaginatedAdminChatMessageList,
  PatchedAdminChatMessage
} from '.././model'
import adminV1ChatMessagesListMutator from '../../shared/services/axios-client';
import adminV1ChatMessagesCreateMutator from '../../shared/services/axios-client';
import adminV1ChatMessagesRetrieveMutator from '../../shared/services/axios-client';
import adminV1ChatMessagesUpdateMutator from '../../shared/services/axios-client';
import adminV1ChatMessagesPartialUpdateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1ChatMessagesList = (
    params?: AdminV1ChatMessagesListParams,
 options?: SecondParameter<typeof adminV1ChatMessagesListMutator>,) => {
      return adminV1ChatMessagesListMutator<PaginatedAdminChatMessageList>(
      {url: `/api/admin/v1/chat_messages/`, method: 'GET',
        params
    },
      options);
    }
  export const adminV1ChatMessagesCreate = (
    adminChatMessage: NonReadonly<AdminChatMessage>,
 options?: SecondParameter<typeof adminV1ChatMessagesCreateMutator>,) => {
      return adminV1ChatMessagesCreateMutator<AdminChatMessage>(
      {url: `/api/admin/v1/chat_messages/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminChatMessage
    },
      options);
    }
  export const adminV1ChatMessagesRetrieve = (
    id: number,
 options?: SecondParameter<typeof adminV1ChatMessagesRetrieveMutator>,) => {
      return adminV1ChatMessagesRetrieveMutator<AdminChatMessage>(
      {url: `/api/admin/v1/chat_messages/${id}/`, method: 'GET'
    },
      options);
    }
  export const adminV1ChatMessagesUpdate = (
    id: number,
    adminChatMessage: NonReadonly<AdminChatMessage>,
 options?: SecondParameter<typeof adminV1ChatMessagesUpdateMutator>,) => {
      return adminV1ChatMessagesUpdateMutator<AdminChatMessage>(
      {url: `/api/admin/v1/chat_messages/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminChatMessage
    },
      options);
    }
  export const adminV1ChatMessagesPartialUpdate = (
    id: number,
    patchedAdminChatMessage: NonReadonly<PatchedAdminChatMessage>,
 options?: SecondParameter<typeof adminV1ChatMessagesPartialUpdateMutator>,) => {
      return adminV1ChatMessagesPartialUpdateMutator<AdminChatMessage>(
      {url: `/api/admin/v1/chat_messages/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminChatMessage
    },
      options);
    }
  export type AdminV1ChatMessagesListResult = NonNullable<Awaited<ReturnType<typeof adminV1ChatMessagesList>>>
export type AdminV1ChatMessagesCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1ChatMessagesCreate>>>
export type AdminV1ChatMessagesRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1ChatMessagesRetrieve>>>
export type AdminV1ChatMessagesUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1ChatMessagesUpdate>>>
export type AdminV1ChatMessagesPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1ChatMessagesPartialUpdate>>>
