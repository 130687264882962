<template>
  <BaseLayout
    title="Users"
    :breadcrumb="[{ label: 'Users', to: { name: ROUTE_NAME.USER_LIST } }]"
  >
    <div
      class="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4"
    >
      <div
        class="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 w-full sm:w-auto"
      >
        <div class="relative w-full sm:w-64">
          <i
            v-if="isSearching"
            class="pi pi-spin pi-spinner absolute left-3 top-1/2 transform -translate-y-1/2"
          />
          <i
            v-else
            class="pi pi-search absolute left-3 top-1/2 transform -translate-y-1/2"
          />
          <InputText
            v-model="searchSelection"
            class="pl-10 pr-3 border rounded-md w-full"
            placeholder="Search..."
            @input="filterResults"
          />
        </div>
        <Select
          v-model="typeSelection"
          :options="[
            { name: 'Coach', id: 'coach' },
            { name: 'Athlete', id: 'athlete' },
          ]"
          option-label="name"
          option-value="id"
          show-clear
          placeholder="Type"
          class="w-full sm:w-56"
          @change="filterResults"
        />
        <Select
          v-if="!isSingleOrgUser"
          v-model="organizationSelection"
          :options="organizations"
          option-label="name"
          option-value="id"
          show-clear
          placeholder="Organization"
          class="w-full sm:w-64"
          @change="filterResults"
        />
      </div>
      <AppButton
        label="Create User"
        class="w-full sm:w-auto"
        @click="
          router.push({
            name: ROUTE_NAME.USER_CREATE,
          })
        "
      />
    </div>

    <AppCard v-if="hasUser" class="mt-6 animate-fadein animate-duration-1000">
      <DataTable
        striped-rows
        row-hover
        selection-mode="single"
        :value="allUsers"
        @row-click="
          (e) =>
            router.push({
              name: ROUTE_NAME.USER_UPDATE,
              params: { id: e.data.id },
            })
        "
      >
        <Column field="name" header="Name" />
        <Column field="email" header="Email" />
        <Column
          v-if="!isSingleOrgUser"
          field="organization.name"
          header="Organization"
        />
        <Column header="Team">
          <template #body="slotProps">
            {{
              slotProps.data.teams.map((d: teamsNameType) => d.name).join(", ")
            }}
          </template>
        </Column>
        <Column field="is_active" header="Active">
          <template #body="slotProps">
            <span v-if="slotProps.data.is_active" class="text-green-600"
              >Yes</span
            >
            <span v-else class="text-red-600">No</span>
          </template>
        </Column>
        <Column field="is_admin" header="Admin">
          <template #body="slotProps">
            <span v-if="slotProps.data.is_admin" class="text-green-600"
              >Yes</span
            >
            <span v-else class="text-red-600">No</span>
          </template>
        </Column>
        <Column v-if="isStaff" field="is_staff" header="TFA Staff">
          <template #body="slotProps">
            <span v-if="slotProps.data.is_staff" class="text-green-600"
              >Yes</span
            >
            <span v-else class="text-red-600">No</span>
          </template>
        </Column>
        <Column field="is_coach" header="Coach">
          <template #body="slotProps">
            <span v-if="slotProps.data.is_coach" class="text-green-600"
              >Yes</span
            >
            <span v-else class="text-red-600">No</span>
          </template>
        </Column>
      </DataTable>

      <Paginator
        :always-show="true"
        :rows="resultsPerPage"
        :total-records="totalRecords"
        :first="first"
        template="
        FirstPageLink
        PrevPageLink
        CurrentPageReport
        NextPageLink
        LastPageLink
        RowsPerPageDropdown"
        current-page-report-template="Showing {first} to {last} of {totalRecords}"
        @page="onPageChange"
      />
    </AppCard>

    <AppCard v-if="!hasUser" class="mt-6"> No users found. </AppCard>
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Select, { type SelectChangeEvent } from "primevue/select";
import Paginator from "primevue/paginator";
import { computed, ref, onMounted } from "vue";
import { useToast } from "primevue/usetoast";
import { debounce } from "lodash";
import router from "@/router";
import { useRoute } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import AppCard from "@/shared/components/AppCard.vue";
import { getAllUsers } from "@/modules/users/api";
import { storeToRefs } from "pinia";
interface teamsNameType {
  name: string;
}

const toast = useToast();

import { useAuthStore } from "@/modules/auth/stores/auth";
const authStore = useAuthStore();
const { isSingleOrgUser, isStaff } = storeToRefs(authStore);

import { useOrganizationStore } from "@/modules/organizations/stores/organizationsStore";
import type { AdminProfile } from "@/api/model";
const { getAllOrganizations } = useOrganizationStore();
const { organizations } = storeToRefs(useOrganizationStore());

const route = useRoute();

let searchSelection = ref<string | undefined>(undefined);
let typeSelection = ref<string | undefined>(undefined);
let organizationSelection = ref<number | undefined>(undefined);

let paginationNum = ref(parseInt((route?.query?.page || 1).toString()));
let queryType = ref((route?.query?.type || "").toString());
let querySearch = ref((route?.query?.q || "").toString());
let queryOrg = ref(parseInt((route?.query?.org || "")?.toString()));
const first = computed(
  () => paginationNum.value * resultsPerPage - resultsPerPage
);

let isSearching = ref(false);

let resultsPerPage = 25;

const filterResults = debounce(async (e: SelectChangeEvent | Event) => {
  paginationNum.value = 1;

  await getUsersPaginated();

  hasUser.value = allUsers.value.length > 0;
}, 500);

const onPageChange = async (e: any) => {
  paginationNum.value = e.page + 1;

  await getUsersPaginated();
};

const getUsersPaginated = async () => {
  router.push({
    query: {
      page: paginationNum.value ?? undefined,
      q: searchSelection.value ?? undefined,
      type: typeSelection.value ?? undefined,
      org: organizationSelection.value ?? undefined,
    },
  });

  const allUsersRaw = await getAllUsers({
    limit: resultsPerPage ?? undefined,
    offset: (paginationNum.value - 1) * resultsPerPage,
    search: searchSelection.value ?? undefined,
    is_coach:
      typeSelection.value === "coach"
        ? true
        : typeSelection.value === "athlete"
          ? false
          : undefined,
    organization_id: organizationSelection.value ?? undefined,
  });
  if ("error" in allUsersRaw) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: allUsersRaw.error,
      life: 10000,
    });
  } else {
    const results = allUsersRaw.results || [];

    allUsers.value = results;
    totalRecords.value = (allUsersRaw || {}).count || 0;

    hasUser.value = totalRecords.value > 0;
  }
};

let allUsers = ref<AdminProfile[]>([]);
let totalRecords = ref(0);
let hasUser = ref(true);

onMounted(async () => {
  if (queryType.value) {
    typeSelection.value = queryType.value;
  }
  if (querySearch.value) {
    searchSelection.value = querySearch.value;
  }
  if (queryOrg.value) {
    organizationSelection.value = queryOrg.value;
  }

  await getAllOrganizations();
  await getUsersPaginated();
});
</script>
