import type { RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import SettingsView from "./views/SettingsView.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/admin/settings",
    name: ROUTE_NAME.GLOBAL_SETTINGS,
    component: SettingsView,
  },
];
