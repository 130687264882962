<template>
  <BaseLayout :title="title">
    <AppCard v-if="!noMessage">
      <div
        v-for="(chat, index) in allChats"
        :key="chat.id"
        class="shadow p-2 my-12 rounded-lg"
      >
        <div
          style="
             {
              display: flex;
              max-width: 400px;
            }
          "
        >
          <video-player
            v-if="showVideoOnIndex[index] && chat?.asset?.file?.cf_url"
            :asset="chat?.asset"
          />
        </div>
        <p>
          <strong>
            <template v-if="chat.sender.is_coach">Coach Note:</template>
            <template v-else>Submission Note:</template>
          </strong>
        </p>
        <p>
          <small v-if="chat.created_at" class="mr-2">{{
            format(new Date(chat.created_at), "MM/dd/yy h:mm aa")
          }}</small>
          <template v-if="chat.sender.is_coach">
            <div>
              <Textarea
                id="text"
                v-model="chat.text"
                class="mt-2"
                rows="5"
                cols="50"
                @keyup="updateButtonEnabled[index] = true"
              />

              <div v-if="chat.text && chat.id">
                <AppButton
                  class="py-1 px-2 text-xs mt-2"
                  :disabled="!updateButtonEnabled[index]"
                  :label="'Save Edit'"
                  @click="onUpdateText(index, chat.id, chat.text)"
                />
              </div>
            </div>
          </template>
          <template v-else>
            {{ chat.text }}
          </template>
        </p>
      </div>
    </AppCard>

    <AppCard v-if="noMessage" class="mt-6"> No Message found. </AppCard>
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import { ref, onMounted } from "vue";
import { useToast } from "primevue/usetoast";
import AppCard from "@/shared/components/AppCard.vue";
import { getChat } from "@/modules/chats/api";
import { format } from "date-fns";
import VideoPlayer from "@/shared/components/VideoPlayer.vue";
import Textarea from "primevue/textarea";
import { updateMessage } from "@/modules/chats/api";

const toast = useToast();

import { useRoute } from "vue-router";
import type { AdminChatMessage, AdminDetailChat } from "@/api/model";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";
const route = useRoute();

const chatId = parseInt(route.params.id.toString());

let coachIsRecipient = ref(true);
let chatResponse = ref<AdminDetailChat>();
let allChats = ref<AdminChatMessage[]>([]);
let showVideoOnIndex: { [key: number]: boolean } = {};

let noMessage = ref(false);
let title = ref("");
let isAthleteSubmission = ref(false);
let newCoachMessage = ref("");
let coachId = ref(-1);
let coachName = ref("");
let athleteName = ref("");
let organizationId = ref(-1);
let updateButtonEnabledProps: { [key: number]: boolean } = {};
let updateButtonEnabled = ref(updateButtonEnabledProps);
const onUpdateText = async (
  messageIndex: number,
  selectedMessageId: number,
  messageText: string
) => {
  if (!chatId) {
    return;
  }

  const results = await updateMessage(selectedMessageId, {
    text: messageText,
  });

  if ("error" in results) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: results.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Note updated.",
      life: 3000,
    });

    updateButtonEnabled.value[messageIndex] = false;
  }
};

const getChatResults = async () => {
  if (!chatId) {
    return;
  }

  let videosOnPage: { [key: string]: boolean } = {};

  const results = await getChat(chatId);
  if ("error" in results) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: results.error,
      life: 10000,
    });

    noMessage.value = true;
  } else {
    organizationId.value = results?.organization?.id;

    // set user and coach
    if (results?.sender?.is_coach) {
      coachId.value = results?.sender?.id;
      coachName.value = results?.sender?.name ?? "";
      coachIsRecipient.value = false;
      athleteName.value = results?.recipient?.name ?? "";
    } else if (results?.recipient?.is_coach) {
      coachId.value = results?.recipient?.id;
      coachName.value = results?.recipient?.name ?? "";
      athleteName.value = results?.sender?.name ?? "";
    }

    // only show video once if duplicate - so indicate which message index should show the video
    results.messages.map((d, index) => {
      if (d?.asset?.file?.cf_url && !videosOnPage[d.asset.file.cf_url]) {
        videosOnPage[d.asset.file.cf_url] = true;
        showVideoOnIndex[index] = true;
      }
    });

    if (results.status === "Pending") {
      title.value = "Athlete Submission Review";
      isAthleteSubmission.value = true;
    } else {
      title.value = "Coach Response Review";
    }

    chatResponse.value = results;

    allChats.value = chatResponse.value?.messages || [];

    noMessage.value = allChats.value.length === 0 ? true : false;
  }
};

onMounted(async () => {
  addMixpanelEvent(EVENT_NAMES.VideoSubmissions.SubmissionVideoViewed);
  await getChatResults();
});
</script>
