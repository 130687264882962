<template>
  <BaseLayout
    :title="EDIT_INVOICE_FORM_TITLE"
    :breadcrumb="[
      {
        label: 'Invoices',
        to: {
          name: ROUTE_NAME.PAYMENT_INVOICES,
        },
      },
      {
        label: invoiceTitle,
      },
    ]"
  >
    <div>
      <div class="fixed-action-bar justify-end rounded-md p-2">
        <Button
          text
          icon="pi"
          outlined
          aria-label="Back"
          label="Exit"
          class="mr-2"
          @click="onBack"
        />
        <Button
          type="button"
          label="Update"
          class="shrink-0"
          @click="onUpdate"
        />
      </div>
    </div>

    <div v-if="invoiceFormFields">
      <InvoiceForm
        v-model:invoice-form-fields="invoiceFormFields"
        :field-validations="formValidations"
        :is-edit="true"
      />

      <div>
        <AppCard class="mb-6">
          <div class="mb-4">
            <p class="block text-md font-semibold text-gray-700">Player</p>
            <p class="font-thin">{{ invoice?.profile?.name || "N/A" }}</p>
          </div>

          <div class="mb-4">
            <p class="block text-md font-semibold text-gray-700">
              Invoice Recipient
            </p>
            <p class="font-thin">{{ invoice?.invoice_recipient?.name }}</p>
          </div>
        </AppCard>
      </div>
    </div>

    <AppCard class="mb-6">
      <div class="created-at-container subtext">
        <span class="created-at"
          >Created at: {{ formattedInvoiceCreatedAt }}</span
        >
        <span class="invoice-number"
          >Invoice No: {{ invoice?.invoice_number }}</span
        >
      </div>
    </AppCard>
  </BaseLayout>
</template>

<script setup lang="ts">
import Button from "primevue/button";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import InvoiceForm from "../components/InvoiceForm.vue";
import { useInvoiceForm } from "../composables/useInvoiceForm";
import { useInvoice } from "../composables/useInvoice";
import { computed, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { format } from "date-fns";
import type { UpdateInvoice } from "@/api/model";
import { useUpdateInvoice } from "../composables/useUpdateInvoice";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { EDIT_INVOICE_FORM_TITLE } from "../constants";
import { getFormattedCreatedAt } from "../helpers";
import AppCard from "@/shared/components/AppCard.vue";

const { fetchInvoice, invoice } = useInvoice();
const {
  invoiceFormFields,
  formValidations,
  invoiceTitle,
  invoiceDueDate,
  lineItems,
  playerId,
  contact,
  invoiceCreatedAt,
  buildFormFields,
} = useInvoiceForm();

const { updateInvoice, invoiceUpdateError } = useUpdateInvoice();

const route = useRoute();
const invoiceId = route.params.id.toString();
const toast = useToast();
const confirm = useConfirm();

watch(invoice, (newInvoice) => {
  if (newInvoice) {
    buildFormFields(newInvoice);
  }
});

onMounted(async () => {
  await fetchInvoice(invoiceId);
});

const onUpdate = async () => {
  const isValid = await formValidations.value.$validate();

  if (!isValid) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Please correct the errors before submitting.",
      life: 3000,
    });

    return;
  }

  if (invoiceDueDate.value && lineItems.value && contact.value) {
    confirm.require({
      message: `An updated invoice will be sent to ${contact.value.email}.`,
      header: "Update & Send",
      rejectLabel: "Cancel",
      acceptLabel: "Update & Send",
      accept: async () => {
        if (invoiceDueDate.value && lineItems.value && contact.value) {
          const formattedDueDate = format(invoiceDueDate.value, "yyyy-MM-dd");

          const apiPayload = {
            invoice_due_date: formattedDueDate,
            invoice_title: invoiceTitle.value,
            items: [
              ...lineItems.value.map((item) => ({
                item_product_name: item.itemName,
                item_cost: item.itemPrice,
                item_qty: item.itemQuantity,
                item_categories: [item.itemCategory],
              })),
            ],

            profile_id: playerId.value,
            invoice_recipient_id: contact.value.id,
          } as UpdateInvoice;

          invoiceUpdateError.value = null;

          updateInvoice(invoiceId, apiPayload);

          if (invoiceUpdateError.value) {
            toast.add({
              severity: "error",
              summary: "Error",
              detail: `Error creating invoice: ${invoiceUpdateError.value}`,
              life: 3000,
            });

            return;
          }

          toast.add({
            severity: "success",
            summary: "Success",
            detail: "Invoice Updated Successfully.",
            life: 3000,
          });
        }
      },
    });
  }
};

const onBack = () => {
  router.push({ name: ROUTE_NAME.VIEW_INVOICE, params: { id: invoiceId } });
};

const formattedInvoiceCreatedAt = computed(() => {
  if (!invoiceCreatedAt.value) return;

  return getFormattedCreatedAt(invoiceCreatedAt.value.toString());
});
</script>

<style scoped>
.fixed-action-bar {
  display: flex;
  width: 100%;
}

.created-at-container {
  display: flex;
  justify-content: space-between;
}

.subtext {
  font-weight: 100;
}
</style>
