import type { AxiosRequestConfig } from "axios";
import http from "./api-client";

export default async function customInstance<T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> {
  const { data } = await http({ ...config, ...options });
  return data;
}
