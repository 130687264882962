<template>
  <BaseLayout
    :title="title"
    :breadcrumb="[
      { label: 'Workouts', to: { name: ROUTE_NAME.WORKOUT_LIST } },
      {},
    ]"
  >
    <WorkoutForm @set-workout-form="(d) => (title = d.title)" />
  </BaseLayout>
</template>

<script setup lang="ts">
import { ref } from "vue";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import WorkoutForm from "../components/WorkoutForm.vue";
import { ROUTE_NAME } from "../../../shared/constants/routes";
let title = ref<string>("");
</script>
