/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `MON` - Monday
* `TUE` - Tuesday
* `WED` - Wednesday
* `THU` - Thursday
* `FRI` - Friday
* `SAT` - Saturday
* `SUN` - Sunday
* `NA` - Not Applicable
 */
export type ReminderDayEnum = typeof ReminderDayEnum[keyof typeof ReminderDayEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReminderDayEnum = {
  MON: 'MON',
  TUE: 'TUE',
  WED: 'WED',
  THU: 'THU',
  FRI: 'FRI',
  SAT: 'SAT',
  SUN: 'SUN',
  NA: 'NA',
} as const;
