import type { RouteRecordRaw } from "vue-router";
import {
  FORGOT_PASSWORD_PATH,
  LOGIN_EMAIL_PATH,
  LOGIN_MAGIC_LINK_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  RESET_PASSWORD_PATH,
  ROUTE_NAME,
} from "@/shared/constants/routes";
import LogoutView from "./views/LogoutView.vue";
import ForgotPasswordView from "./views/ForgotPasswordView.vue";
import ResetPasswordView from "./views/ResetPasswordView.vue";
import LoginPasswordView from "./views/LoginPasswordView.vue";
import LoginWelcomeView from "./views/LoginWelcomeView.vue";
import LoginMagicLinkView from "./views/LoginMagicLinkView.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: LOGIN_PATH,
    name: ROUTE_NAME.LOGIN,
    component: LoginWelcomeView,
  },
  {
    path: LOGIN_EMAIL_PATH,
    name: ROUTE_NAME.LOGIN_PASSWORD,
    component: LoginPasswordView,
    props: true,
  },
  {
    path: LOGOUT_PATH,
    name: ROUTE_NAME.LOGOUT,
    component: LogoutView,
  },
  {
    path: FORGOT_PASSWORD_PATH,
    name: ROUTE_NAME.FORGOT_PASSWORD,
    component: ForgotPasswordView,
  },
  {
    path: RESET_PASSWORD_PATH,
    name: ROUTE_NAME.RESET_PASSWORD,
    component: ResetPasswordView,
  },
  {
    path: LOGIN_MAGIC_LINK_PATH,
    name: ROUTE_NAME.LOGIN_MAGIC_LINK,
    component: LoginMagicLinkView,
  },
];
