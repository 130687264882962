<template>
  <div class="asset-box relative" :style="{ width: boxSize, height: boxSize }">
    <div class="cursor-pointer">
      <Image
        v-if="isAssetImage"
        class="image-box rounded-border"
        :style="{ width: boxSize, height: boxSize }"
        :src="thumbnail"
        alt="Image"
        preview
        image-class="image-box-img"
        :image-style="{
          width: '100%',
          height: '100%',
          'object-fit': 'cover',
          'object-position': 'center',
        }"
      />
      <div
        v-if="isAssetVideo"
        class="image-box rounded-border"
        :style="{ width: boxSize, height: boxSize }"
        @click="visible = true"
      >
        <img v-if="thumbnail" :src="thumbnail" alt="" />
        <div v-if="!thumbnail">Video processing</div>
        <i v-if="thumbnail" class="pi pi-play text-3xl video-icon" />
      </div>
      <Dialog
        v-model:visible="visible"
        modal
        header="Video preview"
        :style="{ width: '50vw' }"
      >
        <video-player
          :options="{
            autoplay: true,
          }"
          :asset="props.assetProp"
        />
      </Dialog>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from "vue";
import Image from "primevue/image";
import Dialog from "primevue/dialog";
import { AssetType } from "@/shared/datamodels/asset";
import { getAssetThumbnail } from "@/shared/utils/helpers";
import VideoPlayer from "@/shared/components/VideoPlayer.vue";
import type { AdminAsset } from "@/api/model";

const props = defineProps<{
  assetProp?: AdminAsset;
  boxSize?: string | undefined;
}>();
let asset = ref();

const visible = ref(false);

const thumbnail = computed((): string => {
  if (asset.value) {
    return getAssetThumbnail(asset.value, 600) ?? "";
  }
  return "";
});
const isAssetImage = computed((): boolean => {
  return asset.value?.type === AssetType.image;
});
const isAssetVideo = computed((): boolean => {
  return asset.value?.type === AssetType.video;
});

onMounted(async () => {
  if (props?.assetProp) {
    asset.value = props.assetProp;
  }

  watch(
    () => props?.assetProp,
    () => {
      asset.value = props?.assetProp;
    }
  );
});
</script>

<style scoped>
.asset-box {
  width: 200px;
}

.image-box {
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.video-icon {
  position: absolute;
  color: white;
}

.video-icon-container {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
  .video-icon {
    position: absolute;
  }
}
</style>
