/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminCoach,
  AdminV1CoachesListParams,
  PaginatedAdminCoachList,
  PatchedAdminCoach
} from '.././model'
import adminV1CoachesListMutator from '../../shared/services/axios-client';
import adminV1CoachesCreateMutator from '../../shared/services/axios-client';
import adminV1CoachesRetrieveMutator from '../../shared/services/axios-client';
import adminV1CoachesUpdateMutator from '../../shared/services/axios-client';
import adminV1CoachesPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1CoachesDestroyMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1CoachesList = (
    params?: AdminV1CoachesListParams,
 options?: SecondParameter<typeof adminV1CoachesListMutator>,) => {
      return adminV1CoachesListMutator<PaginatedAdminCoachList>(
      {url: `/api/admin/v1/coaches/`, method: 'GET',
        params
    },
      options);
    }
  export const adminV1CoachesCreate = (
    adminCoach: NonReadonly<AdminCoach>,
 options?: SecondParameter<typeof adminV1CoachesCreateMutator>,) => {
      return adminV1CoachesCreateMutator<AdminCoach>(
      {url: `/api/admin/v1/coaches/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminCoach
    },
      options);
    }
  export const adminV1CoachesRetrieve = (
    id: number,
 options?: SecondParameter<typeof adminV1CoachesRetrieveMutator>,) => {
      return adminV1CoachesRetrieveMutator<AdminCoach>(
      {url: `/api/admin/v1/coaches/${id}/`, method: 'GET'
    },
      options);
    }
  export const adminV1CoachesUpdate = (
    id: number,
    adminCoach: NonReadonly<AdminCoach>,
 options?: SecondParameter<typeof adminV1CoachesUpdateMutator>,) => {
      return adminV1CoachesUpdateMutator<AdminCoach>(
      {url: `/api/admin/v1/coaches/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminCoach
    },
      options);
    }
  export const adminV1CoachesPartialUpdate = (
    id: number,
    patchedAdminCoach: NonReadonly<PatchedAdminCoach>,
 options?: SecondParameter<typeof adminV1CoachesPartialUpdateMutator>,) => {
      return adminV1CoachesPartialUpdateMutator<AdminCoach>(
      {url: `/api/admin/v1/coaches/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminCoach
    },
      options);
    }
  export const adminV1CoachesDestroy = (
    id: number,
 options?: SecondParameter<typeof adminV1CoachesDestroyMutator>,) => {
      return adminV1CoachesDestroyMutator<void>(
      {url: `/api/admin/v1/coaches/${id}/`, method: 'DELETE'
    },
      options);
    }
  export type AdminV1CoachesListResult = NonNullable<Awaited<ReturnType<typeof adminV1CoachesList>>>
export type AdminV1CoachesCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1CoachesCreate>>>
export type AdminV1CoachesRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1CoachesRetrieve>>>
export type AdminV1CoachesUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1CoachesUpdate>>>
export type AdminV1CoachesPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1CoachesPartialUpdate>>>
export type AdminV1CoachesDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1CoachesDestroy>>>
