import type { RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import UserList from "./views/UserList.vue";
import UserUpdate from "./views/UserUpdate.vue";
import UserCreate from "./views/UserCreate.vue";
import { h } from "vue";
import { RouterView } from "vue-router";

export const routes: RouteRecordRaw[] = [
  {
    path: "/users",
    name: ROUTE_NAME.USERS,
    component: { render: () => h(RouterView) },
    redirect: { name: ROUTE_NAME.USER_LIST },
    children: [
      {
        path: "",
        name: ROUTE_NAME.USER_LIST,
        component: UserList,
      },
      {
        path: ":id",
        name: ROUTE_NAME.USER_UPDATE,
        component: UserUpdate,
      },
      {
        path: "create",
        name: ROUTE_NAME.USER_CREATE,
        component: UserCreate,
      },
    ],
  },
];
