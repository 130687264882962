import {
  adminV1TrainingCollectionsCreate,
  adminV1TrainingCollectionsDestroy,
  adminV1TrainingCollectionsList,
  adminV1TrainingCollectionsPartialUpdate,
  adminV1TrainingCollectionsRetrieve,
  adminV1TrainingCollectionsUpdateOrderCreate,
} from "@/api/admin-training-collections/admin-training-collections";
import { adminV1TrainingCollectionsAssetsRetrieve } from "@/api/admin-training-collection-assets/admin-training-collection-assets";
import {
  adminV1DrillsCreate,
  adminV1DrillsDestroy,
  adminV1DrillsPartialUpdate,
  adminV1DrillsRetrieve,
  adminV1DrillsUpdateOrderCreate,
} from "@/api/admin-drills/admin-drills";
import {
  adminV1TrainingCollectionsComparisonVideosCreate,
  adminV1TrainingCollectionsComparisonVideosDestroy,
  adminV1TrainingCollectionsComparisonVideosRetrieve,
  adminV1TrainingCollectionsComparisonVideosUpdateVideosOrderCreate,
} from "@/api/admin-training-collection-comparison-videos/admin-training-collection-comparison-videos";
import {
  adminV1DrillsAssetsCreate,
  adminV1DrillsAssetsDestroy,
  adminV1DrillsAssetsPartialUpdate,
} from "@/api/admin-drill-assets/admin-drill-assets";
import {
  adminV1DrillsExercisesCreate,
  adminV1DrillsExercisesDestroy,
  adminV1DrillsExercisesList,
  adminV1DrillsExercisesPartialUpdate,
  adminV1DrillsExercisesUpdateOrderCreate,
} from "@/api/admin-drill-exercises/admin-drill-exercises";
import type {
  OrderDrills,
  OrderTrainingCollectionComparisonVideos,
  OrderTrainingCollection,
  SwaggerAdminDrill,
  AdminDrill,
  PatchedAdminDrill,
  OrderDrillExercises,
  AdminRetrieveTrainingCollection,
  PatchedAdminRetrieveTrainingCollection,
  AdminV1TrainingCollectionsListParams,
  PaginatedAdminListTrainingCollectionList,
  AdminDrillExercise,
  PaginatedAdminDrillExerciseList,
  AdminTrainingCollectionComparisonVideo,
  AdminDrillAsset,
  PatchedAdminDrillAsset,
  PatchedAdminDrillExercise,
  PaginatedAdminTagList,
  AdminTrainingCollectionAsset,
  OrderTrainingCollectionComparisonVideosResponse,
} from "../../api/model";
import { adminV1TagsList } from "@/api/admin-tags/admin-tags";
import {
  adminV1TrainingCollectionsAssetsCreate,
  adminV1TrainingCollectionsAssetsDestroy,
} from "@/api/admin-training-collection-assets/admin-training-collection-assets";
import { EVENT_NAMES, addMixpanelEvent } from "../../shared/utils/analytics";

interface errorMessage {
  error: string;
}

export const getTrainingCollections = async (
  filter: AdminV1TrainingCollectionsListParams
): Promise<PaginatedAdminListTrainingCollectionList | errorMessage> => {
  try {
    const results = await adminV1TrainingCollectionsList(filter);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const getSingle = async (
  id: number
): Promise<AdminRetrieveTrainingCollection | errorMessage> => {
  try {
    const results = await adminV1TrainingCollectionsRetrieve(id);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const createTrainingCollection = async (): Promise<
  AdminRetrieveTrainingCollection | errorMessage
> => {
  try {
    // TODO what do we want to do here...
    addMixpanelEvent(EVENT_NAMES.TrainingCollections.TrainingCollectionCreated);
    const results = await adminV1TrainingCollectionsCreate({});
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const updateTrainingCollection = async (
  id: number,
  data: PatchedAdminRetrieveTrainingCollection
): Promise<AdminRetrieveTrainingCollection | errorMessage> => {
  try {
    const results = await adminV1TrainingCollectionsPartialUpdate(id, data);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const deleteTrainingCollection = async (
  id: number
): Promise<void | errorMessage> => {
  try {
    const results = await adminV1TrainingCollectionsDestroy(id);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const createComparisonVideo = async (
  data: Pick<
    AdminTrainingCollectionComparisonVideo,
    "training_collection_id" | "asset_id" | "order"
  >
): Promise<AdminTrainingCollectionComparisonVideo | errorMessage> => {
  try {
    // Todo: same
    addMixpanelEvent(
      EVENT_NAMES.TrainingCollections.TrainingComparisonVideoAdded
    );
    const results =
      await adminV1TrainingCollectionsComparisonVideosCreate(data);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const getComparisonVideo = async (
  id: number
): Promise<AdminTrainingCollectionComparisonVideo | errorMessage> => {
  try {
    const results =
      await adminV1TrainingCollectionsComparisonVideosRetrieve(id);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const deleteComparisonVideo = async (
  id: number
): Promise<void | errorMessage> => {
  try {
    const results = await adminV1TrainingCollectionsComparisonVideosDestroy(id);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const changeOrderComparisonVideo = async (
  videos: OrderTrainingCollectionComparisonVideos[]
): Promise<OrderTrainingCollectionComparisonVideosResponse | errorMessage> => {
  try {
    const results =
      await adminV1TrainingCollectionsComparisonVideosUpdateVideosOrderCreate(
        videos
      );
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const getDrill = async (
  id: number
): Promise<AdminDrill | errorMessage> => {
  try {
    const results = await adminV1DrillsRetrieve(id);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const createDrill = async (
  data: Pick<SwaggerAdminDrill, "training_collection_id">
): Promise<AdminDrill | errorMessage> => {
  try {
    addMixpanelEvent(EVENT_NAMES.TrainingCollections.TrainingDrillCreated);
    const results = await adminV1DrillsCreate(data);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const updateDrill = async (
  id: number,
  data: PatchedAdminDrill
): Promise<AdminDrill | errorMessage> => {
  try {
    const results = await adminV1DrillsPartialUpdate(id, data);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const deleteDrill = async (id: number): Promise<void | errorMessage> => {
  try {
    const results = await adminV1DrillsDestroy(id);
    addMixpanelEvent(EVENT_NAMES.TrainingCollections.TrainingDrillDeleted);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const changeOrderExerciseDrills = async (
  drills: OrderDrillExercises[]
) => {
  try {
    const results = await adminV1DrillsExercisesUpdateOrderCreate(drills);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const changeOrderTrainingCollections = async (
  orderTrainingCollections: OrderTrainingCollection[]
) => {
  try {
    const results = await adminV1TrainingCollectionsUpdateOrderCreate(
      orderTrainingCollections
    );
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const changeOrderDrills = async (drills: OrderDrills[]) => {
  try {
    const results = await adminV1DrillsUpdateOrderCreate(drills);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const createTrainingCollectionAsset = async (
  data: Pick<
    AdminTrainingCollectionAsset,
    "asset_id" | "training_collection_id" | "type"
  >
): Promise<AdminTrainingCollectionAsset | errorMessage> => {
  try {
    const results = await adminV1TrainingCollectionsAssetsCreate(data);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const deleteTrainingCollectionAsset = async (
  id: number
): Promise<void | errorMessage> => {
  try {
    const results = await adminV1TrainingCollectionsAssetsDestroy(id);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const getTags = async (
  limit: number = 5,
  offset: number = 0,
  search: string = ""
): Promise<PaginatedAdminTagList | errorMessage> => {
  try {
    const results = await adminV1TagsList({
      limit: limit,
      offset: offset,
      search,
    });
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const getDrillExercises = async (
  drill_id: number
): Promise<PaginatedAdminDrillExerciseList | errorMessage> => {
  try {
    const results = await adminV1DrillsExercisesList({
      limit: 100,
      offset: 0,
      drill_id: drill_id,
    });
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const createDrillExercise = async (
  data: AdminDrillExercise
): Promise<AdminDrillExercise | errorMessage> => {
  try {
    const results = await adminV1DrillsExercisesCreate(data);
    addMixpanelEvent(
      EVENT_NAMES.TrainingCollections.TrainingDrillExerciseAdded
    );
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const updateDrillExercise = async (
  id: number,
  data: PatchedAdminDrillExercise
): Promise<AdminDrillExercise | errorMessage> => {
  try {
    const results = await adminV1DrillsExercisesPartialUpdate(id, data);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const deleteDrillExercise = async (
  id: number
): Promise<void | errorMessage> => {
  try {
    const results = await adminV1DrillsExercisesDestroy(id);

    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const createDrillAsset = async (
  data: Pick<AdminDrillAsset, "asset_id" | "drill_id">
): Promise<AdminDrillAsset | errorMessage> => {
  try {
    const results = await adminV1DrillsAssetsCreate(data);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const updateDrillAsset = async (
  drillAssetId: number,
  data: PatchedAdminDrillAsset
): Promise<AdminDrillAsset | errorMessage> => {
  try {
    const results = await adminV1DrillsAssetsPartialUpdate(drillAssetId, data);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const deleteDrillAsset = async (
  id: number
): Promise<void | errorMessage> => {
  try {
    const results = await adminV1DrillsAssetsDestroy(id);
    return results;
  } catch (e: any) {
    return { error: e?.message };
  }
};
