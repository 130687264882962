<template>
  <div class="fixed-action-bar justify-end rounded-md p-2">
    <slot />
    <AppButton
      v-if="props.showCreate"
      :loading="isLoading"
      :label="props.showCreateLabel"
      class="mr-2 shrink-0"
      @click="emit('create')"
    />
    <AppButton
      v-if="props.showUpdate"
      class="mr-2 shrink-0"
      :loading="isLoading"
      :label="props.showUpdateLabel"
      :class="showUpdateStyle ? showUpdateStyle : null"
      :pt="{
        label: { class: showUpdateLabelStyle },
      }"
      @click="emit('update')"
    />
    <AppButton
      v-if="props.showDelete"
      :loading="isDeleting"
      icon="pi pi-trash"
      severity="danger"
      outlined
      aria-label="Cancel"
      class="shrink-0"
      @click="emit('delete')"
    />
  </div>
</template>

<script setup lang="ts">
interface Props {
  buttonType?: string;
  showCreate?: boolean;
  showCreateLabel?: string;
  showUpdate?: boolean;
  showUpdateLabel?: string;
  showUpdateStyle?: string;
  showUpdateLabelStyle?: string;
  showDelete?: boolean;
  isLoading?: boolean;
  isDeleting?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  buttonType: "button",
  showCreate: false,
  showCreateLabel: "Save",
  showUpdate: false,
  showUpdateLabel: "Save",
  showUpdateStyle: "",
  showUpdateLabelStyle: "",
  showDelete: false,
  isLoading: false,
  isDeleting: false,
});

const emit = defineEmits<{
  (e: "create"): void;
  (e: "update"): void;
  (e: "delete"): void;
}>();
</script>

<style scoped>
.fixed-action-bar {
  display: flex;
  align-items: center;
  transition: left 0.3s ease-in-out;
  background: var(--surface-100);
  width: 100%;
}
</style>
