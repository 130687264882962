import { ref } from "vue";
import { getTrainingCollections } from "@/modules/trainingCollections/api";
import type TrainingCollectionFilter from "@/shared/datamodels/trainingCollectionFilter";
import type { AdminTrainingCollection } from "@/api/model";

export const FAILED_TO_FETCH_MSG = "Failed to fetch training collections";

export function useTrainingCollections() {
  const trainingCollections = ref<AdminTrainingCollection[]>([]);
  const isLoading = ref(false);
  const currentPage = ref(0);
  const hasNextPage = ref(false);
  const totalRecords = ref(0);
  const errorMessage = ref("");
  const filter = ref<TrainingCollectionFilter>({ limit: 25, offset: 0 });

  const handleApiResponse = (data: any) => {
    if ("error" in data) {
      errorMessage.value = data.error;
      console.error(data.error);
    } else {
      totalRecords.value = data.count ?? 0;
      trainingCollections.value = data.results ?? [];
    }
  };

  const fetch = async () => {
    isLoading.value = true;
    filter.value.offset = currentPage.value * filter.value.limit;

    try {
      const data = await getTrainingCollections(filter.value);

      handleApiResponse(data);
    } catch (error) {
      errorMessage.value = FAILED_TO_FETCH_MSG;
      console.error(error);
    }

    isLoading.value = false;
  };

  return {
    trainingCollections,
    isLoading,
    currentPage,
    totalRecords,
    errorMessage,
    filter,
    fetch,
  };
}
