<template>
  <div class="bg-white rounded-2xl shadow my-0 mx-auto feed-width">
    <div
      class="flex items-center justify-between blue-600 text-black p-2 rounded-border"
    >
      <div class="flex items-center p-2">
        <UserAvatar
          size="xlarge"
          :initials="
            getNameInitials(props?.feedPost?.training_collection?.name)
          "
          :asset="
            props.feedPost?.training_collection?.training_collection_assets.find(
              (asset) => asset.type === 'main_photo'
            )?.asset
          "
        />
        <div class="ml-6">
          <div class="text-lg mb-1 font-bold">
            {{ props?.feedPost?.training_collection?.name }}
          </div>

          <div class="leading-none text-xs text-muted-color">
            {{ new Date(publishedAt).toLocaleString() }}
          </div>
        </div>
      </div>
      <div>
        <Tag
          v-if="props?.feedPost?.status === 'draft'"
          value="Draft"
          severity="success"
          class="mr-6 p-2 text-black/50 bg-gray-200 text-lg"
        />
      </div>
    </div>

    <div
      v-if="props?.feedPost?.attachments.length > 0"
      class="attachments-container p-2 h-44 bg-slate-50 flex items-center"
    >
      <FeedGridItemAttachment
        v-for="(attachment, i) in props?.feedPost?.attachments"
        :key="i"
        :attachment="attachment"
      />
    </div>

    <div class="p-2">
      <div class="text-body-2 px-1 mt-2">
        <div class="text-xl font-bold">
          {{ props?.feedPost?.title }}
        </div>
        <div class="text-sm mb-6 text-muted-color">
          <div>{{ props?.feedPost?.subtitle }}</div>
        </div>

        <div v-html="body"></div>
      </div>
    </div>

    <div
      class="flex py-2 mt-1 px-6 justify-between items-center bg-surface-100 dark:bg-surface-700 overflow-hidden"
    >
      <div class="flex items-center">
        <span class="font-semibold">
          <i class="pi pi-eye text-surface-600 dark:text-surface-200 pr-1"></i>
          {{ props?.feedPost?.view_count }}
        </span>

        <Divider layout="vertical" />
        <span class="font-semibold">
          <i class="pi pi-heart-fill text-pink-600 pr-1"></i>
          {{ props?.feedPost?.like_count }}
        </span>
      </div>
      <div class="flex items-center">
        <div>
          <AppButton
            icon="pi pi-pencil"
            size="small"
            label="Edit Post"
            severity="secondary"
            @click="
              router.push({
                name: ROUTE_NAME.FEED_SINGLE,
                params: { id: props?.feedPost?.id },
              })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Tag from "primevue/tag";
import UserAvatar from "@/shared/components/UserAvatar.vue";
import FeedGridItemAttachment from "./FeedGridItemAttachment.vue";
import { computed } from "vue";
import { format } from "date-fns";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { getNameInitials } from "@/shared/utils/helpers";
import Divider from "primevue/divider";
import type { AdminPost } from "@/api/model";
const props = defineProps<{
  feedPost: AdminPost;
}>();

const publishedAt = computed((): string => {
  return format(new Date(props.feedPost.published_at), "yyyy-MM-dd H:m");
});
const body = computed((): string => {
  const t = props.feedPost.body;
  const limit = 400;
  return t.length > limit ? `${t.substring(0, limit)}…` : t;
});
</script>

<style scoped>
.line-height-1 {
  line-height: 1;
}

.attachments-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  overflow-y: auto;
  gap: 1rem;
}

.feed-width {
  max-width: 50rem;
}
</style>
