<template>
  <i
    v-if="form.workoutName"
    class="pi pi-pencil text-xs ml-6 is-link"
    @click="visible = true"
  />

  <Dialog
    v-model:visible="visible"
    modal
    header="Edit Calendar"
    :style="{ width: '30vw' }"
  >
    <InputText
      id="workoutName"
      v-model="form.workoutName"
      type="text"
      placeholder="Calendar name"
      class="w-full"
      autocomplete="off"
    />
    <small v-if="v$ErrorMessage(v$.workoutName.$errors)" class="text-red-500">
      {{ v$ErrorMessage(v$.workoutName.$errors) }}
    </small>

    <div class="mt-12 flex items-center">
      <Checkbox
        v-model="form.availableToAllAthletes"
        input-id="availableToAllAthletes"
        name="availableToAllAthletes"
        :binary="true"
      />
      <label for="availableToAllAthletes" class="ml-2">
        Available to all athletes
      </label>
    </div>

    <div class="mt-12 flex items-center justify-between">
      <div>
        <div class="text-red-500 is-link" @click="confirmDeleteCalendar">
          Delete
        </div>
      </div>
      <div>
        <AppButton
          label="Save"
          class=""
          @click="async () => ((await v$.$validate()) ? onConfirm() : null)"
        />
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import { onMounted, ref, reactive, computed, watch } from "vue";
import { useToast } from "primevue/usetoast";
import { editCalendar, deleteCalendar } from "../api";
import type { AdminCalendar } from "@/api/model";
import { useConfirm } from "primevue/useconfirm";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";

const toast = useToast();

const emit = defineEmits<{
  (e: "edited", calendar: AdminCalendar): void;
}>();

interface calendarType {
  calendarId: number;
  calendarOrginalName: string;
  calendarNewName: string;
  availableToAllAthletes: boolean;
}

interface Props {
  organizationId: number;
  calendarInfo: calendarType;
}

const props = withDefaults(defineProps<Props>(), {
  organizationId: -1,
  calendarInfo: () => {
    return {
      calendarId: -1,
      calendarOrginalName: "",
      calendarNewName: "",
      availableToAllAthletes: true,
    };
  },
});

const confirm = useConfirm();
const confirmDeleteCalendar = () => {
  confirm.require({
    message: `This will delete all workouts and any athlete started workouts for the calendar "${props.calendarInfo.calendarOrginalName}". This action cannot be undone.`,
    header: "Delete Calendar & Workouts",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        if (!props.calendarInfo.calendarId) {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Invalid Calendar ID. Refresh page and try again.",
            life: 10000,
          });
          return;
        }

        const results = await deleteCalendar(props.calendarInfo.calendarId);
        if (results?.error) {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: results.error,
            life: 10000,
          });
        } else {
          visible.value = false;

          // set url to standard workout page to be sure
          router.push({
            name: ROUTE_NAME.WORKOUT_LIST,
          });
          // force reload
          await router.go(0);
        }
      } catch (e) {}
    },
  });
};

const visible = ref(false);

let form = reactive({
  workoutName: "",
  workoutId: -1,
  availableToAllAthletes: true,
});

const rules = computed(() => ({
  workoutName: { required },
}));

const v$ = useVuelidate(rules, form);

const withWeights = ref<boolean>(false);

onMounted(async () => {
  if (props?.calendarInfo?.calendarOrginalName) {
    form.workoutName = props?.calendarInfo?.calendarOrginalName;
  }
  if (props?.calendarInfo?.calendarId) {
    form.workoutId = props?.calendarInfo?.calendarId;
  }

  form.availableToAllAthletes = props?.calendarInfo?.availableToAllAthletes;

  watch(
    () => props?.calendarInfo?.availableToAllAthletes,
    () => {
      form.availableToAllAthletes = props?.calendarInfo?.availableToAllAthletes;
    }
  );

  watch(
    () => props?.calendarInfo?.calendarOrginalName,
    () => {
      form.workoutName = props?.calendarInfo?.calendarOrginalName;
    }
  );

  watch(
    () => props?.calendarInfo?.calendarId,
    () => {
      form.workoutId = props?.calendarInfo?.calendarId;
    }
  );
});

const onConfirm = async () => {
  if (!form.workoutName || !form.workoutId) {
    return;
  }

  // need to switch to editCalendar
  const data = await editCalendar(form.workoutId, {
    type_name: form.workoutName,
    is_private: form.availableToAllAthletes === true ? false : true,
  });
  if ("error" in data) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: data.error,
      life: 10000,
    });
  } else {
    emit("edited", data);
    visible.value = false;

    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Calendar updated.",
      life: 10000,
    });
  }
};
</script>
