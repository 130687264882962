import http from "@/shared/services/api-client";
import type { UploadableFile } from "@/shared/datamodels/uploadableFile";
import { adminV1UploadUrlCreate } from "@/api/admin-uploads/admin-uploads";
import { adminV1UploadUrlMultipartCreate } from "@/api/admin-uploads/admin-uploads";
import { getAssetThumbnail } from "../utils/helpers";
import type { Asset } from "../../api/model";

/**
 * Chunk size for multipart upload.
 * NB: 5 MB minimum chunk size is required by S3.
 */
const FILE_CHUNK_SIZE = 10 * 1024 * 1024; // 10 MB

/**
 * Obtains a pre-signed URL from the API for uploading the specified file.
 *
 * @param {File} file - The file to be uploaded.
 * @returns {object} - An object containing the pre-signed URL and S3 key.
 */
const getS3PresignedUrl = async (file: File, organization_id: number) => {
  try {
    const result = await adminV1UploadUrlCreate({
      content_type: file.type,
      filename: file.name,
      organization_id: organization_id,
    });

    return result;
  } catch (e) {
    return { error: e.message };
  }
};

/**
 * Creates an asset on the API using the specified S3 key and file metadata.
 *
 * @param {number} organization_id - The organization id.
 * @param {string} key - The S3 key of the uploaded file.
 * @param {File} file - The uploaded file.
 * @returns {number} The ID of the created asset.
 */
const createAsset = async (
  organization_id: number,
  key: string,
  file: File
): Promise<Asset> => {
  let type = "other";
  if (file.type.startsWith("image")) {
    type = "image";
  } else if (file.type.startsWith("video")) {
    type = "video";
  }
  const args = {
    type: type,
    file: {
      name: file.name,
      key: key,
      size: file.size,
      content_type: file.type,
    },
    organization_id: organization_id,
  };
  const resp = await http.post("/api/admin/v1/assets/", args);
  if (resp.status > 299) {
    throw new Error(
      `Can't create an asset. HTTP status: ${resp.status}, response "${resp.statusText}"`
    );
  }
  return resp.data;
};

/**
 * Obtains a pre-signed URLs for multipart upload from the API.
 *
 * @param {File} file - The file to be uploaded.
 * @returns {object} An object containing the pre-signed URLs and S3 key and upload ID.
 */
const getS3PresignedMultipartUrls = async (
  file: File,
  organization_id: number
) => {
  try {
    const result = await adminV1UploadUrlMultipartCreate({
      content_type: file.type,
      filename: file.name,
      organization_id: organization_id,
      num: Math.ceil(file.size / FILE_CHUNK_SIZE),
    });

    return result;
  } catch (e) {
    return { error: e.message };
  }
};

/**
 * Uploads a file to S3 using multipart upload and creates an Asset object.
 */
export const uploadFileMultipart = async (
  organization_id: number,
  uploadableFile: UploadableFile,
  onUploadProgressCallback?: (uploadableFile: UploadableFile) => void
): Promise<number> => {
  const { urls, key, upload_id } = await getS3PresignedMultipartUrls(
    uploadableFile.file,
    organization_id
  );

  const parts = [];
  for (let i = 0; i < urls.length; i++) {
    // Calculate the start and end of the chunk
    const start = i * FILE_CHUNK_SIZE;
    const end = (i + 1) * FILE_CHUNK_SIZE;

    // Slice the file
    let chunk;
    if (i === urls.length - 1) {
      chunk = uploadableFile.file.slice(start);
    } else {
      chunk = uploadableFile.file.slice(start, end);
    }

    const resp = await http.put(urls[i], chunk, {
      headers: {
        // The query fails with default Content-Type,
        // setting it to empty string fixes the issue
        "Content-Type": "",
      },
    });
    if (resp.status > 299) {
      throw new Error(
        `Can't upload the file to S3. HTTP status: ${resp.status}, response "${resp.statusText}"`
      );
    }
    parts.push({
      ETag: resp.headers.etag,
      PartNumber: i + 1,
    });
    uploadableFile.progress = Math.round((i / (urls.length ?? 0)) * 100);
    if (onUploadProgressCallback) {
      onUploadProgressCallback(uploadableFile);
    }
  }

  uploadableFile.progress = 100;
  if (onUploadProgressCallback) {
    onUploadProgressCallback(uploadableFile);
  }

  // Complete the multipart session, submitting the list of uploaded parts,
  // S3 key and upload ID to the APIz
  const resp = await http.post("/api/admin/v1/complete-multipart/", {
    upload_id: upload_id,
    key: key,
    parts: parts,
  });
  if (resp.status > 299) {
    throw new Error(
      `Can't complete the multipart session. HTTP status: ${resp.status}, response "${resp.statusText}"`
    );
  }

  const { id: assetId } = await createAsset(
    organization_id,
    key,
    uploadableFile.file
  );
  return assetId;
};

/**
 * Uploads a file to S3 and creates an Asset object.
 */
export const uploadFile = async (
  organization_id: number,
  uploadableFile: UploadableFile,
  onUploadProgressCallback?: (uploadableFile: UploadableFile) => void
): Promise<Asset> => {
  const { url, key } = await getS3PresignedUrl(
    uploadableFile.file,
    organization_id
  );

  const resp = await http.put(url, uploadableFile.file, {
    headers: {
      // The query fails with default Content-Type,
      // so it should override
      "Content-Type": uploadableFile.file.type,
    },
    onUploadProgress: function (progressEvent) {
      uploadableFile.progress = Math.round(
        (progressEvent.loaded / (progressEvent.total ?? 0)) * 100
      );
      if (onUploadProgressCallback) {
        onUploadProgressCallback(uploadableFile);
      }
    },
  });
  if (resp.status > 299) {
    throw new Error(
      `Can't upload the file to S3. HTTP status: ${resp.status}, response "${resp.statusText}"`
    );
  }
  return createAsset(organization_id, key, uploadableFile.file);
};
