<template>
  <AppCard class="mb-6">
    <div class="space-y-4">
      <div class="items-center">
        <div class="text-xl font-bold mb-6">
          {{ invoiceTitle }}
        </div>
        <div>
          <div class="mb-4">
            <label
              for="status"
              class="block text-md font-semibold text-gray-700"
              >Status</label
            >
            <p
              :style="{
                color: getInvoiceStatusColor(
                  invoice.invoice_status as InvoiceStatus
                ),
              }"
              class="font-thin"
            >
              {{ invoiceStatus }}
            </p>
          </div>
        </div>
        <div class="mb-4">
          <p class="block text-md font-semibold text-gray-700">Due Date</p>
          <p class="font-thin">{{ invoice?.invoice_due_date }}</p>
        </div>

        <div class="mb-4">
          <p class="block text-md font-semibold text-gray-700">Total Amount</p>
          <p class="font-thin">{{ invoiceAmount }}</p>
        </div>
      </div>
    </div>
  </AppCard>

  <AppCard class="mb-6">
    <table class="invoice-table" data>
      <thead>
        <tr>
          <th class="font-normal border-b border-gray-300">Item Name</th>
          <th class="font-normal border-b border-gray-300">Price</th>
          <th class="font-normal border-b border-gray-300">Qty</th>
          <th class="font-normal border-b border-gray-300">Category</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in invoice?.items" :key="index">
          <td class="font-thin">{{ item.item_product_name }}</td>
          <td class="font-thin">{{ getFormattedCurrency(item.item_cost) }}</td>
          <td class="font-thin">{{ item.item_qty }}</td>
          <td class="font-thin">{{ item.item_categories[0] }}</td>
        </tr>
      </tbody>
    </table>
  </AppCard>

  <AppCard class="mb-6">
    <div class="mb-4">
      <p class="block text-md font-semibold text-gray-700">Player</p>
      <p class="font-thin">{{ invoice?.profile?.name || "N/A" }}</p>
    </div>

    <div class="mb-4">
      <p class="block text-md font-semibold text-gray-700">Invoice Recipient</p>
      <p class="font-thin">{{ invoice?.invoice_recipient?.name }}</p>
    </div>
  </AppCard>

  <AppCard class="mb-6">
    <div class="flex justify-between font-thin">
      <span class="p-4">Created at: {{ invoiceCreatedAt }}</span>
      <span class="p-4">Invoice No: {{ invoice?.invoice_number }}</span>
    </div>
  </AppCard>
</template>

<script setup lang="ts">
import { type Invoice } from "@/api/model";

import AppCard from "@/shared/components/AppCard.vue";
import {
  getFormattedTitle,
  getFormattedInvoiceStatus,
  getInvoiceStatusColor,
  getFormattedCurrency,
  getFormattedCreatedAt,
} from "../helpers";

import type { InvoiceStatus } from "@/types";
import { computed } from "vue";

const invoiceTitle = computed(() => {
  return getFormattedTitle(invoice);
});

const invoiceStatus = computed(() => {
  return getFormattedInvoiceStatus(invoice?.invoice_status as InvoiceStatus);
});

const invoiceAmount = computed(() => {
  return getFormattedCurrency(invoice?.invoice_amount);
});

const invoiceCreatedAt = computed(() => {
  return getFormattedCreatedAt(invoice?.invoice_created_at);
});

const props = defineProps<{
  invoice: Invoice;
}>();

const { invoice } = props;
</script>

<style scoped>
.invoice-table {
  width: 60%;
  border-collapse: separate;
  border-spacing: 0;
  text-transform: capitalize;
}

.invoice-table th,
.invoice-table td {
  padding: 8px;
  text-align: left;
  border-bottom: none;
}
</style>
