import type { RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import Update from "./views/ProfileUpdate.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/profile",
    name: ROUTE_NAME.PROFILE_UPDATE,
    component: Update,
  },
];
