<template>
  <AutoComplete
    v-model="state.query"
    multiple
    dropdown
    :suggestions="state.suggestions"
    :pt="{
      tokenLabel: { class: 'mr-1' },
    }"
    @complete="querySelections"
    @change="onChange"
  />
</template>

<script setup lang="ts">
import AutoComplete from "primevue/autocomplete";
import { reactive, onMounted } from "vue";
import { uniq } from "lodash";
import { getTags } from "@/modules/trainingCollections/api";

const props = defineProps<{
  initialData?: string[];
}>();

const emit = defineEmits<{
  (e: "updated", tags: string[]): void;
}>();

interface State {
  loading: boolean;
  suggestions: string[];
  query: string[] | string;
}

const state: State = reactive({
  loading: false,
  suggestions: [],
  tags: [],
  query: "",
});

onMounted(async () => {
  if (props.initialData) {
    state.query = props.initialData;
  }
  state.loading = true;
  const res = await getTags();
  if ("error" in res) {
    console.log(res.error);
  } else {
    state.suggestions = (res.results || []).map((e) => e.name);
  }
  state.loading = false;
});

const querySelections = async (e: any) => {
  state.loading = true;
  const res = await getTags(10, 0, e.query);
  if ("error" in res) {
    console.log(res.error);
  } else {
    state.suggestions = (res.results || []).map((e) => e.name);
  }
  state.loading = false;
};

const onChange = (event: any) => {
  emit("updated", uniq([...event.value, ...state.query]));
};
</script>
