<template>
  <form>
    <FixedActionBar
      button-type="submit"
      :is-loading="isSubmitting"
      :show-create="isCreateForm"
      :show-update="!isCreateForm"
      :show-delete="!isCreateForm"
      show-create-label="Save"
      show-update-label="Save"
      class="mb-6"
      @delete="onConfirmDelete"
      @create="onUpdate"
      @update="async () => ((await v$.$validate()) ? onSubmit() : null)"
    />

    <AppCard class="mb-6">
      <div class="flex mb-4 mt-8">
        <SingleImageUploaderAndDisplay
          :key="drillImageData?.asset?.id"
          label="Image"
          class="mr-20"
          :disclaimer-text="`Allowed file types: ${allowedImageFileExtensionsByKey}`"
          :max-width="160"
          :image-data="drillImageData?.asset?.file"
          :thumbnail-width="140"
          :organization-id="form.organization_id"
          :allow-delete-button="true"
          @upload-complete="uploadDrillImageComplete"
          @upload-failed="uploadImageFailed"
          @request-delete="uploadDrillImageDelete"
        />

        <SingleVideoUploaderAndDisplay
          :key="drillVideoData?.asset?.id"
          label="Video"
          class="mr-20"
          :disclaimer-text="`Allowed file types: ${allowedVideoFileExtensionsByKey}`"
          :max-width="200"
          :video-data="drillVideoData?.asset"
          :organization-id="form.organization_id"
          :allow-delete-button="true"
          :autoplay="false"
          @upload-complete="assetVideoUploadSuccess"
          @upload-failed="uploadVideoFailed"
          @request-delete="assetVideoDelete"
        />
      </div>
    </AppCard>

    <AppCard class="mb-6">
      <div class="flex flex-col">
        <div class="flex flex-col gap-2">
          <label for="name mb-4">Name</label>
          <InputText
            id="name"
            v-model="form.name"
            type="text"
            placeholder="Name"
            @blur="v$.name.$touch"
          />
          <small v-if="v$ErrorMessage(v$.name.$errors)" class="text-red-500">
            {{ v$ErrorMessage(v$.name.$errors) }}
          </small>
        </div>

        <div class="flex flex-col gap-2 mt-4">
          <label for="name">Body</label>
          <ContentEditor
            :model="form?.body || ''"
            editor-style="min-height: 240px; height: auto;"
            @editor-updated="(d) => (form.body = d)"
          />
        </div>

        <div class="flex flex-col gap-2 mt-4 w-full">
          <label for="categories">Categories</label>
          <DrillTags :initial-data="initialTags" @updated="onUpdateTags" />
        </div>
      </div>
    </AppCard>

    <AppCard class="mb-6">
      <div class="text-xl font-bold mb-6">Exercises</div>
      <DrillExercise :drill-id="drillId" />
    </AppCard>
  </form>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import ContentEditor from "@/shared/components/ContentEditor.vue";
import DrillTags from "./DrillTags.vue";
import DrillExercise from "./DrillExercise.vue";
import SingleImageUploaderAndDisplay from "@/shared/components/SingleImageUploaderAndDisplay.vue";
import { computed, onMounted, reactive, ref } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import {
  deleteDrill,
  updateDrill,
  createDrillAsset,
  updateDrillAsset,
  deleteDrillAsset,
} from "@/modules/trainingCollections/api";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { catchErrorMessage } from "@/shared/utils/custom-errors";
import type { AssetType } from "@/shared/datamodels/asset";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import FixedActionBar from "@/shared/components/FixedActionBar.vue";
import AppCard from "@/shared/components/AppCard.vue";

import {
  allowedVideoFileExtensionsByKey,
  allowedImageFileExtensionsByKey,
} from "@/shared/constants/uploader";
import SingleVideoUploaderAndDisplay from "@/shared/components/SingleVideoUploaderAndDisplay.vue";

import { useAuthStore } from "@/modules/auth/stores/auth";
const { defaultOrg } = useAuthStore();

const toast = useToast();
const confirm = useConfirm();

const props = defineProps<{
  trainingCollectionId: number;
  initialData?: AdminDrill;
  onDrillUpdate: (drill: AdminDrill) => void;
}>();

interface FormState {
  training_collection_id: number;
  name?: string;
  body?: string;
  tags_list: string[];
  organization_id: number;
}

const isSubmitting = ref(false);
const deleteDialog = ref(false);

const route = useRoute();
import { useRoute } from "vue-router";
import type { AdminAsset, AdminDrill, Asset } from "@/api/model";

const isCreateForm = computed<boolean>(() => route?.query?.create === "1");
const drillId = computed(() => props.initialData?.id ?? undefined);
const initialTags = computed(() => {
  return props.initialData?.tags?.map((e) => e.name);
});

const form = reactive<FormState>({
  training_collection_id: 0,
  name: undefined,
  body: undefined,
  tags_list: [],
  organization_id: 0,
});

const rules = computed(() => ({
  name: { required },
}));

const assetVideoUploadSuccess = async ({ asset }: { asset: Asset }) => {
  const drillAssetId = drillVideoData.value?.id;

  let drillAsset = null;

  if (drillAssetId) {
    drillAsset = await updateDrillAsset(drillAssetId, {
      asset_id: asset?.id,
      drill_id: drillId.value,
    });
  } else {
    drillAsset = await createDrillAsset({
      asset_id: asset?.id,
      drill_id: drillId.value,
    });
  }

  if ("error" in drillAsset) {
    toast.add({
      severity: "error",
      detail: drillAsset?.error,
      summary: "Error",
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      detail: "Added video.",
      summary: "Success",
      life: 3000,
    });

    drillVideoData.value = drillAsset;
  }
};

const videoIndex = computed(() => {
  return props.initialData?.drill_assets?.findIndex(
    (e) => e.asset.type === "video"
  );
});
const assetVideoDelete = async () => {
  const drillAssetId = drillVideoData.value?.id;
  if (!drillAssetId) {
    return;
  }

  const deletedAsset = await deleteDrillAsset(drillAssetId);
  if (deletedAsset?.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: deletedAsset?.error,
      life: 10000,
    });
  } else {
    drillVideoData.value = undefined;

    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Video deleted.",
      life: 10000,
    });
  }
};

const uploadVideoFailed = (d: { error: string }) => {
  if (d?.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: d?.error,
      life: 10000,
    });
  }
};

const setFormValues = (data: AdminDrill) => {
  form.training_collection_id = props?.trainingCollectionId;
  form.name = data?.name;
  form.body = data?.body;
  if (data?.tags) {
    form.tags_list = data.tags?.map((e) => e.name);
  }
};

const v$ = useVuelidate(rules, form);

let drillImageData = ref();
let drillVideoData = ref();

const uploadDrillImageComplete = async ({
  assetId,
  asset,
}: {
  assetId: number;
  asset?: AdminAsset;
}) => {
  if (!drillId.value) {
    console.log("drillId not set.");
    return;
  }

  const drillAssetId = drillImageData.value?.id;

  let results = null;

  if (drillAssetId) {
    results = await updateDrillAsset(drillAssetId, {
      asset_id: assetId,
      drill_id: drillId.value,
    });
  } else {
    results = await createDrillAsset({
      asset_id: assetId,
      drill_id: drillId.value,
    });
  }

  if ("error" in results) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: results.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Image updated.",
      life: 3000,
    });

    // load asset into component
    drillImageData.value = results;
  }
};

const uploadImageFailed = (d: { error: string }) => {
  if (d.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: d.error,
      life: 10000,
    });
  }
};

interface uploadDrillImageDeleteType {
  assetId: number;
}

const uploadDrillImageDelete = async (d: uploadDrillImageDeleteType) => {
  const drillAssetId = drillImageData.value?.id;
  if (!drillAssetId) {
    return;
  }

  const deletedAsset = await deleteDrillAsset(drillAssetId);
  if (deletedAsset?.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: deletedAsset.error,
      life: 10000,
    });
  } else {
    drillImageData.value = undefined;

    toast.add({
      severity: "success",
      summary: "Image removed.",
      life: 3000,
    });
  }
};

onMounted(async () => {
  if (props.initialData) {
    setFormValues(props.initialData);
  } else {
    form.training_collection_id = props.trainingCollectionId;
  }

  form.organization_id = defaultOrg.id;

  drillImageData.value = props.initialData?.drill_assets.find(
    (d) => d?.asset?.type === "image"
  );

  drillVideoData.value = props.initialData?.drill_assets.find(
    (d) => d?.asset?.type === "video"
  );
});

const onSubmit = async () => {
  if (props.initialData) {
    await onUpdate();
    return;
  }
  await onUpdate();
};

const onUpdate = async () => {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    return;
  }

  if (drillId.value) {
    const drill = await updateDrill(drillId.value, form);

    if ("error" in drill) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: drill.error,
        life: 10000,
      });
    } else {
      toast.add({
        severity: "success",
        summary: "Drill updated.",
        life: 10000,
      });

      await router.push({
        name: ROUTE_NAME.DRILL_UPDATE,
        params: { drill_id: drill.id },
      });

      props.onDrillUpdate(drill);
    }
  }
};

const onUpdateTags = (tags: string[]) => {
  form.tags_list = tags;
};

const deleteAndRedirect = async () => {
  deleteDialog.value = false;
  if (!drillId.value) {
    return;
  }
  try {
    const result = await deleteDrill(drillId.value);
    if (result?.error) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: result.error,
        life: 10000,
      });
    } else {
      toast.add({
        severity: "success",
        summary: "Drill deleted.",
        life: 10000,
      });

      await router.push({
        name: ROUTE_NAME.TRAINING_COLLECTION_UPDATE,
        params: { id: props.trainingCollectionId },
      });
    }
  } catch (e: unknown) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 3000,
    });
  }
};

const onConfirmDelete = async () => {
  confirm.require({
    message: "Are you sure you want to delete this drill?",
    header: "Delete Drill",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        await deleteAndRedirect();
      } catch (e) {}
    },
  });
};

const getAssetByType = (type: AssetType) => {
  try {
    return props.initialData?.drill_assets?.find((e) => e.asset.type === type);
  } catch (e) {
    return undefined;
  }
};
</script>
