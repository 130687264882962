import { ref } from "vue";
import { uploadFile } from "@/shared/services/file-upload";
import { UploadableFile } from "@/shared/datamodels/uploadableFile";
import {
  allowedVideoTypes,
  allowedVideoFileExtensionsByKey,
} from "@/shared/constants/uploader";
import type { AdminAsset } from "@/api/model";

export function useVideoUpload(organizationId: number) {
  const uploadInProgress = ref(false);
  const uploadProgress = ref(0);
  const assetVideo = ref<AdminAsset | undefined>();

  const checkFileType = (file: File): boolean => {
    return Boolean(allowedVideoTypes[file.type]);
  };

  const uploadVideo = async (file: File) => {
    if (!checkFileType(file)) {
      throw new Error(
        `Acceptable file types: ${allowedVideoFileExtensionsByKey}`
      );
    }

    uploadInProgress.value = true;
    uploadProgress.value = 0; // Reset progress

    try {
      const uploadStatus = (d: any) => {
        uploadProgress.value = d.progress;
      };

      const asset = await uploadFile(
        organizationId,
        new UploadableFile(file),
        uploadStatus
      );

      assetVideo.value = asset;

      uploadInProgress.value = false;
      return asset;
    } catch (error) {
      uploadInProgress.value = false;
      throw error;
    }
  };

  return {
    uploadInProgress,
    uploadProgress,
    assetVideo,
    uploadVideo,
  };
}
