<template>
  <BaseLayout
    title="Exercises"
    :breadcrumb="[{ label: 'Exercises', to: { name: ROUTE_NAME.EXERCISES } }]"
  >
    <div v-if="!isLoadingInitial" class="flex justify-between">
      <div class="relative mr-6">
        <i
          class="pi pi-search absolute left-3 top-1/2 transform -translate-y-1/2"
        />
        <InputText
          v-model="searchSelection"
          class="pl-10 pr-3 border rounded-md w-full"
          placeholder="Search..."
          @input="searchResults"
        />
      </div>
      <AppButton
        color="primary"
        label="Create Exercise"
        @click="router.push({ name: ROUTE_NAME.EXERCISE_CREATE })"
      />
    </div>

    <div
      class="bg-white rounded-border p-12 shadow-sm mt-6 animate-fadein animate-duration-1000"
    >
      <DataTable
        :value="exercises"
        table-style="min-width: 50rem"
        striped-rows
        row-hover
        selection-mode="single"
        @row-click="
          (e) => {
            addMixpanelEvent(EVENT_NAMES.Navigation.NavAdminExercise);

            router.push({
              name: ROUTE_NAME.EXERCISE_UPDATE,
              params: { id: e.data.id },
            });
          }
        "
      >
        <Column field="name" header="Name" />
        <Column header="Has Video">
          <template #body="slotProps">
            <div v-if="slotProps?.data?.is_video">Yes</div>
            <div v-else>No</div>
          </template>
        </Column>
        <Column header="Has Thumbnail">
          <template #body="slotProps">
            <div v-if="slotProps?.data?.is_cover">Yes</div>
            <div v-else>No</div>
          </template>
        </Column>
      </DataTable>
    </div>

    <Paginator
      :always-show="true"
      :rows="resultsPerPage"
      :total-records="totalRecords"
      :first="first"
      template="
        FirstPageLink
        PrevPageLink
        CurrentPageReport
        NextPageLink
        LastPageLink
        RowsPerPageDropdown"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      @page="onPageChange"
    />
  </BaseLayout>
</template>

<script setup lang="ts">
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { ref, onMounted, computed } from "vue";
import router from "@/router";
import { getExercises } from "@/modules/workouts/api";
import Paginator from "primevue/paginator";
import { useToast } from "primevue/usetoast";
import { debounce } from "lodash";
import { useAuthStore } from "@/modules/auth/stores/auth";
const authStore = useAuthStore();
const { defaultOrg } = authStore;

const toast = useToast();

let searchSelection = ref<string | undefined>(undefined);

const searchResults = debounce(async (e: Event) => {
  paginationNum.value = 1;
  await getExercisesPaginated();
}, 500);

import { useRoute } from "vue-router";
import type { AdminExerciseList } from "@/api/model";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";
const route = useRoute();

let paginationNum = ref(parseInt((route?.query?.page || 1).toString()));
let queryString = ref((route?.query?.q || "").toString());

let resultsPerPage = 25;
let totalRecords = ref(0);

const first = computed(
  () => paginationNum.value * resultsPerPage - resultsPerPage
);

const onPageChange = async (e: any) => {
  paginationNum.value = e.page + 1;

  router.push({
    query: {
      page: paginationNum.value ?? undefined,
      q: searchSelection.value ?? undefined,
    },
  });

  await getExercisesPaginated();
};

const getExercisesPaginated = async () => {
  router.push({
    query: {
      page: paginationNum.value ?? undefined,
      q: searchSelection.value ?? undefined,
    },
  });

  const rawExercises = await getExercises({
    search: searchSelection.value,
    limit: resultsPerPage,
    offset: (paginationNum.value - 1) * resultsPerPage,
    organization_id: organizationSelection.value ?? undefined,
  });
  if ("error" in rawExercises) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: rawExercises.error,
      life: 10000,
    });
  } else {
    totalRecords.value = rawExercises?.count || 0;
    exercises.value = rawExercises?.results || [];
  }
};

const isLoadingInitial = ref(false);

let exercises = ref<AdminExerciseList[]>([]);

let organizationSelection = ref<number | undefined>(undefined);

onMounted(async () => {
  if (defaultOrg.id) {
    organizationSelection.value = defaultOrg.id;
  }

  if (queryString.value) {
    searchSelection.value = queryString.value;
  }

  await getExercisesPaginated();
});
</script>
