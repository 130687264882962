import { createApp } from "vue";
import { createPinia } from "pinia";
import { initialiseModules } from "@/modules";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Button from "primevue/button";
import AppCard from "@/shared/components/AppCard.vue";
import App from "./App.vue";
import router from "./router";
import "./assets/base.css";
import mixpanel from "mixpanel-browser";
import {
  SENTRY_DSN,
  SENTRY_ENV,
  MIXPANEL_TOKEN,
  RELEASE_VERSION,
} from "@/shared/utils/env";
import * as Sentry from "@sentry/vue";
import Aura from "./assets/presets/aura";

const app = createApp(App);
const pinia = createPinia();

if (MIXPANEL_TOKEN) {
  mixpanel.init(MIXPANEL_TOKEN);
}

Sentry.init({
  app,
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  release: RELEASE_VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^\//],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 20% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

initialiseModules({ router });
pinia.use(piniaPluginPersistedstate);
app.use(PrimeVue, { unstyled: true, pt: Aura });
app.use(ToastService);
app.use(ConfirmationService);
app.use(pinia);
app.component("AppButton", Button);
app.component("AppCard", AppCard);
app.use(router);
app.mount("#app");

// this must be after pinia is used above
import { useAuthStore } from "@/modules/auth/stores/auth";
const { loggedInUserId } = useAuthStore();
Sentry.setTag("loggedInUserId", loggedInUserId);
