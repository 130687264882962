<template>
  <BaseLayout title="Video History">
    <AppCard v-if="chatResponse?.sender?.name">
      <div class="grid grid-cols-12 gap-4 grid-cols-12 gap-6">
        <div class="col-span-12 sm:col-span-4 flex items-center">
          Player: {{ chatResponse?.sender?.name }}
        </div>
        <div class="col-span-12 sm:col-span-8">
          Assigned Coach:
          <Select
            v-model="coachSelection"
            :options="coachesListOptions"
            option-label="name"
            option-value="id"
            placeholder="Assigned Coach"
            class="w-full md:w-56 mr-6 ml-2"
            :disabled="chatStatus !== 'Pending'"
            @change="confirmAssignCoach"
          />
        </div>
        <div class="col-span-12 sm:col-span-4">
          Category: {{ chatResponse?.category }}
        </div>
        <div class="col-span-12 sm:col-span-8">
          Video ID: {{ chatResponse?.id }}
        </div>
      </div>
    </AppCard>

    <AppCard
      v-if="!noVideoSubmissions"
      class="mt-6 animate-fadein animate-duration-1000"
    >
      <DataTable
        :loading="isLoading"
        :value="allChats"
        striped-rows
        row-hover
        selection-mode="single"
        @row-click="
          (e) => {
            router.push({
              name: ROUTE_NAME.CHAT_MESSAGE,
              params: { messageid: e.data.id },
            });
          }
        "
      >
        <Column header="Timestamp">
          <template #body="slotProps">
            {{
              format(new Date(slotProps.data.created_at), "MM/dd/yy h:mm aa")
            }}
          </template>
        </Column>

        <Column header="Action">
          <template #body="slotProps">
            {{
              getStatusTitleBasedOnSender(
                chatResponse,
                slotProps.data,
                firstMessageId
              )
            }}
          </template>
        </Column>

        <Column header="Video">
          <template #body="slotProps">
            <div class="flex gap-6 items-center">
              <div>
                <img
                  v-if="getAssetThumbnail(slotProps.data.asset, 100)"
                  :src="getAssetThumbnail(slotProps.data.asset, 100)"
                  class="rounded-lg"
                  alt="image"
                />
              </div>
              <div class="flex flex-col gap-1">
                <div class="font-bold text-base">
                  {{ slotProps.data.sender.name }}
                </div>
              </div>
            </div>
          </template>
        </Column>

        <Column header="Notes">
          <template #body="slotProps">
            <div class="flex items-center w-full justify-between gap-2">
              {{ slotProps.data.text }}

              <AppButton
                v-if="slotProps.data.is_audited"
                class="py-1 px-2 text-xs"
                label="Link"
                size="small"
                severity="secondary"
                @click="() => onEdited(slotProps.data.id)"
              >
                Respond History
              </AppButton>
            </div>
          </template>
        </Column>
      </DataTable>
    </AppCard>

    <AppCard v-if="noVideoSubmissions" class="mt-6">
      No history found.
    </AppCard>
  </BaseLayout>

  <Dialog
    v-model:visible="visible"
    modal
    header="Respond History"
    :style="{ width: '40vw' }"
  >
    <table class="w-full">
      <tr v-for="item in audits" :key="item.id">
        <td class="py-6 border-b border-surface-200 dark:border-surface-600">
          Updated by
          <span class="underline align-top">
            {{ item.profile.name }}
          </span>
          <div class="text-surface-500 dark:text-surface-300 text-sm mt-1">
            On {{ format(new Date(item.created_at), "MM/dd/yy h:mm aa") }}
          </div>
        </td>

        <td class="p-6 border-b border-surface-300 dark:border-surface-500">
          <div>
            <span
              class="font-bold text-surface-500 dark:text-surface-300 text-sm"
            >
              Updated:
            </span>
            &ldquo;{{ item.new_chat_message_text }}&rdquo;
          </div>
          <div>
            <span
              class="font-bold text-surface-500 dark:text-surface-300 text-sm"
            >
              Previous:
            </span>
            &ldquo;{{ item.previous_chat_message_text }}&rdquo;
          </div>
        </td>
      </tr>
    </table>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import { ROUTE_NAME } from "@/shared/constants/routes";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { ref, onMounted } from "vue";
import { useToast } from "primevue/usetoast";
import router from "@/router";
import { useRoute } from "vue-router";
import AppCard from "@/shared/components/AppCard.vue";
import { getChat, reassignCoach } from "@/modules/chats/api";
import { getStatusTitleBasedOnSender } from "@/modules/chats/helpers/videoSubmissionsUtils";
import { format } from "date-fns";
const toast = useToast();

import { useAuthStore } from "@/modules/auth/stores/auth";
const authStore = useAuthStore();
const { defaultOrg } = authStore;
import { useOrganizationStore } from "@/modules/organizations/stores/organizationsStore";
const { getAllOrganizations } = useOrganizationStore();
import { getAssetThumbnail } from "@/shared/utils/helpers";
import type {
  AdminCoach,
  AdminChatMessage,
  AdminDetailChat,
  ProfileTeams,
  AdminChatAudit,
} from "../../../api/model";
import Select from "primevue/select";
import { getCoachesByTeam } from "../../organizations/api";
import { useConfirm } from "primevue/useconfirm";
import { adminV1ChatAuditsList } from "../../../api/admin-chat-audits/admin-chat-audits";
const confirm = useConfirm();

let chatStatus = ref();
let coachIsRecipient = ref(true);
let chatResponse = ref<AdminDetailChat>();
let organizationId = ref(-1);

const route = useRoute();

const chatId = parseInt(route.params.id.toString());
const visible = ref(false);
const audits = ref<AdminChatAudit[]>([]);
function onEdited(i: number) {
  adminV1ChatAuditsList({
    chat_message_id: i,
  })
    .then((res) => {
      if (res.results) {
        audits.value = res.results;
      }
      visible.value = true;
    })
    .catch((e) => {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: e.message,
        life: 10000,
      });
    });
}
const getChatResults = async () => {
  if (!chatId) {
    return;
  }

  isLoading.value = true;

  const results = await getChat(chatId);
  if ("error" in results) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: results.error,
      life: 10000,
    });

    noVideoSubmissions.value = true;
  } else {
    chatResponse.value = results;
    allChats.value = chatResponse.value?.messages || [];
    firstMessageId.value = allChats.value[0]?.id;
    chatStatus.value = chatResponse.value?.status;
    noVideoSubmissions.value = allChats.value.length === 0 ? true : false;

    organizationId.value = chatResponse.value?.organization?.id;

    // check if the coach was the first to send the message
    if (chatResponse.value?.sender?.is_coach) {
      coachIsRecipient.value = false;

      // kid
      userTeams.value = chatResponse.value?.recipient?.teams.map(
        (d: ProfileTeams) => d.id
      );
    }

    if (chatResponse.value?.recipient?.is_coach) {
      coachSelection.value = chatResponse.value?.recipient?.id;

      // kid
      userTeams.value = chatResponse.value?.sender?.teams.map(
        (d: ProfileTeams) => d.id
      );
    }
  }

  isLoading.value = false;
};
let isLoading = ref(false);
let allChats = ref<AdminChatMessage[]>([]);
let noVideoSubmissions = ref(false);
let organizationSelection = ref<number | undefined>(undefined);
let coachSelection = ref<number | undefined>(undefined);
let userTeams = ref<Array<number>>([]);

const firstMessageId = ref();

const confirmAssignCoach = async (e: any) => {
  const coachId = e.value;

  confirm.require({
    message: "Are you sure you want to reassign to this coach?",
    header: "Assign To Coach",
    icon: "icon-danger",
    rejectLabel: "Cancel",
    acceptLabel: "Reassign",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        const results = await reassignCoach(
          chatId,
          coachId,
          coachIsRecipient.value
        );
        if ("error" in results) {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: results.error,
            life: 10000,
          });
        } else {
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "Reassigned to coach.",
            life: 3000,
          });
          await getChatResults();
        }
      } catch (e) {}
    },
  });
};

const getCoachesSelection = async () => {
  const coaches = await getCoachesByTeam({
    orgId: organizationSelection.value,
    teams: userTeams.value,
    limit: 10000,
    offset: 0,
  });
  if ("error" in coaches) {
    return [];
  }
  const coachesList = coaches.results || [];
  coachesListOptions.value = coachesList.map((coach: AdminCoach) => {
    return {
      name: coach.profile.name ?? "",
      id: coach.profile.id ?? -1,
    };
  });
  return coaches;
};
let coachesListOptions = ref<{ id: number; name: string }[]>([]);

onMounted(async () => {
  if (defaultOrg.id) {
    organizationSelection.value = defaultOrg.id;
  }
  await getAllOrganizations();
  await getChatResults();
  await getCoachesSelection();
});
</script>
