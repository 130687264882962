import type AssetFile from "./assetFile";
import type AssetVideo from "./assetVideo";

interface Asset {
  id: number;
  file: AssetFile;
  video?: AssetVideo;
  user_added_id: number;
  organization_id: number;
  status: "active" | "transcoded" | "deleted";
  type: AssetType;
}

export enum AssetType {
  image = "image",
  video = "video",
  other = "other",
}

export default Asset;
