<template>
  <div
    v-if="
      props.data.type === WorkoutRowType.day ||
      props.data.type === WorkoutRowType.week
    "
    class="text-xs"
    :class="{ 'week-item': props.data.type === WorkoutRowType.week }"
  >
    {{ props.data.label }}
  </div>

  <template v-if="props.data.type === WorkoutRowType.workout">
    <div class="h-full w-full">
      <div
        class="item-top-bar flex items-center justify-between px-2 py-1 bg-surface-200 dark:bg-surface-600"
      >
        <div class="text-xs">
          {{ displayStatus(props?.data?.workout?.status) }}
        </div>
        <i
          class="pi pi-bars cursor-pointer"
          aria-haspopup="true"
          :aria-controls="`overlay_menu_` + props.data.day"
          @click="toggle"
        />
        <Menu
          :id="`overlay_menu_` + props.data.day"
          ref="menu"
          :model="menuItems"
          :popup="true"
        />
      </div>

      <div
        class="item-content flex justify-center"
        :class="{
          'align-start': props.data.workout != null,
          'items-center': props.data.workout == null,
        }"
      >
        <i
          v-if="props.data.workout == null && loading"
          class="pi pi-spin pi-spinner"
          style="font-size: 2rem"
        />
        <div
          v-if="props.data.workout == null && !loading"
          class="create-workout-btn"
          @click="onCreate"
        >
          Create<br />workout
        </div>
        <div v-if="props.data.workout != null" class="text-xs p-2">
          <div
            v-for="(text, i) in getTexts"
            :key="i"
            :class="{
              'text-surface-400 dark:text-surface-400 mt-1':
                getTexts.length > 1 && i === Object.keys(getTexts).length - 1,
            }"
            v-html="text"
          />
        </div>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import Menu from "primevue/menu";
import { capitalize } from "lodash";
import { computed, ref, watchEffect, toRefs } from "vue";
import type { WorkoutRow } from "@/shared/datamodels/workout";
import { WorkoutRowType } from "@/shared/datamodels/workout";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { createWorkout } from "../api";
import { useToast } from "primevue/usetoast";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";
import { FIRST_CALENDAR_CELL_DAY } from "@/shared/constants/workouts";

const toast = useToast();

export interface Props {
  data: WorkoutRow;
  calendarId: number;
  weights: boolean;
  loading: boolean;
  organizationId: number | null;
  copyWorkoutId: number | null;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  organizationId: null,
  copyWorkoutId: null,
  data: undefined,
});

const displayStatus = (status: string | undefined) => {
  if (!status) {
    return "";
  }

  if (status === "draft") {
    return "Unpublished";
  } else {
    return capitalize(status);
  }
};

const { organizationId, copyWorkoutId } = toRefs(props);

const menu = ref();
const menuItems = computed(() => {
  const arr = [];
  if (props?.data?.workout?.id) {
    arr.push({
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        onEdit(props?.data?.workout?.id);
      },
    });

    arr.push({
      label: "Copy",
      icon: "pi pi-copy",
      command: () => {
        onCopy(props?.data?.workout?.id);
      },
    });

    arr.push({
      label: "Delete",
      icon: "pi pi-eraser",
      command: () => {
        onDelete(props?.data?.workout?.id);
      },
    });
  } else {
    arr.push({
      label: "Create",
      icon: "pi pi-pencil",
      command: () => {
        onCreate();
      },
    });

    if (
      copyWorkoutId.value &&
      (props?.data?.day || props?.data?.day === FIRST_CALENDAR_CELL_DAY)
    ) {
      arr.push({
        label: "Paste",
        icon: "pi pi-clone",
        command: () => {
          onPaste(props.data.day);
        },
      });
    }
  }

  return arr;
});

const toggle = (event: any) => {
  menu.value.toggle(event);
};

const getTexts = computed<string[]>(() => {
  try {
    if (props?.data?.workout) {
      const blocks = props.data.workout?.blocks;
      let str: string[] = [];
      for (let i = 0; i < blocks.length; i++) {
        if (i === 2) {
          str.push(`${blocks.length - 2} more...`);
          break;
        }
        const b = blocks[i];
        const exercises = b.block_exercises;
        const span = `<span class="font-semibold">${exercises[0].exercise.name}</span>`;
        const text = `${span} (+${exercises.length - 1} more ex.)`;
        str.push(text);
      }
      return str;
    }
    return [];
  } catch (e) {
    return [];
  }
});

const onCreate = async () => {
  if (props.data.day > -1 && organizationId.value) {
    addMixpanelEvent(EVENT_NAMES.Workouts.WorkoutCreated);
    const workout = await createWorkout({
      calendar_id: props.calendarId,
      status: "draft",
      instructions: "",
      day: props.data.day,
      organization_id: organizationId.value,
    });

    if ("error" in workout) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Could not create the workout",
        life: 10000,
      });
      return;
    }

    await router.push({
      name: ROUTE_NAME.WORKOUT_UPDATE,
      params: { id: workout.id },
      query: { create: 1 },
    });
  }
};

const emit = defineEmits(["deleteWorkout", "copyWorkout", "pasteWorkout"]);

const onCopy = (workoutId: number | undefined) => {
  emit("copyWorkout", { workoutId });
};

const onEdit = (day: number | undefined) => {
  if (day) {
    addMixpanelEvent(EVENT_NAMES.Workouts.WorkoutEdited);

    return router.push({
      name: ROUTE_NAME.WORKOUT_UPDATE,
      params: { id: day },
    });
  }
  onCreate();
};

const onPaste = (copyToDay: number | undefined) => {
  if (copyToDay || copyToDay === FIRST_CALENDAR_CELL_DAY) {
    emit("pasteWorkout", { copyToDay });
  }
};

const onDelete = (workoutId: number | undefined) => {
  if (workoutId) {
    emit("deleteWorkout", { workoutId });
  }
};
</script>

<style scoped>
.create-workout-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  line-height: 1.1;
  font-weight: bold;
  background: var(--surface-200);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.create-workout-btn:hover {
  opacity: 1;
}

.week-item {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
}

.item-top-bar {
  height: 20%;
  width: 100%;
}

.item-content {
  height: 80%;
  width: 100%;
}
</style>
