import { ref, type Ref } from "vue";

import { getInvoices } from "../api";
import type { PaginatedInvoiceListList } from "@/api/model";

const PAGE_SIZE = 20;
const FIRST_PAGE = 0;

export function useInvoices(): {
  paginatedInvoices: Ref<PaginatedInvoiceListList | null>;
  fetchInvoices: () => Promise<void>;
  invoiceFetchError: Ref<string | null>;
  currentPage: Ref<number>;
  pageSize: Ref<number>;
  totalRecords: Ref<number | undefined>;
} {
  const pageSize = ref(PAGE_SIZE);
  const currentPage = ref(FIRST_PAGE);
  const totalRecords = ref<number | undefined>(0);

  const invoiceFetchError = ref<string | null>(null);
  const paginatedInvoices = ref<PaginatedInvoiceListList | null>(null);

  async function fetchInvoices() {
    const offset = currentPage.value * pageSize.value;
    const results = await getInvoices({ limit: pageSize.value, offset });

    if ("error" in results) {
      invoiceFetchError.value = results.error;
    } else {
      paginatedInvoices.value = results;
      totalRecords.value = results.count;
    }
  }

  return {
    paginatedInvoices,
    fetchInvoices,
    invoiceFetchError,
    currentPage,
    pageSize,
    totalRecords,
  };
}
