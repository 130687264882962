<template>
  <div v-if="props.breadcrumb?.length > 0" class="card flex">
    <Breadcrumb
      class="bg-surface-100 overflow-x-visible"
      :model="props.breadcrumb"
    >
      <template #item="slotProps">
        <template
          v-if="slotProps?.item?.to && slotProps.item !== lastItemInBreadcrumb"
        >
          <router-link :to="slotProps.item.to" class="">
            <span class="font-semibold">{{ slotProps?.item?.label }}</span>
          </router-link>
        </template>
        <template v-else>
          <span
            :class="[
              { 'font-semibold': slotProps.item === lastItemInBreadcrumb },
            ]"
            class="py-4"
            >{{ slotProps?.item?.label }}</span
          >
        </template>
      </template>
    </Breadcrumb>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import Breadcrumb from "primevue/breadcrumb";
import type { MenuItem } from "primevue/menuitem";

interface Props {
  breadcrumb?: MenuItem[];
}
const props = withDefaults(defineProps<Props>(), {
  breadcrumb: () => [],
});

const lastItemInBreadcrumb = computed(() => {
  // Returns the last item from the breadcrumb array
  return props.breadcrumb[props.breadcrumb.length - 1];
});
</script>

<style scoped></style>
