<template>
  <form>
    <FixedActionBar
      button-type="submit"
      :is-loading="isSubmitting"
      :show-update="true"
      :show-delete="true"
      class="mb-6"
      @create="async () => await onCreate()"
      @update="async () => await onUpdate()"
      @delete="
        async () => {
          const workoutId = props.initialData?.id ?? 0;
          await confirmDeleteWorkout(workoutId);
        }
      "
    >
      <Select
        id="status"
        v-model="form.status"
        option-label="name"
        option-value="id"
        :options="[
          { id: 'published', name: 'Published' },
          { id: 'draft', name: 'Unpublished' },
        ]"
        placeholder="Status"
        class="mr-2"
      />
    </FixedActionBar>

    <AppCard class="fixed-box-width mb-12">
      <div class="flex flex-col gap-2">
        <label for="name">Coach Instructions</label>
        <Textarea v-model="form.instructions" class="w-full" />
        <div class="flex justify-end">
          <small class="text-xs text-surface-500 dark:text-surface-300">
            {{ form?.instructions?.length }} / 2500
          </small>
        </div>
      </div>
    </AppCard>

    <Sortable
      v-if="!form.blocks || form.blocks?.length > 0"
      :key="`sortable_block_${(form.blocks && form.blocks?.length > 0) ?? 0}-${
        sortableBlockOptions.disabled
      }`"
      :list="form.blocks"
      item-key="id"
      :options="sortableBlockOptions"
    >
      <template #item="{ element }">
        <AppCard :key="element.id" class="mb-12" :data-id="element.id">
          <div class="flex items-center justify-end pb-2">
            <i v-if="isDeletingBlock" class="pi pi-spin pi-spinner text-xl" />
            <i
              v-else
              class="pi pi-times-circle text-xl"
              :class="
                numberRealBlocks > 1
                  ? 'text-red-500 cursor-pointer'
                  : 'text-gray-300'
              "
              @click="
                () => (numberRealBlocks > 1 ? removeBlock(element.id) : null)
              "
            />
          </div>
          <div class="flex overflow-x-auto h-full">
            <Sortable
              v-if="
                !element.block_exercises || element.block_exercises?.length > 0
              "
              :key="`sortable_blockEx_${
                (element.block_exercises &&
                  element.block_exercises?.length > 0) ??
                0
              }-${sortableBlockExerciseOptions.disabled}`"
              :list="element.block_exercises"
              item-key="id"
              class="flex"
              :options="sortableBlockExerciseOptions"
            >
              <template #item="elementItem">
                <WBlock
                  :key="elementItem.element.id"
                  class=""
                  :data-id="`${element.id}-${elementItem.element.id}`"
                >
                  <div class="flex items-center justify-end mb-6">
                    <i
                      v-if="
                        isDeletingBlockExercise ===
                        `${element.id}-${elementItem.element.id}`
                      "
                      class="pi pi-spin pi-spinner"
                    />
                    <i
                      v-else-if="element.id && elementItem.element.id"
                      class="pi pi-times-circle"
                      :class="
                        element.block_exercises.filter(
                          (d: formTypeBlockExercises) =>
                            d?.exercise?.name !== ''
                        ).length > 1
                          ? 'text-red-500 cursor-pointer'
                          : 'text-gray-300'
                      "
                      @click="
                        () =>
                          element.block_exercises.filter(
                            (d: formTypeBlockExercises) =>
                              d?.exercise?.name !== ''
                          ).length > 1
                            ? removeBlockExercise(
                                element.id,
                                elementItem.element.id
                              )
                            : null
                      "
                    />
                  </div>

                  <Select
                    id="name"
                    v-model="elementItem.element.exercise.id"
                    option-label="name"
                    option-value="id"
                    :options="allExercises"
                    placeholder="Select an Exercise"
                    class="w-full"
                    filter
                    auto-filter-focus
                    reset-filter-on-hide
                    @change="
                      (e: onChangeUpdateExerciseType) =>
                        onChangeUpdateExercise(e.value, elementItem.element)
                    "
                  />

                  <InputGroup
                    v-for="(set, sIndex) in elementItem.element.sets"
                    :key="sIndex"
                    class="mt-2 mb-2"
                  >
                    <InputGroupAddon> Set {{ sIndex + 1 }} </InputGroupAddon>
                    <InputNumber
                      v-model="set.reps"
                      :disabled="
                        isUpdatingSet ===
                          `${element.id}-${elementItem.element.id}-${set.id}` ||
                        set.reps === null
                      "
                      placeholder="Reps"
                      fluid
                      @update:model-value="
                        (reps: number) =>
                          onChangeSet(
                            reps,
                            set.id,
                            element.id,
                            elementItem.element.id
                          )
                      "
                    />
                    <InputGroupAddon>
                      <Checkbox
                        :model-value="set.reps === null"
                        :binary="true"
                        class="mr-2"
                        @click="
                          () =>
                            toggleSetMax(
                              set,
                              element.id,
                              elementItem.element.id,
                              set.id
                            )
                        "
                      />
                      Max
                    </InputGroupAddon>
                  </InputGroup>

                  <AppButton
                    v-if="elementItem.element.exercise.id !== -1"
                    :loading="
                      isCreatingSet ===
                      `${element.id}-${elementItem.element.id}`
                    "
                    label="Add set"
                    class="py-1 px-2 text-xs font-light mr-2 mt-2"
                    @click="() => addSet(element.id, elementItem.element.id)"
                  />
                  <AppButton
                    v-if="elementItem.element.exercise.id !== -1"
                    :loading="
                      isDeletingSet ===
                      `${element.id}-${elementItem.element.id}`
                    "
                    label="Remove set"
                    severity="danger"
                    class="py-1 px-2 text-xs font-light"
                    :disabled="elementItem.element.sets.length === 1"
                    @click="() => removeSet(element.id, elementItem.element.id)"
                  />
                </WBlock>
              </template>
            </Sortable>

            <WBlock
              v-if="!syntheticActive"
              @add="() => addBlockExercise(element)"
            />
          </div>
        </AppCard>
      </template>
    </Sortable>

    <AppButton
      v-if="!syntheticActive"
      :loading="isCreatingBlock"
      label="Add next exercise"
      class="mb-20"
      @click="addNextBlock(false)"
    />
  </form>
</template>

<script setup lang="ts">
import Select from "primevue/select";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputnumber";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import Checkbox from "primevue/checkbox";
import WBlock from "./WBlock.vue";
import { Sortable } from "sortablejs-vue3";
import { catchErrorMessage } from "@/shared/utils/custom-errors";
import { computed, onMounted, ref } from "vue";
import router from "@/router";
import {
  createWorkout,
  postSet,
  updateWorkout,
  getExercises,
  postBlock,
  postBlockExercise,
  deleteBlock,
  deleteSet,
  updateSet,
  deleteBlockExercise,
  updateBlock,
  updateBlockExercise,
  deleteWorkout,
} from "../api";

import { useToast } from "primevue/usetoast";
import AppCard from "@/shared/components/AppCard.vue";
import FixedActionBar from "@/shared/components/FixedActionBar.vue";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { useRoute } from "vue-router";
import { useConfirm } from "primevue/useconfirm";

import { storeToRefs } from "pinia";
import { useAuthStore } from "@/modules/auth/stores/auth";
import type {
  AdminBlock,
  AdminSet,
  AdminBlockExercise,
  AdminWorkout,
} from "@/api/model";
import type { PublishStatus } from "@/api/model";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";
const authStore = useAuthStore();
const { defaultOrg } = authStore;
const { isSingleOrgUser } = storeToRefs(authStore);

let syntheticActive = ref(false);

const toast = useToast();
const route = useRoute();

interface initialDataType extends AdminWorkout {
  calendar_id: number;
  organization_id: number;
}

const props = defineProps<{
  initialData?: initialDataType;
}>();

let workoutHeader = ref("");

interface onChangeUpdateExerciseTypeElement {
  block_id: number;
  id: number;
  order: number;
}

interface onChangeUpdateExerciseType {
  value: number;
}

const createRealBlocksFromSynthetic = async (
  exercise_id: number,
  element: onChangeUpdateExerciseTypeElement
) => {
  const block = await postBlock({
    order: element.order,
    workout_id: props.initialData?.id ?? -1,
  });
  if ("error" in block) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: block.error,
      life: 10000,
    });
  } else {
    // synthetic index is always -1
    const blockIndex = getBlockIndexById(-1);

    form.value.blocks[blockIndex] = block;

    const blockId = block?.id;

    if (!blockId || blockId === -1) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "AdminBlock ID is not set. Refresh the page and try again.",
        life: 10000,
      });
      return;
    }

    const blockExercise = await postBlockExercise({
      exercise_id: exercise_id,
      order: 0,
      with_weights: calendarHasWeights.value,
      block_id: blockId,
    });
    if ("error" in blockExercise) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: blockExercise.error,
        life: 10000,
      });
    } else {
      form.value.blocks[blockIndex].block_exercises[0] = blockExercise;

      addSet(block.id, blockExercise.id);

      disableSynthetic();
    }
  }
};

const createRealExerciseBlocksFromSynthetic = async (
  exerciseId: number,
  element: onChangeUpdateExerciseTypeElement
) => {
  const blockId = element?.block_id;
  const blockIndex = getBlockIndexById(blockId);

  const blockExerciseIndex = form.value.blocks[
    blockIndex
  ].block_exercises.findIndex((e) => e?.exercise?.name === "");

  const blockExercise = await postBlockExercise({
    exercise_id: exerciseId,
    order: blockExerciseIndex + 1,
    with_weights: calendarHasWeights.value,
    block_id: blockId,
  });
  if ("error" in blockExercise) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: blockExercise.error,
      life: 10000,
    });
  } else {
    form.value.blocks[blockIndex].block_exercises[blockExerciseIndex] =
      blockExercise;

    addSet(blockId, blockExercise.id);

    disableSynthetic();
  }
};

const enableSynthetic = () => {
  syntheticActive.value = true;
  sortableBlockOptions.value.disabled = true;
  sortableBlockExerciseOptions.value.disabled = true;
};

const disableSynthetic = () => {
  syntheticActive.value = false;
  sortableBlockOptions.value.disabled = false;
  sortableBlockExerciseOptions.value.disabled = false;
};

const onChangeUpdateExercise = async (
  exercise_id: number,
  element: onChangeUpdateExerciseTypeElement
) => {
  const blockId = element.block_id || -1;
  const blockExerciseId = element.id || -1;

  // convert synthetic block to real block
  if (blockId === -1) {
    await createRealBlocksFromSynthetic(exercise_id, element);
    // exit early
    return;
  }

  if (blockExerciseId === -1) {
    await createRealExerciseBlocksFromSynthetic(exercise_id, element);
    // exit early
    return;
  }

  // now do existing content only
  const blockIndex = getBlockIndexById(blockId);
  const block = form.value.blocks[blockIndex];

  const blockExercise = await updateBlockExercise(blockExerciseId, {
    exercise_id: exercise_id,
    with_weights: calendarHasWeights.value,
  });
  if ("error" in blockExercise) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: blockExercise.error,
      life: 10000,
    });
  } else {
    const blockExerciseIndex = getBlockExerciseIndex(blockId, blockExerciseId);

    const blockExercises =
      form.value.blocks[blockIndex].block_exercises[blockExerciseIndex];

    block.block_exercises[blockExerciseIndex] = blockExercise;
    form.value.blocks[blockIndex] = block;
  }
};

const confirm = useConfirm();
const confirmDeleteWorkout = (workoutId: number) => {
  confirm.require({
    message: "Are you sure you want to delete this workout?",
    header: "Delete Workout",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        await deleteWorkout(workoutId);
        router.push({
          name: ROUTE_NAME.WORKOUT_LIST,
        });
      } catch (e: any) {
        showToast({ msg: e.error });
      }
    },
  });
};

let sortableBlockOptions = ref({
  animation: 100,
  direction: "vertical",
  disabled: false,
  store: {
    set: function (sortable: typeof Sortable) {
      const ids = sortable.toArray();
      for (let i = 0; i < ids.length; i++) {
        const id = parseInt(ids[i]);
        if (form.value.blocks) {
          const index = form.value.blocks.findIndex((e) => e.id === id);
          form.value.blocks[index].order = i;
          updateBlockOrder(id, i);
        }
      }
    },
  },
});

let sortableBlockExerciseOptions = ref({
  animation: 100,
  disabled: false,
  store: {
    set: function (sortable: typeof Sortable) {
      const ids = sortable.toArray();
      for (let i = 0; i < ids.length; i++) {
        const blockId = parseInt(ids[i].split("-")[0]);
        const id = parseInt(ids[i].split("-")[1]);
        const blockIndex = getBlockIndexById(blockId);
        const blockExerciseIndex = getBlockExerciseIndex(blockId, id);
        const blockExercise =
          form.value.blocks[blockIndex].block_exercises[blockExerciseIndex];
        blockExercise.order = i;
        updateBlockExerciseOrder(id, {
          order: i,
          exercise_id: blockExercise?.exercise?.id,
          with_weights: blockExercise.with_weights,
        });
      }
    },
  },
});

interface allExercisesType {
  id: number;
  name: string;
}

const isSubmitting = ref(false);
const isCreatingBlock = ref(false);
const isDeletingBlock = ref(false);
const isCreatingSet = ref<string | null>(null);
const isDeletingSet = ref<string | null>(null);
const isUpdatingSet = ref<string | null>(null);
const isDeletingBlockExercise = ref<string | null>(null);
const isCreateForm = computed<boolean>(() => route?.query?.create === "1");

const allExercises = ref<allExercisesType[]>([]);

interface blockExerciseExercise {
  id: number;
  name: string;
}

interface formTypeBlockExercises extends AdminBlockExercise {
  order?: number;
  exercise?: blockExerciseExercise;
  block_id?: number;
}

interface formTypeBlock extends AdminBlock {
  order?: number;
  block_exercises: formTypeBlockExercises[];
}

interface formType {
  calendar_id: number;
  status?: PublishStatus;
  instructions?: string;
  day: number;
  blocks: formTypeBlock[];
  organization_id?: number;
}

const form = ref<formType>({
  calendar_id: -1,
  status: "published",
  instructions: undefined,
  day: -1,
  blocks: [],
  organization_id: -1,
});

let selectedOrganization = ref(
  parseInt((route?.query?.organization_id || "").toString())
);
if (isSingleOrgUser.value || !selectedOrganization.value) {
  selectedOrganization.value = defaultOrg.id;
}

const emit = defineEmits(["setWorkoutForm"]);

const setFormValues = (workout: AdminWorkout) => {
  form.value = workout;

  if (workout?.organization_id) {
    selectedOrganization.value = workout.organization_id;
  }

  if (isCreateForm.value) {
    form.value.status = "published";
  }

  workoutHeader.value = generateWorkoutHeader(workout?.day);

  emit("setWorkoutForm", { title: workoutHeader.value });
};

let calendarHasWeights = ref<boolean>(true);

const generateWorkoutHeader = (dayNumber: number) => {
  if (!dayNumber && dayNumber !== 0) {
    return "";
  }

  const weekNumber = Math.floor(dayNumber / 7) + 1;
  const dayNumberOfWeek = dayNumber % 7;
  let days = ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7"];
  const workoutTitle = isCreateForm.value ? "New Workout: " : "Edit Workout: ";
  return `${workoutTitle}${days[dayNumberOfWeek]}, Week ${weekNumber}`;
};

const numberRealBlocks = computed(() => {
  return form.value.blocks.filter((d: formTypeBlock) => d?.id !== -1).length;
});

onMounted(async () => {
  if (props.initialData) {
    setFormValues(props.initialData);
  } else {
    if (route?.params?.day) {
      workoutHeader.value = generateWorkoutHeader(
        parseInt((route?.params?.day || "").toString())
      );
    }

    form.value = {
      calendar_id: parseInt(route.params.type.toString()),
      status: "published",
      instructions: undefined,
      day: parseInt(route.params.day.toString()),
      blocks: [],
      organization_id: selectedOrganization.value,
    };

    emit("setWorkoutForm", { title: workoutHeader.value });
  }

  if (!numberRealBlocks.value) {
    await addNextBlock(true);
  }

  if (props.initialData?.calendar?.with_weights) {
    calendarHasWeights.value = props.initialData?.calendar?.with_weights;
  }
  await fetchAllExercises(selectedOrganization.value);
});

const getBlockIndexById = (id: number) => {
  return form.value.blocks.findIndex((e) => e.id === id);
};
const getBlockExerciseIndex = (blockId: number, exerciseId: number) => {
  const index = getBlockIndexById(blockId);
  return form.value.blocks[index].block_exercises.findIndex(
    (e) => e.id === exerciseId
  );
};
const getSetIndex = (blockId: number, exerciseId: number, setId: number) => {
  const blockIndex = getBlockIndexById(blockId);
  const blockExerciseIndex = getBlockExerciseIndex(blockId, exerciseId);
  return form.value.blocks[blockIndex].block_exercises[
    blockExerciseIndex
  ].sets.findIndex((e) => e.id === setId);
};

const fetchAllExercises = async (organization_id: number) => {
  const rawExercises = await getExercises({
    organization_id: selectedOrganization.value,
    limit: 1000,
  });
  if ("error" in rawExercises) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: rawExercises?.error,
      life: 10000,
    });
  } else {
    const results = rawExercises.results || [];
    results.map((d) => {
      allExercises.value.push({ id: d.id, name: d.name });
    });
  }
};

const addNextBlock = async (disableEvent: boolean) => {
  if (!disableEvent) {
    addMixpanelEvent(EVENT_NAMES.Workouts.ExerciseBlockAdded);
  }
  isCreatingBlock.value = true;

  enableSynthetic();

  const block = {
    workoutId: props.initialData?.id ?? 0,
    order: form.value.blocks.length,
    id: -1,
    block_exercises: [
      {
        block_id: -1,
        exercise: { id: -1, name: "" },
        order: form.value.blocks.length,
        sets: [],
      },
    ],
  };

  form.value.blocks.push(block);

  isCreatingBlock.value = false;
};

const updateBlockOrder = async (id: number, order: number) => {
  try {
    await updateBlock(id, {
      order,
    });
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 3000,
    });
  }
};

const updateBlockExerciseOrder = async (id: number, data: {}) => {
  try {
    const result = await updateBlockExercise(id, data);
    if ("error" in result) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: result.error,
        life: 10000,
      });
    }
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 10000,
    });
  }
};

const removeBlock = async (id: number) => {
  try {
    isDeletingBlock.value = true;

    if (id && id !== -1) {
      await deleteBlock(id);
    }

    const index = getBlockIndexById(id);
    form.value.blocks.splice(index, 1);

    if (id === -1) {
      disableSynthetic();
    }
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 10000,
    });
  }
  isDeletingBlock.value = false;
};

interface addBlockExerciseType {
  id: number;
}

const addBlockExercise = async (element: addBlockExerciseType) => {
  enableSynthetic();
  addMixpanelEvent(EVENT_NAMES.Workouts.SupersetAdded);
  const blockId = element.id || -1;
  const blockIndex = getBlockIndexById(blockId);
  const blockExerciseIndex =
    form.value.blocks[blockIndex].block_exercises.length;

  form.value.blocks[blockIndex].block_exercises[blockExerciseIndex] = {
    block_id: blockId,
    exercise: { id: -1, name: "" },
    order: form.value.blocks[blockIndex].block_exercises.length,
    sets: [],
  };
};

const addSet = async (blockId: number, blockExerciseId: number) => {
  isCreatingSet.value = `${blockId}-${blockExerciseId}`;
  try {
    addMixpanelEvent(EVENT_NAMES.Workouts.SetAdded);
    const blockIndex = getBlockIndexById(blockId);
    const blockExerciseIndex = getBlockExerciseIndex(blockId, blockExerciseId);
    const blockExercises =
      form.value.blocks[blockIndex].block_exercises[blockExerciseIndex];
    const data = await postSet({
      block_exercise_id: blockExercises.id,
      reps: 10,
      order: blockExercises.sets.length,
    });
    blockExercises.sets.push(data);
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 10000,
    });
  }
  isCreatingSet.value = null;
};

const onChangeSet = async (
  reps: number,
  setId: number,
  blockId: number,
  blockExerciseId: number
) => {
  await updateSetValue(reps, blockId, blockExerciseId, setId);
};

const removeSet = async (blockId: number, blockExerciseId: number) => {
  isDeletingSet.value = `${blockId}-${blockExerciseId}`;
  try {
    const blockIndex = getBlockIndexById(blockId);
    const blockExerciseIndex = getBlockExerciseIndex(blockId, blockExerciseId);
    const blockExercises = form.value.blocks[blockIndex].block_exercises;
    const sets = blockExercises[blockExerciseIndex].sets;
    const lastItemIndex = sets.length - 1;
    if (lastItemIndex <= 0) {
      await removeBlockExercise(blockId, blockExerciseId);
    } else {
      const id = sets[lastItemIndex].id;
      if (id) {
        await deleteSet(id);
      }
      sets.splice(lastItemIndex, 1);
    }
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 10000,
    });
  }
  isDeletingSet.value = null;
};

const removeBlockExercise = async (
  blockId: number,
  blockExerciseId: number
) => {
  isDeletingBlockExercise.value = `${blockId}-${blockExerciseId}`;
  try {
    const blockIndex = getBlockIndexById(blockId);
    await deleteBlockExercise(blockExerciseId);
    const items = form.value.blocks[blockIndex].block_exercises;
    const blockExerciseIndex = getBlockExerciseIndex(blockId, blockExerciseId);
    items.splice(blockExerciseIndex, 1);
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 10000,
    });
  }
  isDeletingBlockExercise.value = null;
};

const updateSetValue = async (
  value: number | null,
  blockId: number,
  bxId: number,
  setId: number
) => {
  isUpdatingSet.value = `${blockId}-${bxId}-${setId}`;
  try {
    const blockIndex = getBlockIndexById(blockId);
    const blockExerciseIndex = getBlockExerciseIndex(blockId, bxId);
    const setIndex = getSetIndex(blockId, bxId, setId);
    if (setId) {
      const sets =
        form.value.blocks[blockIndex].block_exercises[blockExerciseIndex].sets;
      sets[setIndex] = await updateSet(setId, {
        reps: value,
        order: sets[setIndex].order,
      });
    }
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 3000,
    });
  }
  isUpdatingSet.value = null;
};

const toggleSetMax = async (
  set: AdminSet,
  blockId: number,
  bxId: number,
  setId: number
) => {
  const value = set.reps != null ? null : 0;
  await updateSetValue(value, blockId, bxId, setId);
};

const onCreate = async () => {
  try {
    addMixpanelEvent(EVENT_NAMES.Workouts.WorkoutCreated);
    const workout = await createWorkout({
      calendar_id: parseInt(route.params.type.toString()),
      status: form.value.status,
      instructions: form.value?.instructions,
      day: parseInt(route.params.day.toString()),
      organization_id: selectedOrganization.value,
    });
    if ("error" in workout) {
      showToast({ msg: workout.error });
    } else {
      await router.push({
        name: ROUTE_NAME.WORKOUT_UPDATE,
        params: { id: workout.id },
        query: { create: 1 },
      });
    }
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 3000,
    });
  }
};

interface showToastType {
  severity?: "success" | "info" | "warn" | "error" | undefined;
  summary?: string;
  msg: string;
  life?: number;
}

const showToast = (data: showToastType) => {
  toast.add({
    severity: data.severity ?? "error",
    summary: data?.summary ?? "Error",
    detail: data?.msg,
    life: data.life ?? 10000,
  });
};

const onUpdate = async () => {
  const workoutId = props.initialData?.id ?? 0;
  if (!workoutId) {
    showToast({ msg: "No workout ID" });
    return;
  }

  const result = await updateWorkout(workoutId, {
    calendar_id: props.initialData?.calendar_id,
    status: form.value.status,
    instructions: form.value?.instructions,
    day: props.initialData?.day,
    organization_id: form.value.organization_id,
  });

  if ("error" in result) {
    showToast({ msg: result.error });
  } else {
    showToast({
      severity: "success",
      msg: "Workout updated.",
      summary: "Success",
    });
    setFormValues(result);
  }
};
</script>

<style scoped>
.fixed-box-width {
  width: 100%;
}
</style>
