import { computed } from "vue";
import { useAuth } from "@/composables/useAuth";
import { useUserOrganizations } from "@/composables/useUserOrganizations";

interface CurrentUser {
  defaultOrganizationId: ReturnType<typeof useAuth>["defaultOrganizationId"];
  loggedInUserId: ReturnType<typeof useAuth>["loggedInUserId"];
  isStaff: ReturnType<typeof useAuth>["isStaff"];
  isSingleOrgUser: ReturnType<typeof useAuth>["isSingleOrgUser"];
  fetchOrganizations: () => Promise<void>;
  organizations: ReturnType<typeof useUserOrganizations>["organizations"];
  defaultOrganization: ReturnType<
    typeof useUserOrganizations
  >["defaultOrganization"];
}

/* 
    This composable is used to fetch and return the current user's information. 
    It returns the auth state, user organizations, and default organization.
    */

export function useCurrentUser(): CurrentUser {
  const auth = useAuth();
  const userOrganizations = useUserOrganizations();

  return {
    ...auth,
    fetchOrganizations: userOrganizations.fetchUserOrganizations,
    organizations: userOrganizations.organizations,
    defaultOrganization: computed(
      () => userOrganizations.defaultOrganization.value ?? undefined
    ),
  };
}
