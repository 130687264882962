<template>
  <BaseLayout title="Categories">
    <div class="flex justify-end">
      <Button label="Create Category" class="mb-4" @click="showModalCreate" />
      <StatCategoryDialog
        v-model:visible="showModal"
        v-model:is-edit="isEditing"
        v-model:stat-category-form-fields="statCategoryFormFields"
        :stat-category-id="statCategoryId"
        :field-validations="fieldValidations"
        :upsert-stat-category="upsertStatCategory"
        @upsert-success="fetchStatCategories"
        @after-hide="clearValidations"
      />
    </div>

    <div>
      <Tabs value="0">
        <TabList>
          <Tab value="0" class="font-bold">Categories</Tab>
        </TabList>
        <TabPanels>
          <TabPanel header="Categories" value="0">
            <div v-if="statCategories">
              <div class="button flex justify-end">
                <Button
                  icon="pi pi-bars"
                  class="border-0 mb-4"
                  text
                  :label="
                    showReorder
                      ? DISABLE_REORDER_LABEL
                      : REORDER_STAT_CATEGORY_BUTTON_LABEL
                  "
                  severity="secondary"
                  @click="toggleShowReorder"
                />
              </div>
              <div class="fadein animation-duration-250">
                <StatCategoriesTable
                  v-if="showTable"
                  v-model:stat-categories="statCategories"
                  :build-form-state="buildStatCategoryFormState"
                  :delete-stat-category="deleteStatCategory"
                  :show-modal-edit="showModalEdit"
                  :stat-category-id="statCategoryId"
                />

                <OrderStatCategories
                  v-if="showReorder"
                  v-model:stat-categories="statCategories"
                />
              </div>
            </div>

            <Paginator
              :always-show="true"
              :rows="pageSize"
              :total-records="totalRecords"
              template="
              FirstPageLink
              PrevPageLink
              CurrentPageReport
              NextPageLink
              LastPageLink
              RowsPerPageDropdown"
              current-page-report-template="Showing {first} to {last} of {totalRecords}"
              @page="onPageChange"
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import Button from "primevue/button";
import TabPanel from "primevue/tabpanel";
import TabPanels from "primevue/tabpanels";
import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import Paginator from "primevue/paginator";

import StatCategoryDialog from "../components/StatCategoryDialog.vue";
import StatCategoriesTable from "../components/StatCategoriesTable.vue";
import OrderStatCategories from "../components/OrderStatCategories.vue";

import { computed, onMounted, ref, watch } from "vue";
import { useStatCategoryForm } from "../composables/useStatCategoryForm";
import { useStatCategoryTable } from "../composables/useStatCategoryTable";
import { useToast } from "primevue/usetoast";

import type { PageState } from "primevue/paginator";
import {
  DISABLE_REORDER_LABEL,
  REORDER_STAT_CATEGORY_BUTTON_LABEL,
  SHOW_STAT_CATEGORY_BUTTON_LABEL,
} from "../constants";

const showModal = ref(false);
const isEditing = ref(false);
const showReorder = ref<boolean>(false);

const toast = useToast();

const {
  paginatedStatCategories,
  fetchStatCategories,
  statCategoryError,
  currentPage,
  pageSize,
  totalRecords,
  deleteStatCategory,
} = useStatCategoryTable();

const {
  statCategoryFormFields,
  fieldValidations,
  buildStatCategoryFormState,
  statCategoryId,
  upsertStatCategory,
  clearFormState,
} = useStatCategoryForm();

const toggleShowReorder = () => {
  if (showReorder.value) {
    fetchStatCategories();
  }

  showReorder.value = !showReorder.value;
};

const showTable = computed(() => !showReorder.value);

watch(statCategoryError, (_oldValue, _newValue) => {
  if (statCategoryError.value) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: statCategoryError.value,
    });
  }
});

const statCategories = computed(() => paginatedStatCategories.value?.results);

const onPageChange = (event: PageState) => {
  statCategoryError.value = undefined;

  currentPage.value = event.page;
  fetchStatCategories();
};

const showModalCreate = () => {
  clearFormState();

  isEditing.value = false;
  showModal.value = true;
};

const clearValidations = () => {
  fieldValidations.value.$reset();
};

const showModalEdit = () => {
  isEditing.value = true;
  showModal.value = true;
};

onMounted(() => {
  fetchStatCategories();
});
</script>
