/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `most_recent` - Most Recent
* `average` - Average
* `highest` - Highest
* `lowest` - Lowest
 */
export type HeadlineValueEnum = typeof HeadlineValueEnum[keyof typeof HeadlineValueEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HeadlineValueEnum = {
  most_recent: 'most_recent',
  average: 'average',
  highest: 'highest',
  lowest: 'lowest',
} as const;
