import { computed } from "vue";
import { useAuthStore } from "@/modules/auth/stores/auth";
import { storeToRefs } from "pinia";

export interface Auth {
  defaultOrganizationId: ReturnType<typeof computed>;
  loggedInUserId: ReturnType<typeof computed>;
  isStaff: ReturnType<typeof computed>;
  isSingleOrgUser: ReturnType<typeof computed>;
}

/* 
    This composable is used to fetch and return the auth state. 
    It returns the default organization id, logged in user id, staff status, and single org user status.
    */

export function useAuth(): Auth {
  const authStore = useAuthStore();
  const { defaultOrg, loggedInUserId, isStaff, isSingleOrgUser } =
    storeToRefs(authStore);

  return {
    defaultOrganizationId: computed(() => defaultOrg.value?.id),
    loggedInUserId,
    isStaff,
    isSingleOrgUser,
  };
}
