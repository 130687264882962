import { defineStore } from "pinia";
import { getDrills } from "@/modules/feed/api";
import type { AdminDrill, AdminTrainingCollectionDrillList } from "@/api/model";
import { JsonParsingError } from "@/shared/utils/custom-errors";

interface DrillsState {
  drills: AdminTrainingCollectionDrillList[];
  isLoading: boolean;
}

export const useDrillsStore = defineStore("drillsStore", {
  state: (): DrillsState => ({
    drills: [],
    isLoading: false,
  }),
  getters: {
    findById: (state) => {
      return (id: number) => state.drills.find((e) => e.id === id);
    },
  },
  actions: {
    async fetchDrills() {
      this.isLoading = true;
      try {
        const response = await getDrills();
        if (Array.isArray(response)) {
          this.drills = response;
        } else {
          throw new JsonParsingError();
        }
      } catch (e) {
        throw new JsonParsingError();
      }
      this.isLoading = false;
    },
  },
});
