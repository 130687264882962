<template>
  <AssetThumbnail
    v-if="isAsset && props.attachment.asset"
    :asset-prop="props.attachment.asset"
    box-size="140px"
  />

  <div
    v-if="isDrill"
    class="attach-box bg-surface-400 dark:bg-surface-400 rounded-border bg-cover rounded-border bg-cover"
    :style="{
      backgroundImage: `url(${drillThumbnailUrl()})`,
      'background-position': 'center',
    }"
  >
    <router-link
      :to="{
        name: ROUTE_NAME.DRILL_UPDATE,
        params: {
          drill_id: props.attachment.drill?.id,
          id: props.attachment.drill?.training_collection?.id,
        },
      }"
      class="text-indigo h-full text-white hover:shadow text-black/90 flex items-center justify-center text-xl font-medium text-primary cursor-pointer"
    >
      Drill
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type Attachment from "@/shared/datamodels/attachment";
import AssetThumbnail from "@/shared/components/AssetThumbnail.vue";
import { ROUTE_NAME } from "@/shared/constants/routes";
const props = defineProps<{
  attachment: Attachment;
}>();

const drillThumbnailUrl = () => {
  const drills = props.attachment.drill?.drill_assets;

  return drills?.find((e) => e.asset.type === "image")?.asset.file.cf_url;
};
const isDrill = computed((): boolean => {
  return props?.attachment.type === "drill";
});
const isAsset = computed((): boolean => {
  return props?.attachment?.type === "asset";
});
</script>

<style scoped>
.attach-box {
  height: 140px;
  min-width: 140px;
}
</style>
