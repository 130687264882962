<template>
  <Avatar
    v-if="props.asset && getAssetThumbnail(props.asset)"
    :image="getAssetThumbnail(props.asset)"
    :size="size"
    shape="circle"
    class="avatar-object-fit"
  />
  <Avatar
    v-else
    :label="props.initials"
    :size="size"
    style="color: #000"
    shape="circle"
  />
</template>

<script setup lang="ts">
import type { AdminAsset } from "@/api/model";
import { getAssetThumbnail } from "@/shared/utils/helpers";
import Avatar from "primevue/avatar";

interface Props {
  initials: string;
  size?: "normal" | "large" | "xlarge";
  color?: string;
  asset?: AdminAsset;
}
const props = withDefaults(defineProps<Props>(), {
  initials: "",
  size: "normal",
  color: "black",
  asset: undefined,
});
</script>
