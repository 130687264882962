<template>
  <div class="bg-surface-900 p-4 sm:p-8">
    <div class="flex items-center justify-center min-h-screen">
      <div class="bg-surface-0 w-full max-w-[800px] p-4 sm:p-8 rounded-md">
        <div
          class="flex flex-col md:flex-row items-center md:items-start gap-8"
        >
          <div class="w-full md:w-1/2 flex justify-center md:justify-start">
            <img
              alt="TFA logo"
              class="max-w-[200px] md:max-w-xs"
              src="@/assets/logo-f-512.png"
            />
          </div>
          <div class="w-full md:w-1/2">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
