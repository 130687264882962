import { defineStore } from "pinia";
import { getTrainingCollections } from "@/modules/feed/api";
import type { AdminTrainingCollection } from "@/api/model";

interface TrainingCollectionsState {
  trainingCollections: AdminTrainingCollection[];
  isLoading: boolean;
}

export const useTrainingCollectionsStore = defineStore(
  "trainingCollectionsStore",
  {
    state: (): TrainingCollectionsState => ({
      trainingCollections: [],
      isLoading: false,
    }),
    getters: {
      findById: (state) => {
        return (id: number) =>
          state.trainingCollections.find((e) => e.id === id);
      },
      getTitleById: (state) => {
        return (id: number) => {
          const item = state.trainingCollections.find((e) => e.id === id);
          if (item) {
            return item?.name;
          }
          return "Not Found.";
        };
      },
      getOptionById: (state) => {
        return (id: number) => {
          const item = state.trainingCollections.find((e) => e.id === id);
          if (item) {
            return {
              label: item?.name,
              value: item?.id,
            };
          }
          return undefined;
        };
      },
      getAsOptions: (state) => {
        return state.trainingCollections
          .filter((e) => e?.name && e?.id)
          .map((e) => ({
            label: e?.name,
            value: e?.id,
          }));
      },
    },
    actions: {
      async fetchTrainingCollections() {
        this.isLoading = true;
        try {
          const rawTrainingCollections = await getTrainingCollections();
          if ("error" in rawTrainingCollections) {
            this.trainingCollections = [];
          } else {
            this.trainingCollections = rawTrainingCollections;
          }
        } catch (e) {
          this.trainingCollections = [];
        }
        this.isLoading = false;
      },
    },
  }
);
