<template>
  <AuthLayout>
    <div class="w-full">
      <div class="text-2xl font-semibold mb-2">Login</div>
      <p class="text-subtitle-2 mb-6">
        Welcome back, please login to your account.
      </p>

      <form @submit.prevent="submit">
        <div class="flex flex-col gap-1 mb-4">
          <label for="email">Email</label>
          <InputText
            id="email"
            ref="emailInput"
            v-model="email"
            autocomplete="username"
            type="text"
            placeholder="Email"
            class="w-full"
          />
          <small v-if="v$ErrorMessage(v$.email.$errors)" class="text-red-500">
            {{ v$ErrorMessage(v$.email.$errors) }}
          </small>
        </div>

        <div class="flex items-center justify-between mt-6">
          <Button
            type="submit"
            :loading="isSubmitting"
            label="Submit"
            class="w-full sm:w-auto"
          />
        </div>
      </form>
    </div>
  </AuthLayout>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import AuthLayout from "../components/AuthLayout.vue";
import InputText from "primevue/inputtext";
import router from "@/router";
import Button from "primevue/button";

import { useToast } from "primevue/usetoast";

import { v$ErrorMessage } from "@/shared/utils/helpers";

import { useAuthStore } from "../stores/auth";
import type { PreferredAdminAuthMethodEnum } from "@/api/model";

const email = ref<string>("");
const isSubmitting = ref<boolean>(false);
const emailInput = ref<HTMLInputElement | null>();

const preferredAuthMethod = ref<PreferredAdminAuthMethodEnum>();

const magicLinkAlreadySent = ref<boolean | undefined>(false);
import {
  PASSWORD_AND_MAGIC_LINK_VALUE,
  MAGIC_LINK_VALUE,
} from "@/modules/profile/contants";
import { ROUTE_NAME } from "@/shared/constants/routes";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  EMAIL_REQUIRED_MAGIC_LINK_ERROR,
  MAGIC_LINK_SENT_COPY,
} from "@/shared/constants/auth";

const { checkEmail, sendMagicLinkEmail } = useAuthStore();

const toast = useToast();

const rules = { email: { required } };
const form = { email };

const v$ = useVuelidate(rules, form);

const submit = async () => {
  isSubmitting.value = true;

  await v$.value.$validate();
  if (v$.value.$invalid) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Email is required",
      life: 3000,
    });
    isSubmitting.value = false;

    return;
  }

  const response = await checkEmail(email.value);

  if ("error" in response) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: response.error,
    });

    isSubmitting.value = false;

    return;
  }

  preferredAuthMethod.value =
    response.preferred_admin_auth_method as PreferredAdminAuthMethodEnum;
  magicLinkAlreadySent.value = response.magic_link_sent;

  if (preferredAuthMethod.value === MAGIC_LINK_VALUE) {
    await handleMagicLink();
  }

  if (preferredAuthMethod.value === PASSWORD_AND_MAGIC_LINK_VALUE) {
    await router.push({
      name: ROUTE_NAME.LOGIN_PASSWORD,
      params: { email: email.value },
    });

    isSubmitting.value = false;
  }
};

const handleMagicLink = async () => {
  if (!email.value) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: EMAIL_REQUIRED_MAGIC_LINK_ERROR,
      life: 30000,
    });
    isSubmitting.value = false;
    return;
  }

  if (magicLinkAlreadySent.value) {
    toast.add({
      severity: "success",
      summary: "success",
      detail: MAGIC_LINK_SENT_COPY,
      life: 30000,
    });
    isSubmitting.value = false;
    return;
  }

  const response = await sendMagicLinkEmail(email.value);

  if (response && "error" in response) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: response.error,
    });
    isSubmitting.value = false;

    return;
  }

  toast.add({
    severity: "success",
    summary: "Success",
    detail: MAGIC_LINK_SENT_COPY,
  });

  isSubmitting.value = false;
  return;
};

const focusEmailInput = () => {
  if (emailInput.value) {
    emailInput.value.$el.focus();
  }
};

onMounted(focusEmailInput);
</script>
