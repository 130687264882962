const metaEnv = import.meta.env;

export const BASE_URL = metaEnv.VITE_BASE_URL;
export const API_URL = metaEnv.VITE_API_URL;
export const SENTRY_DSN = metaEnv.VITE_SENTRY_DSN;
export const SENTRY_ENV = metaEnv.VITE_SENTRY_ENV;
export const SENTRY_ORG = metaEnv.VITE_SENTRY_ORG;
export const SENTRY_PROJECT = metaEnv.VITE_SENTRY_PROJECT;
export const SENTRY_AUTH_TOKEN = metaEnv.VITE_SENTRY_AUTH_TOKEN;
export const MIXPANEL_TOKEN = metaEnv.VITE_MIXPANEL_TOKEN;
export const RELEASE_VERSION = metaEnv.VITE_RELEASE_VERSION;
