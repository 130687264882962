import { defineStore, storeToRefs } from "pinia";
import { getOrganization, getOrganizations } from "@/modules/organizations/api";
import { useAuthStore } from "@/modules/auth/stores/auth";

interface OrganizationState {
  organization: object;
  organizations: [] | undefined;
  organizationError: string | undefined;
  organizationsError: string | undefined;
}

export const useOrganizationStore = defineStore("organizationStore", {
  state: (): OrganizationState => ({
    organization: {
      name: undefined,
    },
    organizations: [],
    organizationError: undefined,
    organizationsError: undefined,
  }),
  getters: {
    organizationName: (state) => state.organization.name,
    organizationData: (state) => state.organization,
    hasOrganizationError: (state) => state.organizationError,
    hasOrganizationsError: (state) => state.organizationsError,
  },
  actions: {
    async fetchOrganization(
      orgId: number,
      isStaff: boolean,
      limit: number,
      offset: number
    ) {
      const org = await getOrganization(orgId, limit, offset);
      if (org?.error) {
        this.organizationError = org.error;
        this.organization = {};
      } else {
        this.organization = org?.data ?? {};
        this.organizationError = "";
      }
    },
    async getAllOrganizations() {
      const authStore = useAuthStore();
      const { defaultOrg, logout } = authStore;
      const { isSingleOrgUser } = storeToRefs(authStore);

      if (isSingleOrgUser.value === null) {
        await logout();
      }

      if (isSingleOrgUser.value) {
        this.organizations = [defaultOrg];
      } else {
        const orgs = await getOrganizations(null, 1000000, null, null);
        if (orgs?.error) {
          this.organizations = [];
          this.organizationsError = orgs.error;
        } else {
          this.organizations = orgs?.results ?? [];
          this.organizationsError = "";
        }
      }
    },
  },
});
