import {
  adminV1TeamsList,
  adminV1TeamsCreate,
  adminV1TeamsDestroy,
  adminV1TeamsRetrieve,
  adminV1TeamsUpdate,
} from "@/api/admin-teams/admin-teams";
import {
  adminV1ProfilesTeamsCreate,
  adminV1ProfilesTeamsDestroy,
} from "@/api/admin-profiles/admin-profiles";

import { adminV1CoachesList } from "@/api/admin-coaches/admin-coaches";
import type { AdminTeam } from "@/api/model";
import { EVENT_NAMES, addMixpanelEvent } from "../../shared/utils/analytics";

export const getTeams = async (
  resultsPerPage: number,
  paginationNum: number,
  organization_id?: number
) => {
  try {
    const result = await adminV1TeamsList({
      limit: resultsPerPage,
      offset: paginationNum,
      organization: organization_id,
    });
    return result;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const getTeam = async (teamId: number) => {
  try {
    const result = await adminV1TeamsRetrieve(teamId);
    return result;
  } catch (e: any) {
    return { error: e?.message };
  }
};

export const updateTeam = async (
  teamId: number,
  form: Pick<AdminTeam, "name" | "organization_id">
) => {
  try {
    const result = await adminV1TeamsUpdate(teamId, form);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const createTeam = async (
  form: Pick<AdminTeam, "name" | "organization_id">
) => {
  try {
    const result = await adminV1TeamsCreate({
      name: form?.name,
      organization_id: form?.organization_id,
    });
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const deleteTeam = async (teamId: number) => {
  try {
    await adminV1TeamsDestroy(teamId);
  } catch (e: any) {
    return { error: e.message };
  }
};

export const addUserToTeam = async (teamId: number, userId: number) => {
  try {
    const result = await adminV1ProfilesTeamsCreate(userId, teamId);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const deleteUserFromTeam = async (userId: number, teamId: number) => {
  try {
    const result = await adminV1ProfilesTeamsDestroy(userId, teamId);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const deleteMemberFromTeam = async (userId: number, teamId: number) => {
  try {
    const result = await adminV1ProfilesTeamsDestroy(userId, teamId);
    return result;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getCoaches = async (
  teamIds: number[],
  limit: number,
  offset: number
) => {
  try {
    const coaches =
      (await adminV1CoachesList({
        teams: teamIds,
        limit: limit,
        offset: offset,
      })) || [];
    return coaches;
  } catch (e: any) {
    return {
      error: e?.message,
    };
  }
};
