<template>
  <AuthLayout>
    <div class="text-2xl font-semibold">{{ page?.header }}</div>
    <p class="text-sm mb-6">{{ page?.body }}</p>
    <form @submit.prevent="submit">
      <div class="flex flex-col gap-1 mb-2">
        <label for="code">Code</label>
        <InputText
          id="code"
          v-model="form.code"
          type="text"
          :class="{ 'p-invalid': v$ErrorMessage(v$.code.$errors) }"
          placeholder="Code"
          @blur="v$.code.$touch"
        />
        <small v-if="v$ErrorMessage(v$.code.$errors)" class="text-red-500">
          {{ v$ErrorMessage(v$.code.$errors) }}
        </small>
      </div>

      <div class="flex flex-col gap-1 mb-2">
        <label for="password">Password</label>
        <span class="p-input-icon-right">
          <InputText
            id="password"
            v-model="form.password"
            :type="form.showPassword ? 'text' : 'password'"
            class="w-full"
            :class="{ 'p-invalid': v$ErrorMessage(v$.password.$errors) }"
            placeholder="Password"
            @blur="v$.password.$touch"
          />
          <i
            class="pi cursor-pointer absolute top-1/2 right-3 transform -translate-y-1/2"
            :class="[form.showPassword ? 'pi-eye' : 'pi-eye-slash']"
            @click="form.showPassword = !form.showPassword"
          />
        </span>
        <small v-if="v$ErrorMessage(v$.password.$errors)" class="text-red-500">
          {{ v$ErrorMessage(v$.password.$errors) }}
        </small>
      </div>

      <div class="flex flex-col gap-1 mb-2">
        <label for="confirmPassword">Confirm password</label>
        <span class="p-input-icon-right">
          <InputText
            id="confirmPassword"
            v-model="form.confirmPassword"
            :type="form.showConfirmPassword ? 'text' : 'password'"
            class="w-full"
            :class="{ 'p-invalid': v$ErrorMessage(v$.confirmPassword.$errors) }"
            placeholder="Confirm password"
            @blur="v$.confirmPassword.$touch"
          />
          <i
            class="pi cursor-pointer absolute top-1/2 right-3 transform -translate-y-1/2"
            :class="[form.showConfirmPassword ? 'pi-eye' : 'pi-eye-slash']"
            @click="form.showConfirmPassword = !form.showConfirmPassword"
          />
        </span>
        <small
          v-if="v$ErrorMessage(v$.confirmPassword.$errors)"
          class="text-red-500"
        >
          {{ v$ErrorMessage(v$.confirmPassword.$errors) }}
        </small>
      </div>
      <AppButton
        type="submit"
        class="mt-2"
        :loading="form.isSubmitting"
        label="Submit"
      />
    </form>
  </AuthLayout>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import AuthLayout from "../components/AuthLayout.vue";
import { changePassword } from "../api";
import { computed, reactive } from "vue";
import router from "@/router";
import { useRoute } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { catchErrorMessage } from "@/shared/utils/custom-errors";
import {
  minLength,
  maxLength,
  required,
  helpers,
  sameAs,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import { useToast } from "primevue/usetoast";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";

const toast = useToast();

const route = useRoute();

interface LoginForm {
  code: string;
  password: string;
  showPassword: boolean;
  confirmPassword: string;
  showConfirmPassword: boolean;
  isSubmitting: boolean;
}

const form = reactive<LoginForm>({
  code: "",
  password: "",
  showPassword: false,
  confirmPassword: "",
  showConfirmPassword: false,
  isSubmitting: false,
});

const rules = computed(() => ({
  code: { required, minLength: minLength(6), maxLength: maxLength(6) },
  password: { required, minLength: minLength(6) },
  confirmPassword: {
    sameAs: helpers.withMessage(
      "Password and Confirm password do not match.",
      sameAs(form.password)
    ),
  },
}));

const v$ = useVuelidate(rules, form);

const submit = async () => {
  await v$.value.$validate();
  if (v$.value.$invalid) {
    return;
  }
  try {
    addMixpanelEvent(EVENT_NAMES.PASSWORD.PasswordResetRequested, {
      source: "admin",
    });

    await changePassword(form.code, form.password);
    await router.push({ name: ROUTE_NAME.LOGIN });

    addMixpanelEvent(EVENT_NAMES.PASSWORD.PasswordResetSucceeded, {
      source: "admin",
    });

    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Password changed. Login with new credentials.",
      life: 3000,
    });
  } catch (e: unknown) {
    addMixpanelEvent(EVENT_NAMES.PASSWORD.PasswordResetFailed, {
      source: "admin",
    });

    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 3000,
    });
  }
};

const reset_password = parseInt((route?.query?.reset_required || 0).toString());

const page = computed(() => {
  if (reset_password) {
    return {
      header: "Reset Your Password",
      body: "You are required to update your password. An email has been sent to you with a reset code. Please check your email and fill in the following information.",
    };
  } else {
    return {
      header: "Reset Your Password?",
      body: "Fill in the following information.",
    };
  }
});
</script>
