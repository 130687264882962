<template>
  <AuthLayout>
    <div class="text-2xl font-semibold">Login</div>
    <p class="text-subtitle-2 mb-6">
      <a
        href="#"
        class="text-blue-600 underline hover:text-blue-800"
        @click="handleMagicLinkLogin"
        >Sign in with a magic link
      </a>
      or use your password.
    </p>
    <form @submit.prevent="submit">
      <div class="flex flex-col gap-1 mb-2">
        <label for="email">Email</label>
        <InputText
          id="email"
          v-model="form.email"
          type="text"
          :class="{ 'p-invalid': v$ErrorMessage(v$.email.$errors) }"
          placeholder="Email"
          autocomplete="username"
          @blur="v$.email.$touch"
        />
        <small v-if="v$ErrorMessage(v$.email.$errors)" class="text-red-500">
          {{ v$ErrorMessage(v$.email.$errors) }}
        </small>
      </div>

      <div class="flex flex-col gap-1">
        <label for="password">Password</label>
        <div class="relative">
          <InputText
            id="password"
            ref="passwordInput"
            v-model="form.password"
            :type="form.showPassword ? 'text' : 'password'"
            class="w-full"
            :class="{ 'p-invalid': v$ErrorMessage(v$.password.$errors) }"
            placeholder="Password"
            autocomplete="password"
            @blur="v$.password.$touch"
          />
          <i
            class="pi cursor-pointer absolute top-1/2 right-3 transform -translate-y-1/2"
            :class="[form.showPassword ? 'pi-eye' : 'pi-eye-slash']"
            @click="form.showPassword = !form.showPassword"
          />
        </div>
        <small v-if="v$ErrorMessage(v$.password.$errors)" class="text-red-500">
          {{ v$ErrorMessage(v$.password.$errors) }}
        </small>
      </div>

      <div class="flex items-center justify-between mt-6">
        <AppButton type="submit" :loading="form.isSubmitting" label="Submit" />
        <RouterLink
          :to="{ name: ROUTE_NAME.FORGOT_PASSWORD }"
          class="text-blue-600 underline hover:text-blue-800"
        >
          Forgot Password ?
        </RouterLink>
      </div>
    </form>
  </AuthLayout>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import AuthLayout from "../components/AuthLayout.vue";
import { ref, onMounted, computed, reactive } from "vue";
import { useAuthStore } from "../stores/auth";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { catchErrorMessage } from "@/shared/utils/custom-errors";
import { email, minLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import { useToast } from "primevue/usetoast";
import {
  EMAIL_REQUIRED_MAGIC_LINK_ERROR,
  MAGIC_LINK_SENT_COPY,
  NOT_ADMIN_ERROR_COPY,
} from "@/shared/constants/auth";

const toast = useToast();

const passwordInput = ref<HTMLInputElement | null>();

interface LoginForm {
  email: string;
  password: string;
  isSubmitting: boolean;
  showPassword: boolean;
}

const props = defineProps<{
  email?: string;
}>();

const form = reactive<LoginForm>({
  email: props.email || "",
  password: "",
  isSubmitting: false,
  showPassword: false,
});

const rules = computed(() => ({
  email: { required, email },
  password: { required, minLength: minLength(6) },
}));

const v$ = useVuelidate(rules, form);

const { login, sendMagicLinkEmail } = useAuthStore();

const handleMagicLinkLogin = async () => {
  if (!form.email) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: EMAIL_REQUIRED_MAGIC_LINK_ERROR,
    });
    return;
  }
  const response = await sendMagicLinkEmail(form.email);

  if (response && "error" in response) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: response.error,
    });
    form.isSubmitting = false;

    return;
  }

  toast.add({
    severity: "success",
    summary: "Success",
    detail: MAGIC_LINK_SENT_COPY,
  });

  form.isSubmitting = false;
  return;
};

const submit = async () => {
  await v$.value.$validate();

  if (v$.value.$invalid) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(
        "Email / Password is required. Please fill out both and try again."
      ),
      life: 30000,
    });
    return;
  }

  try {
    form.isSubmitting = true;
    await login({
      email: form.email,
      password: form.password,
    });

    const { isLoggedInButNotAdmin, isLoggedIn } = useAuthStore();

    if (isLoggedInButNotAdmin) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: NOT_ADMIN_ERROR_COPY,
        life: 10000,
      });
    } else if (!isLoggedIn) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Invalid username/password.",
        life: 10000,
      });
    } else {
      await router.push({ name: ROUTE_NAME.FEED });
      // force reload
      await router.go(0);
    }
  } catch (e: unknown) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 3000,
    });
  }
  form.isSubmitting = false;
};

const focusPasswordInput = () => {
  if (passwordInput.value) {
    passwordInput.value.$el.focus();
  }
};

onMounted(focusPasswordInput);
</script>
