import type { Invoice, InvoiceList } from "@/api/model";
import type {
  InvoiceStatus,
  InvoiceStatusColors,
  InvoiceStatusDisplay,
} from "@/types";

import { format } from "date-fns";

import {
  ACTIVE_INVOICE,
  ACTIVE_INVOICE_DISPLAY,
  CANCELLED_INVOICE,
  CANCELLED_INVOICE_DISPLAY,
  DRAFT_INVOICE,
  DRAFT_INVOICE_DISPLAY,
  PAID_INVOICE,
  PAID_INVOICE_DISPLAY,
  PARTIALLY_PAID_INVOICE,
  PARTIALLY_PAID_INVOICE_DISPLAY,
  PAST_DUE_INVOICE,
  PAST_DUE_INVOICE_DISPLAY,
  UNKNOWN_INVOICE_DISPLAY,
} from "./constants";
import { BLACK, BLUE, GREEN, RED } from "@/shared/constants/colors";

export const getInvoiceStatusColor = (
  status: InvoiceStatus
): InvoiceStatusColors => {
  switch (status) {
    case ACTIVE_INVOICE:
      return BLUE;
    case PAID_INVOICE:
      return GREEN;
    case PAST_DUE_INVOICE:
      return RED;
    default:
      return BLACK;
  }
};

export const getFormattedInvoiceStatus = (
  status: InvoiceStatus
): InvoiceStatusDisplay => {
  switch (status) {
    case ACTIVE_INVOICE:
      return ACTIVE_INVOICE_DISPLAY;
    case PAID_INVOICE:
      return PAID_INVOICE_DISPLAY;
    case PAST_DUE_INVOICE:
      return PAST_DUE_INVOICE_DISPLAY;
    case DRAFT_INVOICE:
      return DRAFT_INVOICE_DISPLAY;
    case PARTIALLY_PAID_INVOICE:
      return PARTIALLY_PAID_INVOICE_DISPLAY;
    case CANCELLED_INVOICE:
      return CANCELLED_INVOICE_DISPLAY;
    default:
      return UNKNOWN_INVOICE_DISPLAY;
  }
};

export const getFormattedTitle = (data: Invoice | InvoiceList) => {
  if ("invoice_title" in data && data.invoice_title) {
    return data.invoice_title;
  }

  if ("invoice_line_item_names" in data && data.invoice_line_item_names) {
    const lineItemNames = data.invoice_line_item_names;
    const firstLineItem = lineItemNames[0];
    return firstLineItem || "N/A";
  }

  if ("items" in data && data.items && data.items.length > 0) {
    return data.items[0].item_product_name || "N/A";
  }

  return "N/A";
};

export const getLineItemCount = (data: InvoiceList) => {
  const lineItemNames = data.invoice_line_item_names;
  const lineItemsLength = lineItemNames.length - 1;

  if (lineItemsLength > 0) {
    return `+ ${lineItemsLength}`;
  }
};

export const getFormattedCurrency = (value: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const getFormattedCreatedAt = (dateString: string | Date) => {
  return format(new Date(dateString), "MMMM dd, yyyy");
};
