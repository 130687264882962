<template>
  <div v-if="sources.length && displayVideoError < 2" class="flex">
    <video ref="videoPlayer" class="video-js w-full h-full" />
  </div>
  <div
    v-if="videoIsProcessing && displayVideoError >= 2"
    class="flex flex-col items-center justify-center w-full h-full"
  >
    <div
      class="bg-surface-50 dark:bg-surface-950 rounded-border border-surface border-4 text-surface-100 font-bold p-2 flex items-center justify-center"
    >
      Video processing
    </div>
  </div>
  <div
    v-if="!videoIsProcessing && displayVideoError >= 2"
    class="flex flex-col items-center justify-center w-full h-full"
  >
    Video could not be loaded. Either the video is unsupported in this browser,
    it is currently processing, or a network error occured. Refresh the page
    might resolve the network issue.
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, watchEffect } from "vue";
import videojs from "video.js";
import { differenceInMinutes } from "date-fns";

import { useSignedCookies } from "@/shared/stores/signedCookies";
import type { AdminAsset } from "@/api/model";
import type Player from "video.js/dist/types/player";
import type Tech from "video.js/dist/types/tech/tech";
const { getSignedCookies } = useSignedCookies();

const videoPlayer = ref(null);

interface sourcesType {
  src: string;
  type: string;
  withCredentials?: boolean;
}

interface propsType {
  autoplay?: boolean;
  aspectRatio?: string;
  controls?: boolean;
  fluid?: boolean;
  sources?: sourcesType[];
  html5?: {
    vhs: {
      overrideNative: boolean;
      withCredentials: boolean;
      enableLowInitialPlaylist: boolean;
    };
    nativeAudioTracks: boolean;
    nativeVideoTracks: boolean;
  };
}

interface ExtendedTech extends Tech {
  vhs?: {
    xhr?: {
      onResponse: (
        playerResponseHook: (
          request: Request,
          error: Error,
          response: Response
        ) => void
      ) => void;
    };
  };
}

const props = withDefaults(
  defineProps<{
    defaults?: propsType;
    options?: propsType;
    asset: AdminAsset;
  }>(),
  {
    defaults: () => ({
      autoplay: false,
      aspectRatio: "16:9",
      controls: true,
      errorDisplay: false,
      fluid: true,
      sources: [],
      html5: {
        vhs: {
          overrideNative: true,
          withCredentials: true,
          bandwidth: 120000000,
          useDevicePixelRatio: true,
        },
        nativeAudioTracks: false,
        nativeVideoTracks: false,
      },
    }),
    options: undefined,
    asset: undefined,
  }
);

const assetVideo = ref<AdminAsset | undefined>();
watchEffect(() => (assetVideo.value = props.asset));

const sources = ref<sourcesType[]>([]);

const updateSources = () => {
  if (props.options?.sources && props.options.sources.length > 0) {
    sources.value = [...props.options.sources];
  } else {
    sources.value = [];

    if (
      assetVideo.value?.video?.hls?.cf_url &&
      assetVideo.value?.video?.hls?.content_type
    ) {
      sources.value.push({
        src: assetVideo.value?.video?.hls?.cf_url,
        type: assetVideo.value?.video?.hls?.content_type,
        withCredentials: true,
      });
    }
    if (
      assetVideo.value?.file?.cf_url &&
      assetVideo.value?.file?.content_type
    ) {
      sources.value.push({
        src: assetVideo.value?.file?.cf_url,
        type: assetVideo.value?.file?.content_type,
      });
    }
  }
};

watchEffect(() => {
  updateSources();
});

let signedCookies = ref();

const options = computed(() => {
  return {
    ...props?.defaults,
    ...props?.options,
    sources: sources.value.length > 0 ? sources.value : props.defaults.sources,
    html5: {
      ...props?.defaults?.html5,
      ...props?.options?.html5,
      vhs: {
        ...props?.defaults?.html5?.vhs,
        ...props?.options?.html5?.vhs,
      },
    },
  };
});

const videoIsProcessing = computed(() => {
  if (assetVideo.value?.video?.hls) {
    return false;
  }

  const uploadedDate = assetVideo.value?.created_at;
  if (uploadedDate) {
    if (differenceInMinutes(new Date(), new Date(uploadedDate)) < 5) {
      return true;
    }
  }

  return false;
});

interface setPosterImageType {
  videoWidth: number;
  videoHeight: number;
  autoplay: boolean;
  thumbnail_url_tpl?: string | null;
}

const setPosterImage = (data: setPosterImageType) => {
  // set poster based on video width - while maintaining aspect ratio
  // round up to the nearest 100 pixels to hope for some caching
  const videoWidth = Math.ceil(data.videoWidth / 100) * 100;
  const aspectRatio = data.videoWidth / data.videoHeight;
  const videoHeight = Math.ceil(videoWidth / aspectRatio);

  const isAutoplay = data.autoplay;
  const thumbnail_url_tpl = data?.thumbnail_url_tpl;

  if (!isAutoplay && videoWidth && videoHeight && thumbnail_url_tpl) {
    return thumbnail_url_tpl
      ?.replace("{width}", videoWidth.toString())
      ?.replace("{height}", videoHeight.toString());
  } else {
    return "";
  }
};

let playerStateError = ref(false);
let displayVideoError = ref(0);
let fetchingCookieDate = ref(new Date().getTime());
let currentlyRefreshingVideoPlayer = ref(false);

const refreshVideoPlayer = async () => {
  sources.value = sources.value.map((d) => {
    d.src = d?.src?.split("?")[0];
    return d;
  });

  const now = new Date().getTime();
  // do not look at the cookie for at least 10 seconds to prevent ddos
  if (
    now - fetchingCookieDate.value < 10000 ||
    currentlyRefreshingVideoPlayer.value
  ) {
    return;
  }

  currentlyRefreshingVideoPlayer.value = true;
  signedCookies.value = (await getSignedCookies())?.cookies;

  fetchingCookieDate.value = now;

  currentlyRefreshingVideoPlayer.value = false;
};

onMounted(async () => {
  if (props.asset) {
    assetVideo.value = props.asset;
  }

  if (
    options.value?.sources?.length &&
    options.value?.sources[0]?.src &&
    videoPlayer.value
  ) {
    signedCookies.value = (await getSignedCookies())?.cookies;

    const player = videojs(videoPlayer.value, options.value);

    player.on("error", () => handlePlayerError(player));

    player.on("playing", () => handlePlayerPlaying());

    player.on("ready", () => handlePlayerReady(player));
  }
});

const handlePlayerError = async (player: Player) => {
  // signed url might have expired, so try to get new signed cookie, do this once as we do not want to dos ourselves
  if (player.error()?.code === 4 && !playerStateError.value) {
    playerStateError.value = true;

    await refreshVideoPlayer();
    player.src(sources.value);
  }

  // if we still have an error, keep track so we can eventually display to the user an error
  if (playerStateError.value) {
    displayVideoError.value++;
  }
};

const handlePlayerPlaying = () => {
  playerStateError.value = false;
  displayVideoError.value = 0;
};

const handlePlayerReady = async (player: Player) => {
  const playerResponseHook = async (
    _request: Request,
    _error: Error,
    response: Response
  ) => {
    if (response?.status === 403) {
      player.pause();
      await refreshVideoPlayer();
      player.play();
    }
  };

  (player.tech("silence-warning") as ExtendedTech)?.vhs?.xhr?.onResponse(
    playerResponseHook
  );

  const posterImage = setPosterImage({
    videoHeight: player.currentHeight(),
    videoWidth: player.currentWidth(),
    autoplay: player.autoplay()?.toString() === "true",
    thumbnail_url_tpl: assetVideo.value?.file?.thumbnail_url_tpl,
  });
  if (posterImage) {
    player.poster(posterImage);
  }
};
</script>
