import type {
  AdminAssociatedContact,
  AdminProfileAssociatedContact,
  AdminTeam,
  AssociatedContactProfileSummary,
} from "@/api/model";
import { useOrganizationMembers } from "@/composables/useOrganizationMembers";
import { getOrganizationAssociatedContacts } from "@/modules/users/api";
import type { AthleteOption, Recipient } from "@/types";
import { ref, type Ref } from "vue";
import { useOrganizationTeams } from "@/composables/useOrganizationTeams";

const CONTACT_LIMIT = 10_000;

interface UseRecipients {
  players: Ref<Recipient[]>;
  teams: Ref<AdminTeam[]>;
  selectedRecipients: Ref<Recipient[]>;
  selectedTeams: Ref<AdminTeam[]>;
  fetchRecipients: () => Promise<void>;
}

export const useRecipients = (): UseRecipients => {
  const { athleteListOptions, fetchOrgMembers } = useOrganizationMembers();
  const { orgTeams: teams, fetchAndInitializeData: fetchTeams } =
    useOrganizationTeams();
  const players: Ref<Recipient[]> = ref([]);
  const associatedContacts: Ref<AdminAssociatedContact[]> = ref([]);
  const selectedRecipients: Ref<Recipient[]> = ref([]);
  const selectedTeams: Ref<AdminTeam[]> = ref([]);

  const fetchData = async () => {
    try {
      const [associatedContactsResponse] = await Promise.all([
        getOrganizationAssociatedContacts({ limit: CONTACT_LIMIT }),
        fetchOrgMembers(),
        fetchTeams(),
      ]);

      if ("error" in associatedContactsResponse) {
        throw new Error(associatedContactsResponse.error);
      }
      associatedContacts.value = associatedContactsResponse.results || [];
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const buildAthleteContactOptions = (): Recipient[] => {
    if (!athleteListOptions.value) return [];
    return athleteListOptions.value.map((athlete: AthleteOption) => {
      const athleteAssociatedContacts = findAssociatedContacts(athlete);
      const matchingProfile = findMatchingProfile(
        athlete,
        athleteAssociatedContacts
      );
      const defaultContact = findDefaultContact(
        matchingProfile,
        athleteAssociatedContacts
      );
      return {
        id: athlete.id,
        name: athlete.name,
        teamIds: athlete.teams.map((team: AdminTeam) => team.id),
        associatedContacts: athleteAssociatedContacts,
        defaultContact,
        organization: athlete.organization,
      };
    });
  };

  const findAssociatedContacts = (athlete: AthleteOption) => {
    return associatedContacts.value.filter((contact) =>
      contact.profiles?.some((profile) => profile.id === athlete.id)
    );
  };

  const findMatchingProfile = (
    athlete: AthleteOption,
    contacts: AdminProfileAssociatedContact[]
  ) => {
    return contacts[0]?.profiles?.find((profile) => profile.id === athlete.id);
  };

  const findDefaultContact = (
    matchingProfile: AssociatedContactProfileSummary | undefined,
    contacts: AdminProfileAssociatedContact[]
  ) => {
    if (matchingProfile && matchingProfile?.primary_contact_id) {
      return contacts.find(
        (contact: AdminAssociatedContact) =>
          contact.id === matchingProfile.primary_contact_id
      );
    }
    return contacts[0];
  };

  const fetchRecipients = async () => {
    await fetchData();
    players.value = buildAthleteContactOptions();
  };

  return {
    players,
    fetchRecipients,
    selectedRecipients,
    selectedTeams,
    teams,
  };
};
