<template>
  <Dialog
    v-model:visible="showContactDialog"
    modal
    :header="recipient?.name"
    class="w-[48rem]"
    @show="formValidations.$reset()"
    @after-hide="setSelectedContact"
  >
    <div class="space-y-4">
      <div v-if="!showCreateNewContact">
        <p class="font-semibold mb-2">Invoice Recipient:</p>
        <div
          v-for="contact in recipient?.associatedContacts"
          :key="contact.id"
          class="flex items-center mb-2"
        >
          <RadioButton
            v-model="selectedContact"
            :inputId="contact.id.toString()"
            name="contact"
            :value="contact"
          />
          <label :for="contact.id.toString()" class="ml-2">{{
            contact.name
          }}</label>
        </div>

        <div v-if="showCreateButton" class="pt-4">
          <Button
            icon="pi pi-plus"
            text
            label="Create new contact"
            @click="toggleShowCreateNewContact"
          />
        </div>
      </div>

      <div v-if="showCreateNewContact">
        <CreateAssociatedContact
          :form-validations="formValidations"
          v-model:associated-contact-form="associatedContactForm"
          :create-contact="handleContactSave"
          :on-back="handleCreateContactBack"
        />
      </div>
      <div v-if="!showCreateNewContact">
        <div class="flex justify-end pr-5">
          <Button
            label="Confirm"
            @click="handleConfirm"
            class="p-button-primary"
          />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch, type Ref } from "vue";

import type { AdminAssociatedContact } from "@/api/model";
import type { ManageAssociatedContactsDialogProps, Recipient } from "@/types";

import Dialog from "primevue/dialog";
import RadioButton from "primevue/radiobutton";
import Button from "primevue/button";

import { useCreateAssociatedContact } from "../composables/useCreateAssociatedContact";
import CreateAssociatedContact from "./CreateAssociatedContact.vue";

const recipient = defineModel<Recipient>("recipient");
const showContactDialog = defineModel<boolean>("visible", {
  required: true,
});

defineProps<ManageAssociatedContactsDialogProps>();

const { associatedContactForm, formValidations, clearForm, createContact } =
  useCreateAssociatedContact();

const createButtonHit: Ref<boolean> = ref(false);
const selectedContact: Ref<AdminAssociatedContact | undefined> = ref();

const showCreateNewContact = computed(() => {
  return createButtonHit.value || !hasAssociatedContacts.value;
});

const handleCreateContactBack = () => {
  clearForm();
  createButtonHit.value = false;

  if (!hasAssociatedContacts.value) {
    showContactDialog.value = false;
  }
};

const hasAssociatedContacts = computed(() => {
  return !!recipient.value?.associatedContacts?.length;
});

const handleConfirm = () => {
  if (recipient.value && selectedContact.value) {
    recipient.value.selectedContact = selectedContact.value;
  }

  showContactDialog.value = false;
};

const showCreateButton = computed(() => {
  return hasAssociatedContacts.value && !createButtonHit.value;
});

const toggleShowCreateNewContact = () => {
  createButtonHit.value = !createButtonHit.value;
};

const setSelectedContact = () => {
  if (recipient.value) {
    selectedContact.value =
      recipient.value.selectedContact || recipient.value.defaultContact;
  }
};

const handleContactSave = async () => {
  if (recipient.value) {
    const result = await createContact(recipient.value);

    if (result) {
      const newContact = {
        id: result.id,
        name: result.name,
        email: result.email,
        primaryContact: result.primary_contact || false,
        profile: recipient.value.id,
        organization: result.organization,
        profiles: [],
      };

      recipient.value.associatedContacts.push(newContact);
      selectedContact.value = newContact;
      recipient.value.selectedContact = newContact;
      createButtonHit.value = false;

      clearForm();
    }
  }
};

watch(recipient, () => {
  if (recipient.value) {
    setSelectedContact();
  }
});
</script>
