/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AppConfig
} from '.././model'
import adminV1AppConfigRetrieveMutator from '../../shared/services/axios-client';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  /**
 * App config API for IOS app
 * @summary App config API for IOS app
 */
export const adminV1AppConfigRetrieve = (
    
 options?: SecondParameter<typeof adminV1AppConfigRetrieveMutator>,) => {
      return adminV1AppConfigRetrieveMutator<AppConfig>(
      {url: `/api/admin/v1/app-config/`, method: 'GET'
    },
      options);
    }
  export type AdminV1AppConfigRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1AppConfigRetrieve>>>
