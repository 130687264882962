export class ErrorMessages {
  static apiError: string =
    "Request to API failed. Check if url is valid and try again.";
  static unexpected: string = "Unexpected error.";
  static badRequest: string = "Bad request.";
  static badCredentials: string = "User with given credentials not found.";
  static unauthorized: string = "Unauthorized. Please login.";
  static emailNotFound: string = "Email not found.";
  static jsonParseFailed: string =
    "Failed to parse/convert json to data model.";
}

export const catchErrorMessage = (e: unknown) => {
  if (e instanceof Error) {
    return e.message;
  } else if (typeof e === "string") {
    return e;
  }
  return "unknown error.";
};

export class UnexpectedError extends Error {
  constructor(message?: string) {
    super(message ? message : ErrorMessages.unexpected);
    Object.setPrototypeOf(this, UnexpectedError.prototype);
  }
}

export class BadRequestError extends Error {
  constructor(message?: string) {
    super(message ? message : ErrorMessages.badRequest);
    Object.setPrototypeOf(this, BadRequestError.prototype);
  }
}

export class BadAuthCredentialsError extends Error {
  constructor(message?: string) {
    super(message ? message : ErrorMessages.badCredentials);
    Object.setPrototypeOf(this, BadAuthCredentialsError.prototype);
  }
}

export class UnauthorizedError extends Error {
  constructor(message?: string) {
    super(message ? message : ErrorMessages.unauthorized);
    Object.setPrototypeOf(this, BadAuthCredentialsError.prototype);
  }
}

export class JsonParsingError extends Error {
  constructor(message?: string) {
    super(message ? message : ErrorMessages.jsonParseFailed);
    Object.setPrototypeOf(this, JsonParsingError.prototype);
  }
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}
