import type {
  FormValidation,
  StatCategoryFormFields,
  StatCategoryIconDropdownOption,
} from "@/types";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  maxLength,
  required,
  requiredIf,
} from "@vuelidate/validators";
import { useToast } from "primevue/usetoast";
import { computed, ref, type Ref } from "vue";
import {
  type AdminStatCategory,
  type HeadlineValueEnum,
  type IconEnum,
  ReminderDayEnum,
  ReminderFrequencyEnum,
} from "@/api/model";
import { useCreateStatCategory } from "./useCreateStatCategory";
import { useCurrentUser } from "@/composables/useCurrentUser";

import {
  CREATE_SUCCESS_MESSAGE,
  ICON_DROPDOWN_OPTIONS,
  UPDATE_SUCCESS_MESSAGE,
} from "../constants";
import { useUpdateStatCategory } from "./useUpdateStatCategory";
import { addMixpanelEvent, EVENT_NAMES } from "@/shared/utils/analytics";

interface useStatCategoryForm {
  fieldValidations: Ref<FormValidation>;
  statCategoryFormFields: StatCategoryFormFields;
  upsertStatCategory: () => Promise<boolean>;
  statCategoryId: Ref<number | null | undefined>;
  buildStatCategoryFormState: (statCategory: AdminStatCategory) => void;
  clearFormState: () => void;
}

export const useStatCategoryForm = (): useStatCategoryForm => {
  const { createStatCategory, statCategoryCreateError } =
    useCreateStatCategory();

  const toast = useToast();

  const statCategoryId: Ref<number | null> = ref(null);
  const title: Ref<string | null> = ref(null);
  const unitLabel: Ref<string | null> = ref(null);
  const icon: Ref<StatCategoryIconDropdownOption | null | undefined> =
    ref(null);
  const headlineValue: Ref<HeadlineValueEnum | null | undefined> = ref(null);
  const reminderFrequency: Ref<ReminderFrequencyEnum | null> = ref(null);
  const reminderDay: Ref<ReminderDayEnum | null> = ref(null);
  const lastReminderSent: Ref<string | null> = ref(null);

  const { defaultOrganizationId: organizationIdRef } = useCurrentUser();

  const statCategoryFormFields: StatCategoryFormFields = {
    unitLabel,
    title,
    icon,
    headlineValue,
    reminderFrequency,
    reminderDay,
    lastReminderSent,
  };

  const isReminderDayRequired = computed(
    () =>
      reminderFrequency.value === ReminderFrequencyEnum.WEEKLY ||
      reminderFrequency.value === ReminderFrequencyEnum.BIWEEKLY
  );

  const validReminderDay = (value: ReminderDayEnum | null) => {
    if (isReminderDayRequired.value) {
      return value !== null && value !== ReminderDayEnum.NA;
    }
    return true;
  };

  const rules = computed(() => ({
    title: {
      required,
      maxLength: helpers.withMessage(
        "Title must be no more than 25 characters long",
        maxLength(25)
      ),
    },
    unitLabel: { required },
    icon: { required },
    headlineValue: { required },
    reminderFrequency: { required },
    reminderDay: {
      required: helpers.withMessage(
        "Reminder day is required for weekly or bi-weekly reminders",
        requiredIf(isReminderDayRequired)
      ),
      validDay: helpers.withMessage(
        "Please select a valid reminder day",
        validReminderDay
      ),
    },
  }));

  const fieldValidations = useVuelidate(rules, statCategoryFormFields);

  const upsertStatCategory = async () => {
    const isValid = await fieldValidations.value.$validate();

    if (!isValid) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Please fill out all required fields",
        life: 3000,
      });
      return false;
    }
    if (
      title.value &&
      unitLabel.value &&
      icon.value &&
      headlineValue.value &&
      reminderFrequency.value
    ) {
      statCategoryCreateError.value = null;
      const apiPayload: NonReadonly<AdminStatCategory> = {
        title: title.value,
        unit_label: unitLabel.value,
        headline_value: headlineValue.value,
        organization_id: organizationIdRef.value as number,
        icon: icon.value.value as IconEnum,
        reminder_frequency: reminderFrequency.value,
        reminder_day: reminderDay.value || ReminderDayEnum.NA,
      };

      let errorMessage: string | null = null;
      let mixPanelEventName: string = statCategoryId.value
        ? EVENT_NAMES.STATS.StatCategoryUpdated
        : EVENT_NAMES.STATS.StatCategoryCreated;

      if (!statCategoryId.value) {
        await createStatCategory(apiPayload);

        errorMessage = statCategoryCreateError.value;
      } else if (statCategoryId.value) {
        const { updateStatCategory, statCategoryUpdateError } =
          useUpdateStatCategory(statCategoryId.value);

        await updateStatCategory(apiPayload);

        errorMessage = statCategoryUpdateError.value;
      }

      if (errorMessage) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: errorMessage,
          life: 3000,
        });

        return false;
      } else {
        const successMessage = statCategoryId.value
          ? UPDATE_SUCCESS_MESSAGE
          : CREATE_SUCCESS_MESSAGE;

        toast.add({
          severity: "success",
          summary: "Success",
          detail: successMessage,
          life: 3000,
        });

        fieldValidations.value.$reset();

        addMixpanelEvent(mixPanelEventName);

        return true;
      }
    } else {
      return false;
    }
  };

  const buildStatCategoryFormState = (statCategory: AdminStatCategory) => {
    const iconOption = ICON_DROPDOWN_OPTIONS.find(
      (option) => option.value === statCategory.icon
    );

    title.value = statCategory.title;
    unitLabel.value = statCategory.unit_label;
    icon.value = iconOption;
    headlineValue.value = statCategory.headline_value;
    reminderFrequency.value = statCategory.reminder_frequency;
    reminderDay.value = statCategory.reminder_day ?? null;
    statCategoryId.value = statCategory.id;
    lastReminderSent.value = statCategory.last_reminder_sent;
  };

  const clearFormState = () => {
    title.value = null;
    unitLabel.value = null;
    icon.value = null;
    headlineValue.value = null;
    reminderFrequency.value = null;
    reminderDay.value = null;
    statCategoryId.value = null;
    lastReminderSent.value = null;
  };

  return {
    fieldValidations,
    statCategoryFormFields,
    upsertStatCategory,
    buildStatCategoryFormState,
    statCategoryId,
    clearFormState,
  };
};
