/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminBlockExercise,
  AdminBlockExerciseCreate,
  PatchedAdminBlockExercise
} from '.././model'
import adminV1BlocksExercisesCreateMutator from '../../shared/services/axios-client';
import adminV1BlocksExercisesUpdateMutator from '../../shared/services/axios-client';
import adminV1BlocksExercisesPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1BlocksExercisesDestroyMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1BlocksExercisesCreate = (
    adminBlockExerciseCreate: NonReadonly<AdminBlockExerciseCreate>,
 options?: SecondParameter<typeof adminV1BlocksExercisesCreateMutator>,) => {
      return adminV1BlocksExercisesCreateMutator<AdminBlockExerciseCreate>(
      {url: `/api/admin/v1/blocks_exercises/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminBlockExerciseCreate
    },
      options);
    }
  export const adminV1BlocksExercisesUpdate = (
    id: number,
    adminBlockExercise: NonReadonly<AdminBlockExercise>,
 options?: SecondParameter<typeof adminV1BlocksExercisesUpdateMutator>,) => {
      return adminV1BlocksExercisesUpdateMutator<AdminBlockExercise>(
      {url: `/api/admin/v1/blocks_exercises/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminBlockExercise
    },
      options);
    }
  export const adminV1BlocksExercisesPartialUpdate = (
    id: number,
    patchedAdminBlockExercise: NonReadonly<PatchedAdminBlockExercise>,
 options?: SecondParameter<typeof adminV1BlocksExercisesPartialUpdateMutator>,) => {
      return adminV1BlocksExercisesPartialUpdateMutator<AdminBlockExercise>(
      {url: `/api/admin/v1/blocks_exercises/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminBlockExercise
    },
      options);
    }
  export const adminV1BlocksExercisesDestroy = (
    id: number,
 options?: SecondParameter<typeof adminV1BlocksExercisesDestroyMutator>,) => {
      return adminV1BlocksExercisesDestroyMutator<void>(
      {url: `/api/admin/v1/blocks_exercises/${id}/`, method: 'DELETE'
    },
      options);
    }
  export type AdminV1BlocksExercisesCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1BlocksExercisesCreate>>>
export type AdminV1BlocksExercisesUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1BlocksExercisesUpdate>>>
export type AdminV1BlocksExercisesPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1BlocksExercisesPartialUpdate>>>
export type AdminV1BlocksExercisesDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1BlocksExercisesDestroy>>>
