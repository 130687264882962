import { adminV1AppConfigRetrieve } from "@/api/admin-app-configuration/admin-app-configuration";

export const getAppConfig = async () => {
  try {
    const result = await adminV1AppConfigRetrieve();
    return result;
  } catch (e) {
    return { error: e.message };
  }
};
