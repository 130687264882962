/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `NONE` - None
* `DAILY` - Every Day
* `WEEKLY` - Every Week
* `BIWEEKLY` - Every 2 Weeks
 */
export type ReminderFrequencyEnum = typeof ReminderFrequencyEnum[keyof typeof ReminderFrequencyEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReminderFrequencyEnum = {
  NONE: 'NONE',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  BIWEEKLY: 'BIWEEKLY',
} as const;
