<template>
  <div v-if="props.loading" class="">
    <div v-for="n in 10" :key="n" class="">
      <Skeleton width="100%" :height="props.list ? '100px' : '300px'" />
    </div>
  </div>
  <Message v-if="!props.loading && props.message" :closable="false">
    {{ props.message }}
  </Message>
</template>

<script setup lang="ts">
import Skeleton from "primevue/skeleton";
import Message from "primevue/message";

interface Props {
  loading: boolean;
  message?: string;
  list?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  loading: false,
  message: undefined,
  list: false,
});
</script>
