import type { ListOption } from "@/types";

export const PASSWORD_AND_MAGIC_LINK_LABEL = "Password & Magic Link";
export const PASSWORD_AND_MAGIC_LINK_VALUE = "P-ML";
export const MAGIC_LINK_LABEL = "Magic Link only";
export const MAGIC_LINK_VALUE = "ML";

export const PREFERRED_AUTH_OPTIONS: ListOption[] = [
  {
    id: PASSWORD_AND_MAGIC_LINK_VALUE,
    name: PASSWORD_AND_MAGIC_LINK_LABEL,
  },
  {
    id: MAGIC_LINK_VALUE,
    name: MAGIC_LINK_LABEL,
  },
];

export const PREFERRED_AUTH_DISCLAIMER =
  "Note, choose Magic Link only for a more convenient and secure option";
