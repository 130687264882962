<template>
  <BaseLayout
    :title="`Drill: ${data?.name ?? ''}`"
    :breadcrumb="[
      {
        label: 'Training',
        to: { name: ROUTE_NAME.TRAINING_COLLECTION_LIST },
      },
      {
        label: `${trainingCollection?.name ?? ''}`,
        to: {
          name: ROUTE_NAME.TRAINING_COLLECTION_UPDATE,
          params: { id: trainingCollection?.id },
        },
      },
      {
        label: 'Drills',
        to: {
          name: ROUTE_NAME.TRAINING_COLLECTION_UPDATE,
          params: { id: trainingCollection?.id },
        },
      },
      {
        label: data?.name || 'Create',
      },
    ]"
  >
    <InitialPageLoader
      :loading="isLoadingInitial"
      list
      :message="!data ? 'Drill not found.' : undefined"
    />
    <DrillForm
      v-if="!isLoadingInitial && data"
      :key="data?.id"
      :training-collection-id="parseInt(route?.params?.id.toString())"
      :initial-data="data"
      :on-drill-update="
        (drill) => {
          data = drill;
        }
      "
    />
  </BaseLayout>
</template>

<script setup lang="ts">
import InitialPageLoader from "@/shared/components/InitialPageLoader.vue";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import { getDrill, getSingle } from "@/modules/trainingCollections/api";
import DrillForm from "@/modules/trainingCollections/components/DrillForm.vue";
import { useToast } from "primevue/usetoast";
import { type AdminTrainingCollection, type AdminDrill } from "@/api/model";
import { ROUTE_NAME } from "../../../shared/constants/routes";

const route = useRoute();
const toast = useToast();

const isLoadingInitial = ref<boolean>(false);
const data = ref<AdminDrill | undefined>(undefined);
const trainingCollection = ref<AdminTrainingCollection>();

onMounted(async () => {
  isLoadingInitial.value = true;

  const [results, trainingCollectionData] = await Promise.all([
    getDrill(parseInt(route.params.drill_id.toString())),
    getSingle(parseInt(route.params.id.toString())),
  ]);

  if ("error" in results) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: results.error,
      life: 3000,
    });
  } else {
    data.value = results;
  }

  isLoadingInitial.value = false;

  if ("error" in trainingCollectionData) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: trainingCollectionData.error,
      life: 3000,
    });
  } else {
    trainingCollection.value = trainingCollectionData;
  }
});
</script>
