<template>
  <img src="@/assets/logo-f-512.png" :width="props.size" alt="Logo" />
</template>

<script setup lang="ts">
interface Props {
  size?: number;
}
const props = withDefaults(defineProps<Props>(), {
  size: 100,
});
</script>
