import type { LineItem } from "@/types";

export const ACTIVE_INVOICE_DISPLAY = "Active";
export const PAID_INVOICE_DISPLAY = "Paid";
export const PAST_DUE_INVOICE_DISPLAY = "Past Due";
export const DRAFT_INVOICE_DISPLAY = "Draft";
export const PARTIALLY_PAID_INVOICE_DISPLAY = "Partially Paid";
export const CANCELLED_INVOICE_DISPLAY = "Cancelled";
export const UNKNOWN_INVOICE_DISPLAY = "Unknown";

export const INVOICE_STATUSES_DISPLAY = [
  ACTIVE_INVOICE_DISPLAY,
  PAID_INVOICE_DISPLAY,
  PAST_DUE_INVOICE_DISPLAY,
  DRAFT_INVOICE_DISPLAY,
  PARTIALLY_PAID_INVOICE_DISPLAY,
  CANCELLED_INVOICE_DISPLAY,
  UNKNOWN_INVOICE_DISPLAY,
];

export const ACTIVE_INVOICE = "active";
export const PAID_INVOICE = "paid";
export const PAST_DUE_INVOICE = "past due";
export const DRAFT_INVOICE = "draft";
export const PARTIALLY_PAID_INVOICE = "partially paid";
export const CANCELLED_INVOICE = "cancelled";
export const UNKNOWN_INVOICE = "unknown";

export const INVOICE_STATUSES = [
  ACTIVE_INVOICE,
  PAID_INVOICE,
  PAST_DUE_INVOICE,
  DRAFT_INVOICE,
  PARTIALLY_PAID_INVOICE,
  CANCELLED_INVOICE,
  UNKNOWN_INVOICE,
];

export const BLANK_INVOICE_LINE_ITEM: LineItem = {
  itemName: "",
  itemPrice: 0,
  itemQuantity: 1,
  itemCategory: "",
};

export const CREATE_INVOICE_FORM_TITLE = "Create Invoice";
export const EDIT_INVOICE_FORM_TITLE = "Edit Invoice";
