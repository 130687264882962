<template>
  <form class="relative">
    <FixedActionBar
      v-if="validTeamId"
      button-type="submit"
      :is-loading="isSubmitting"
      :show-create="isCreateForm"
      :show-update="!isCreateForm"
      :show-delete="!isCreateForm"
      class="mb-6"
      @delete="confirmDeleteTeam(teamId)"
      @create="
        async () => ((await v$.$validate()) ? createTeamThenRedisplay() : null)
      "
      @update="
        async () =>
          (await v$.$validate()) ? updateTeamThenRedisplay(teamId) : null
      "
    />

    <AppCard
      v-if="validTeamId"
      class="mb-6 animate-fadein animate-duration-1000"
    >
      <div v-if="!isCreateForm" class="flex justify-center flex-wrap mb-4">
        Player Code: {{ accessCode }}
      </div>

      <div class="col flex flex-col gap-2">
        <label for="name">Name</label>
        <InputText
          id="name"
          v-model="form.name"
          type="text"
          placeholder="Name"
          @blur="v$.name.$touch"
        />
        <small v-if="v$ErrorMessage(v$.name.$errors)" class="text-red-500">
          {{ v$ErrorMessage(v$.name.$errors) }}
        </small>
      </div>

      <div class="col flex flex-col gap-2">
        <label v-if="!isSingleOrgUser" for="organization_id"
          >Organization</label
        >
        <Select
          v-if="!isSingleOrgUser"
          v-model="form.organization_id"
          :options="organizations"
          option-label="name"
          option-value="id"
          placeholder="Select an organization"
          class="w-full md:w-72"
          :disabled="!isCreateForm"
        />
        <small
          v-if="v$ErrorMessage(v$.organization_id.$errors)"
          class="text-red-500"
        >
          {{ v$ErrorMessage(v$.organization_id.$errors) }}
        </small>
      </div>
    </AppCard>
  </form>

  <AppCard v-if="validTeamId && !isCreateForm">
    <Tabs value="0">
      <TabList>
        <Tab value="0">Coaches</Tab>
        <Tab value="1">Athletes</Tab>
      </TabList>
      <TabPanels>
        <TabPanel value="0">
          <DataTable
            :key="orgCoaches.length"
            :value="orgCoaches"
            striped-rows
            row-hover
            selection-mode="single"
            @row-click="
              async (e) =>
                await router.push({
                  name: ROUTE_NAME.USER_UPDATE,
                  params: { id: e?.data?.profile?.id },
                })
            "
          >
            <Column field="profile.name" header="Name" />

            <Column field="profile.email" header="Email" />
            <Column header="Is Available">
              <template #body="{ data }">
                <div v-if="data.is_available">Yes</div>
                <div v-else>No</div>
              </template>
            </Column>
            <Column header="Remove">
              <template #body="{ data }">
                <Button
                  icon="pi pi-times"
                  severity="danger"
                  text
                  rounded
                  aria-label="Cancel"
                  class="flex items-center justify-center"
                  @click="confirmDeleteUserFromTeam(data.profile.id, teamId)"
                />
              </template>
            </Column>
          </DataTable>

          <Paginator
            :always-show="true"
            :rows="resultsPerPage"
            :total-records="totalCoachRecords"
            :first="firstCoach"
            template="
        FirstPageLink
        PrevPageLink
        CurrentPageReport
        NextPageLink
        LastPageLink
        RowsPerPageDropdown"
            current-page-report-template="Showing {first} to {last} of {totalRecords}"
            @page="onPageChangeCoach"
          />
        </TabPanel>

        <TabPanel value="1">
          <DataTable
            :key="orgMembers.length"
            :value="orgMembers"
            striped-rows
            row-hover
            selection-mode="single"
            @row-click="
              async (e) =>
                await router.push({
                  name: ROUTE_NAME.USER_UPDATE,
                  params: { id: e?.data?.id },
                })
            "
          >
            <Column field="name" header="Name">
              <template #body="{ data }">
                <div
                  @click="
                    async () =>
                      await router.push({
                        name: ROUTE_NAME.USER_UPDATE,
                        params: { id: data.id },
                      })
                  "
                >
                  {{ data.name }}
                </div>
              </template>
            </Column>
            <Column field="email" header="Email" />
            <Column header="Status">
              <template #body="{ data }">
                <template v-if="data.is_active"> Active </template>
                <template v-if="!data.is_active"> InActive </template>
              </template>
            </Column>
            <Column header="Remove">
              <template #body="{ data }">
                <Button
                  icon="pi pi-times"
                  severity="danger"
                  text
                  rounded
                  aria-label="Cancel"
                  class="flex items-center justify-center"
                  @click="confirmDeleteMemberFromTeam(data.id, teamId)"
                />
              </template>
            </Column>
          </DataTable>

          <Paginator
            :always-show="true"
            :rows="resultsPerPage"
            :total-records="totalMemberRecords"
            :first="firstMember"
            template="
      FirstPageLink
      PrevPageLink
      CurrentPageReport
      NextPageLink
      LastPageLink
      RowsPerPageDropdown"
            current-page-report-template="Showing {first} to {last} of {totalRecords}"
            @page="onPageChangeMember"
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  </AppCard>

  <AppCard v-if="!validTeamId && !isCreateForm" class="mb-6">
    <div>Team not found.</div>
  </AppCard>

  <Dialog
    v-model:visible="createTeamDialog"
    modal
    header="Create Team"
    :style="{ width: '50vw' }"
    @show="teamv$.$reset()"
  >
    <div class="col flex flex-col gap-2">
      <label for="teamName">Name</label>
      <InputText
        id="teamName"
        v-model="teamForm.teamName"
        type="text"
        placeholder="Team name"
        @blur="teamv$.teamName.$touch"
      />
      <small
        v-if="v$ErrorMessage(teamv$.teamName.$errors)"
        class="text-red-500"
      >
        {{ v$ErrorMessage(teamv$.teamName.$errors) }}
      </small>
      <div class="flex justify-end flex-wrap">
        <Button
          label="Create"
          class="mb-6 flex items-center justify-center"
          @click="
            async () =>
              (await teamv$.$validate()) ? createTeamThenRedisplay() : null
          "
        />
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Tabs from "primevue/tabs";
import Tab from "primevue/tab";
import TabList from "primevue/tablist";
import TabPanels from "primevue/tabpanels";
import TabPanel from "primevue/tabpanel";
import TabsChangeEvent from "primevue/tabs";
import Button from "primevue/button";
import Select from "primevue/select";
import FixedActionBar from "@/shared/components/FixedActionBar.vue";
import Paginator from "primevue/paginator";
import { useToast } from "primevue/usetoast";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import { computed, onMounted, reactive, ref } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import AppCard from "@/shared/components/AppCard.vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import {
  getTeam,
  createTeam,
  updateTeam,
  deleteTeam,
} from "@/modules/teams/api";
import { deleteUserFromTeam, deleteMemberFromTeam } from "@/modules/teams/api";
import { getAllMembers } from "@/modules/users/api";
import Dialog from "primevue/dialog";
import { useConfirm } from "primevue/useconfirm";

import { storeToRefs } from "pinia";

const emit = defineEmits(["team"]);

import { useAuthStore } from "@/modules/auth/stores/auth";
const authStore = useAuthStore();
const { defaultOrg } = authStore;
const { isSingleOrgUser } = storeToRefs(authStore);

import { useOrganizationStore } from "@/modules/organizations/stores/organizationsStore";
import { getCoaches } from "@/modules/teams/api";
import type { AdminCoach, AdminProfile, AdminTeam } from "@/api/model";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";
const { getAllOrganizations } = useOrganizationStore();
const { organizations } = storeToRefs(useOrganizationStore());

const route = useRoute();

const firstMember = computed(
  () => paginationNum.value * resultsPerPage - resultsPerPage
);

const firstCoach = computed(
  () => paginationNum.value * resultsPerPage - resultsPerPage
);

interface showToastType {
  severity?: "success" | "info" | "warn" | "error" | undefined;
  summary?: string;
  msg: string;
  life?: number;
}

const toast = useToast();
const showToast = (data: showToastType) => {
  toast.add({
    severity: data?.severity ?? "error",
    summary: data?.summary ?? "Error",
    detail: data?.msg,
    life: data?.life ?? 10000,
  });
};

const confirm = useConfirm();

let createTeamDialog = ref(false);

const confirmDeleteTeam = (teamId: number) => {
  confirm.require({
    message: `Are you sure you want to remove this team?`,
    header: `Remove Team`,
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      addMixpanelEvent(EVENT_NAMES.TEAMS.TEAM_DELETED);
      const result = await deleteTeam(teamId);
      if (result?.error) {
        showToast({ msg: result.error });
      } else {
        router.push({
          name: ROUTE_NAME.ORGANIZATION_UPDATE,
          params: { id: form.organization_id },
        });
      }
    },
  });
};

const confirmDeleteUserFromTeam = (userId: number, teamId: number) => {
  confirm.require({
    message: `Are you sure you want to remove this coach?`,
    header: `Remove Coach`,
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        const result = await deleteUserFromTeam(userId, teamId);
        if (result?.error) {
          showToast({ msg: result.error });
        } else {
          showToast({
            severity: "success",
            msg: "Coach removed from team.",
            summary: "Success",
          });
          await setTeam();
        }
      } catch (e) {}
    },
  });
};

const confirmDeleteMemberFromTeam = (userId: number, teamId: number) => {
  confirm.require({
    message: "Are you sure you want to remove this team for this member?",
    header: "Remove Team For Member",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        const result = await deleteMemberFromTeam(userId, teamId);
        if (result?.error) {
          showToast({ msg: result.error });
        } else {
          await getTeamMembersThenRedisplay();
          showToast({
            severity: "success",
            msg: "Member removed from team.",
            summary: "Success",
          });
        }
      } catch (e) {}
    },
  });
};

const tabs = [
  { title: "Coaches", value: "0" },
  { title: "Athletes", value: "1" },
];

const tabChange = async (e: typeof TabsChangeEvent) => {
  const tabIndex = e?.index;
  if (tabs[tabIndex].title === "Athletes") {
    await getTeamMembersThenRedisplay();
  }
};

const onPageChangeMember = async (e: any) => {
  paginationNum.value = e.page + 1;

  router.push({
    query: { page: paginationNum.value },
  });

  await getTeamMembersThenRedisplay();
};

const onPageChangeCoach = async (e: any) => {
  paginationNum.value = e.page + 1;

  router.push({
    query: { page: paginationNum.value },
  });

  await getTeamCoachesThenRedisplay();
};

let resultsPerPage = 25;

let totalMemberRecords = ref(0);
let totalCoachRecords = ref(0);
let paginationNum = ref(parseInt((route?.query?.page || 1).toString()));

const isSubmitting = ref(false);

const teamId = parseInt(((route.params || {}).teamId || 0).toString());

const orgMembers = ref<AdminProfile[]>([]);
const orgCoaches = ref<AdminCoach[]>([]);
const isCreateForm = computed<boolean>(() => !teamId);

let accessCode = ref<string>("");

let validTeamId = ref(true);

interface formType {
  name: string;
  organization_id: number;
}

let form = reactive<formType>({
  name: "",
  organization_id: -1,
});
const rules = computed(() => ({
  name: { required },
  organization_id: { required },
}));
const v$ = useVuelidate(rules, form);

let teamForm = reactive({
  teamName: "",
});
const teamRules = computed(() => ({
  teamName: { required },
}));
const teamv$ = useVuelidate(teamRules, teamForm);

const createTeamThenRedisplay = async () => {
  addMixpanelEvent(EVENT_NAMES.TEAMS.TEAM_CREATED);
  const result = await createTeam(form);

  if ("error" in result) {
    showToast({ msg: result?.error });
  } else {
    router.push({
      name: ROUTE_NAME.TEAM_UPDATE,
      params: { teamId: result?.id, id: result.organization_id },
    });

    showToast({
      severity: "success",
      msg: "Created team.",
      summary: "Success",
    });
  }
};

const updateTeamThenRedisplay = async (teamId: number) => {
  const result = await updateTeam(teamId, form);
  if ("error" in result) {
    showToast({ msg: result.error });
  } else {
    await setTeam();

    showToast({
      severity: "success",
      msg: "Updated team.",
      summary: "Success",
    });
  }
};

const getTeamCoachesThenRedisplay = async () => {
  const result = await getCoaches(
    [teamId],
    resultsPerPage,
    (paginationNum.value - 1) * resultsPerPage
  );

  if ("error" in result) {
    showToast({ msg: result?.error });
  } else {
    orgCoaches.value = result?.results || [];
    totalCoachRecords.value = result?.count || 0;
  }
};

const getTeamMembersThenRedisplay = async () => {
  const result = await getAllMembers(
    resultsPerPage,
    (paginationNum.value - 1) * resultsPerPage,
    teamId
  );

  if ("error" in result) {
    showToast({ msg: result?.error });
  } else {
    orgMembers.value = result?.results || [];
    totalMemberRecords.value = result?.count || 0;
  }
};

const setTeam = async () => {
  if (teamId) {
    const teamData = (await getTeam(teamId)) || {};

    if ("error" in teamData) {
      validTeamId.value = false;
      showToast({ msg: teamData?.error });
      return;
    } else {
      if (!teamData?.name) {
        validTeamId.value = false;
      }
    }

    if (!teamData?.id) {
      validTeamId.value = false;
    } else {
      validTeamId.value = true;
    }

    form.name = teamData?.name || "";
    form.organization_id = teamData?.organization;

    accessCode.value = teamData?.code?.code;

    emit("team", { teamName: form });

    getTeamCoachesThenRedisplay();
    getTeamMembersThenRedisplay();
  } else {
    const organizationIdParam = isCreateForm.value
      ? parseInt((route?.params?.id || 0).toString())
      : null;
    if (organizationIdParam) {
      form.organization_id = organizationIdParam;
    }
    if (
      !form.organization_id &&
      isCreateForm &&
      isSingleOrgUser.value &&
      defaultOrg
    ) {
      form.organization_id = defaultOrg.id;
    }
  }
};

onMounted(async () => {
  await getAllOrganizations();
  await setTeam();
});
</script>
