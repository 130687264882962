import { defineStore } from "pinia";

// DO NOT CLEAR THIS FROM LOCAL STORAGE DURING LOGOUT
// THIS SHOULD PERSIST ACROSS DIFFERENT LOGINS

interface FeedPreferenceState {
  showDateControl: boolean;
}

export const useFeedPreferenceStore = defineStore("feedPreferenceStore", {
  persist: true,
  state: (): FeedPreferenceState => ({
    showDateControl: false,
  }),
  actions: {
    shouldShowDateControl(queryString: number | null) {
      if (queryString === 1) {
        this.showDateControl = true;
      } else if (queryString === 0) {
        this.showDateControl = false;
      }

      return this.showDateControl;
    },
  },
});
