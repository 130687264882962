import { ref } from "vue";
import { useAuth } from "@/composables/useAuth";
import { useOrganizations } from "@/composables/useOrganizations";
import { useOrganizationStoreV2 } from "@/modules/organizations/stores/organizationsStoreV2";

export interface UserOrganizations {
  fetchUserOrganizations: () => Promise<void>;
  organizations: ReturnType<typeof useOrganizations>["organizations"];
  defaultOrganization: ReturnType<
    typeof useOrganizations
  >["defaultOrganization"];
}

/* 
    This composable is used to fetch and return the user organization(s) and default organization.
    */

export function useUserOrganizations(): UserOrganizations {
  const { defaultOrganizationId, isSingleOrgUser } = useAuth();
  const { organizations, defaultOrganization } = useOrganizations();
  const organizationStore = useOrganizationStoreV2();

  const fetchUserOrganizations = async () => {
    if (!defaultOrganizationId.value) return;

    if (isSingleOrgUser.value) {
      await organizationStore.fetchOrganization(
        Number(defaultOrganizationId.value)
      );

      organizations.value = defaultOrganization.value
        ? [defaultOrganization.value]
        : [];
    } else {
      await organizationStore.fetchOrganizations();
    }
  };

  return {
    fetchUserOrganizations,
    organizations,
    defaultOrganization,
  };
}
