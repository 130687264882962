import { defineStore } from "pinia";
import {
  checkEmail,
  fetchCurrentUser,
  loginWithEmail,
  loginWithToken,
  sendMagicLinkEmail,
} from "../api";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { getNameInitials } from "@/shared/utils/helpers";
import { useAdminAvatar } from "@/modules/profile/stores/adminAvatar";
import {
  EVENT_NAMES,
  addMixpanelEvent,
  mixpanelLogin,
  mixpanelLogout,
} from "@/shared/utils/analytics";
import type { AdminOrganization, AdminTokenObtainPair } from "@/api/model";
import type { AdminEmailCheckResponse } from "@/api/model";
import type User from "@/shared/datamodels/user";
import type { ApiError } from "@/types";

export interface AuthState {
  user: User | null;
  token: AdminTokenObtainPair | null;
  singleOrgUser: boolean | null;
}

export interface LoginResult {
  success: boolean;
  user?: User;
  error?: string;
}

export interface AuthGetters {
  isLoggedIn: (state: AuthState) => boolean;
  isLoggedInButNotAdmin: (state: AuthState) => boolean;
  userInitials: (state: AuthState) => string;
  isStaff: (state: AuthState) => boolean;
  defaultOrg: (state: AuthState) => AdminOrganization | undefined;
  isSingleOrgUser: (state: AuthState) => boolean | null;
  loggedInUserId: (state: AuthState) => number | undefined;
  [key: string]: any;
}

export interface AuthActions {
  login(options: LoginOptions): Promise<LoginResult>;
  logout(): Promise<void>;
  processSuccessfulLogin(loginResult: AdminTokenObtainPair): Promise<void>;
  checkEmail(email: string): Promise<AdminEmailCheckResponse | ApiError>;
  sendMagicLinkEmail(email: string): Promise<void | ApiError>;
}

export type AuthStore = AuthState & AuthGetters & AuthActions;

interface LoginOptions {
  email?: string;
  password?: string;
  magicLinkToken?: string;
}

/*
 * This store is used to manage the user's authentication state.
 */

export const useAuthStore = defineStore<
  "authStore",
  AuthState,
  AuthGetters,
  AuthActions
>("authStore", {
  persist: true,
  state: (): AuthState => ({
    user: null,
    token: null,
    singleOrgUser: null,
  }),
  getters: {
    isLoggedIn: (state: AuthState): boolean =>
      Boolean(state.user?.is_admin && state.token),
    isLoggedInButNotAdmin: (state: AuthState): boolean =>
      Boolean(!state.user?.is_admin && state.token),
    userInitials: (state: AuthState): string =>
      getNameInitials(state.user?.name ?? ""),
    isStaff: (state: AuthState): boolean => Boolean(state.user?.is_staff),
    defaultOrg: (state: AuthState): AdminOrganization | undefined =>
      state.user?.default_organization,
    isSingleOrgUser: (state: AuthState): boolean | null => state.singleOrgUser,
    loggedInUserId: (state: AuthState): number | undefined => state.user?.id,
  },
  actions: {
    async login(options: LoginOptions): Promise<LoginResult> {
      const { magicLinkToken, email, password } = options;

      try {
        if (magicLinkToken) {
          const loginResult = await loginWithToken({ token: magicLinkToken });
          if ("error" in loginResult) {
            return handleLoginError(loginResult.error);
          }

          addMixpanelEvent(EVENT_NAMES.USER.AdminLoggedIn, {
            method: "Magic Link",
          });

          await this.processSuccessfulLogin(loginResult);
          return { success: true, user: this.user as User };
        }

        if (email && password) {
          const loginResult = await loginWithEmail(email, password);
          if (!loginResult) {
            return { success: false, error: "Login failed" };
          }
          if ("error" in loginResult) {
            return handleLoginError(loginResult.error);
          }

          addMixpanelEvent(EVENT_NAMES.USER.AdminLoggedIn, {
            method: "Magic Link",
          });
          await this.processSuccessfulLogin(loginResult);
          return { success: true, user: this.user as User };
        }

        return { success: false, error: "No valid login credentials provided" };
      } catch (error) {
        console.error("Error during login:", error);
        return {
          success: false,
          error: "An error occurred during login",
        };
      }
    },

    async logout(): Promise<void> {
      clearLocalStorage();
      this.$reset();
      mixpanelLogout();
      await router.push({ name: ROUTE_NAME.LOGIN });
    },

    async processSuccessfulLogin(
      loginResult: AdminTokenObtainPair
    ): Promise<void> {
      this.token = loginResult;
      this.user = await fetchCurrentUser();
      this.singleOrgUser = !this.user?.is_staff;

      await updateAdminProfileImage();
      if (this.user) {
        mixpanelLogin(this.user);
      }
    },
    async checkEmail(
      email: string
    ): Promise<AdminEmailCheckResponse | ApiError> {
      return await checkEmail(email);
    },

    async sendMagicLinkEmail(email: string): Promise<void | ApiError> {
      return await sendMagicLinkEmail(email);
    },
  },
});

function handleLoginError(error: string): LoginResult {
  if (error === "Password change required") {
    return { success: false, error: "Password change required" };
  }
  return { success: false, error: "Login error" };
}

async function updateAdminProfileImage() {
  const { updateLatestAdminProfileImage } = useAdminAvatar();
  await updateLatestAdminProfileImage();
}

function clearLocalStorage() {
  localStorage.removeItem("authStore");
  localStorage.removeItem("adminAvatar");
  localStorage.removeItem("signedCookies");
}

export type AuthStoreType = ReturnType<typeof useAuthStore>;
