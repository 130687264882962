/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

/**
 * * `chart.line.uptrend.xyaxis` - Chart Line
* `percent` - Percentage
* `arrow.triangle.2.circlepath` - Sync
* `clock` - Clock
* `arrow.up` - Arrow Up
* `figure.run` - Stopwatch
* `dumbbell` - Dumbbell
* `scalemass` - Weight
* `gauge.with.needle` - Speedometer
 */
export type IconEnum = typeof IconEnum[keyof typeof IconEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IconEnum = {
  chartlineuptrendxyaxis: 'chart.line.uptrend.xyaxis',
  percent: 'percent',
  arrowtriangle2circlepath: 'arrow.triangle.2.circlepath',
  clock: 'clock',
  arrowup: 'arrow.up',
  figurerun: 'figure.run',
  dumbbell: 'dumbbell',
  scalemass: 'scalemass',
  gaugewithneedle: 'gauge.with.needle',
} as const;
