<template>
  <Button label="Create Submission" @click="visible = true" />
  <Dialog
    v-model:visible="visible"
    modal
    header="Create Submission"
    :style="{ width: '48rem' }"
  >
    <div class="p-20">
      <Select
        v-model="selectedAthlete"
        :options="athleteListOptions"
        option-label="name"
        option-value="id"
        placeholder="Select an Athlete"
        class="w-full mb-2"
      />

      <small
        v-if="v$ErrorMessage(v$.selectedAthlete.$errors)"
        class="error-message"
      >
        {{ v$ErrorMessage(v$.selectedAthlete.$errors) }}
      </small>

      <Select
        v-model="selectedCoach"
        :options="coachOptions"
        option-label="name"
        option-value="id"
        :placeholder="
          selectedAthlete ? 'Select a Coach' : 'Select Athlete First'
        "
        :disabled="!selectedAthlete"
        class="w-full mb-2"
      />

      <small
        v-if="v$ErrorMessage(v$.selectedCoach.$errors)"
        class="error-message"
      >
        {{ v$ErrorMessage(v$.selectedCoach.$errors) }}
      </small>

      <Select
        v-model="selectedCategory"
        :options="videoCategoryOptions"
        option-label="name"
        option-value="id"
        placeholder="Select a Category"
        class="w-full mb-2"
      />

      <small
        v-if="v$ErrorMessage(v$.selectedCategory.$errors)"
        class="error-message"
      >
        {{ v$ErrorMessage(v$.selectedCategory.$errors) }}
      </small>

      <div class="flex items-center justify-between mb-2">
        <VideoUploader
          class="w-full"
          label="Video:"
          data-testid="video-uploader"
          :organization-id="organizationId as number"
          :allow-delete-button="false"
          :disclaimer-text="disclaimerText"
          @upload-complete="uploadVideoComplete"
          @upload-failed="uploadVideoFailed"
          @upload-started="uploadStarted"
        />
      </div>

      <small v-if="v$ErrorMessage(v$.videoAsset.$errors)" class="error-message">
        {{ v$ErrorMessage(v$.videoAsset.$errors) }}
      </small>

      <Textarea v-model="notes" placeholder="Notes" class="w-full mb-2" />

      <small v-if="v$ErrorMessage(v$.notes.$errors)" class="error-message">
        {{ v$ErrorMessage(v$.notes.$errors) }}
      </small>
      <Button
        :label="isVideoUploading ? 'Video Upload in Progress...' : 'Submit'"
        :disabled="isVideoUploading"
        class="w-full mt-2"
        @click="onConfirm"
      />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { v$ErrorMessage } from "@/shared/utils/helpers";

import Dialog from "primevue/dialog";
import Select from "primevue/select";
import VideoUploader from "@/shared/components/VideoUploader.vue";
import Textarea from "primevue/textarea";
import Button from "primevue/button";

import { allowedVideoFileExtensionsByKey } from "@/shared/constants/uploader";
import { createAdminChat, createAdminChatMessage } from "../api";
import { useToast } from "primevue/usetoast";
import { useCurrentUser } from "@/composables/useCurrentUser";
import { useOrganizationMembers } from "@/composables/useOrganizationMembers";
import { useOrganizationTeams } from "@/composables/useOrganizationTeams";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";

import {
  type AdminChat,
  type AdminChatMessage,
  type AdminAsset,
  CategoryEnum,
} from "@/api/model";
import type { ListOption } from "@/types";

const emit = defineEmits(["chatCreated"]);

const toast = useToast();

const { defaultOrganizationId: organizationId, loggedInUserId } =
  useCurrentUser();
const { athletes, athleteListOptions, fetchOrgMembers } =
  useOrganizationMembers();
const { teamIdToCoachesMap, fetchAndInitializeData } = useOrganizationTeams();

const visible = ref(false);

const selectedAthlete = ref<number | undefined>(undefined);
const selectedCoach = ref<number | undefined>(undefined);
const selectedCategory = ref<string | undefined>(undefined);
const notes = ref<string | undefined>();
const videoAsset = ref<AdminAsset | undefined>(undefined);
const isVideoUploading = ref(false);

const rules = {
  selectedAthlete: { required },
  selectedCoach: { required },
  selectedCategory: { required },
  videoAsset: { required },
  notes: { required },
};

const v$ = useVuelidate(rules, {
  selectedAthlete,
  selectedCoach,
  selectedCategory,
  videoAsset,
  notes,
});

const disclaimerText = `Allowed file types: ${allowedVideoFileExtensionsByKey}`;

const videoCategoryOptions = [
  { name: "Hitting", id: "Hitting" },
  { name: "Catching", id: "Catching" },
  { name: "Pitching", id: "Pitching" },
  { name: "Infield", id: "Infield" },
  { name: "Outfield", id: "Outfield" },
];

const coachOptions = computed(() => {
  if (!selectedAthlete.value || !teamIdToCoachesMap.value) {
    return [];
  }

  const selectedAthleteId = selectedAthlete.value;
  const athlete = athletes.value?.find((ath) => ath.id === selectedAthleteId);

  if (athlete && athlete.teams) {
    const teamIds = athlete.teams.map((team) => team.id);
    return teamIds.reduce((selectOptions, teamId) => {
      const coaches = teamIdToCoachesMap.value[teamId] || [];
      return [...selectOptions, ...coaches];
    }, [] as ListOption[]);
  }

  return [];
});

const uploadVideoComplete = ({ asset }: { asset: AdminAsset }): void => {
  videoAsset.value = asset;
  isVideoUploading.value = false;
};
const uploadStarted = (): void => {
  isVideoUploading.value = true;
};

const uploadVideoFailed = (_error: string): void => {
  toast.add({
    severity: "error",
    summary: "Error",
    detail:
      "Video failed to upload, please try again, our team has been notified.",
    life: 3000,
  });
};

const resetDialogState = () => {
  selectedAthlete.value = undefined;
  selectedCoach.value = undefined;
  selectedCategory.value = undefined;
  notes.value = "";
  videoAsset.value = undefined;

  v$.value.$reset();
};

const onConfirm = async () => {
  const isValid = await v$.value.$validate();

  if (!isValid) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Please correct the errors before submitting.",
      life: 3000,
    });
    return;
  }

  const chatPayload: Partial<AdminChat> = {
    category: selectedCategory.value as CategoryEnum,
    organization_id: organizationId.value,
    status: "Pending",
    submitted_by_id: loggedInUserId.value,
    recipient_id: selectedCoach.value,
    sender_id: selectedAthlete.value,
    asset_id: videoAsset?.value?.id,
  };

  const chat = await createAdminChat(chatPayload as AdminChat);

  if ("error" in chat) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Something went wrong, and our team has been notified",
      life: 3000,
    });

    return;
  }

  const chatMessagePayload: Partial<AdminChatMessage> = {
    chat_id: (chat as AdminChat).id,
    organization_id: organizationId.value,
    sender_id: selectedAthlete.value,
    text: notes.value,
    asset_id: videoAsset?.value?.id,
    type: "asset",
  };

  const chatMessage = await createAdminChatMessage(
    chatMessagePayload as AdminChatMessage
  );

  if ("error" in chatMessage) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Something went wrong, and our team has been notified",
      life: 3000,
    });

    return;
  }

  toast.add({
    severity: "success",
    summary: "Success",
    detail: "Video Submission created successfully.",
    life: 3000,
  });

  emit("chatCreated");

  addMixpanelEvent(EVENT_NAMES.VideoSubmissions.VideoSubmittedFromAdmin);

  resetDialogState();

  visible.value = false;
};

watch(visible, (newVal) => {
  if (!newVal) {
    resetDialogState();
  }
});

onMounted(async () => {
  await fetchOrgMembers();
  await fetchAndInitializeData();
});
</script>

<style scoped>
.error-message {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #e3342f;
  display: flex;
  align-items: center;
  opacity: 0.9;
}
</style>
