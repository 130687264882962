<template>
  <form>
    <FixedActionBar
      button-type="submit"
      show-update
      class="mb-6"
      @update="async () => ((await v$.$validate()) ? updateProfile() : null)"
    />

    <AppCard>
      <div class="grid grid-cols-12 gap-4 grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-6 lg:col-span-3">
          <div class="flex items-start content-center justify-center flex-wrap">
            <SingleImageUploaderAndDisplay
              label="Profile Image"
              :disclaimer-text="`Allowed file types: ${allowedImageFileExtensionsByKey}.`"
              :image-data="imageData"
              :thumbnail-width="280"
              :organization-id="organizationId"
              :allow-delete-button="false"
              @upload-complete="updateProfileImage"
              @upload-failed="uploadFailed"
            />
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-3">
          <div class="field">
            <label for="email">Email</label>
            <InputText
              id="email"
              v-model="email"
              type="text"
              class="text-base text-color bg-surface-0 dark:bg-surface-900 p-2 border border-solid border-surface rounded-border appearance-none outline-0 focus:border-primary w-full"
              disabled
            />
          </div>

          <div class="field">
            <label for="first_name">Name</label>
            <InputText
              id="name"
              v-model="name"
              type="text"
              class="text-base text-color bg-surface-0 dark:bg-surface-900 p-2 border border-solid border-surface rounded-border appearance-none outline-0 focus:border-primary w-full"
            />
          </div>
          <div class="field">
            <label for="phone">Phone</label>
            <InputText
              id="phone"
              v-model="form.phone_number"
              type="text"
              class="text-base text-color bg-surface-0 dark:bg-surface-900 p-2 border border-solid border-surface rounded-border appearance-none outline-0 focus:border-primary w-full"
            />
            <small
              v-if="v$ErrorMessage(v$.phone_number.$errors)"
              class="text-red-500"
            >
              {{ v$ErrorMessage(v$.phone_number.$errors) }}
            </small>
          </div>
        </div>

        <div class="auth-container col-span-12 md:col-span-6 lg:col-span-3">
          <div class="field">
            <label for="preferredAuthMethod">Sign In Preferences</label>
            <Select
              v-model="preferredAuthMethod"
              :options="PREFERRED_AUTH_OPTIONS"
              option-label="name"
              option-value="id"
              class="w-full"
            />
            <small>
              {{ PREFERRED_AUTH_DISCLAIMER }}
            </small>
          </div>
        </div>
      </div>
    </AppCard>
  </form>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import Select from "primevue/select";
import InputText from "primevue/inputtext";
import FixedActionBar from "@/shared/components/FixedActionBar.vue";
import AppCard from "@/shared/components/AppCard.vue";
import SingleImageUploaderAndDisplay from "@/shared/components/SingleImageUploaderAndDisplay.vue";
import { allowedImageFileExtensionsByKey } from "@/shared/constants/uploader";

import { v$ErrorMessage } from "@/shared/utils/helpers";
import { useToast } from "primevue/usetoast";
interface Toast {
  severity?: "success" | "info" | "warn" | "error" | undefined;
  summary: string;
  detail: string;
  msg: string;
  life: number;
}

import { helpers, maxLength, minLength, numeric } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import { useAuthStore } from "@/modules/auth/stores/auth";
const { loggedInUserId } = useAuthStore();

import { getUserMe, updateUserProfile } from "@/modules/profile/api";
import { watch } from "vue";

import { useAdminAvatar } from "@/modules/profile/stores/adminAvatar";
import type {
  PatchedAdminProfile,
  PreferredAdminAuthMethodEnum,
} from "@/api/model";
import {
  PASSWORD_AND_MAGIC_LINK_VALUE,
  PREFERRED_AUTH_DISCLAIMER,
  PREFERRED_AUTH_OPTIONS,
} from "../contants";
const { saveThumbnailToLocalStorage } = useAdminAvatar();

let imageData = ref();

const email = ref<string>("");
const phoneNumber = ref<string>();
const name = ref<string>();
const preferredAuthMethod = ref<PreferredAdminAuthMethodEnum>();

const form: PatchedAdminProfile = {
  phone_number: phoneNumber.value,
  name: name.value,
  preferred_admin_auth_method: preferredAuthMethod.value,
};

watch([name, phoneNumber, preferredAuthMethod], () => {
  form.name = name.value;
  form.phone_number = phoneNumber.value;
  form.preferred_admin_auth_method = preferredAuthMethod.value;
});

const rules = computed(() => ({
  phone_number: {
    numeric: helpers.withMessage("Numbers only", numeric),
    minLength: minLength(10),
    maxLength: maxLength(10),
  },
}));

const updateProfileImage = async (d: { assetId?: number }) => {
  if (!loggedInUserId) {
    return;
  }

  const assetId = d?.assetId;

  // update profile with assetId
  const result: any = await updateUserProfile(loggedInUserId, {
    picture_id: assetId,
  });
  const error = (result as any)?.error ?? "";
  if (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Profile image updated.",
      life: 3000,
    });

    // load asset into component
    imageData.value = result?.picture?.file;

    await saveThumbnailToLocalStorage(result?.picture?.file);
  }
};

const uploadFailed = async (d: { error: string }) => {
  if (d.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: d.error,
      life: 10000,
    });
  }
};

const updateProfile = async () => {
  if (!loggedInUserId) {
    return;
  }

  const result: any = await updateUserProfile(loggedInUserId, form);
  const error = (result as any)?.error ?? "";
  if (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Profile updated.",
      life: 3000,
    });
  }
};

const toast = useToast();
const showToast = (data: Toast) => {
  toast.add({
    severity: data?.severity ?? "error",
    summary: data?.summary ?? "Error",
    detail: data?.msg,
    life: data?.life ?? 10000,
  });
};

const v$ = useVuelidate(rules, form);

let organizationId = ref(0);

onMounted(async () => {
  if (!loggedInUserId) {
    return;
  }

  const result: any = await getUserMe(loggedInUserId);
  if (result?.error) {
    showToast(result.error);
  } else {
    name.value = result?.name;
    email.value = result?.email;
    phoneNumber.value = result.phone_number;
    preferredAuthMethod.value =
      result?.preferred_admin_auth_method || PASSWORD_AND_MAGIC_LINK_VALUE;

    organizationId.value = result?.organization?.id;

    imageData.value = result?.picture?.file;

    // just in case someone else updated the image elsewhere, and user has not logged out, update adminProfileImage to what is in db
    await saveThumbnailToLocalStorage(result?.picture?.file);
  }
});
</script>

<style scoped>
.auth-container {
  margin-left: 20px;
}
</style>
