<template>
  <div class="p-6 rounded-md bg-surface-0 dark:bg-surface-900 shadow-sm">
    <Sortable
      v-if="!videos || videos?.length > 0"
      :key="`sortable_${videos && videos?.length > 0 ? videos.length : 0}`"
      :list="videos"
      item-key="id"
      :options="sortableOptions"
      class="flex flex-wrap gap-2"
      @end="changeSortOrder"
    >
      <template #item="{ element }">
        <div
          :key="element?.id"
          class="draggable shadow-sm p-2 rounded-border"
          :data-id="element?.id"
        >
          <div class="position-relative mr-6">
            <div :class="videos.length > 1 ? 'pi pi-bars is-link' : undefined">
              <AssetThumbnail
                :key="`${element?.id}-${element?.asset?.file?.thumbnail_url_tpl}`"
                :asset-prop="getAsset(element?.id)"
                class="mb-2 mt-2"
                box-size="140px"
              />
              <div
                class="flex items-center justify-between pt-1 attachment-controls"
              >
                <AppButton
                  :loading="isDeletingId === element?.id"
                  severity="danger"
                  class="py-1 px-2 text-xs"
                  label="Delete"
                  @click="() => deleteComparisonVideoConfirm(element?.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </Sortable>
  </div>
  <FileDropzone
    :organization-id="props.organizationId"
    :only-videos-allowed="true"
    class="my-6"
    @completed="onVideoUploaded"
  />
</template>

<script setup lang="ts">
import { Sortable } from "sortablejs-vue3";
import FileDropzone from "@/shared/components/FileDropzone.vue";
import AssetThumbnail from "@/shared/components/AssetThumbnail.vue";
import { ref, onMounted } from "vue";
import {
  createComparisonVideo,
  deleteComparisonVideo,
  changeOrderComparisonVideo,
  getComparisonVideo,
} from "@/modules/trainingCollections/api";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import type { AdminTrainingCollectionComparisonVideo } from "@/api/model";

const confirm = useConfirm();
const toast = useToast();

const props = defineProps<{
  trainingCollectionId: number;
  initialData?: AdminTrainingCollectionComparisonVideo[];
  organizationId: number;
}>();

const videos = ref<AdminTrainingCollectionComparisonVideo[]>([]);
const isDeletingId = ref<number | null>(null);

const sortableOptions = {
  animation: 100,
  store: {
    set: function (sortable: typeof Sortable) {
      const ids = sortable.toArray();
      for (let i = 0; i < ids.length; i++) {
        const id = parseInt(ids[i]);
        if (videos.value) {
          const index = videos.value.findIndex((e) => e.id === id);
          videos.value[index].order = i;
        }
      }
    },
  },
};

const changeSortOrder = async () => {
  const updatedList = [];
  for (let idx = 0; idx < (videos.value || []).length; idx++) {
    const video = videos.value[idx];
    if (video?.id && video?.order > -1) {
      updatedList.push({ id: video.id, order: video?.order });
    }
  }

  const result = await changeOrderComparisonVideo(updatedList);
  if ("error" in result) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Video order updated.",
      life: 3000,
    });
  }
};

const getAsset = (comparisonVideoId: number) => {
  try {
    return props.initialData?.find((e) => e.id === comparisonVideoId)?.asset;
  } catch (e) {
    return undefined;
  }
};

const onVideoUploaded = async (ids: number[]) => {
  for (let i = 0; i < ids.length; i++) {
    const video = await createComparisonVideo({
      training_collection_id: props.trainingCollectionId,
      asset_id: ids[i],
      order: videos.value.length,
    });
    if ("error" in video) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: video.error,
        life: 10000,
      });
    } else {
      toast.add({
        severity: "info",
        summary: "Processing",
        detail: "Video is processing.",
        life: 3000,
      });

      videos.value.push(video);

      // check endpoint for thumbnail
      await pollForUpdatedComparisonVideo(video.id);
    }
  }
};

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const pollForUpdatedComparisonVideo = async (comparisonVideoId: number) => {
  // check every 10 seconds for new image...if found exit early
  for (let idx = 0; idx < 5; idx++) {
    await delay(10000);
    const refreshedVideo = await getComparisonVideo(comparisonVideoId);

    if ("error" in refreshedVideo) {
      return;
    } else {
      if (refreshedVideo?.asset?.file?.thumbnail_url_tpl) {
        const index = videos.value?.findIndex(
          (e) => e.id === comparisonVideoId
        );

        if (videos.value[index]) {
          videos.value[index] = refreshedVideo;
          return;
        }
      }
    }
  }
};

const onRemoveComparisonVideo = async (id: number) => {
  isDeletingId.value = id;

  const result = await deleteComparisonVideo(id);
  if (result?.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
      life: 10000,
    });
  } else {
    if (videos.value) {
      const index = videos.value.findIndex((e) => e.id === id);
      videos.value.splice(index, 1);

      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Video deleted.",
        life: 3000,
      });
    }
  }

  isDeletingId.value = null;
};

const deleteComparisonVideoConfirm = async (id: number) => {
  confirm.require({
    message: "You sure you want to delete this video?",
    header: "Delete Video",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      await onRemoveComparisonVideo(id);
    },
  });
};

onMounted(async () => {
  if (props?.initialData) {
    videos.value = props.initialData;
  }
});
</script>
