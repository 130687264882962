<template>
  <Dialog
    header="Send Invoices"
    v-model:visible="showModal"
    :modal="true"
    class="w-[34rem]"
  >
    <div class="">
      <p>
        The following players don't have an "Invoice Recipient" and will be
        removed.
      </p>

      <ul class="pt-2">
        <li
          v-for="recipient in props.recipientsMissingContact"
          :key="recipient.playerId"
          class="font-semibold pt-2"
        >
          {{ recipient.playerName }}
        </li>
      </ul>
    </div>
    <div class="flex flex-row justify-end space-x-3 pt-7">
      <Button label="Go Back" class="" @click="goBack" />
      <Button :label="buttonCopy" class="" @click="handleInvoiceSend" />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import Button from "primevue/button";

import { type MissingContactDialogProps } from "@/types";
import { computed } from "vue";

const showModal = defineModel<boolean>("showModal", {
  required: true,
});

const props = defineProps<MissingContactDialogProps>();

const { handleInvoiceCreation } = props;

const buttonCopy = computed(
  () => `Send ${props.validInvoiceRecipients.length} Invoices`
);

const handleInvoiceSend = () => {
  handleInvoiceCreation();
};

const goBack = () => {
  showModal.value = false;
};
</script>
