import { adminV1CloudfrontCookiesRetrieve } from "@/api/admin-cloudfront-cookies/admin-cloudfront-cookies";
import type { CloudFrontCookiesResponse } from "@/api/model/cloudFrontCookiesResponse";
import { defineStore } from "pinia";
import { Buffer } from "buffer";
import axios from "axios";

interface SignedCookiesState {
  cloudFrontKeyPairId?: string;
  cloudFrontSignature?: string;
  cloudFrontPolicy?: string;
  baseUrl?: string;
}

export const useSignedCookies = defineStore("signedCookies", {
  persist: true,
  state: (): SignedCookiesState => ({
    cloudFrontKeyPairId: "",
    cloudFrontSignature: "",
    cloudFrontPolicy: "",
    baseUrl: "",
  }),
  actions: {
    async getEpochTimeFromPolicy(policy: string): Promise<number> {
      const base64Data = Buffer.from(policy, "base64").toString("utf8");
      // remove extra question mark at end if exists
      const jsonData = JSON.parse(base64Data.replace(/\?$/, ""));
      return (
        jsonData["Statement"][0]["Condition"]["DateLessThan"][
          "AWS:EpochTime"
        ] ?? undefined
      );
    },
    async getSecondsUntilExpiration(policy?: string): Promise<number> {
      if (!policy) {
        return 0;
      }
      const epochFromPolicy = await this.getEpochTimeFromPolicy(policy);
      const nowEpoch = Math.floor(Date.now() / 1000);

      return epochFromPolicy - nowEpoch;
    },
    async getSignedCookies(): Promise<CloudFrontCookiesResponse> {
      // check if expired or about to expire
      const secondsTillExpiration = await this.getSecondsUntilExpiration(
        this.cloudFrontPolicy
      );

      // if within 30 seconds of expiration, get new cookies
      if (secondsTillExpiration < 30) {
        return await this.getNewSignedCookiesFromAPI();
      } else {
        return {
          base_url: this.baseUrl ?? "",
          cookies: {
            "CloudFront-Policy": this.cloudFrontPolicy ?? "",
            "CloudFront-Signature": this.cloudFrontSignature ?? "",
            "CloudFront-Key-Pair-Id": this.cloudFrontKeyPairId ?? "",
          },
        };
      }
    },
    async getCloudfrontSignedCookies(): Promise<CloudFrontCookiesResponse> {
      const cookies = await adminV1CloudfrontCookiesRetrieve();
      if ("error" in cookies) {
        console.log(cookies?.error);
        return {
          base_url: "",
          cookies: {
            "CloudFront-Policy": "",
            "CloudFront-Signature": "",
            "CloudFront-Key-Pair-Id": "",
          },
        };
      } else {
        try {
          await axios.get(
            `${cookies.base_url}/set-cookies-for-cloudfront/set-cookies?Policy=${cookies.cookies["CloudFront-Policy"]}&Signature=${cookies.cookies["CloudFront-Signature"]}&Key-Pair-Id=${cookies.cookies["CloudFront-Key-Pair-Id"]}`,
            {
              withCredentials: true,
            }
          );
          return cookies;
        } catch (e) {
          console.log(e);
          return {
            base_url: "",
            cookies: {
              "CloudFront-Policy": "",
              "CloudFront-Signature": "",
              "CloudFront-Key-Pair-Id": "",
            },
          };
        }
      }
    },
    async getNewSignedCookiesFromAPI(): Promise<CloudFrontCookiesResponse> {
      const cookies = await this.getCloudfrontSignedCookies();
      this.cloudFrontKeyPairId =
        cookies?.cookies["CloudFront-Key-Pair-Id"] ?? "";
      this.cloudFrontSignature = cookies?.cookies["CloudFront-Signature"] ?? "";
      this.cloudFrontPolicy = cookies?.cookies["CloudFront-Policy"] ?? "";
      this.baseUrl = cookies?.base_url ?? "";

      return cookies;
    },
    async clearLocalStorageSignedCookie(): Promise<void> {
      this.cloudFrontKeyPairId = undefined;
      this.cloudFrontSignature = undefined;
      this.cloudFrontPolicy = undefined;
      this.baseUrl = undefined;
    },
  },
});
