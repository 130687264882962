<template>
  <div class="flex">
    <div class="flex space-x-4">
      <span class="flex-auto">
        <label for="name">Exercise name</label>
        <InputText
          id="name"
          v-model="form.name"
          placeholder="Exercise Name"
          class="w-full border"
        />
      </span>
      <span class="flex-auto">
        <label for="reps">Reps</label>
        <InputNumber
          id="reps"
          v-model="form.reps"
          show-buttons
          mode="decimal"
          :min="0"
          fluid
        >
          <template #incrementbuttonicon>
            <span class="pi pi-angle-up z-20"></span>
          </template>
          <template #decrementbuttonicon>
            <span class="pi pi-angle-down z-20"></span>
          </template>
        </InputNumber>
      </span>
    </div>
    <AppButton
      :label="isEditModeId ? 'Update' : 'Add'"
      :disabled="!canAddExercise"
      :loading="isLoading"
      size="small"
      class="self-end ml-4"
      @click="onSubmit"
    />
  </div>

  <Sortable
    v-if="!exercises || exercises?.length > 0"
    :key="`sortable_${(exercises && exercises?.length > 0) ?? 0}`"
    :list="exercises"
    item-key="id"
    class="mt-6"
    :options="sortableOptions"
    @update="changeSortOrder"
  >
    <template #item="{ element }">
      <div
        :key="element.id"
        :class="isEditModeId === element.id ? 'bg-yellow-100' : 'surface-0'"
        class="draggable flex items-center justify-between shadow-sm p-6 mb-2 is-link"
        :data-id="element?.id"
      >
        <div>
          <span class="pi pi-sort mr-2"></span>
          <span class="font-semibold mr-2">{{ element.name }}</span>
          <span>(reps: {{ element.reps }})</span>
        </div>
        <div class="flex">
          <AppButton
            icon="pi pi-copy"
            size="small"
            class="mr-2 bg-surface-900 dark:bg-surface-0"
            label="duplicate"
            @click="() => onDuplicateExercise(element)"
          />
          <AppButton
            icon="pi pi-file-edit"
            size="small"
            class="mr-2"
            label="edit"
            @click="() => onEditExercise(element)"
          />
          <AppButton
            severity="danger"
            icon="pi pi-trash"
            size="small"
            label="delete"
            :loading="isDeleting === element.id"
            @click="() => onDeleteExercise(element.id)"
          />
        </div>
      </div>
    </template>
  </Sortable>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import InputNumber, { type InputNumberInputEvent } from "primevue/inputnumber";
import { Sortable } from "sortablejs-vue3";
import { orderBy } from "lodash";
import { computed, onMounted, reactive, ref } from "vue";
import {
  getDrillExercises,
  createDrillExercise,
  updateDrillExercise,
  deleteDrillExercise,
  changeOrderExerciseDrills,
} from "@/modules/trainingCollections/api";
import { catchErrorMessage } from "@/shared/utils/custom-errors";

import { useToast } from "primevue/usetoast";
import type { AdminDrillExercise } from "@/api/model";

const toast = useToast();

const props = defineProps<{
  drillId: number | undefined;
}>();

const sortableOptions = {
  animation: 100,
  store: {
    set: function (sortable: typeof Sortable) {
      const ids = sortable.toArray();
      for (let i = 0; i < ids.length; i++) {
        const id = parseInt(ids[i]);
        if (exercises.value) {
          const index = exercises.value.findIndex((e) => e.id === id);
          exercises.value[index].order = i;
        }
      }
    },
  },
};

const changeSortOrder = async () => {
  const result = await changeOrderExerciseDrills(exercises.value);
  if ("error" in result) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Order updated.",
      life: 3000,
    });
  }
};

const canAddExercise = computed(() => {
  return form.name != "" && form.reps > 0;
});

const exercises = ref<AdminDrillExercise[]>([]);
const isEditModeId = ref<number | undefined>(undefined);
const isLoading = ref<boolean>(false);
const isDeleting = ref<number | undefined>(undefined);

interface formType {
  drill_id: number | null;
  name: string;
  reps: number;
  order: number;
}

const form = reactive<formType>({
  drill_id: null,
  name: "",
  reps: 0,
  order: 0,
});

onMounted(async () => {
  form.drill_id = props?.drillId;
  await fetchExercises();
});

const fetchExercises = async () => {
  if (form.drill_id) {
    const items = await getDrillExercises(form.drill_id);
    if ("error" in items) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: items.error,
        life: 10000,
      });
    } else {
      exercises.value = orderBy(items?.results || [], "order");
    }
  }
};

const onSubmit = async () => {
  // submit main drill to get drill_id
  if (!form.drill_id) {
    return;
  }

  isLoading.value = true;
  if (isEditModeId.value) {
    await onUpdateExercise(isEditModeId.value);
    isLoading.value = false;
    isEditModeId.value = undefined;
    return;
  }
  await onCreateExercise();
  isLoading.value = false;
  return;
};

const onCreateExercise = async () => {
  form.order = exercises.value.length;
  const result = await createDrillExercise(form);
  if ("error" in result) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
      life: 10000,
    });
  } else {
    form.name = "";
    form.reps = 0;
    form.order = 0;
    await fetchExercises();
  }
};

const onUpdateExercise = async (id: number) => {
  try {
    await updateDrillExercise(id, form);
    form.name = "";
    form.reps = 0;
    form.order = 0;
    await fetchExercises();
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 3000,
    });
  }
};

const onDeleteExercise = async (id: number) => {
  try {
    isDeleting.value = id;
    await deleteDrillExercise(id);
    await fetchExercises();
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 3000,
    });
  }
  isDeleting.value = undefined;
};

const onEditExercise = (data: AdminDrillExercise) => {
  isEditModeId.value = data?.id;
  form.name = data?.name;
  form.reps = data.reps ?? 0;
  form.order = data.order ?? 0;
};

interface onDuplicateExerciseType {
  name: string;
  reps: number;
}

const onDuplicateExercise = async (data: onDuplicateExerciseType) => {
  const result = await createDrillExercise({
    name: data?.name,
    reps: data?.reps,
    order: exercises.value.length,
    drill_id: form.drill_id ?? undefined,
  });
  if ("error" in result) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
      life: 10000,
    });
  } else {
    await fetchExercises();
  }
};
</script>
