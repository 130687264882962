<template>
  <Dialog
    v-model:visible="showModal"
    modal
    :header="dialogHeader"
    class="w-[48rem]"
  >
    <div class="p-4">
      <div class="flex flex-row justify-between items-start space-x-4">
        <div class="flex flex-col mb-8 flex-1 p-2.5">
          <label class="mb-2 font-medium" for="title">Title</label>
          <InputText v-model="title" placeholder="Title" class="w-full" />
          <small
            v-if="v$ErrorMessage(fieldValidations.title.$errors)"
            class="text-red-500 text-sm mt-1"
          >
            {{ v$ErrorMessage(fieldValidations.title.$errors) }}
          </small>
        </div>
        <div class="flex flex-col mb-8 flex-1 p-2.5">
          <label class="mb-2 font-medium" for="unitLabel">Unit Label</label>
          <InputText v-model="unitLabel" placeholder="Unit" class="w-full" />
          <small
            v-if="v$ErrorMessage(fieldValidations.unitLabel.$errors)"
            class="text-red-500 text-sm mt-1"
          >
            {{ v$ErrorMessage(fieldValidations.unitLabel.$errors) }}
          </small>
        </div>
        <div class="flex flex-col mb-8 flex-1 p-2.5">
          <label class="mb-2 font-medium" for="icon">Icon</label>
          <Select
            v-model="icon"
            :options="ICON_DROPDOWN_OPTIONS"
            option-label="label"
            placeholder="Select an Icon"
            class="w-full"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex items-center">
                <div>{{ slotProps.value.label }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="flex items-center">
                <div>{{ slotProps.option.label }}</div>
              </div>
            </template>
          </Select>
          <small
            v-if="v$ErrorMessage(fieldValidations.icon.$errors)"
            class="text-red-500 text-sm mt-1"
          >
            {{ v$ErrorMessage(fieldValidations.icon.$errors) }}
          </small>
        </div>
      </div>

      <div class="mb-8 p-2.5">
        <label class="mb-2 font-medium block" for="reminderFrequency"
          >Reminders</label
        >
        <div
          class="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-4"
        >
          <Select
            v-model="reminderFrequency"
            :options="REMINDER_FREQUENCY_OPTIONS"
            option-label="label"
            option-value="value"
            placeholder="Select Reminder Frequency"
            class="w-full sm:w-48"
          />
          <span v-if="showReminderDay" class="text-sm text-gray-500">on</span>
          <Select
            v-if="showReminderDay"
            v-model="reminderDay"
            :options="REMINDER_DAY_OPTIONS"
            option-label="label"
            option-value="value"
            placeholder="Select Reminder Day"
            class="w-full sm:w-48"
          />
        </div>
        <small
          v-if="v$ErrorMessage(fieldValidations.reminderFrequency.$errors)"
          class="text-red-500 text-sm mt-1 block"
        >
          {{ v$ErrorMessage(fieldValidations.reminderFrequency.$errors) }}
        </small>
        <small
          v-if="fieldValidations.reminderDay.$errors.length"
          class="text-red-500 text-sm mt-1 block"
        >
          {{ fieldValidations.reminderDay.$errors[0].$message }}
        </small>
        <p class="text-sm text-gray-600 mt-2">
          {{
            reminderFrequency === ReminderFrequencyEnum.NONE
              ? "Players will not receive notifications."
              : "Players will receive push notifications."
          }}
        </p>

        <div v-if="lastReminderSent" class="mt-8">
          <label class="mb-2 font-medium block">Last Reminder Sent</label>
          <p class="text-sm text-gray-600">
            {{ formatLastReminderSent(lastReminderSent) }}
          </p>
        </div>
      </div>

      <div class="flex flex-col p-4">
        <label for="headlineValue" class="mb-2 font-medium"
          >Headline Value</label
        >
        <div class="flex flex-col mt-4">
          <div
            v-for="option in HEADLINE_VALUE_OPTIONS"
            :key="option.value"
            class="flex items-center mb-4"
          >
            <RadioButton
              v-model="headlineValue"
              :input-id="option.value"
              :name="option.value"
              :value="option.value"
            />
            <label :for="option.value" class="ml-2">{{ option.label }}</label>
          </div>
        </div>
        <small
          v-if="v$ErrorMessage(fieldValidations.headlineValue.$errors)"
          class="text-red-500 text-sm mt-1"
        >
          {{ v$ErrorMessage(fieldValidations.headlineValue.$errors) }}
        </small>
      </div>

      <div class="flex justify-end p-5">
        <Button :label="buttonLabel" @click="handleUpsert" />
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, toRefs, watch } from "vue";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";
import Select from "primevue/select";
import Button from "primevue/button";
import { format, parseISO } from "date-fns";

import {
  HeadlineValueEnum,
  ReminderFrequencyEnum,
  ReminderDayEnum,
} from "@/api/model";

import { v$ErrorMessage } from "@/shared/utils/helpers";

import {
  EDIT_STAT_CATEGORY_HEADER,
  CREATE_STAT_CATEGORY_HEADER,
  ICON_DROPDOWN_OPTIONS,
  STAT_CATEGORY_DIALOG_UPDATE_BUTTON,
  STAT_CATEGORY_DIALOG_CREATE_BUTTON,
  REMINDER_FREQUENCY_OPTIONS,
  REMINDER_DAY_OPTIONS,
  HEADLINE_VALUE_OPTIONS,
} from "../constants";
import {
  type StatCategoryDialogProps,
  type StatCategoryFormFields,
} from "@/types";

const props = defineProps<StatCategoryDialogProps>();
const showModal = defineModel<boolean>("visible", {
  required: true,
});
const statsCategoryModel = defineModel<StatCategoryFormFields>(
  "statCategoryFormFields",
  {
    required: true,
  }
);

const { isEdit, fieldValidations } = toRefs(props);
const { upsertStatCategory } = props;

const {
  title,
  unitLabel,
  headlineValue,
  icon,
  reminderFrequency,
  reminderDay,
  lastReminderSent,
} = statsCategoryModel.value;

const emit = defineEmits(["upsertSuccess"]);

const dialogHeader = computed(() =>
  isEdit.value ? EDIT_STAT_CATEGORY_HEADER : CREATE_STAT_CATEGORY_HEADER
);

const buttonLabel = computed(() =>
  isEdit.value
    ? STAT_CATEGORY_DIALOG_UPDATE_BUTTON
    : STAT_CATEGORY_DIALOG_CREATE_BUTTON
);

const showReminderDay = computed(
  () =>
    reminderFrequency.value === ReminderFrequencyEnum.WEEKLY ||
    reminderFrequency.value === ReminderFrequencyEnum.BIWEEKLY
);

watch(reminderFrequency, (newValue) => {
  if (
    newValue !== ReminderFrequencyEnum.WEEKLY &&
    newValue !== ReminderFrequencyEnum.BIWEEKLY
  ) {
    reminderDay.value = ReminderDayEnum.NA;
  }
});

const handleUpsert = async () => {
  const result = await upsertStatCategory();

  if (result) {
    emit("upsertSuccess");
    showModal.value = false;
  }
};

const formatLastReminderSent = (dateString: string | undefined | null) => {
  if (!dateString) return "Never";
  try {
    const date = parseISO(dateString);
    return format(date, "MMMM d, yyyy h:mm a");
  } catch (error) {
    console.error("Error parsing date:", error);
    return null;
  }
};
</script>
