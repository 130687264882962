import { ref } from "vue";

import type { Invoice } from "@/api/model";
import type { Ref } from "vue";
import { getInvoice } from "../api";

export function useInvoice(): {
  invoice: Ref<Invoice | null>;
  fetchInvoice: (id: string) => Promise<void>;
  fetchInvoiceError: Ref<string | null>;
} {
  const invoice = ref<Invoice | null>(null);
  const fetchInvoiceError = ref<string | null>(null);
  async function fetchInvoice(id: string) {
    const result = await getInvoice(id);
    if ("error" in result) {
      fetchInvoiceError.value = result.error;
    } else {
      invoice.value = result;
    }
  }
  return {
    invoice,
    fetchInvoice,
    fetchInvoiceError,
  };
}
