import type { PaginatedAdminStatCategoryList } from "@/api/model";
import { ref, type Ref } from "vue";
import { useToast } from "primevue/usetoast";
import {
  fetchStatCategoryStatCount,
  getStatCategories,
  deleteStatCategory as deleteStatCategoryAPI,
} from "../api";
import { useConfirm } from "primevue/useconfirm";

export const STAT_CAT_PAGE_SIZE = 50;
const FIRST_PAGE = 0;

interface UseStatCategoryTable {
  paginatedStatCategories: Ref<PaginatedAdminStatCategoryList | undefined>;
  fetchStatCategories: () => Promise<void>;
  statCategoryError: Ref<string | undefined>;
  currentPage: Ref<number>;
  pageSize: Ref<number>;
  totalRecords: Ref<number | undefined>;
  deleteStatCategory: (id: number) => Promise<void>;
}

export const useStatCategoryTable = (): UseStatCategoryTable => {
  const pageSize: Ref<number> = ref(STAT_CAT_PAGE_SIZE);
  const currentPage: Ref<number> = ref(FIRST_PAGE);
  const totalRecords: Ref<number | undefined> = ref(0);
  const statCategoryError: Ref<string | undefined> = ref(undefined);
  const paginatedStatCategories: Ref<
    PaginatedAdminStatCategoryList | undefined
  > = ref(undefined);

  const toast = useToast();
  const confirm = useConfirm();

  const fetchStatCategories = async (): Promise<void> => {
    const offset = currentPage.value * pageSize.value;
    const results = await getStatCategories({ limit: pageSize.value, offset });

    if (results && "error" in results) {
      statCategoryError.value = results.error;
    } else {
      paginatedStatCategories.value = results;
      totalRecords.value = results.count;
    }
  };

  const deleteStatCategory = async (id: number): Promise<void> => {
    const result = await fetchStatCategoryStatCount(id);

    if ("error" in result) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: result.error,
      });

      return;
    }

    const { count: statCount } = result;
    let message;

    if (statCount > 0) {
      message = `This category is associated with ${statCount} stats that will also be deleted. Are you sure you want to delete it?`;
    } else {
      message = "Are you sure you want to delete this category?";
    }
    confirm.require({
      header: "Confirmation",
      message: message,
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await deleteStatCategoryAPI(id);
          await fetchStatCategories();

          toast.add({
            severity: "success",
            summary: "Success",
            detail: "Stat category deleted successfully",
            life: 3000,
          });
        } catch (e: any) {
          toast.add({
            severity: "error",
            summary: "Error",
            life: 3000,
            detail: e.message,
          });
        }
      },
    });

    return;
  };

  return {
    paginatedStatCategories,
    fetchStatCategories,
    statCategoryError,
    currentPage,
    pageSize,
    totalRecords,
    deleteStatCategory,
  };
};
