import { ref, type Ref } from "vue";
import { getAllUsers } from "@/modules/users/api";
import type { AdminProfile, PaginatedAdminProfileList } from "@/api/model";
import { useCurrentUser } from "./useCurrentUser";

interface AthleteOption {
  id: number;
  name: string;
}

interface UseOrganizationAthletesReturn {
  athletes: Ref<AdminProfile[]>;
  coaches: Ref<AdminProfile[]>;
  admins: Ref<AdminProfile[]>;
  athleteListOptions: Ref<AthleteOption[]>;
  fetchOrgMembers: () => Promise<void>;
}

export function useOrganizationMembers(): UseOrganizationAthletesReturn {
  const athletes = ref<AdminProfile[]>([]);
  const coaches = ref<AdminProfile[]>([]);
  const admins = ref<AdminProfile[]>([]);
  const athleteListOptions = ref<AthleteOption[]>([]);

  const { defaultOrganizationId: organizationIdRef } = useCurrentUser();

  const fetchOrgMembers = async (): Promise<void> => {
    const organizationId = organizationIdRef.value;
    if (!organizationId) return;

    try {
      athletes.value = [];
      coaches.value = [];
      admins.value = [];

      let response = await getAllUsers({ organizationId, limit: 10_000 });
      const results = (response as PaginatedAdminProfileList)?.results ?? [];

      athletes.value.push(...(results?.filter(isAthlete) ?? []));
      coaches.value.push(
        ...results?.filter((member: AdminProfile) => member.is_coach)
      );
      admins.value.push(
        ...results?.filter((member: AdminProfile) => member.is_admin)
      );

      athleteListOptions.value = athletes.value.map(
        (athlete: AdminProfile) => ({
          id: athlete.id,
          name: String(athlete.name),
          teams: athlete.teams,
          organization: athlete.organization,
        })
      );
    } catch (error) {
      console.error("Failed to fetch athletes", error);
      throw error;
    }
  };

  return {
    athletes,
    athleteListOptions,
    admins,
    coaches,
    fetchOrgMembers,
  };
}

const isAthlete = (profile: AdminProfile): boolean => {
  const { is_active, is_staff, is_coach } = profile;

  return (is_active && !is_staff && !is_coach) ?? false;
};
