<template>
  <AppButton
    label="Attach Drill"
    size="small"
    class="my-4"
    @click="visible = true"
  />
  <Dialog
    v-model:visible="visible"
    modal
    header="Drills"
    :style="{ width: '90vw', maxWidth: '400px' }"
    class="p-2 sm:p-4"
  >
    <Select
      v-model="drill"
      :options="options"
      :loading="isLoading"
      option-label="name"
      option-value="id"
      placeholder="Select a Drill"
      class="w-full my-2"
    />
    <div class="flex justify-end mt-4">
      <AppButton label="Confirm" @click="onConfirm" class="w-full sm:w-auto" />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import Select from "primevue/select";
import { catchErrorMessage } from "@/shared/utils/custom-errors";
import { onMounted, ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDrillsStore } from "@/modules/feed/stores/drillsStore";
import type { AdminDrill } from "@/api/model";
import { useToast } from "primevue/usetoast";

const toast = useToast();

const { fetchDrills, findById } = useDrillsStore();
const { isLoading, drills } = storeToRefs(useDrillsStore());

const props = defineProps<{
  trainingCollectionId: number;
}>();

const emit = defineEmits<{
  (e: "confirm", drill: AdminDrill): void;
}>();

const visible = ref(false);
const drill = ref<number | undefined>(undefined);

const fetchOptions = async () => {
  try {
    await fetchDrills();
  } catch (e: unknown) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 3000,
    });
  }
};

onMounted(() => {
  fetchOptions();
});

const options = computed(() => {
  const options = [];

  for (let i = 0; i < drills.value.length; i++) {
    const d = drills.value[i];

    if (
      d.training_collection &&
      d.training_collection?.id === props.trainingCollectionId
    ) {
      options.push(d);
    }
    if (d.training_collection_id === props.trainingCollectionId) {
      options.push(d);
    }
  }
  return options;
});

const onConfirm = () => {
  if (drill.value) {
    const d = findById(drill.value);
    if (d) {
      emit("confirm", d);
    }
    drill.value = undefined;
  }
  visible.value = false;
};
</script>
