/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminRetrieveTrainingCollection,
  AdminV1TrainingCollectionsListParams,
  OrderTrainingCollection,
  PaginatedAdminListTrainingCollectionList,
  PatchedAdminRetrieveTrainingCollection,
  TrainingCollectionsOrderResponse
} from '.././model'
import adminV1TrainingCollectionsListMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsCreateMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsRetrieveMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsUpdateMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsDestroyMutator from '../../shared/services/axios-client';
import adminV1TrainingCollectionsUpdateOrderCreateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1TrainingCollectionsList = (
    params?: AdminV1TrainingCollectionsListParams,
 options?: SecondParameter<typeof adminV1TrainingCollectionsListMutator>,) => {
      return adminV1TrainingCollectionsListMutator<PaginatedAdminListTrainingCollectionList>(
      {url: `/api/admin/v1/training_collections/`, method: 'GET',
        params
    },
      options);
    }
  export const adminV1TrainingCollectionsCreate = (
    adminRetrieveTrainingCollection: NonReadonly<AdminRetrieveTrainingCollection>,
 options?: SecondParameter<typeof adminV1TrainingCollectionsCreateMutator>,) => {
      return adminV1TrainingCollectionsCreateMutator<AdminRetrieveTrainingCollection>(
      {url: `/api/admin/v1/training_collections/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminRetrieveTrainingCollection
    },
      options);
    }
  export const adminV1TrainingCollectionsRetrieve = (
    id: number,
 options?: SecondParameter<typeof adminV1TrainingCollectionsRetrieveMutator>,) => {
      return adminV1TrainingCollectionsRetrieveMutator<AdminRetrieveTrainingCollection>(
      {url: `/api/admin/v1/training_collections/${id}/`, method: 'GET'
    },
      options);
    }
  export const adminV1TrainingCollectionsUpdate = (
    id: number,
    adminRetrieveTrainingCollection: NonReadonly<AdminRetrieveTrainingCollection>,
 options?: SecondParameter<typeof adminV1TrainingCollectionsUpdateMutator>,) => {
      return adminV1TrainingCollectionsUpdateMutator<AdminRetrieveTrainingCollection>(
      {url: `/api/admin/v1/training_collections/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminRetrieveTrainingCollection
    },
      options);
    }
  export const adminV1TrainingCollectionsPartialUpdate = (
    id: number,
    patchedAdminRetrieveTrainingCollection: NonReadonly<PatchedAdminRetrieveTrainingCollection>,
 options?: SecondParameter<typeof adminV1TrainingCollectionsPartialUpdateMutator>,) => {
      return adminV1TrainingCollectionsPartialUpdateMutator<AdminRetrieveTrainingCollection>(
      {url: `/api/admin/v1/training_collections/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminRetrieveTrainingCollection
    },
      options);
    }
  export const adminV1TrainingCollectionsDestroy = (
    id: number,
 options?: SecondParameter<typeof adminV1TrainingCollectionsDestroyMutator>,) => {
      return adminV1TrainingCollectionsDestroyMutator<void>(
      {url: `/api/admin/v1/training_collections/${id}/`, method: 'DELETE'
    },
      options);
    }
  export const adminV1TrainingCollectionsUpdateOrderCreate = (
    orderTrainingCollection: OrderTrainingCollection[],
 options?: SecondParameter<typeof adminV1TrainingCollectionsUpdateOrderCreateMutator>,) => {
      return adminV1TrainingCollectionsUpdateOrderCreateMutator<TrainingCollectionsOrderResponse>(
      {url: `/api/admin/v1/training_collections/update_order/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: orderTrainingCollection
    },
      options);
    }
  export type AdminV1TrainingCollectionsListResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsList>>>
export type AdminV1TrainingCollectionsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsCreate>>>
export type AdminV1TrainingCollectionsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsRetrieve>>>
export type AdminV1TrainingCollectionsUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsUpdate>>>
export type AdminV1TrainingCollectionsPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsPartialUpdate>>>
export type AdminV1TrainingCollectionsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsDestroy>>>
export type AdminV1TrainingCollectionsUpdateOrderCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1TrainingCollectionsUpdateOrderCreate>>>
