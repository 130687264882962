<template>
  <main>
    <div
      class="app-sidebar bg-white py-4 px-4"
      :class="{ opened: isOpenSidebar }"
    >
      <router-link :to="{ name: ROUTE_NAME.FEED }">
        <AppLogo :size="40" />
      </router-link>
      <div class="overflow-y-auto block">
        <MenuList class="mt-2" />
      </div>
    </div>
    <div class="app-content" :class="{ opened: isOpenSidebar }">
      <div class="flex justify-between px-8 py-1 bg-surface-100">
        <div class="flex items-center">
          <i
            class="pi pi-bars cursor-pointer"
            @click="isOpenSidebar = !isOpenSidebar"
          />

          <TFABreadcrumb
            v-if="props.breadcrumb.length"
            :breadcrumb="props.breadcrumb"
          />
          <span v-else-if="props?.title" class="font-semibold px-4 py-3">
            {{ props.title }}
          </span>
        </div>
        <div class="flex items-center">
          <AdminAvatar class="cursor-pointer" @click="toggle" />
        </div>
        <Menu id="profile-menu" ref="menu" :model="items" :popup="true" />
      </div>

      <div class="p-4 relative">
        <slot />
      </div>
      <div class="p-4 relative text-center">
        <div class="text-xs">
          Copyright &copy; {{ currentYear }} TFA, All rights reserved.
          <a
            class="text-blue-600 underline hover:text-blue-800"
            href="https://www.thefuturesapp.com/terms-of-service"
            target="_blank"
            >Terms of Service</a
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import AppLogo from "@/shared/components/AppLogo.vue";
import AdminAvatar from "@/shared/components/AdminAvatar.vue";
import MenuList from "@/shared/components/MenuList.vue";
import Menu from "primevue/menu";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { useAuthStore } from "@/modules/auth/stores/auth";
import { watch, ref, onMounted, computed } from "vue";
import { useWindowSize } from "@vueuse/core";
const { logout, defaultOrg } = useAuthStore();
import router from "@/router";
import type { MenuItem } from "primevue/menuitem";
import TFABreadcrumb from "@/shared/components/TFABreadcrumb.vue";

interface Props {
  title?: string;
  breadcrumb?: MenuItem[];
}
const props = withDefaults(defineProps<Props>(), {
  title: "Page",
  breadcrumb: () => [],
});

const { width } = useWindowSize();

const isLargeScreen = computed(() => width.value >= 992);
const isOpenSidebar = ref(true);

watch(isLargeScreen, (isLarge) => {
  isOpenSidebar.value = isLarge;
});

onMounted(async () => {
  if (!defaultOrg?.id) {
    await logout();
  }

  if (!isLargeScreen.value) {
    isOpenSidebar.value = false;
  }
});

const menu = ref();
const toggle = (event: any) => {
  menu.value.toggle(event);
};
const items = ref([
  {
    label: "Profile",
    icon: "pi pi-user",
    command: async () => {
      await router.push({
        name: ROUTE_NAME.PROFILE_UPDATE,
      });
    },
  },
  {
    label: "Logout",
    icon: "pi pi-sign-out",
    command: () => {
      logout();
    },
  },
]);

const currentYear = new Date().getFullYear();
</script>

<style scoped>
.app-sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 100vh;
  transform: translateX(-240px);
  transition: transform 0.3s ease-in-out;
}

.app-sidebar.opened {
  transform: translateX(0);
}

.app-content {
  width: 100%;
  transition:
    width 0.3s ease-in-out,
    margin-left 0.3s ease-in-out;
}

.app-content.opened {
  margin-left: 240px;
  width: calc(100% - 240px);
}
</style>
