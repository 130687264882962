<template>
  <AppButton
    label="Create Calendar"
    @click="
      visible = true;
      v$.$reset();
    "
  />
  <Dialog
    v-model:visible="visible"
    modal
    header="Create Calendar"
    :closable="true"
    :style="{ width: '30vw' }"
  >
    <InputText
      id="workoutName"
      v-model="form.workoutName"
      type="text"
      placeholder="Calendar name"
      class="w-full"
      autocomplete="off"
    />
    <small v-if="v$ErrorMessage(v$.workoutName.$errors)" class="text-red-500">
      {{ v$ErrorMessage(v$.workoutName.$errors) }}
    </small>

    <div class="mt-12 flex items-center">
      <Checkbox
        v-model="form.availableToAllAthletes"
        input-id="availableToAllAthletes"
        name="availableToAllAthletes"
        :binary="true"
      />
      <label for="availableToAllAthletes" class="ml-2">
        Available to all athletes
      </label>
    </div>

    <div class="flex justify-end mt-12">
      <AppButton
        label="Confirm"
        @click="async () => ((await v$.$validate()) ? onConfirm() : null)"
      />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import { catchErrorMessage } from "@/shared/utils/custom-errors";
import { onMounted, ref, reactive, computed } from "vue";
import { useToast } from "primevue/usetoast";
import { createCalendar } from "../api";
import type { AdminCalendar } from "@/api/model";
import Checkbox from "primevue/checkbox";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";

const toast = useToast();

const emit = defineEmits<{
  (e: "created", calendar: AdminCalendar): void;
}>();

export interface Props {
  organizationId: number;
}

const props = withDefaults(defineProps<Props>(), {
  organizationId: -1,
});

const visible = ref(false);

let form = reactive({
  workoutName: "",
  availableToAllAthletes: true,
});

const rules = computed(() => ({
  workoutName: { required },
}));

const v$ = useVuelidate(rules, form);

onMounted(async () => {});

const onConfirm = async () => {
  try {
    if (!form.workoutName) {
      return;
    }

    addMixpanelEvent(EVENT_NAMES.Workouts.CalendarCreated, {
      availableToAll: form.availableToAllAthletes,
    });
    const data = await createCalendar({
      type_name: form.workoutName,
      is_private: form.availableToAllAthletes === true ? false : true,
      with_weights: false,
    });
    if ("error" in data) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: data.error,
        life: 10000,
      });
    } else {
      emit("created", data);
      form.workoutName = "";
      visible.value = false;

      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Calendar created.",
        life: 10000,
      });
    }
  } catch (e: unknown) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: catchErrorMessage(e),
      life: 3000,
    });
  }
};
</script>
