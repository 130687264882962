import { RouterView, type RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import WorkoutList from "./views/WorkoutList.vue";
import WorkoutUpdate from "./views/WorkoutUpdate.vue";
import WorkoutCreate from "./views/WorkoutCreate.vue";
import ExerciseList from "./views/ExerciseList.vue";
import ExerciseUpdate from "./views/ExerciseUpdate.vue";
import ExerciseCreate from "./views/ExerciseCreate.vue";
import { h } from "vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/calendars",
    name: ROUTE_NAME.CALENDARS,
    component: { render: () => h(RouterView) },
    redirect: { name: ROUTE_NAME.WORKOUT_LIST },
    children: [
      {
        path: "workouts",
        name: ROUTE_NAME.WORKOUTS,
        component: { render: () => h(RouterView) },
        redirect: { name: ROUTE_NAME.WORKOUT_LIST },
        children: [
          {
            path: "",
            name: ROUTE_NAME.WORKOUT_LIST,
            component: WorkoutList,
          },
          {
            path: ":id",
            name: ROUTE_NAME.WORKOUT_UPDATE,
            component: WorkoutUpdate,
          },
          {
            path: "create/:day/:type/:weights",
            name: ROUTE_NAME.WORKOUT_CREATE,
            component: WorkoutCreate,
          },
        ],
      },
      {
        path: "exercises",
        name: ROUTE_NAME.EXERCISES,
        component: { render: () => h(RouterView) },
        redirect: { name: ROUTE_NAME.EXERCISE_LIST },
        children: [
          {
            path: "",
            name: ROUTE_NAME.EXERCISE_LIST,
            component: ExerciseList,
          },
          {
            path: ":id",
            name: ROUTE_NAME.EXERCISE_UPDATE,
            component: ExerciseUpdate,
          },
          {
            path: "create",
            name: ROUTE_NAME.EXERCISE_CREATE,
            component: ExerciseCreate,
          },
        ],
      },
    ],
  },
];
