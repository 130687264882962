/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminCalendar,
  AdminV1CalendarsListParams,
  PaginatedAdminCalendarList,
  PatchedAdminCalendar
} from '.././model'
import adminV1CalendarsListMutator from '../../shared/services/axios-client';
import adminV1CalendarsCreateMutator from '../../shared/services/axios-client';
import adminV1CalendarsRetrieveMutator from '../../shared/services/axios-client';
import adminV1CalendarsUpdateMutator from '../../shared/services/axios-client';
import adminV1CalendarsPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1CalendarsDestroyMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1CalendarsList = (
    params?: AdminV1CalendarsListParams,
 options?: SecondParameter<typeof adminV1CalendarsListMutator>,) => {
      return adminV1CalendarsListMutator<PaginatedAdminCalendarList>(
      {url: `/api/admin/v1/calendars/`, method: 'GET',
        params
    },
      options);
    }
  export const adminV1CalendarsCreate = (
    adminCalendar: NonReadonly<AdminCalendar>,
 options?: SecondParameter<typeof adminV1CalendarsCreateMutator>,) => {
      return adminV1CalendarsCreateMutator<AdminCalendar>(
      {url: `/api/admin/v1/calendars/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminCalendar
    },
      options);
    }
  export const adminV1CalendarsRetrieve = (
    id: number,
 options?: SecondParameter<typeof adminV1CalendarsRetrieveMutator>,) => {
      return adminV1CalendarsRetrieveMutator<AdminCalendar>(
      {url: `/api/admin/v1/calendars/${id}/`, method: 'GET'
    },
      options);
    }
  export const adminV1CalendarsUpdate = (
    id: number,
    adminCalendar: NonReadonly<AdminCalendar>,
 options?: SecondParameter<typeof adminV1CalendarsUpdateMutator>,) => {
      return adminV1CalendarsUpdateMutator<AdminCalendar>(
      {url: `/api/admin/v1/calendars/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminCalendar
    },
      options);
    }
  export const adminV1CalendarsPartialUpdate = (
    id: number,
    patchedAdminCalendar: NonReadonly<PatchedAdminCalendar>,
 options?: SecondParameter<typeof adminV1CalendarsPartialUpdateMutator>,) => {
      return adminV1CalendarsPartialUpdateMutator<AdminCalendar>(
      {url: `/api/admin/v1/calendars/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminCalendar
    },
      options);
    }
  /**
 * **Warning: Deleting a Calendar will delete all it's Workouts and StartedWorkouts of this Calendar. This action cannot be undone. Make huge warning on frontend!**
 * @summary Delete Calendar. Please read description!
 */
export const adminV1CalendarsDestroy = (
    id: number,
 options?: SecondParameter<typeof adminV1CalendarsDestroyMutator>,) => {
      return adminV1CalendarsDestroyMutator<void>(
      {url: `/api/admin/v1/calendars/${id}/`, method: 'DELETE'
    },
      options);
    }
  export type AdminV1CalendarsListResult = NonNullable<Awaited<ReturnType<typeof adminV1CalendarsList>>>
export type AdminV1CalendarsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1CalendarsCreate>>>
export type AdminV1CalendarsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1CalendarsRetrieve>>>
export type AdminV1CalendarsUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1CalendarsUpdate>>>
export type AdminV1CalendarsPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1CalendarsPartialUpdate>>>
export type AdminV1CalendarsDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1CalendarsDestroy>>>
