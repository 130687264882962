<template>
  <BaseLayout
    :title="title ? `Training: ${title}` : 'Create Collection'"
    :breadcrumb="[
      {
        label: 'Training',
        to: { name: ROUTE_NAME.TRAINING_COLLECTION_LIST },
      },
      {
        label: title,
      },
    ]"
  >
    <InitialPageLoader
      :loading="isLoadingInitial"
      list
      :message="!data ? 'Training Collection not found.' : undefined"
    />
    <TrainingCollectionForm
      v-if="!isLoadingInitial && data"
      :key="data?.id"
      :organization-id="organization_id"
      :initial-data="data"
    />
  </BaseLayout>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";

import InitialPageLoader from "@/shared/components/InitialPageLoader.vue";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import TrainingCollectionForm from "@/modules/trainingCollections/components/TrainingCollectionForm.vue";

import { getSingle } from "@/modules/trainingCollections/api";
import type { AdminTrainingCollection } from "@/api/model";
import { ROUTE_NAME } from "../../../shared/constants/routes";
import { TRAINING_COLLECTIONS } from "@/modules/trainingCollections/constants";

const route = useRoute();
const toast = useToast();

const isLoadingInitial = ref<boolean>(false);
const data = ref<
  Pick<AdminTrainingCollection, "name" | "id" | "organization_id">
>({
  name: "",
  id: -1,
  organization_id: -1,
});
let organization_id = ref(-1);

const trainingCollectionInfo = (d: AdminTrainingCollection) => {
  data.value.first_name = d?.first_name;
  data.value.last_name = d?.last_name;
};

const hasFetchedContent = computed(() => {
  return data.value?.id !== -1;
});

const title = computed(() => {
  if (!hasFetchedContent.value) {
    return "";
  } else {
    return data.value?.name ?? "Create Training";
  }
});

const fetchTrainingCollection = async () => {
  isLoadingInitial.value = true;

  const results = await getSingle(parseInt(route.params.id.toString()));
  if ("error" in results) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: results.error,
      life: 10000,
    });
  } else {
    data.value = results;
  }

  organization_id.value = data.value.organization_id;

  isLoadingInitial.value = false;
};

onMounted(async () => {
  await fetchTrainingCollection();
});
</script>
