<template>
  <div>Logging out</div>
</template>
;

<script setup lang="ts">
import { onMounted } from "vue";
import { useAuthStore } from "@/modules/auth/stores/auth";
const { logout } = useAuthStore();

onMounted(async () => {
  await logout();
});
</script>
