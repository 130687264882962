<template>
  <BaseLayout
    title="Workouts"
    :breadcrumb="[
      {
        label: 'Workouts',
        to: {
          name: ROUTE_NAME.WORKOUT_LIST,
          query: { cid: calendarInfo?.calendarId, page: paginationNum },
        },
      },
    ]"
  >
    <div class="grid grid-cols-3 mb-6 items-center">
      <div class="col justify-start">
        <CalendarSelect
          :organization-id="organizationSelection"
          :refresh-calendar="refresh_calendar"
          @change="onCalendarSelect"
        />
      </div>
      <div class="col flex justify-center">
        <div class="text-lg">
          <strong>{{ selectedCalendar?.type?.name }}</strong>
          <EditCalendarDialog
            :organization-id="organizationSelection"
            :calendar-info="calendarInfo"
            @edited="onCalendarEdit"
          />
        </div>
      </div>
      <div class="col flex justify-end">
        <CreateCalendarDialog
          :organization-id="organizationSelection"
          @created="onCalendarCreate"
        />
      </div>
    </div>

    <div
      v-if="selectedCalendar"
      class="workout-grid-container animate-fadein animate-duration-1000"
    >
      <div class="workout-grid">
        <template v-for="(row, i) in tableData" :key="i">
          <div v-for="(item, k) in row" :key="k" class="workout-grid-item">
            <TableBlock
              :copy-workout-id="copyWorkoutId"
              :data="item"
              :loading="isFetchingWorkouts"
              :calendar-id="selectedCalendar?.id"
              :weights="selectedCalendar?.with_weights"
              :organization-id="organizationSelection"
              @paste-workout="
                (d) => pasteWorkout(d.copyToDay, selectedCalendar?.id)
              "
              @copy-workout="(d) => copyWorkout(d.workoutId)"
              @delete-workout="(d) => confirmDeleteWorkout(d.workoutId)"
            />
          </div>
        </template>
      </div>
    </div>

    <Paginator
      v-if="selectedCalendar"
      :always-show="true"
      :rows="resultsPerPage"
      :total-records="totalRecords"
      :first="first"
      template="
        FirstPageLink
        PrevPageLink
        CurrentPageReport
        NextPageLink
        LastPageLink
        RowsPerPageDropdown"
      current-page-report-template="Showing Weeks {first} to {last} of {totalRecords}"
      @page="onPageChange"
    />
  </BaseLayout>
</template>

<script setup lang="ts">
import BaseLayout from "@/shared/components/BaseLayout.vue";
import TableBlock from "../components/TableBlock.vue";
import CalendarSelect from "../components/CalendarSelect.vue";
import Paginator from "primevue/paginator";
import CreateCalendarDialog from "../components/CreateCalendarDialog.vue";
import EditCalendarDialog from "../components/EditCalendarDialog.vue";
import { chunk } from "lodash";
import { ref, computed, onMounted } from "vue";
import type { WorkoutRow } from "@/shared/datamodels/workout";
import {
  getWorkouts,
  deleteWorkout,
  cloneWorkout,
} from "@/modules/workouts/api";
import { WorkoutRowType } from "@/shared/datamodels/workout";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { useRoute } from "vue-router";
import router from "@/router";

import { useAuthStore } from "@/modules/auth/stores/auth";
import type {
  AdminCalendar,
  AdminWorkout,
  CopyWorkoutResponse,
  AdminV1WorkoutsListParams,
} from "@/api/model";
import { ROUTE_NAME } from "../../../shared/constants/routes";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";
const { defaultOrg } = useAuthStore();
import { FIRST_CALENDAR_CELL_DAY } from "@/shared/constants/workouts";

const calendarInfo = computed(() => {
  const selectedCalendarId = selectedCalendar.value?.id;

  // update the page with proper query string
  if (selectedCalendarId) {
    router.push({
      query: {
        cid: selectedCalendarId,
        page: paginationNum.value,
      },
    });
    filter.value.calendar_id = selectedCalendarId;
  }

  return {
    calendarId: selectedCalendarId,
    calendarOrginalName: selectedCalendar.value?.type?.name,
    availableToAllAthletes:
      selectedCalendar.value?.is_private === true ? false : true,
    calendarNewName: "",
  };
});

const route = useRoute();

let paginationNum = ref(parseInt((route?.query?.page || "").toString()) || 1);

const resultsPerPage = 4;
let totalRecords = 100;

const first = computed(
  () => paginationNum.value * resultsPerPage - resultsPerPage
);

interface onPageChangeType {
  page: number;
}

const onPageChange = async (e: onPageChangeType) => {
  paginationNum.value = e.page + 1;

  await fetch();
};

const onCalendarEdit = async (d: AdminCalendar) => {
  if (d?.id) {
    filter.value.calendar_id = d?.id;
    refresh_calendar.value = `${d?.id}-${d?.type?.name}`;
    selectedCalendar.value = d;
  }
};

const cid = parseInt((route?.query?.cid || "").toString() || "-1");

const refresh_calendar = ref(cid + "-default");

let copyWorkoutId = ref();
const copyWorkout = (workoutId: number) => {
  copyWorkoutId.value = workoutId;
};

interface errorMessage {
  error: string;
}

const pasteWorkout = async (copyToDay: number, calendarId?: number) => {
  if (
    copyWorkoutId.value &&
    (copyToDay || copyToDay === FIRST_CALENDAR_CELL_DAY)
  ) {
    addMixpanelEvent(EVENT_NAMES.Workouts.WorkoutPasted);
    const result: CopyWorkoutResponse | errorMessage = await cloneWorkout(
      copyWorkoutId.value,
      copyToDay,
      calendarId
    );
    if ("error" in result) {
      showToast({ msg: result.error });
    } else {
      await fetch();
    }
  }
};

const confirm = useConfirm();
const confirmDeleteWorkout = (workoutId: number) => {
  confirm.require({
    message: "Are you sure you want to delete this workout?",
    header: "Delete Workout",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        const result = await deleteWorkout(workoutId);
        if (typeof result === "object" && "error" in result) {
          showToast({ msg: result.error });
        } else {
          showToast({
            severity: "success",
            msg: "Deleted workout.",
            summary: "Success",
          });
          await fetch();
        }
      } catch (e: any) {
        showToast({ msg: e.error });
      }
    },
  });
};

interface showToastType {
  severity?: "success" | "info" | "warn" | "error" | undefined;
  summary?: string;
  msg: string;
  life?: number;
}

const toast = useToast();
const showToast = (data: showToastType) => {
  toast.add({
    severity: data.severity ?? "error",
    summary: data?.summary ?? "Error",
    detail: data.msg,
    life: data?.life ?? 10000,
  });
};

let filter = ref<AdminV1WorkoutsListParams>({
  day_end: 28,
  day_start: 0,
  calendar_id: -1,
});
const selectedCalendar = ref<AdminCalendar>();
const isFetchingWorkouts = ref(false);
const workouts = ref<AdminWorkout[]>([]);
const lastDay = ref<number>();
const weekNumberStart = ref<number>(0);

const fetch = async () => {
  isFetchingWorkouts.value = true;

  filter.value.day_start =
    (paginationNum.value * resultsPerPage - resultsPerPage) * 7;
  filter.value.day_end = filter.value.day_start + 28;

  const data = await getWorkouts(filter.value);

  if ("error" in data) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: data.error,
      life: 10000,
    });
  } else {
    workouts.value = data.results || [];
    lastDay.value = data.last_day || 28;
    weekNumberStart.value = (filter.value.day_start || 0) / 7 + 1;
  }

  isFetchingWorkouts.value = false;
};

const rows = computed(() => {
  const arr: WorkoutRow[] = [];

  if (filter.value?.day_start == null || filter.value?.day_end == null) {
    return arr;
  }

  for (let i = filter.value.day_start; i < filter.value.day_end; i++) {
    const workout = workouts.value.find((e) => e.day === i);

    arr.push({
      day: i,
      workout: workout,
      type: WorkoutRowType.workout,
    });
  }

  const chunks = chunk(arr, 7);
  for (let i = 0; i < chunks.length; i++) {
    chunks[i].unshift({
      label: `Week ${weekNumberStart.value + i}`,
      day: undefined,
      type: WorkoutRowType.week,
    });
  }
  return chunks;
});

const tableData = computed(() => {
  if (!filter.value.calendar_id) {
    return [];
  }
  const dayNames = [
    "",
    "Day 1",
    "Day 2",
    "Day 3",
    "Day 4",
    "Day 5",
    "Day 6",
    "Day 7",
  ];
  const days = dayNames.map((e) => ({
    day: undefined,
    label: e,
    type: WorkoutRowType.day,
  }));
  const items = [days, ...rows.value];
  return items;
});

const onCalendarSelect = async (calendar: AdminCalendar) => {
  filter.value.calendar_id = calendar.id;

  await fetch();
  selectedCalendar.value = calendar;
};

const onCalendarCreate = async (calendar: AdminCalendar) => {
  if (calendar?.id) {
    filter.value.calendar_id = calendar?.id;
    selectedCalendar.value = calendar;
    refresh_calendar.value = `${calendar?.id}-${calendar?.type?.name}`;

    // reset the week to 1
    paginationNum.value = 1;
  }
};

let organizationSelection = ref(defaultOrg.id);
</script>

<style scoped>
.workout-grid-container {
  overflow-x: auto;
  width: 100%;
}

.workout-grid {
  display: grid;
  grid-template-columns: 30px repeat(7, 1fr); /* 8 columns total */
  grid-template-rows: 30px repeat(7, 140px); /* 8 rows total */
  min-width: 800px;
  max-height: 600px;
}

.workout-grid-item {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.workout-grid-item:nth-child(9),
.workout-grid-item:nth-child(17),
.workout-grid-item:nth-child(25),
.workout-grid-item:nth-child(33) {
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}

.workout-grid-item:nth-child(-n + 8) {
  display: flex;
  align-items: center;
  background: black;
  color: white;
}
</style>
