<template>
  <AppCard class="mb-6">
    <div
      v-for="(item, index) in lineItems"
      :key="index"
      class="grid grid-cols-12 gap-6 mb-2"
    >
      <div class="col flex">
        <div class="input-group flex-col">
          <label
            v-if="index === 0"
            class="input-label"
            :for="'itemName' + index"
            >Item Name</label
          >
          <InputText
            :id="'itemName' + index"
            v-model="item.itemName"
            type="text"
            placeholder="Item Name"
          />

          <small
            v-if="
              v$ErrorMessage(
                fieldValidations?.lineItems?.$errors?.[0]?.$response?.$errors?.[
                  index
                ]?.itemName
              )
            "
            class="error-message"
          >
            {{
              v$ErrorMessage(
                fieldValidations?.lineItems?.$errors?.[0]?.$response?.$errors?.[
                  index
                ]?.itemName
              )
            }}
          </small>
        </div>

        <div class="input-group flex-col">
          <label
            v-if="index === 0"
            class="input-label"
            :for="'itemPrice' + index"
            >Price</label
          >
          <InputNumber
            :id="'itemPrice' + index"
            v-model="item.itemPrice"
            type="number"
            placeholder="Price"
            mode="currency"
            currency="USD"
            locale="en-US"
          />

          <small
            v-if="
              v$ErrorMessage(
                fieldValidations?.lineItems?.$errors?.[0]?.$response?.$errors?.[
                  index
                ]?.itemPrice
              )
            "
            class="error-message"
          >
            {{
              v$ErrorMessage(
                fieldValidations?.lineItems?.$errors?.[0]?.$response?.$errors?.[
                  index
                ]?.itemPrice
              )
            }}
          </small>
        </div>

        <div class="input-group flex-col">
          <label
            v-if="index === 0"
            class="input-label"
            :for="'itemQuantity' + index"
            >Qty</label
          >
          <InputNumber
            :id="'itemQuantity' + index"
            v-model="item.itemQuantity"
            type="number"
            class="quantity-input"
          />

          <small
            v-if="
              v$ErrorMessage(
                fieldValidations?.lineItems?.$errors?.[0]?.$response?.$errors?.[
                  index
                ]?.itemQuantity
              )
            "
            class="error-message"
          >
            {{
              v$ErrorMessage(
                fieldValidations?.lineItems?.$errors?.[0]?.$response?.$errors?.[
                  index
                ]?.itemQuantity
              )
            }}
          </small>
        </div>

        <div class="input-group flex-col">
          <label
            v-if="index === 0"
            class="input-label"
            :for="'itemCategory' + index"
            >Category</label
          >
          <Select
            v-model="item.itemCategory"
            :options="categoryOptions"
            option-label="label"
            option-value="id"
            placeholder="Category"
            class="category-input"
          />

          <small
            v-if="
              v$ErrorMessage(
                fieldValidations?.lineItems?.$errors?.[0]?.$response?.$errors?.[
                  index
                ]?.itemCategory
              )
            "
            class="error-message"
          >
            {{
              v$ErrorMessage(
                fieldValidations?.lineItems?.$errors?.[0]?.$response?.$errors?.[
                  index
                ]?.itemCategory
              )
            }}
          </small>
        </div>
        <div v-if="index != 0" class="flex items-center">
          <Button
            icon="pi pi-times"
            severity="danger"
            text
            rounded
            aria-label="Cancel"
            @click="removeItem(index)"
          />
        </div>
      </div>
    </div>

    <AppButton
      text
      icon="pi pi-plus"
      label="Add new item"
      class="shrink-0"
      @click="addNewItem"
    />
  </AppCard>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Select from "primevue/select";
import Button from "primevue/button";

const confirm = useConfirm();

import type { LineItem } from "@/types";

import { v$ErrorMessage } from "@/shared/utils/helpers";
import { BLANK_INVOICE_LINE_ITEM } from "../constants";
import { useConfirm } from "primevue/useconfirm";

const categoryOptions = [
  { label: "Fees", id: "fees" },
  { label: "Lesson", id: "lesson" },
  { label: "Tournament", id: "tournament" },
  { label: "Uniform", id: "uniform" },
  { label: "Other", id: "other" },
];

const lineItems = defineModel<LineItem[]>("lineItems", { required: true });

const addNewItem = () => {
  lineItems.value.push({ ...BLANK_INVOICE_LINE_ITEM });
};

const removeItem = (index: number) => {
  const lineItem = lineItems.value[index];

  confirm.require({
    message: `Are you sure you want to remove ${lineItem.itemName}?`,
    header: "Delete invoice item",
    rejectLabel: "Cancel",
    acceptLabel: "Delete Item",
    accept: () => {
      lineItems.value.splice(index, 1);
    },
  });
};

defineProps({
  fieldValidations: {
    type: Object,
    default: {},
  },
});
</script>

<style scoped>
.error-message {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #e3342f;
  display: flex;
  align-items: right;
  opacity: 0.9;
}

.input-label {
  margin-bottom: 10px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.quantity-input :deep(.p-inputtext) {
  width: 75px;
}

.category-input :deep(.p-inputtext) {
  width: 150px;
}
</style>
