/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminOrderStatCategories,
  AdminStatCategory,
  AdminStatCategoryCount,
  AdminV1StatCategoriesListParams,
  PaginatedAdminStatCategoryList,
  PatchedAdminStatCategory,
  StatCategoriesOrderResponse
} from '.././model'
import adminV1StatCategoriesListMutator from '../../shared/services/axios-client';
import adminV1StatCategoriesCreateMutator from '../../shared/services/axios-client';
import adminV1StatCategoriesRetrieveMutator from '../../shared/services/axios-client';
import adminV1StatCategoriesUpdateMutator from '../../shared/services/axios-client';
import adminV1StatCategoriesPartialUpdateMutator from '../../shared/services/axios-client';
import adminV1StatCategoriesDestroyMutator from '../../shared/services/axios-client';
import adminV1StatCategoriesCountRetrieveMutator from '../../shared/services/axios-client';
import adminV1StatCategoriesUpdateOrderCreateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1StatCategoriesList = (
    params?: AdminV1StatCategoriesListParams,
 options?: SecondParameter<typeof adminV1StatCategoriesListMutator>,) => {
      return adminV1StatCategoriesListMutator<PaginatedAdminStatCategoryList>(
      {url: `/api/admin/v1/stat-categories/`, method: 'GET',
        params
    },
      options);
    }
  export const adminV1StatCategoriesCreate = (
    adminStatCategory: NonReadonly<AdminStatCategory>,
 options?: SecondParameter<typeof adminV1StatCategoriesCreateMutator>,) => {
      return adminV1StatCategoriesCreateMutator<AdminStatCategory>(
      {url: `/api/admin/v1/stat-categories/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminStatCategory
    },
      options);
    }
  export const adminV1StatCategoriesRetrieve = (
    id: number,
 options?: SecondParameter<typeof adminV1StatCategoriesRetrieveMutator>,) => {
      return adminV1StatCategoriesRetrieveMutator<AdminStatCategory>(
      {url: `/api/admin/v1/stat-categories/${id}/`, method: 'GET'
    },
      options);
    }
  export const adminV1StatCategoriesUpdate = (
    id: number,
    adminStatCategory: NonReadonly<AdminStatCategory>,
 options?: SecondParameter<typeof adminV1StatCategoriesUpdateMutator>,) => {
      return adminV1StatCategoriesUpdateMutator<AdminStatCategory>(
      {url: `/api/admin/v1/stat-categories/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminStatCategory
    },
      options);
    }
  export const adminV1StatCategoriesPartialUpdate = (
    id: number,
    patchedAdminStatCategory: NonReadonly<PatchedAdminStatCategory>,
 options?: SecondParameter<typeof adminV1StatCategoriesPartialUpdateMutator>,) => {
      return adminV1StatCategoriesPartialUpdateMutator<AdminStatCategory>(
      {url: `/api/admin/v1/stat-categories/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminStatCategory
    },
      options);
    }
  export const adminV1StatCategoriesDestroy = (
    id: number,
 options?: SecondParameter<typeof adminV1StatCategoriesDestroyMutator>,) => {
      return adminV1StatCategoriesDestroyMutator<void>(
      {url: `/api/admin/v1/stat-categories/${id}/`, method: 'DELETE'
    },
      options);
    }
  /**
 * Get the count of stats for a stat category.
 * @summary Get the count of stats for a stat category.
 */
export const adminV1StatCategoriesCountRetrieve = (
    id: number,
 options?: SecondParameter<typeof adminV1StatCategoriesCountRetrieveMutator>,) => {
      return adminV1StatCategoriesCountRetrieveMutator<AdminStatCategoryCount>(
      {url: `/api/admin/v1/stat-categories/${id}/count/`, method: 'GET'
    },
      options);
    }
  export const adminV1StatCategoriesUpdateOrderCreate = (
    adminOrderStatCategories: AdminOrderStatCategories[],
 options?: SecondParameter<typeof adminV1StatCategoriesUpdateOrderCreateMutator>,) => {
      return adminV1StatCategoriesUpdateOrderCreateMutator<StatCategoriesOrderResponse>(
      {url: `/api/admin/v1/stat-categories/update_order/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminOrderStatCategories
    },
      options);
    }
  export type AdminV1StatCategoriesListResult = NonNullable<Awaited<ReturnType<typeof adminV1StatCategoriesList>>>
export type AdminV1StatCategoriesCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1StatCategoriesCreate>>>
export type AdminV1StatCategoriesRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1StatCategoriesRetrieve>>>
export type AdminV1StatCategoriesUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1StatCategoriesUpdate>>>
export type AdminV1StatCategoriesPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1StatCategoriesPartialUpdate>>>
export type AdminV1StatCategoriesDestroyResult = NonNullable<Awaited<ReturnType<typeof adminV1StatCategoriesDestroy>>>
export type AdminV1StatCategoriesCountRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1StatCategoriesCountRetrieve>>>
export type AdminV1StatCategoriesUpdateOrderCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1StatCategoriesUpdateOrderCreate>>>
