<template>
  <div class="space-y-4">
    <div class="flex justify-end flex-wrap mb-6">
      <Button label="Save" :disabled="!hasChanges" @click="handleSubmit" />
    </div>
    <div class="flex items-center space-x-4">
      <label for="workout" class="block w-64 text-md font-medium text-gray-700">
        Workout
      </label>
      <div class="col">
        <Select
          v-model="workoutCalendarId"
          data-testid="workout-calendar-dropdown"
          class="mt-1 block w-80 border-gray-300 rounded-md shadow-sm"
          :options="allWorkoutCalendars"
          placeholder="Select workout calendar"
          option-label="name"
          option-value="id"
        />
      </div>
    </div>

    <div class="flex items-center space-x-4">
      <label for="workout" class="block w-64 text-md font-medium text-gray-700">
        Workout Start Date
      </label>
      <div class="col">
        <DatePicker
          v-model="workoutStartDate"
          class="mt-1 block w-64 border-gray-300 rounded-md shadow-sm"
          data-testid="workout-start-date-calendar"
          show-icon
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from "primevue/button";
import DatePicker from "primevue/datepicker";
import Select from "primevue/select";
import { useToast } from "primevue/usetoast";
import {
  assignWorkoutCalendar,
  getSelectedWorkoutTypes,
  getAssignedWorkoutCalendar,
  getCalendars,
} from "@/modules/workouts/api";
import { onMounted, ref, watch, type Ref } from "vue";
import { EVENT_NAMES, addMixpanelEvent } from "@/shared/utils/analytics";

type SelectedWorkoutTypeForm = {
  workoutCalendarId: Ref<number>;
  workoutStartDate: Ref<Date>;
};

type WorkoutCalendarType = {
  id: number;
  name: string;
};

interface showToastType {
  severity?: "success" | "info" | "warn" | "error" | undefined;
  summary?: string;
  msg: string;
  life?: number;
}

const { userId } = defineProps<{
  userId: number;
}>();

const previousWorkoutCalendarId: Ref<number | undefined> = ref();
const previousWorkoutStartDate: Ref<Date | undefined> = ref();
const allWorkoutCalendars: Ref<{ id: number; name: string }[]> = ref([]);

const workoutCalendarId = ref<number>(0);
const workoutStartDate = ref<Date>(new Date());

const form = ref<SelectedWorkoutTypeForm>({
  workoutCalendarId,
  workoutStartDate,
});

const hasChanges: Ref<boolean> = ref(false);
const startDateChanged = ref(false);
const calendarChanged = ref(false);

watch(
  form,
  (newValue, _) => {
    calendarChanged.value =
      newValue.workoutCalendarId !== previousWorkoutCalendarId.value;
    startDateChanged.value =
      newValue.workoutStartDate !== previousWorkoutStartDate.value;
    hasChanges.value = calendarChanged.value || startDateChanged.value;
  },
  { deep: true }
);

const computeTypeOfChange = () => {
  if (calendarChanged.value) {
    return "calendar selection";
  } else if (startDateChanged.value) {
    return "start date";
  }
  return "";
};

watch(
  workoutCalendarId,
  () => {
    workoutStartDate.value = new Date();
  },
  { deep: true }
);

const handleSubmit = async () => {
  try {
    const response = await assignWorkoutCalendar({
      profile_id: userId,
      workout_type_id: form.value.workoutCalendarId as number,
      seed_date: dateToString(form.value.workoutStartDate),
    });

    if ("error" in response) {
      showToast({ msg: response.error });
      return;
    }

    showToast({
      msg: "Workout calendar assigned successfully.",
      severity: "success",
      summary: "Success",
      life: 10000,
    });

    const changeType = computeTypeOfChange();
    addMixpanelEvent(EVENT_NAMES.Workouts.CalendarAssigned, {
      type: changeType,
    });

    hasChanges.value = false;
    calendarChanged.value = false;
    startDateChanged.value = false;
  } catch (error) {
    console.log("API Error: ", error);
  }
};

const toast = useToast();
const showToast = (data: showToastType) => {
  toast.add({
    severity: data.severity ?? "error",
    summary: data?.summary ?? "Error",
    detail: data?.msg,
    life: data.life ?? 10000,
  });
};

const parseLocalDate = (date: string) => {
  const [year, month, day] = date.split("-");
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

const dateToString = (seedDate: Date) => {
  const year = seedDate.getFullYear();
  const month = String(seedDate.getMonth() + 1);
  const day = String(seedDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const getAllWorkoutCalendars = async () => {
  const results = await getCalendars({
    limit: 50,
    offset: 0,
  });

  if ("error" in results) {
    showToast({ msg: results.error });
    return [];
  } else {
    const allResults = results?.results || [];
    if (allResults.length > 0) {
      return allResults
        .map((d) => {
          if (d?.type?.id && d?.type?.name) {
            return { id: d.type.id, name: d.type.name };
          }
          return undefined;
        })
        .filter((d): d is WorkoutCalendarType => d !== undefined);
    } else {
      return [];
    }
  }
};

const setupSelectedWorkoutSeedDate = async () => {
  const getSelectedWorkoutTypesData = await getSelectedWorkoutTypes({
    profile_id: userId,
  });

  if ("error" in getSelectedWorkoutTypesData) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: getSelectedWorkoutTypesData?.error,
      life: 10000,
    });
  } else {
    const results = getSelectedWorkoutTypesData?.results || [];
    const seedDate = results[0]?.seed_date;
    form.value.workoutStartDate = seedDate
      ? parseLocalDate(seedDate)
      : new Date();
    previousWorkoutStartDate.value = form.value.workoutStartDate;
  }
};

const setupWorkoutCalendar = async () => {
  allWorkoutCalendars.value = await getAllWorkoutCalendars();

  const workoutCalendarData = await getAssignedWorkoutCalendar({
    profile_id: userId,
    is_active: true,
  });

  if ("error" in workoutCalendarData) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: workoutCalendarData?.error,
      life: 10000,
    });
  } else {
    const results = workoutCalendarData?.results || [];
    if (results.length === 1) {
      form.value.workoutCalendarId = results[0]?.workout_type?.id;
      previousWorkoutCalendarId.value = results[0]?.workout_type?.id;
    }
  }
};

onMounted(async () => {
  setupWorkoutCalendar();
  setupSelectedWorkoutSeedDate();
});
</script>
