import type { AdminStatCategory } from "@/api/model";
import { ref, type Ref } from "vue";
import { updateStatCategory as apiUpdateStatCategory } from "../api";

export function useUpdateStatCategory(id: number): {
  statCategoryUpdateError: Ref<string | null>;
  updateStatCategory: (params: NonReadonly<AdminStatCategory>) => Promise<void>;
  statCategory: Ref<AdminStatCategory | null>;
} {
  const statCategoryUpdateError: Ref<string | null> = ref(null);
  const statCategory: Ref<AdminStatCategory | null> = ref(null);

  async function updateStatCategory(params: NonReadonly<AdminStatCategory>) {
    statCategoryUpdateError.value = null;

    const result = await apiUpdateStatCategory(id, params);

    if ("error" in result) {
      statCategoryUpdateError.value = result.error;
    } else {
      statCategory.value = result;
    }
  }

  return {
    statCategoryUpdateError,
    updateStatCategory,
    statCategory,
  };
}
