import type { UpdateInvoice } from "@/api/model";
import { ref, type Ref } from "vue";

import { updateInvoice as updateInvoiceAPI } from "../api";

export function useUpdateInvoice(): {
  invoiceUpdateError: Ref<string | null>;
  updateInvoice: (id: string, params: UpdateInvoice) => void;
  invoice: Ref<UpdateInvoice | null>;
} {
  const invoiceUpdateError = ref<string | null>(null);
  const invoice = ref<UpdateInvoice | null>(null);

  async function updateInvoice(id: string, params: UpdateInvoice) {
    const result = await updateInvoiceAPI(id, params);

    if ("error" in result) {
      invoiceUpdateError.value = result.error;
    } else {
      invoice.value = result;
    }
  }

  return {
    invoiceUpdateError,
    invoice,
    updateInvoice,
  };
}
